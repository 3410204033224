import React, { useEffect, useState } from "react";

function CheckTask(props) {
    const { selectedTask_id } = props;

    const [checkList, setCheckList] = useState([]);

    useEffect(() => {
        const token = window.localStorage.getItem('AUTH_TOKEN');
        fetch(`https://live.avertisystems.com/api/v1/task_new/get_checklist/${selectedTask_id}`, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'Application/json',
                'Accept': 'Application/json',
            }
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                setCheckList(data.data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [selectedTask_id]);

    return (
        <div>
            <div className="mb-3">
                <h5>TaskList</h5>
            </div>
            <div>
                {checkList && checkList.length > 0 ? (
                    checkList.map((item) => (
                        <div className="row" key={item.id}>
                            <div className="col-md-12 d-flex">
                                {item.status === 1 && <Item message={item.name} checked={true} />}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No items found</p>
                )}
            </div>
        </div>
    );
}

function Item({ message, checked }) {
    return (
        <>
            <input type="checkbox" checked={checked} readOnly />
            &nbsp;{message}
            <hr />
        </>
    );
}

export default CheckTask;
