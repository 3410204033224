import React, { useRef, useState } from 'react'
import { Button, Icon, Modal } from 'semantic-ui-react'

const initialForm = { error: { bill_no: '', amount: '', due_date: '', bill_image: '', description: '', bill_frequency:'', bill_type:'', vendor_id:'' } };

function UploadBillModal(props) {
	const [open, setOpen] = useState(false)
	const bill_no = useRef()
	const amount = useRef()
	const due_date = useRef()
	const description = useRef()
	const [bill_frequency,setBill_frequency] = useState('One Time')
	const bill_type = useRef()
	const vendor_id = useRef()

	const [bill_image, setBill_image] = useState(null);
	const [FormError, setFormError] = useState(initialForm)
	const vendorList = props.vendorList

	const handleRadio = radio => event => setBill_frequency(radio)

	function handleSubmit(event) {
		console.log(bill_image)
		event.preventDefault();
		let postData = {
			bill_no: bill_no.current.value,
			amount: amount.current.value,
			due_date: due_date.current.value,
			bill_image: bill_image,
			description: description.current.value,
			vendor_id:vendor_id.current.value,
			bill_type:bill_type.current.value,
			bill_frequency:bill_frequency

		}
		console.log(postData)
		if (ValidateForm(postData)) {
			props.uploadBillForm(postData)
			setOpen(false)
		}else {
			console.log('nai validate hai apka form.')
		}
	}


	function ValidateForm(inputs) {
		let isValid = true;
		let error = { bill_no: '', amount: '', bill_date: '', bill_image: '', bill_frequency:'', bill_type:'', vendor_id:'' };

		if (inputs.amount.length === 0) {
			error.amount = 'Amount is required.'
			isValid = false;
		} else {
			if (inputs.amount.length <= 0) {
				isValid = false;
				error.amount = 'amount must be in greater than 0.'
			} else if (!inputs.amount.match(/^[0-9]+$/)) {
				error.amount = 'amount must be valid digit.'
				isValid = false;
			}
		}

		if(inputs.bill_type===""){
			error.amount = 'Bill type require.'
			isValid = false;
		}

		/* if(inputs.vendor_id===""){
			error.amount = 'Vendor is require.'
			isValid = false;
		} */


		if (inputs.bill_no.length === 0) {
			error.bill_no = 'bill_no is required.'
			isValid = false;
		}

		setFormError({ error });
		return isValid;
	}


	return (
		<Modal
			closeIcon={true}
			open={open}
			trigger={<Button>Upload Bill</Button>}
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			className="modal-responsive modal-sm"
		>
		<Modal.Header className='justify-content-between d-flex'>
			<span className='w-50'>Upload New Bill</span>
			<span className='w-50'>
				<label className='font-weight-light h6 mr-3 text-center'>
					<span className='inputFile'><Icon name='upload' />  Upload Bill
					<input className='inputUpload' type="file" id="myFile" name="bill_image" onChange={e=>{setBill_image(e.target.files[0])}} />
					</span>
				</label>
				{/* <input type="file" name="bill_image" onChange={e=>{setBill_image(e.target.files[0])}} /> */}
			</span>
			</Modal.Header>
		
		<Modal.Content>
			<form className="ui form w-100" onSubmit={handleSubmit}>
			{/* add vendor name and bill no */}
				<div className='column grid two ui mb-1'>
					<div className='column'>
						<div className="field">
							<label className="ui-input-label">Bill Category</label>
							<select aria-expanded="false" tabIndex="0" className="ui-input ui selection dropdown ui-input p-0" ref={bill_type}>
								<option aria-checked="false" aria-selected="false" className="selected item" value="electricity bill">Electricity  Bills</option>
								<option aria-checked="false" aria-selected="false" className="selected item" value="internet bill">Internet Bills</option>
								<option aria-checked="false" aria-selected="false" className="selected item" value="gas bill">Gas Bills</option>
								<option aria-checked="false" aria-selected="false" className="selected item" value="house rent">House Rent</option>
								<option aria-checked="false" aria-selected="false" className="selected item" value="water bill">Water Bills</option>
								<option aria-checked="false" aria-selected="false" className="selected item" value="medicin bill">Medicin Bills</option>
								<option aria-checked="false" aria-selected="false" className="selected item" value="other">Other</option>
							</select>  
							{FormError.error.bill_type.length > 0 && <span className='color-text'>{FormError.error.bill_type}</span>} 
						</div>
					</div>
					<div className='column'>
						<div className="field">
							<label className="ui-input-label">Vendor Name</label>
							<select aria-expanded="false" tabIndex="0" className="ui-input ui selection dropdown ui-input p-0" ref={vendor_id}>
								{
									vendorList.length>0 && vendorList.map((item)=>{
										return(<option aria-checked="false" aria-selected="false" className="selected item" value={item.vendorid} key={item}>{item.vendor_name}</option>)
									})
								}
							</select> 
							{FormError.error.vendor_id.length > 0 && <span className='color-text'>{FormError.error.vendor_id}</span>} 
						</div>
					</div>
				</div>
			{/* add vendor name and bill no */}



				<div className="field">
					<label className="ui-input-label">Bill Number</label>
					<input ref={bill_no} name="bill_no" className="ui-input" placeholder='Enter Bill Number' />
					{FormError.error.bill_no.length > 0 && <span className='color-text'>{FormError.error.bill_no}</span>}
				</div>
				<div className="field">
					<label className="ui-input-label">Bill Amount</label>
					<input ref={amount} name="amount" className="ui-input" placeholder='$ 0.00' />
					{FormError.error.amount.length > 0 && <span className='color-text'>{FormError.error.amount}</span>}
				</div>					
				<div className="field">
					<div className="tabs">
						<input id="tab1" type="radio" name="bill_frequency" value={'One Time'} onClick={handleRadio('One Time')} defaultChecked />
						<label htmlFor="tab1">ONE TIME</label>
							
						<input id="tab2" type="radio" name="bill_frequency" value={'Weekly'} onClick={handleRadio('Weekly')} />
						<label htmlFor="tab2">WEEKLY</label>
							
						<input id="tab3" type="radio" name="bill_frequency" value={'Monthly'} onClick={handleRadio('Monthly')} />
						<label htmlFor="tab3">MONTHLY</label>
					</div>
				</div>
				<div className="field">
					<label className="ui-input-label">Date</label>
					<input ref={due_date} type="date" name='due_date' className="ui-input" placeholder='Due Date' />
					{/* {FormError.error.bill_date.length > 0 && <span className='color-text'>{FormError.error.bill_date}</span>} */}
				</div>
				<div className="field">
					<label className="ui-input-label">Note (Optional)</label>
					<textarea rows={1} name="description" ref={description} className="ui-input" placeholder='Write Description'></textarea>
					{/* {FormError.error.description.length > 0 && <span className='color-text'>{FormError.error.description}</span>} */}
				</div>
				<Modal.Actions className='modal-action border-0'>
					<div className=''>
						<Button color='blue' className="my-0" type='submit'><Icon name='checkmark' /> Save and schedule a payment</Button>
					</div>
				</Modal.Actions>
			</form>
		</Modal.Content>

	</Modal>
	)
}

export default UploadBillModal