import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import CurrencySelectBox from "../../widget/currency-select";
import "../../custom.css";
import "semantic-ui-css/semantic.min.css";
import { connect } from "react-redux";
import auth from "../../../api/auth";

const initialForm = {
  error: {
    document_id: "",
    amount: "",
    due_date: "",
    description: "",
    bill_frequency: "",
    bill_type: "",
    vendor_id: "",
    currency: "USD",
  },
};
const initialState = {
  counterpart: {
    type: "individual",
    individual: {
      first_name: "",
      last_name: "",
      title: "",
      is_vendor: false,
      is_customer: false,
      phone: "",
      email: "",
      residential_address: {
        country: "",
        city: "",
        postal_code: "",
        state: "",
        line1: "",
        line2: "",
      },
    },
    organization: {
      legal_name: "",
      is_vendor: true,
      is_customer: true,
      phone: "",
      email: "",
      registered_address: {
        country: "",
        city: "",
        postal_code: "",
        state: "",
        line1: "",
        line2: "",
      },
    },
    created_automatically: false,
  },
  tax_id: {
    type: "",
    value: "",
  },
  bank_account: {
    name: "",
    account_holder_name: "",
    iban: "",
    bic: "",
    account_number: "",
    sort_code: "",
    routing_number: "",
    currency: "",
    country: "",
  },
};

function AddBillModal(props) {
  const [open, setOpen] = useState(false);
  const bill_type = useRef();
  const document_id = useRef();
  const vendor_id = useRef();
  const amount = useRef();
  const due_date = useRef();
  const description = useRef();
  const currencyRef = React.createRef();
  const [bill_frequency, setBill_frequency] = useState("One Time");
  const [type, setType] = useState("organization");
  const { user } = props;
  const [vendors, setVendors] = useState([]);
  const [formState, setFormState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const [FormError, setFormError] = useState(initialForm);

  const handleRadio = (radio) => (event) => setBill_frequency(radio);

  function handleSubmit(event) {
    event.preventDefault();
    let postData = {
      document_id: document_id.current.value,
      amount: amount.current.value,
      due_date: due_date.current.value,
      description: description.current.value,
      vendor_id: vendor_id.current.value,
      bill_frequency: bill_frequency,
    };
    if (ValidateForm(postData)) {
      props.submitBillForm(postData);
      setOpen(false);
    } else {
      window.toastr.error("Invalid information submitted");
    }
  }

  function ValidateForm(inputs) {
    let isValid = true;
    let error = {
      document_id: "",
      currency: "",
      amount: "",
      due_date: "",
      bill_frequency: "",
      bill_type: "",
      vendor_id: "",
    };

    if (inputs.amount.length === 0) {
      error.amount = "Bill amount is required.";
      isValid = false;
    } else {
      if (inputs.amount.length <= 0) {
        isValid = false;
        error.bill_amount = "amount must be in greater than 0.";
      } else if (!inputs.amount.match(/^[0-9]+$/)) {
        error.amount = "amount must be valid digit.";
        isValid = false;
      }
    }

    if (inputs.due_date === "") {
      error.due_date = "Due date require.";
      isValid = false;
    }

    if (inputs.bill_type === "") {
      error.bill_type = "Bill type require.";
      isValid = false;
    }

    if (inputs.vendor_id === "") {
      error.vendor_id = "Vendor is require.";
      isValid = false;
    }

    if (inputs.document_id.length === 0) {
      error.document_id = "Bill No. is required.";
      isValid = false;
    }

    if (inputs.description.length === 0) {
      error.description = "description is required.";
      isValid = false;
    }

    setFormError({ error: error });
    return isValid;
  }

  const callApi = useCallback((x_monite_entity_id) => {
    if (x_monite_entity_id === undefined || x_monite_entity_id === null) {
      return;
    }

    setLoading(true);

    auth
      .get(`counterparts/${x_monite_entity_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("AUTH_TOKEN")}`,
          "X-Monite-Token": `${window.localStorage.getItem("x_monite_token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setVendors(res.data.data);
      })

      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    const x_monite_entity_id = user.userDetail.monite_entity_id;
    if (x_monite_entity_id !== undefined || x_monite_entity_id !== null) {
      callApi(x_monite_entity_id);
    }

    return () => {};
  }, [user, callApi]);

  formState.counterpart.type = type;

  return (
    <Modal
      closeIcon={true}
      open={open}
      trigger={<Button>Add Bill</Button>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      className="modal-responsive modal-sm"
      style={{ height: "75vh" }}
    >
      <Modal.Header className="justify-content-between d-flex">
        <span className="w-50" style={{ fontSize: "18px" }}>
          Add New Bill
        </span>
      </Modal.Header>

      <Modal.Content>
        <form className="ui form w-100" onSubmit={handleSubmit}>
          <div className="field mb-3">
            <label className="ui-input-label">Vendor Name</label>
            <select
              aria-expanded="false"
              className="ui-input ui selection dropdown ui-input p-0"
              ref={vendor_id}
            >
              {vendors.map((vendor, i) => {
                return (
                  <Fragment key={i}>
                    {vendor.type === "individual" ? (
                      <>
                        <option
                          aria-checked="false"
                          aria-selected="false"
                          className="selected item"
                          value={vendor.individual.id}
                          key={vendor}
                        >
                          {vendor.individual.first_name}
                        </option>
                      </>
                    ) : (
                      <>
                        <option
                          aria-checked="false"
                          aria-selected="false"
                          key={vendor}
                          className="selected item"
                          value={vendor.organization.id}
                        >
                          {vendor.organization.legal_name}
                        </option>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </select>
            {FormError.error.vendor_id.length > 0 && (
              <span className="color-text text-danger">{FormError.error.vendor_id}</span>
            )}
          </div>
          {/* add vendor name and bill no */}
          <div className="field addbillfield">
            <label className="ui-input-label">Currency</label>
            <CurrencySelectBox
              className="ui-input"
              style={{ height: "40px" }}
              ref={currencyRef}
            />
          </div>

          <div className="field addbillfield">
            <label className="ui-input-label">Bill No.</label>
            <input
              ref={document_id}
              name="document_id"
              className="ui-input"
              placeholder="Bill No."
            />
            {FormError.error.document_id.length > 0 && (
              <span className="color-text text-danger">{FormError.error.document_id}</span>
            )}
          </div>

          <div className="field">
            <label className="ui-input-label">Bill Amount</label>
            <input
              ref={amount}
              name="amount"
              className="ui-input"
              placeholder="$ 0.00"
            />
            {FormError.error.amount.length > 0 && (
              <span className="color-text text-danger text-danger">{FormError.error.amount}</span>
            )}
          </div>

          <div className="field">
            <label className="ui-input-label">Due Date</label>
            <input
              ref={due_date}
              type="date"
              name="due_date"
              className="ui-input"
              placeholder="Due Date"
            />
            {FormError.error.due_date.length > 0 && (
              <span className="color-text text-danger">{FormError.error.due_date}</span>
            )}
          </div>

          <div className="field">
            <div className="tabs">
              <input
                id="tab1"
                type="radio"
                name="bill_frequency"
                value={"One Time"}
                onClick={handleRadio("One Time")}
                defaultChecked
              />
              <label htmlFor="tab1">ONE TIME</label>

              <input
                id="tab2"
                type="radio"
                name="bill_frequency"
                value={"Weekly"}
                onClick={handleRadio("Weekly")}
              />
              <label htmlFor="tab2">WEEKLY</label>

              <input
                id="tab3"
                type="radio"
                name="bill_frequency"
                value={"Monthly"}
                onClick={handleRadio("Monthly")}
              />
              <label htmlFor="tab3">MONTHLY</label>
            </div>
          </div>

          <div className="field">
            <label className="ui-input-label">Note (Optional)</label>
            <textarea
              rows={4}
              name="description"
              ref={description}
              className="ui-input"
              placeholder="Write Description"
            ></textarea>
            {/* {FormError.error.description.length > 0 && <span className='color-text text-danger'>{FormError.error.description}</span>} */}
          </div>

          <Modal.Actions className="modal-action border-0">
            <div className="">
              <Button className="my-0 secondary" type="submit">
                <Icon name="checkmark" />
                Save and schedule a payment
              </Button>
            </div>
          </Modal.Actions>
        </form>
      </Modal.Content>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userDetail,
  ...state,
});

export default connect(mapStateToProps)(AddBillModal);
