import React, { Component } from "react";
import Section from "./layouts/Section";
import { Link } from "react-router-dom";
import './custom.css'
import './prashant.css';
import healthIcon from '../../src/icons/health.png';
import stockIcon from '../../src/icons/stocks.png';


class About extends Component {
    render() {
        return (
            <Section allNotification={false} searchPopup={true} title={'About'}>

                <div className="" style={{ overflowY: "scroll", height: "100vh" }}>
                    <div className="new-about-era">
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/cal2.avif'} alt="img" />
                                </div>
                                <div className="col-6">
                                    <h6 className="head6">
                                        <p className="about-paragraph0">Age : 45 Years</p>
                                        <p className="about-paragraph0">Status : Married</p>
                                        <p className="about-paragraph0">Work : HR Manager</p>
                                        <p className="about-paragraph0">Location : New York</p>
                                        <p className="about-paragraph0">Blood : B+</p>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="_aboutmain">
                            <div className="row row-about ">
                                <div className="col-12">
                                    <div className="about-detail">
                                        <p className="about-detailsp text-white"> Aron Fince is a HR Manager is one of the top MNC with 15 years of experience.
                                            He starts his day at work by coordinating with the team on the task of the day.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="_aboutmain1">
                            <div className="row row-about ">
                                <div className="col-6" style={{ borderRight: "2px solid #000" }}>
                                    <div className="about-detail">
                                        <h6 className="head6">Goals</h6>
                                        <ul className="about-detailul">
                                            <li className="about-detailli">Weight Loss</li>
                                            <li className="about-detailli">Read Books Daily</li>
                                            <li className="about-detailli">Save Money</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="about-detail">
                                        <h6 className="head6">Habits</h6>
                                        <ul className="about-detailul">
                                            <li className="about-detailli">Excercise</li>
                                            <li className="about-detailli">Reading Books</li>
                                            <li className="about-detailli">Browsing Internet</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="_aboutmain">
                            <div className="row row-about ">
                                <div className="col-12">
                                    <div className="about-detail">
                                        <h6 className="head6">Past Medical Hx</h6>
                                        <ul className="about-detailul">
                                            <li className="about-detailli1"> Asthema Well controlled , Excercise included</li>
                                            <li className="about-detailli1">Breathing Problem , Shortness of Breathing</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="_aboutmain">
                            <div className="row row-about ">
                                <div className="col-12">
                                    <div className="about-detail">
                                        <h6 className="head6">Family Medical Hx</h6>
                                        <ul className="about-detailul">
                                            <li className="about-detailli1"> Discuss Medical history of parents, grandparetns and sibling especially if related to parents.</li>
                                            {/* <li></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="_aboutmain">
                            <div className="row row-about ">
                                <div className="col-12">
                                    <div className="about-detail">
                                        <h6 className="head6">Report</h6>
                                        <div className="healthreport_">
                                            <div className="">
                                                <img src={healthIcon} alt="icons" style={{ height: "50px", width: "50px" }} />
                                            </div>
                                            <div className="">
                                                <h6>Health report</h6>
                                                <Link to='/' className="a_about">See here</Link>
                                            </div>
                                        </div>
                                        <div className="financereport_">
                                            <div className="">
                                                <img src={stockIcon} alt="icons" style={{ height: "50px", width: "50px" }} />
                                            </div>
                                            <div className="">
                                                <h6>Finance report</h6>
                                                <Link to='/' className="a_about">See here</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: '35px' }}></div>
            </Section>
        );
    }
}

export default About;