import React from 'react';
import ChatIcon from '../../../icons/chatnew.png';
import './chatList.css';
import History from '../../../History'


const styles = {
    chatLink: {
        color: '#222',
        fontSize: '13px',
        textDecoration: 'none',
        textTransform: 'Capitalize',
    },
    img: {
        boxShadow: 'none',
        width: 40,
        height: 40,
        marginRight: "auto",
        marginLeft: "auto",
        display: "block"
    },
    h6: {
        fontSize: 14,
        fontWeight: 600,
        textAlign: "center"
    },
    p: {
        fontSize: 13,
        fontWeight: 700,
        textAlign: "center",
        color: "red",
    }
}

function Item1(props) {

    const { item } = props

    const message = (item) => {
        if (item.users.length === 1) {
            return item.users[0].name
        }
        return 'Life Navigator - Professional'
    }


    const image = (item) => {
        if (item.users.length === 1) {
            return item.users[0].avatar
        }
        return '/assets/img/group.png'
    }

    const sendToChat = (e, item) => {
        e.preventDefault()
        History.push(`/chat/${window.btoa(item.id)}`)
    }

    return (
        <>
            <a style={styles.chatLink} href='/#' onClick={(e) => sendToChat(e, item)}>
                <span className="badge first_badge">
                    <hr className='hr_first' />
                    {/* <hr className='hr_second' /> */}
                </span>
                <img style={styles.img} className='avatar thumb mr-1' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiyvgGSx2_5ZxsU7xO8z-6nb0NtBe6g1AIbXz8To7KCvVaKkNjDzDbmYYKz5Aa17NJBqA&usqp=CAU" width={'40'} height={'40'} />
            </a>

            <span className='item1_span' onClick={(e) => sendToChat(e, item)}>
                <img style={styles.img} src={ChatIcon} alt='' width={'60'} height={'60'} />
                <h6 style={styles.h6}>Check-in</h6>
                <p style={styles.p}>{message(item)}</p>
            </span>

            {item.users.length === 1 ?
                <a style={styles.chatLink} href='/#' onClick={(e) => sendToChat(e, item)}>
                    <span className="badge second_badge">
                        <hr className='hr_first' />
                        {/* <hr className='hr_second' /> */}
                    </span>
                    <img style={styles.img} className='avatar thumb mr-1' src={image(item)} width={'40'} height={'40'} />
                </a>
                :
                <a style={styles.chatLink} href='/#' onClick={(e) => sendToChat(e, item)}>
                    <span style={{ gap: "20px", display: "grid" }}>
                        <img style={styles.img} className='avatar thumb mr-1' src={image(item)} width={'40'} height={'40'} />
                        <img style={styles.img} className='avatar thumb mr-1' src={image(item)} width={'40'} height={'40'} />
                    </span>
                </a>
            }
        </>
    )
}

export default Item1;
