import React, { useState } from 'react';
import './ChildHome.css'; // Import your CSS file for styling
import Boxie from './Boxie';
import Collection from './Collection';
import Ch1Calander from './Ch1Calander';

function ChildHome1(props) {
  const {
    imageUrl,
    inputValue,
    itemname,
    formData,
    dragimgaebox,
    setDragImageBox,
    updateFormData,
    setFormData,
    setNewBoxes,
    newBoxes,
    setSelectedBoxIndex,
    selectedBoxIndex,
    handleCreateNewBox,
  } = props;

  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  props.sendTabdata(activeTab);

  return (
    <div className="tab-container container">
      <div className="tab-box">
        <button
          id="onetwo"
          className={activeTab === 'tab1' ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabClick('tab1')}
        >
          Item
        </button>
        <button
          id="onetwo"
          className={activeTab === 'tab2' ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabClick('tab2')}
        >
          Outfit
        </button>
        <button
          id="onetwo"
          className={activeTab === 'tab3' ? 'tab-btn active' : 'tab-btn'}
          onClick={() => handleTabClick('tab3')}
        >
          Collection
        </button>
      </div>
      <div className="tab-contenti" style={{ display: activeTab === 'tab1' ? 'block' : 'none' }}>
        <Boxie imageUrl={imageUrl} inputValue={inputValue} itemname={itemname}  formData={formData} updateFormData={updateFormData} setFormData={setFormData}/>
      </div>
      <div className="tab-contenti" style={{ display: activeTab === 'tab2' ? 'block' : 'none' }}>
        <Ch1Calander formData={formData} dragimgaebox={dragimgaebox} setDragImageBox={setDragImageBox} />
      </div>
      <div className="tab-contenti" style={{ display: activeTab === 'tab3' ? 'block' : 'none' }}>
        <Collection handleCreateNewBox={handleCreateNewBox} selectedBoxIndex={selectedBoxIndex}  setSelectedBoxIndex={setSelectedBoxIndex}  newBoxes={newBoxes} setNewBoxes={setNewBoxes} setDragImageBox={setDragImageBox} formData={formData} dragimgaebox={dragimgaebox} setFormData ={setFormData }/>
      </div>
    </div>
  );
}

export default ChildHome1;
