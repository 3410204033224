import React from 'react'
import Pagination from 'react-bootstrap/Pagination';
import "./pagination.css"


const CustomPagination = ({ onNext, onPrevious,currentPage }) => {
    return (
      <div>
        <Pagination className='lippgn'>
        
         
        {
  currentPage ===3 ? (
    
 ""

  ) : (
    <Pagination.Next onClick={onNext} className="custom-next-btn notch huihiuh">
      
      <div className='footer_collie' >
  <div className='button_fnext' > 
 
  {/* Display current section */}
  <span aria-hidden="true">Next</span>
      {/* <ArrowRightAltIcon sx={{ color: "black", marginLeft: "176px" }} />  */}
       </div>
       <div><i style={{color:"black"}} class="bi bi-arrow-right"></i> </div>
 
</div>
    </Pagination.Next> 
  )
}

        </Pagination>
      </div>
    );
  }
  
  export default CustomPagination;

