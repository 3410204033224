import React, { useEffect, useState } from "react";
import './commonTable.css'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


import Summary from './Summary';

const Table = ({ columns, rows, handleOpen }) => {
  return (
    <table className="table table-striped">
      <thead className="theadct">
        <tr>
          {columns.map((column) => (
            <th key={column.field}>{column.headerName}</th>
          ))}
        </tr>
      </thead>
      <tbody className="tbodyct">
        {rows.map((row, index) => (
          <tr
            key={index}
            className={index % 2 === 0 ? 'even' : 'odd'}
            onClick={() => handleOpen(row)}
          >
            {columns.map((column) => (
              <td key={`${index}-${column.field}`}>{row[column.field]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};


function CommonTable(props) {


    const [lgShow, setLgShow] = useState(false);
  
    const [key, setKey] = useState('summary');
  

  const { allstock, rows, columns } = props;
  const [selectedSymbol, setSelectedSymbol] = useState(null); // State to store selected symbol


  const handleOpen = (symbol) =>{
    setSelectedSymbol(symbol)
    setLgShow(true);
  }
  // console.log("selectedSymbol asdasda",selectedSymbol);
  // console.log("allstock", allstock);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="kjbbiubiu" style={{ height: props.height, width: "99%" }} >
   
<Table rows={rows} columns={columns} handleOpen={handleOpen} />
        
      <Modal
        id="newModal"
        size="xl"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        style={{position: "absolute", top: "10px", right: "10px"}}
      >
        <Modal.Header closeButton >
          
        </Modal.Header>
        <Modal.Body id="sadhhajgbv">
        <div >
        <h1 className='single-stock-container knnoioi'>
        {selectedSymbol&&selectedSymbol.longName}{selectedSymbol&&selectedSymbol.symbol}
        </h1>
        <p className='paragraph'>NasdaqGS - NasdaqGS Real Time Price. Currency in USD</p>
        <div className='container_price'>
          {selectedSymbol && selectedSymbol.price}
          <span className='StockSpan' > {selectedSymbol&&selectedSymbol.regularMarketChange ? selectedSymbol.regularMarketChange.fmt : "N/A"}{selectedSymbol&&selectedSymbol.regularMarketChangePercent ? selectedSymbol.regularMarketChangePercent.fmt : "N/A"}</span>
          {selectedSymbol&&selectedSymbol.postMarketPrice ? selectedSymbol.postMarketPrice.fmt : "N/A"}
          <span className='StockSpan'> {selectedSymbol&&selectedSymbol.postMarketChange ? selectedSymbol.postMarketChange.fmt : "N/A"}{selectedSymbol&&selectedSymbol.postMarketChangePercent ? selectedSymbol.postMarketChangePercent.fmt : "N/A"}</span>
        </div>
      </div>
      <div style={{ width: '100%' }}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 bjbibl"
        >
        <Tab className="uiugugui" eventKey="summary" title="Summary">
            <Summary selectedSymbol={selectedSymbol} allstock={allstock} />
        </Tab>
        <Tab className="jyggi" eventKey="chart" title="Chart">
            Item Two
        </Tab>
        </Tabs>
      </div>
        </Modal.Body>
      </Modal>
      </div>

    </>
  )
}

export default CommonTable;