import React, { Component } from "react";
import { Router, Route, Switch, withRouter } from "react-router-dom";
import history from "./History";
import Signin from "./components/Signin";
import Home from "./components/Home";
import UserSetting from "./components/UserSetting";
import ProtectedRoute from "./components/subcomponent/protectedroute";
import Error404 from "./components/subcomponent/errorpage/404";
import Error403 from "./components/subcomponent/errorpage/403";
import Otp from "./components/Otp";
import About from "./components/About";
import AllPages from "./components/AllPages";
import BillPay from "./components/BillPay";
import Calendar from "./components/Calendar";
import document from "./components/Document/document"
import Health from "./components/Health";
import Blog from "./components/Blog";
import BlogDetails from "./components/BlogDetails";
import Report from "./components/Report";
import ReportPage from "./components/ReportPage";
import information from "./components/Document/information";
import Contact from "./components/Contact";
import Notification from "./components/Notification";
import Saving from "./components/Saving";
import Signup from "./components/Signup";
import Transaction from "./components/Transaction";
import finances from "./components/finance/finances";
import financeBlog from "./components/finance/financeBlog";
import financeHistory from "./components/cash flow/financeHistory";
import CashFlow from "./components/cash flow/cashFlow";
import savingGoals from "./components/cash flow/savingGoals";
import TransactionDetails from "./components/TransactionDetails";
import UserVerification from "./components/UserVerification";
import Viewmore from "./components/Viewmore";

import Chat from "./components/Chat";
import StripeSuccess from "./components/subcomponent/stripe/stripeSuccess";
import PaymentHistory from "./components/PaymentHistory";
import Wallet from "./components/Wallet";

import WebCall from './webcall';
import Speech from "./components/subcomponent/speechtotext/speech";
import SkyflowTest from "./components/SkyflowTest";
import Addbalance from "./components/AddBalance";
import recentMessage from "./components/cash flow/recentMessage";
import Gallery from "./components/Gallery";
import Bankaccount from "./components/finance/bankaccount";
import JoinVendor from "./components/JoinVendor";
import AddPayable from "./components/addPayable";
import GoogleCalendar from "./components/google-calendar";
import EventCalendar from "./components/event-calendar";
import UploadDocuments from "./components/UploadDocuments";

// report


// 
import Mainpage from "./components/Mainpage";
import ChatList from "./components/Chat/ChatList/ChatList";

//Task
import Task from './components/Task';
import Zoom from "./components/Zoom meeting/Zoom";
import Training from "./components/Training/Training";
import BankAuth from "./components/Bank Auth/BankAuth";

//Event Iframe

import EventFrame from "./components/Event-frame";
import ChatGpt from "./components/Chat-gpt";
import FinanceReport from "./components/FinanceReport";
import HealthReport from "./components/HealthReport";
import BillsReport from "./components/BillsReport";
import OutFits from "./components/OutFits";
import StockTickers from "./components/Stock-Tickers/StockTickers";
import CashFlowReport from "./components/CashFlowReport";
import EstateReport from "./components/EstateReport";
import Task1 from "./components/Task1";
import AgoraCallKit from "./agora/callkit";


class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <ProtectedRoute exact path={'/'} component={Mainpage} />
                    <ProtectedRoute exact path={'/home'} component={Home} />
                    <ProtectedRoute exact path={'/main'} component={Mainpage} />
                    <ProtectedRoute exact path={'/wallet'} component={Wallet} />
                    <ProtectedRoute exact path={'/user-setting'} component={UserSetting} />
                    <ProtectedRoute exact path={'/task'} component={Task} />
                    <ProtectedRoute exact path={'/tasklist'} component={Task1} />
                    <ProtectedRoute exact path={'/training'} component={Training} />
                    <ProtectedRoute exact path={'/zoom'} component={Zoom} />
                    <ProtectedRoute exact path={'/about'} component={About} />
                    <ProtectedRoute exact path={'/upload-documents'} component={UploadDocuments} />
                    <ProtectedRoute exact path={'/all-pages'} component={AllPages} />
                    <ProtectedRoute exact path={'/bill-pay'} component={BillPay} />
                    <ProtectedRoute exact path={'/calendar'} component={Calendar} />
                    <ProtectedRoute exact path={'/event-calendar'} component={EventCalendar} />
                    <ProtectedRoute exact path={'/events'} component={EventFrame} />
                    <ProtectedRoute exact path={'/chatgpt'} component={ChatGpt} />
                    <ProtectedRoute exact path={'/health'} component={Health} />
                    <ProtectedRoute exact path={'/gallery'} component={Gallery} />
                    <ProtectedRoute exact path={'/health-report'} component={HealthReport} />
                    <ProtectedRoute exact path={'/information/:folder'} component={information} />
                    <ProtectedRoute exact path={'/finances'} component={finances} />
                    <ProtectedRoute exact path={'/financeHistory'} component={financeHistory} />
                    <ProtectedRoute exact path={'/finance-blog'} component={financeBlog} />
                    <ProtectedRoute exact path={'/cashflow'} component={CashFlow} />
                    <ProtectedRoute exact path={'/report'} component={Report} />
                    <ProtectedRoute exact path={'/report-page'} component={ReportPage} />
                    <ProtectedRoute exact path={'/finance-report'} component={FinanceReport} />
                    <ProtectedRoute exact path={'/health-report'} component={HealthReport} />
                    <ProtectedRoute exact path={'/bills-report'} component={BillsReport} />
                    <ProtectedRoute exact path={'/outfits-section'} component={OutFits} />
                    <ProtectedRoute exact path={'/stocks-section'} component={StockTickers} />
                    <ProtectedRoute exact path={'/flow-report'} component={CashFlowReport} />
                    <ProtectedRoute exact path={'/estate-report'} component={EstateReport} />
                    <ProtectedRoute exact path={'/recentmessage'} component={recentMessage} />
                    <ProtectedRoute exact path={'/savinggoals'} component={savingGoals} />
                    <ProtectedRoute exact path={'/document'} component={document} />
                    <ProtectedRoute exact path={'/banks'} component={Bankaccount} />
                    <ProtectedRoute exact path={'/vendors'} component={JoinVendor} />
                    <ProtectedRoute exact path={'/payables'} component={AddPayable} />
                    <ProtectedRoute exact path={'/blog'} component={Blog} />
                    <ProtectedRoute exact path={'/blog-details'} component={BlogDetails} />
                    <ProtectedRoute exact path={'/contact'} component={Contact} />
                    <ProtectedRoute exact path={'/notification'} component={Notification} />
                    <ProtectedRoute exact path={'/saving'} component={Saving} />
                    <ProtectedRoute exact path={'/transaction'} component={Transaction} />
                    <ProtectedRoute exact path={'/transaction-details'} component={TransactionDetails} />
                    <ProtectedRoute exact path={'/user-verification'} component={UserVerification} />
                    <ProtectedRoute exact path={'/viewmore'} component={Viewmore} />
                    <ProtectedRoute exact path={'/add-balance'} component={Addbalance} />
                    <ProtectedRoute exact path={'/stripe-success'} component={StripeSuccess} />
                    <ProtectedRoute exact path={'/payment-history'} component={PaymentHistory} />
                    <ProtectedRoute exact path={'/speech'} component={Speech} />
                    <ProtectedRoute exact path={'/call/:channel?'} component={AgoraCallKit} />
                    {/*  */}
                    <ProtectedRoute exact path={'/skyflow'} component={SkyflowTest} />
                    <ProtectedRoute exact path={'/chats'} component={ChatList} />
                    <ProtectedRoute exact path={'/chat/:uid'} component={Chat} />
                    <ProtectedRoute exact path={'/webcall/room/:id/:type'} component={WebCall} />
                    <ProtectedRoute exact path={'/gcalendar'} component={GoogleCalendar} />
                    <ProtectedRoute exact path={'/bank-authorize'} component={BankAuth} />
                    
                    <Route exact path={'/login'} component={Signin} />
                    <Route exact path={'/otp'} component={Otp} />
                    <Route exact path={'/signup'} component={Signup} />
                    <Route path={'/403'} component={Error403} />
                    <Route path="*" component={Error404} />
                </Switch>
            </Router>
        )
    }
}

export default withRouter(Routes);

