import React from 'react';
import Section from '../layouts/Section';
import VideoGallery from './VideoGallery';
import './style.css';


function Training() {
    return (
        <div className=''>
            <Section allNotification={false} searchPopup={true} title={'Training'} />
            <div className='training'>
                <div className='container traincon'>
                    <div className='row'>
                        <VideoGallery />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Training;
