import React, { Component } from "react";
import { connect } from "react-redux";
import Section from "./layouts/Section";
import { Link } from "react-router-dom";
import AddBillModal from "./subcomponent/modals/addbillmodal";
import UploadBillModal from "./subcomponent/modals/uploadbillmodal";

import {
  getBills,
  addBills,
  uploadBills,
  getVendorList,
  getInvoicePDF,
} from "../actions";

import Spinner from "./subcomponent/spinner/Spinner";

class BillPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billsData: [],
      vendorList: [],
      waiting: false,
    };
    this.submitBill = this.submitBill.bind(this);
    this.uploadBill = this.uploadBill.bind(this);
    this.Loder = this.Loder.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);

    this.client = false;
  }

  componentDidMount() {
    this.props.getVendorList();
  }

  componentDidUpdate() {
    const { user } = this.props;
    if (user.id !== "" && !this.client) {
      this.client = true;
      this.props.getBills(user);
    }
  }

  downloadPdf() {
    this.props.getInvoicePDF();
  }

  submitBill(formData) {
    this.props.addBills(formData);
    this.setState({ waiting: true });
  }

  uploadBill(postData) {
    const data = new FormData();
    data.append("bill_image", postData.bill_image);
    data.append("amount", postData.amount);
    data.append("document_id", postData.document_id);
    data.append("due_date", postData.due_date);
    data.append("description", postData.description);
    data.append("vendor_id", postData.vendor_id);
    data.append("bill_frequency", postData.bill_frequency);
    this.props.uploadBills(data);
    this.setState({ waiting: true });
  }
  static getDerivedStateFromProps(props, state) {
    if (props) {
      let bills = { ...state.billsData };
      let vendors = { ...state.vendorList };
      bills = props.bills;
      vendors = props.vendorList;
      return { ...state, billsData: bills, vendorList: vendors };
    }
    return state;
  }

  Loder() {
    return (
      <div className="row">
        <div className="col-4"></div>
        <div className="col-4">
          <Spinner />
        </div>
        <div className="col-4"></div>
      </div>
    );
  }

  render() {
    return (
      <Section
        allNotification={false}
        searchPopup={true}
        title={"Monthly Bill"}
      >
        <div className="bill-pay-area my-5 mg-bottom-35 mg-top-35 pd-top-15">
          <div className="container">
            <div className="section-title style-three align-items-center d-flex justify-content-between mb-0">
              <h3 className="title">Pay Monthly Bill</h3>
              <Link to={"/bill-pay"}>
                <i className="fa fa-bell" />
              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-xl-6 col-6 text-left mb-3">
                  <AddBillModal
                    submitBillForm={this.submitBill}
                    vendorList={this.state.vendorList}
                  />
                </div>
                <div className="col-xl-6 col-6 text-left mb-3">
                  <UploadBillModal
                    uploadBillForm={this.uploadBill}
                    vendorList={this.state.vendorList}
                  />
                </div>
              </div>
            </div>
            {this.props.loader && this.state.waiting ? this.Loder() : null}
            {this.state.billsData.length > 0 ? (
              this.state.billsData.map((item, i) => {
                return (
                  <div className="ba-bill-pay-inner" key={"b" + i}>
                    <div className="ba-single-bill-pay">
                      <div className="thumb">
                        <img
                          src={
                            item.bill_image
                              ? item.bill_image
                              : process.env.PUBLIC_URL +
                                "/assets/img/icon/6.png"
                          }
                          alt="img"
                        />
                      </div>
                      <div className="details">
                        <h5>{item.document_id}</h5>
                        <p>Due Date: {item.due_date}</p>
                        <p>{item.description}</p>
                      </div>
                    </div>
                    <div className="amount-inner">
                      <h5 onClick={this.downloadPdf}>${item.amount / 100}</h5>
                      <Link className="btn btn-blue" to={"/bill-pay"}>
                        {item.status.split("_").join(" ")}
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : this.state.billsData.length === 0 ? (
              <div className="alert alert-danger" role="alert">
               Payment history not available
              </div>
            ) : (
              this.Loder()
            )}
            <div className="ba-bill-pay-inner"></div>
          </div>
        </div>
      </Section>
    );
  }
}

BillPay.defaultProps = {
  bills: [],
  vendorList: [],
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userDetail,
    bills: state.payment.bills,
    vendorList: state.payment.vendorList,
    loader: state.payment.loader,
  };
};

export default connect(mapStateToProps, {
  getBills,
  addBills,
  uploadBills,
  getVendorList,
  getInvoicePDF,
})(BillPay);
