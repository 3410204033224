import React, { useEffect, useState } from "react";
import ChatBody from "./Chat/ChatBody/ChatBody";

function Chat(props) {

    const [uid, setUID] = useState('')
    const [imgUrl, setImgUrl] = useState();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setUID(props.match.params.uid)

        const $this = window.jQuery;

        $this(document).on('click', '.preview-img', function(e){
            e.preventDefault();
            let href = $this(this).data('href');
            setImgUrl(href);
            setOpen(true);
        });

        return () => {}
    }, [props.match.params.uid])

    return (
        <>
        <div className="__main" style={{marginTop:'0px'}}>
            <ChatBody {...props} uid={uid} />
        </div>
        
        { open ?
        <div className="preview-img-container">
            <div onClick={(e) => setOpen(false)} className="close-icon-x">&times;</div>
            <img className="show" src={imgUrl} alt={imgUrl} />
        </div> : '' }
        </>
    );
}

export default Chat;