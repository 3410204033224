import React from 'react'
import Footer from './layouts/Footer';
import Section from './layouts/Section';
import { Link } from 'react-router-dom';
import Healthicon from '../icons/health.png';
import Billsicon from '../icons/bills.png';
import Stocksicon from '../icons/stocks.png';
import Outfiticon3 from '../icons//Outfiticon3.jpg';
import Stocks from '../icons/stc.jpg'


function ReportPage() {
  return (
    <div>
      <Section allNotification={false} searchPopup={true} title={"Report Page"}>
        <div className="mainreport mb-4" style={{marginTop:"5rem"}}>
          <div className="mainpagehead">
            <div className="container">
              <div className="row mainpagerow">
                <ul className='mainpageulul'>
                  <li className='mainpageli col-md-3 col-4 mb-3'>
                    <Link className="ba-add-balance-btn" to={'/health-report'}>
                      <img loading='lazy' src={Healthicon} alt={Healthicon} className="healthIcon" />
                      <h6 className='ta-center'>Health Report</h6>
                    </Link>
                  </li>

                  <li className='mainpageli col-md-3 col-4 mb-3'>
                    <Link className="ba-add-balance-btn" to={'/finance-report'}>
                      <img loading='lazy' src={Stocksicon} alt={Stocksicon} className="stocksIcon" />
                      <h6 className='ta-center'>Finance Report</h6>
                    </Link>
                  </li>

                  <li className='mainpageli col-md-3 col-4 mb-3'>
                    <Link className="ba-add-balance-btn" to={'/bills-report'}>
                      <img loading='lazy' src={Billsicon} alt={Billsicon} className="billsIcon" />
                      <h6 className='ta-center'>Bills Report</h6>
                    </Link>
                  </li>
                  <li className='mainpageli col-md-3 col-4 mb-3'>
                    <Link className="ba-add-balance-btn" to={'/outfits-section'}>
                      <img loading='lazy' src={Outfiticon3} alt={Outfiticon3} className="outfiticon" />
                      <h6 className='ta-center'>OutFits</h6>
                    </Link>
                  </li>

                  <li className='mainpageli col-md-3 col-4 mb-3'>
                      <Link className="ba-add-balance-btn" to={'/stocks-section'}>
                        <img loading='lazy' src={Stocks} alt={Stocks} className="stockicon" />
                        <h6 className='ta-center'>Stocks</h6>
                      </Link>
                   </li>
                </ul>
              </div>



            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </div>
  )
}

export default ReportPage;