import React, { useState } from 'react';
import { SITE_URL } from '../../../api/auth';

function CashAudioModal(props) {

    const { list } = props;
    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(true);
    };

	const closeModal = () => {
		setShowModal(false);
	};

    let myAudios;
    try {
        myAudios = JSON.parse(list.audio);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

	return (
		<div>
			<button type="button" className="btn btn-sm" onClick={openModal}>
				<i className="bi bi-mic-fill"></i>
			</button>

			{showModal && (
				<div
					className="modal"
					tabIndex="-1"
					role="dialog"
					style={{ display: "block", background: "#111110a8" }}
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Audios</h5>
								<button
									type="button"
									className="close _newclose"
									onClick={closeModal}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<audio controls autoPlay style={{ width: "100%" }}>
									<source src={`${SITE_URL}/${myAudios}`} type="audio/mp3" />
									Your browser does not support the video tag.
								</audio>
							</div>
							<div className="modal-footer">
								<button
									className="commonmodalbutton"
									onClick={closeModal}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default CashAudioModal;
