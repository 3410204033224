import React from 'react';
import '../new.css';
import { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';



function FooterModal(props) {

    const { userDetail, chats } = props;

    const chatLink = (user) => {

        for (let i in chats) {
            if (chats[i]['users'].length === 1 && chats[i]['users'][0]['id'] === user.uid) {
                return '/chat/' + window.btoa(chats[i]['id']) + '?call'
            }
        }
        return '/chats'
    }

    return (
        <Fragment>
            <div id="__footer-madal" className="modal fade">
                <div className="modal-dialog " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <b className='modal-bold' style={{ fontSize: '16px' }}>Call Life Navigator & Advisor</b>
                            <button type="button" className="closes btn-danger" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-2">
                                {userDetail['mappings'] !== undefined && userDetail?.mappings.map((user, index) => {
                                    return (
                                        <Link key={index} className="col-12" to={chatLink(user)}>
                                            <button className="__footerbtn">
                                                <i className="fa fa-phone _footericon_" aria-hidden="true"></i>
                                                <h6 className="__footerh6">Talk to your {user.role === 'LIFE_NAVIGATOR' ? "‘Life Navigator" : "Advisor"}</h6>
                                            </button>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        userDetail: state.user.userDetail,
        chats: state.chat.chats
    }
}

export default connect(mapStateToProps)(FooterModal);
