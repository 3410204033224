import React from "react";
import { Spinner } from "react-bootstrap";

const style = {
  position: "fixed",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  zIndex: "999999",
  background: "rgb(255, 255, 255, 0.8)",
  justifyContent: "center",
  alignItems: "center",
};

function ThemeLoader() {
  return (
    <div style={style}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export default ThemeLoader;
