import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function Irregular(props) {

  const { myData } = props;

  let parsedData;
  try {
    parsedData = JSON.parse(myData.form_data);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }

  const allValues =
    Number(parsedData.irregular.car_repair) +
    Number(parsedData.irregular.auto_insurance) +
    Number(parsedData.irregular.gifts) +
    Number(parsedData.irregular.vacation) +
    Number(parsedData.irregular.cosemetic) +
    Number(parsedData.irregular.home_furnishing) +
    Number(parsedData.irregular.tax_refund);

  props.sendDataRe(allValues)

  return (
    <div className="main_adkj">
      <h5 className="title_cash">ANNUAL AMOUNT</h5>
      <div className="askwl">
        <div className="daildy_living" style={{ background: "#945ab2" }}>
          IRREGULAR EXPENSES
        </div>
        <Form>
          <div className="living_state">
            <Form.Label className="adksk">Car Repair</Form.Label>
            <input
              type="text"
              className=" input_style jadj"
              name="car_repair"
              value={parsedData.irregular.car_repair}
            />
          </div>
          <div className="living_state">
            <Form.Label className="lable_css">Auto Insurance</Form.Label>
            <input
              className="input_style jkha"
              type="text"
              name="auto_insurance"
              value={parsedData.irregular.auto_insurance}
            />
          </div>
          <div className="living_state">
            <Form.Label className="adksk">Gifts</Form.Label>
            <input
              className="input_style jadj"
              type="text"
              name="gifts"
              value={parsedData.irregular.gifts}
            />
          </div>
          <div className="living_state">
            <Form.Label className="lable_css">Vacation</Form.Label>
            <input
              className="input_style jkha"
              type="text"
              name="vacation"
              value={parsedData.irregular.vacation}
            />
          </div>
          <div className="living_state">
            <Form.Label className="adksk">Cosemetic/Skin Care</Form.Label>
            <input
              className="input_style jadj"
              type="text"
              name="cosemetic"
              value={parsedData.irregular.cosemetic}
            />
          </div>
          <div className="living_state">
            <Form.Label className="lable_css">Home Furnishing</Form.Label>
            <input
              className="input_style jkha"
              type="text"
              name="home_furnishing"
              value={parsedData.irregular.home_furnishing}
            />
          </div>
          <div className="living_state">
            <Form.Label className="adksk">Tax Refund</Form.Label>
            <input
              className="input_style jadj"
              type="text"
              name="tax_refund"
              value={parsedData.irregular.tax_refund}
            />
          </div>

          <div className="living_state">
            <Form.Label className="adksk"></Form.Label>
            <input
              className="input_style_ jadj jkdju"
              type="text"
              name="totaleHousing"
              value={allValues}
            />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Irregular;
