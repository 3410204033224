import React, { useEffect, useState } from "react";
import Section from "./layouts/Section";
import "./prashant.css";
import PdfIcon from "../icons/pdf.png";
import axios from "axios";
import AvertiLoader from "../loader";
import './prashant.css';
import { Modal } from "bootstrap";
import DemoPdf from './assets/sample.pdf';
import auth
  from "../api/auth";

const dummyComments = [
  "Admin: Hello, this is the first comment.",
  "Admin: Here's another comment.",
  "Admin: This is the third comment.",
  "Admin: Hello, this is the first comment.",
  "Admin: Here's another comment.",
  "Admin: This is the third comment.",
  "Admin: Hello, this is the first comment.",
  "Admin: Here's another comment.",
  "Admin: This is the third comment.",
];

function Report() {

  const [loader] = AvertiLoader()

  const openPDF = (url) => {
    window.open(url, '_blank');
  };

  const [posts, setPosts] = useState([]);
  console.log(posts, "posts")
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [audioUrl, setAudioUrl] = useState("");

  const [videoUrl, setVideoUrl] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cModalOpen, setCModalOpen] = useState(false);

  const getReports = async () => {
    const token = window.localStorage.getItem('AUTH_TOKEN');
    const response = await auth.get('/reports', {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'Application/json',
        'Accept': 'Application/json',
      },
    });
    setPosts(response.data.data);
  }
  useEffect(() => {
    getReports();
  }, []);


  const openCModal = () => {
    setCModalOpen(true)
  }

  const closeCModal = () => {
    setCModalOpen(false)
  }


  const openPdfModal = () => {
    setIsModalOpen(true);
  };

  const closePdfModal = () => {
    setIsModalOpen(false);
  };

  const openVideoModal = () => {

    const videoModal = new window.bootstrap.Modal(document.getElementById('videoModal')); // Initialize the Bootstrap modal
    videoModal.show(); // Show the modal
  };

  const openModal = (audioUrl) => {
    setAudioUrl(audioUrl);
    const modal = new Modal(document.getElementById('audioModal'));
    modal.show();
  };

  const closeModal = () => {
    const modal = new Modal(document.getElementById('audioModal'));
    modal.hide();
  };

  const currentYear = new Date().getFullYear();

  const startYear = currentYear - 10;;
  const endYear = currentYear;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index,
  )

  const [selectedYear, setSelectedYear] = useState('')

  console.log("Selected year", selectedYear)

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value)
  }

  const handleClick = (month) => {
    console.log(`Clicked month: ${month}`);
  }

  const [isOpen, setIsOpen] = useState(true);

  const handleToggleModal = () => {
    setIsOpen(!isOpen);
  };



  return (
    <>
      {loader}
      <Section allNotification={false} searchPopup={true} title={"Report"} />
      <div className="cart-area pd-top-36 mg-bottom-40" id="_report_css">
        <div className={`container ${isOpen ? 'modal-open' : 'modal-closed'}`}>
          {isOpen ? (
            <div className="buttons_cross">
              <select
                id="yearSelector"
                className="year_btn"
                onChange={handleYearChange}
              >
                <option value="">-- Select Year --</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <i className="bi bi-x _xicon" onClick={handleToggleModal}></i>
            </div>
          ) : (
            <div className="buttons_dot">
              <i className="bi bi-filter _dicon" onClick={handleToggleModal}></i>
            </div>
          )}
        </div>
        <div className="container">
          {isOpen && (
            <div className="section-title">
              <div className="d-flex report_container">
                <button className="month_btn" onClick={() => handleClick('JAN')}>JAN</button>
                <button className="month_btn" onClick={() => handleClick('FEB')}>FEB</button>
                <button className="month_btn" onClick={() => handleClick('MAR')}>MAR</button>
                <button className="month_btn" onClick={() => handleClick('APR')}>APR</button>
                <button className="month_btn" onClick={() => handleClick('MAY')}>MAY</button>
                <button className="month_btn" onClick={() => handleClick('JUN')}>JUN</button>
                <button className="month_btn" onClick={() => handleClick('JUL')}>JUL</button>
                <button className="month_btn" onClick={() => handleClick('AUG')}>AUG</button>
                <button className="month_btn" onClick={() => handleClick('SEP')}>SEP</button>
                <button className="month_btn" onClick={() => handleClick('OCT')}>OCT</button>
                <button className="month_btn" onClick={() => handleClick('NOV')}>NOV</button>
                <button className="month_btn" onClick={() => handleClick('DEC')}>DEC</button>
              </div>
            </div>
          )}
        </div>
        <div className="container">
          {posts.map((report, index) => {
            return (
              <div key={index} className="section-title mb-2 _reportbgsha">
                <div className="d-flex p-3 bg-white justify-content-between align-items-center rounded-4">
                  <div className="_report_name">{report.pdf.toString().slice(26, 35)}</div>
                  <div className=""><img className="shadow-none" src={PdfIcon} height={30} width={30} /></div>
                  <div className="d-flex _icon_button">
                    <button type="button" className="btn btn-sm" onClick={openPdfModal}><i className="bi bi-eye-fill"></i></button>
                    <button type="button" className="btn btn-sm" onClick={openVideoModal}><i className="bi bi-camera-video-fill"></i></button>
                    <button type="button" className="btn btn-sm" onClick={openModal}><i className="bi bi-mic-fill"></i></button>
                    <button type="button" className="btn btn-sm" onClick={openCModal}><i className="bi bi-chat-left-dots-fill"></i></button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* Bootstrap Modal */}
      <div className="modal fade" id="audioModal" tabIndex="-1" aria-labelledby="audioModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="audioModalLabel">Audio Player</h5>
              <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {posts.map((audio, index) => {
                const audioArray = JSON.parse(audio.audio);
                return (
                  <audio key={index} controls autoPlay style={{ width: "100%" }}>
                    <source src={`${process.env.REACT_APP_MAIN_URL}${audioArray}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </audio>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Video modal */}

      <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header _videoclose">
              <h5 className="modal-title" id="videoModalLabel">Video Player</h5>
              <button
                type="button"
                className="btn-close report_clodebtn close report_close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setVideoUrl("")}
              ></button>
            </div>
            <div className="modal-body">
              {posts.map((video, index) => {
                const videoArray = JSON.parse(video.video);
                return (
                  <video key={index} controls autoPlay style={{ width: "100%" }}>
                    <source src={`${process.env.REACT_APP_MAIN_URL}${videoArray}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal for PDF */}
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">View PDF</h5>
              <button
                type="button"
                className="close report_clodebtn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closePdfModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {posts.map((pdf, index) => (
                <iframe
                  key={index}
                  title="PDF Viewer"
                  src={`${process.env.REACT_APP_MAIN_URL}` + pdf.pdf}
                  width="100%"
                  height="500px"
                ></iframe>
              ))}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal-backdrop fade show"></div>
      )}

      {/* Bootstrap Modal for Comments */}
      <div
        className={`modal fade ${cModalOpen ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: cModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Comments</h5>
              <button
                type="button"
                className="close report_clodebtn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeCModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <ul id="report_ul">
                {posts.map((comment, index) => (
                  <li key={index}>{comment.comment}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {cModalOpen && (
        <div className="modal-backdrop fade show"></div>
      )}

    </>
  );
}

export default Report;
