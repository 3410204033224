import React from 'react'

const Newpage = (props) => {
  const {selectProduct} = props;
  return(
  
    <div style={{width:"100%"}}>
      <div>
        
       <img src={selectProduct && selectProduct.product_image} />
      </div>
    </div>
  )
}

export default Newpage
