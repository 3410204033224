import * as type from "../store/redux-constant"

const initialState = {
    notifications: [],
    count: 0,
    error: false,
    errorMessage: '',
	loader:false
}

const eventReducers = (state = initialState, action) => {
	
    switch (action.type) {
		case type.LOADER:
			return { ...state, loader: true }
		case type.GET_NOTIFICATION_SUCCESS:
			return {...state, loader: false, count: action.payload.count, notifications: action.payload.notifications}
		case type.GET_NOTIFICATION_ERROR:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage: 'getting errors in notifications'
			}
		default:
			return state
	}
}

export default eventReducers