import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import { getCardListOption, InitiatePayment } from '../actions'
import Spinner from "./subcomponent/spinner/Spinner";
import RechargeList from "./subcomponent/payment/RechargeList";
import History from "../History";
import { Alert } from "../alertServices";

const synth = window.speechSynthesis;
class Wallet extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userDetail: JSON.parse(localStorage.getItem('userDetail')),
			cardForm: {},
			errors: {},
			stripeOptions: {
				clientSecret: '',
				appearance: {
					theme: 'stripe'
				}
			},
			cardOpt: []
		}
		this.handleInput = this.handleInput.bind(this)
		this.speak = this.speak.bind(this);
	}

	componentDidMount() {
		this.props.getCardListOption()
	}

	handleInput(e, field) {
		let _cardForm = this.state.cardForm;
		_cardForm[field] = e.target.value;
		this.setState({ cardForm: _cardForm });
	}

	handleSelect(e, field) {
		let cardValue = e.target.value
		if (cardValue === 'add-card') {
			History.push('add-balance')
		} else {
			let _cardForm = this.state.cardForm;
			_cardForm[field] = cardValue;
			this.setState({ cardForm: _cardForm });
		}
	}

	handleValidation() {
		let fields = this.state.cardForm;
		// console.log(fields["amount"])
		// console.log(Number(fields["amount"], 10))
		let errors = {};
		let formIsValid = true;
		if (isNaN(Number(fields["amount"], 10))) {
			formIsValid = false;
			errors["amount"] = "Please Enter valid amount.";
		}

		if (fields["amount"] === null || (typeof fields["amount"] !== "undefined" && Number(fields["amount"], 10) <= 0)) {
			formIsValid = false;
			errors["amount"] = "Please Enter valid amount.";
		}

		this.setState({ errors: errors });
		return formIsValid;
	}

	initPayment(e) {
		e.preventDefault();
		if (this.handleValidation(e)) {
			// console.log('form', this.state.cardForm)
			this.props.InitiatePayment(this.state.cardForm)
			// console.log(this.props.loader, this.props.client_secret)
		} else {
			// console.log('invalid')
		}
	}

	static getDerivedStateFromProps(props, state) {
		// console.log(props.cardListOpt)
		if (props) {
			let stripe_option = { ...state.cardOpt }
			stripe_option.cardOpt = props.cardListOpt
			return { ...state, cardOpt: props.cardListOpt };
		}
		return state;
	}

	speak(){
		if (synth.speaking) {
			console.error('speechSynthesis.speaking');
			return;
		}
		var voices = synth.getVoices().sort(function (a, b) {
			const aname = a.name.toUpperCase(), bname = b.name.toUpperCase();
			if ( aname < bname ) return -1;
			else if ( aname === bname ) return 0;
			else return +1;
		});
		var inputTxt = document.querySelector('.balance-title').innerHTML;
		console.log(inputTxt.replace(/<\/?[^>]+(>|$)/g, ""))

		var texttospeech = 'Hi toshu, how are you?'
		texttospeech = inputTxt.replace(/<\/?[^>]+(>|$)/g, "")

		texttospeech += '. Your total balance is $0'

		if (texttospeech !== '') {
		var utterThis = new SpeechSynthesisUtterance(texttospeech);
		utterThis.onend = function (event) {
			console.log('SpeechSynthesisUtterance.onend');
		}
		utterThis.onerror = function (event) {
			console.error('SpeechSynthesisUtterance.onerror');
		}
		// var selectedOption = voiceSelect.selectedOptions[0].getAttribute('data-name');
		var selectedOption = 'Google US English';
		for(let i = 0; i < voices.length ; i++) {
		  if(voices[i].name === selectedOption) {
			utterThis.voice = voices[i];
			break;
		  }
		}
		utterThis.pitch = 1;
		utterThis.rate = 1;
		synth.speak(utterThis);
	  }
	}
	


	render() {
		// console.log(this.state.cardOpt)
		return (
			<>
				{/* Navigation Bar Imported from Layouts */}
				<Navbar />

				{
					this.props.loader === false && this.props.client_secret === "" ?
						Alert.success({ title: "your payment is successful." }) : ''
				}

				{/* Header Starts */}
				<div id="header-section" className="header-area">
					<div className="container">
						<div className="row">
							<div className="col-sm-4 col-3">
								<div className="menu-bar">
									<i className="fa fa-bars" />
								</div>
							</div>
							<div className="col-sm-4 col-4 text-center">
								<Link to={'/'} className="logo">
									<img src={process.env.PUBLIC_URL + '/assets/img/averti2.png'} alt="logo" />
								</Link>
							</div>
							<div className="col-sm-4 col-5 text-right">
								<ul className="header-right">
									<li>
										<Link to={'/'}>
											<i className="fa fa-envelope" />
											<span className="badge">9</span>
										</Link>
									</li>
									<li>
										<Link to={'/notification'}>
											<i className="fa fa-bell animated infinite swing" />
											<span className="badge">6</span>
										</Link>
									</li>
									<li>
										<Link id="header-user" className="header-user" to={'/user-setting'}>
											<img src={this.state.userDetail?.profile_image ? this.state.userDetail?.profile_image : process.env.PUBLIC_URL + '/assets/img/user.png'} alt="img" width={25} height={25} />
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{/* Header Ends */}

				<div className="add-balance-inner-wrap">
					<div className="container">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Add Balance</h5>
								</div>
								<div className="modal-body">
									<div className="action-sheet-content">

										<div className="form-group basic">
											<label className="label">Enter Amount</label>
											<div className="input-group mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="input1"><i className="dollar sign icon" /></span>
												</div>
												<input
													type="number"
													onChange={(e) => this.handleInput(e, 'amount')}
													className="form-control form-control-lg"
													placeholder="Enter your amount"
												/>
											</div>
											{this.state.errors.amount !== undefined ? <p className="text-center text-danger">{this.state.errors.amount}</p> : null}
										</div>
										<div className="form-group basic">
											<label className="label">Select Card</label>
											<div className="input-group mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="input1"><i className="fa fa-credit-card" /></span>
												</div>
												<select className="form-control" onChange={(e) => this.handleSelect(e, 'card_id')}>
													<option value={''}>Select Card</option>
													{
														this.state.cardOpt.length > 0 && this.state.cardOpt.map((item, i) => {
															return (
																<option value={item.id} key={item}>{item.card_holder_name}</option>
															)
														})
													}
													<option value={'add-card'}>ADD CARD</option>
												</select>
											</div>
											{this.state.errors.amount !== undefined ? <p className="text-center text-danger">{this.state.errors.amount}</p> : null}
										</div>
										<div className="form-group basic">
											<button type="button" className="btn-c btn-primary btn-block btn-lg" onClick={this.initPayment.bind(this)} >Add Balance {this.props.loader ? <Spinner size="mini" /> : null}</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Navbar Ends */}

				{/* balance start */}
				<div className="balance-area pd-top-40 mg-top-35">
					<div className="container">
						<div className="balance-area-bg balance-area-bg-home">
							<div className="balance-title text-center" onClick={this.speak} style={{'cursor':'pointer'}}>
								<h6>Welcome! <br />{this.state.userDetail?.name}</h6>
							</div>
							<div id="balance-section" className="ba-balance-inner text-center">
								<div className="icon">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/12.png'} alt="img" />
								</div>
								<h5 className="title">Total Balance</h5>
								<h5 className="amount">${this.state.userDetail?.balance}</h5>
							</div>
						</div>
					</div>
				</div>
				{/* balance End */}

				{/* add balance start */}
				<div className="add-balance-area pd-top-40">
					<div className="container">
						<div className="ba-add-balance-title ba-add-balance-btn">
							<h5>Add Balance</h5>
							<i className="fa fa-plus" />
						</div>

						{/* add balance End */}


						{/* Payment History */}
						<RechargeList />
						{/* Payment History */}
					</div>
				</div>
				{/* Footer Component Imported from Layouts */}
				<Footer />
			</>
		);
	}
}

const mapStateToProps = state => {
	// console.log(state.payment)
	return {
		client_secret: state.payment.clientSecret,
		loader: state.payment.loader,
		cardListOpt: state.payment.cardListOpt
	}
}

export default connect(mapStateToProps, { getCardListOption, InitiatePayment })(Wallet);