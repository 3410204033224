import React from "react";
import { Form } from "react-bootstrap";
import CountrySelectBox from "../widget/country-select";

function AddressInformation(props) {
  const { formState, setFormState, type, errors } = props;

  const addressType = type === 'individual' ? 'residential_address' : 'registered_address';

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>
          <b>Residential Address:</b>
        </Form.Label>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Line1</Form.Label>
        <Form.Control
          name="line1"
          value={formState.counterpart[type][addressType].line1}
          onChange={(e) =>
            setFormState({
              ...formState,
              counterpart: {
                ...formState.counterpart,
                [type]: {
                  ...formState.counterpart[type],
                  [addressType]: {
                    ...formState.counterpart[type][addressType],
                    line1: e.target.value,
                  },
                },
              },
            })
          }
          type="text"
          placeholder=""
        />
        <span className="form-error text-danger">{errors.line1}</span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Line2</Form.Label>
        <Form.Control
          name="line2"
          value={formState.counterpart[type][addressType].line2}
          onChange={(e) =>
            setFormState({
              ...formState,
              counterpart: {
                ...formState.counterpart,
                [type]: {
                  ...formState.counterpart[type],
                  [addressType]: {
                    ...formState.counterpart[type][addressType],
                    line2: e.target.value,
                  },
                },
              },
            })
          }
          type="text"
          placeholder=""
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Country</Form.Label>
        <CountrySelectBox
          name="country"
          value={formState.counterpart[type][addressType].country}
          onChange={(e) =>
            setFormState({
              ...formState,
              counterpart: {
                ...formState.counterpart,
                [type]: {
                  ...formState.counterpart[type],
                  [addressType]: {
                    ...formState.counterpart[type][addressType],
                    country: e.target.value,
                  },
                },
              },
            })
          }
        />
        <span className="form-error text-danger">{errors.country}</span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          name="city"
          value={formState.counterpart[type][addressType].city}
          onChange={(e) =>
            setFormState({
              ...formState,
              counterpart: {
                ...formState.counterpart,
                [type]: {
                  ...formState.counterpart[type],
                  [addressType]: {
                    ...formState.counterpart[type][addressType],
                    city: e.target.value,
                  },
                },
              },
            })
          }
          placeholder="Enter City"
        />
        <span className="form-error text-danger">{errors.city}</span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>State</Form.Label>
        <Form.Control
          name="state"
          value={formState.counterpart[type][addressType].state}
          onChange={(e) =>
            setFormState({
              ...formState,
              counterpart: {
                ...formState.counterpart,
                [type]: {
                  ...formState.counterpart[type],
                  [addressType]: {
                    ...formState.counterpart[type][addressType],
                    state: e.target.value,
                  },
                },
              },
            })
          }
          type="text"
          placeholder="State"
        />
        <span className="form-error text-danger">{errors.state}</span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          type="text"
          name="postal_code"
          value={
            formState.counterpart[type][addressType].postal_code
          }
          onChange={(e) =>
            setFormState({
              ...formState,
              counterpart: {
                ...formState.counterpart,
                [type]: {
                  ...formState.counterpart[type],
                  [addressType]: {
                    ...formState.counterpart[type][addressType],
                    postal_code: e.target.value,
                  },
                },
              },
            })
          }
          placeholder="Postal Code"
        />
        <span className="form-error text-danger">{errors.postal_code}</span>
      </Form.Group>
    </>
  );
}

export default AddressInformation;
