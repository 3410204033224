import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";


function CharityGift(props) {

    const { myData } = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const allValues =
        Number(parsedData.charity.charitable_donations) +
        Number(parsedData.charity.tithe);
    props.sendDatatc(allValues);


    return (
        <div className="dhhejk">
            <div className="daildy_living">CHARITY & GIFT</div>
            <Form>
                <div className="living_state">
                    <Form.Label className="adksk">Tithe</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="tithe"
                        value={parsedData.charity.tithe}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className=" lable_css ">Charitable Donations</Form.Label>
                    <input
                        type="text"
                        className=" input_style jkha "
                        name="charitable_donations"
                        value={parsedData.charity.charitable_donations}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css_">Total Amount</Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    );
}

export default CharityGift;
