import React from "react";
import { Form } from "react-bootstrap";

function BasicInformation(props) {
  const { formState, setFormState } = props;

  const onChange = (e) => {
    props.setType(e.target.value);
  };

  const organization = () => {
    const { errors } = props;
    return (
      <>
        <Form.Group className="mb-3">
          <div className="d-flex">
            <Form.Check
              value={true}
              checked={formState.counterpart.organization.is_vendor}
              type="switch"
              id="custom-switch-individual-vendor"
              label="Is Vendor"
              className="mr-5"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  counterpart: {
                    ...formState.counterpart,
                    organization: {
                      ...formState.counterpart.organization,
                      is_vendor: e.target.checked,
                    },
                  },
                })
              }
            />
            <Form.Check
              value={true}
              checked={formState.counterpart.organization.is_customer}
              type="switch"
              id="custom-switch-individual-customer"
              label="Is Customer"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  counterpart: {
                    ...formState.counterpart,
                    organization: {
                      ...formState.counterpart.organization,
                      is_customer: e.target.checked,
                    },
                  },
                })
              }
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Legal Name</Form.Label>
          <Form.Control
            type="text"
            name="legal_name"
            value={formState.counterpart.organization.legal_name}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  organization: {
                    ...formState.counterpart.organization,
                    legal_name: e.target.value,
                  },
                },
              })
            }
            placeholder="Legal Name"
            required
          />
          <span className="form-error text-danger">{errors.legal_name}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            maxLength="10"
            value={formState.counterpart.organization.phone}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  organization: {
                    ...formState.counterpart.organization,
                    phone: e.target.value,
                  },
                },
              })
            }
            placeholder="Phone"
          />
          <span className="form-error text-danger">{errors.phone}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formState.counterpart.organization.email}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  organization: {
                    ...formState.counterpart.organization,
                    email: e.target.value,
                  },
                },
              })
            }
            placeholder="Email"
            required
          />
          <span className="form-error text-danger">{errors.email}</span>
        </Form.Group>
      </>
    );
  };

  const individual = () => {
    const { errors } = props;

    return (
      <>
        <Form.Group className="mb-3">
          <div className="d-flex">
            <Form.Check
              value={true}
              checked={formState.counterpart.individual.is_vendor}
              type="switch"
              id="custom-switch-individual-vendor"
              label="Is Vendor"
              className="mr-5"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  counterpart: {
                    ...formState.counterpart,
                    individual: {
                      ...formState.counterpart.individual,
                      is_vendor: e.target.checked,
                    },
                  },
                })
              }
            />
            <Form.Check
              value={true}
              checked={formState.counterpart.individual.is_customer}
              type="switch"
              id="custom-switch-individual-customer"
              label="Is Customer"
              onChange={(e) =>
                setFormState({
                  ...formState,
                  counterpart: {
                    ...formState.counterpart,
                    individual: {
                      ...formState.counterpart.individual,
                      is_customer: e.target.checked,
                    },
                  },
                })
              }
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Select
            name="title"
            aria-label="Title Select Box"
            value={formState.counterpart.individual.title}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  individual: {
                    ...formState.counterpart.individual,
                    title: e.target.value,
                  },
                },
              })
            }
          >
            <option value="Mr.">Mr.</option>
            <option value="Ms.">Ms.</option>
            <option value="Dr.">Dr.</option>
            <option value="Prof.">Prof.</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="text"
            name="first_name"
            value={formState.counterpart.individual.first_name}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  individual: {
                    ...formState.counterpart.individual,
                    first_name: e.target.value,
                  },
                },
              })
            }
            placeholder="First Name"
            required
          />
          <span className="form-error text-danger">{errors.first_name}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="text"
            name="last_name"
            value={formState.counterpart.individual.last_name}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  individual: {
                    ...formState.counterpart.individual,
                    last_name: e.target.value,
                  },
                },
              })
            }
            placeholder="Last Name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            maxLength="10"
            value={formState.counterpart.individual.phone}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  individual: {
                    ...formState.counterpart.individual,
                    phone: e.target.value,
                  },
                },
              })
            }
            placeholder="Phone"
          />
          <span className="form-error text-danger">{errors.phone}</span>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formState.counterpart.individual.email}
            onChange={(e) =>
              setFormState({
                ...formState,
                counterpart: {
                  ...formState.counterpart,
                  individual: {
                    ...formState.counterpart.individual,
                    email: e.target.value,
                  },
                },
              })
            }
            placeholder="Email"
            required
          />
          <span className="form-error text-danger">{errors.email}</span>
        </Form.Group>
      </>
    );
  };

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>
          <b>Basic Information:</b>
        </Form.Label>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Type</Form.Label>
        <Form.Select
          name="type"
          aria-label="Default select example"
          onChange={onChange}
          value={props.type}
        >
          <option value="individual">Individual</option>
          <option value="organization">Organization</option>
        </Form.Select>
      </Form.Group>

      {props.type === "individual" ? individual() : organization()}
    </>
  );
}

export default BasicInformation;
