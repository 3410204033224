import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import './main.css';
import '../darkmode.css';
import Homeicon from '../icons/home.png';
import Callicon from '../icons/phone.png';
import Chaticon from '../icons/chat.png';
import Billsicon from '../icons/bills.png';
import Filesicon from '../icons/files.png';
import Healthicon from '../icons/health.png';
import Stocksicon from '../icons/stocks.png';
import Reporticon from '../icons/report.png';
import settingIcon from '../icons/settings.png';
import ZoomIcon from '../icons/zoom.webp';
import Galleryicon from '../icons/gallery.png';
import Taskicons from '../icons/task.png';
import TrainingIcon from '../icons/training.png';
import Notificationicons from '../icons/notification.jpg';
import FooterModal from './layouts/Footer-Modal';
import { connect } from "react-redux";
import { getChats, GetProfile } from '../actions';
import CalendarButton from './calendar-button';
import SwitchTheme from "./Switchmode";
import Outfiticon3 from '../icons//Outfiticon3.jpg';


function Mainpage(props) {

    const { userDetail, GetProfile, getChats } = props;

    useEffect(() => {
        if(userDetail.id === '')
            GetProfile()
        getChats()

        return () => {}
    }, [GetProfile, getChats])

    return (
        <>
            <div className="mainpage">
                <div className="mainpagehead">
                    <div className="container mainpagecontain">
                        <div className="row mainpagerow">
                            <ul className='mainpageul' style={{marginBottom:"50px"}}>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/home'}>
                                        <img loading='lazy' src={Homeicon} alt={Homeicon} className="homeIcon" />
                                        <h6 className='ta-center'>Home</h6>
                                    </Link>
                                </li>

                                <li className='mainpageli'>
                                    <CalendarButton />
                                </li>

                                <li className='mainpageli'>
                                    <div className="ba-add-balance-btnhome-clicked __callbtn" data-toggle="modal" data-target="#__footer-madal">
                                        <img loading='lazy' src={Callicon} alt={Callicon} className="callIcon" />
                                        <h6 className='ta-center'>Call</h6>
                                        <FooterModal />
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="row mainpagerow">
                            <ul className='mainpageul' style={{marginBottom:"50px"}}>
                                
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/chats'}>
                                        <img loading='lazy' src={Chaticon} alt={Chaticon} className="chatIcon" />
                                        <h6 className='ta-center'>Chat</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/report-page'}>
                                        <img loading='lazy' src={Reporticon} alt={Reporticon} className="reportIcon" />
                                        <h6 className='ta-center'>Reports</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/document'}>
                                        <img loading='lazy' src={Filesicon} alt={Filesicon} className="filesIcon" />
                                        <h6 className='ta-center'>Document</h6>
                                    </Link>
                                </li>
                            </ul>
                        </div>


                        <div className="row mainpagerow">
                            <ul className='mainpageul' style={{marginBottom:"50px"}}>

                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/task'}>
                                        <img loading='lazy' src={Taskicons} alt={Taskicons} className="taskIcon" />
                                        <h6 className='ta-center'>Task</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/outfits-section'}>
                                        <img loading='lazy' src={Outfiticon3} alt={Outfiticon3} className="zoomIcon" />
                                        <h6 className='ta-center'>Outfits</h6>
                                    </Link>
                                </li>
                                <li className='mainpageli'>
                                    <Link className="ba-add-balance-btn" to={'/zoom'}>
                                        <img loading='lazy' src={ZoomIcon} alt={ZoomIcon} className="zoomIcon" />
                                        <h6 className='ta-center'>Meetings</h6>
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className='row mb-auto'>
                            <div className='col-md-12 d-flex align-items-center justify-content-center'>
                                <SwitchTheme />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        client_secret: state.payment.clientSecret,
        loader: state.payment.loader,
        userDetail: state.user.userDetail,
        chats: state.chat.chats,
        events: state.event.events
    }
}

export default connect(mapStateToProps, { getChats, GetProfile })(Mainpage);
