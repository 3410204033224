import { AVATAR_UPDATE, AVATAR_UPDATE_ERROR, GET_GALLERY, GET_GALLERY_ERROR, GET_GALLERY_SUCCESS, LOADER_PROFILE, PASSWORD_CHANGE, PASSWORD_CHANGE_ERROR, PROFILE_ERROR, PROFILE_FETCH, PROFILE_UPDATE, TOGGLE_THEME } from "../store/redux-constant";

const initialState = {
	userDetail: {
		first_name:'',
		middle_name:'',
		last_name:'',
		email:'',
		mobile:'',
		city:'',
		state:'',
		zip_code:'',
		country:'',
		address:'',
		id: '',
		avatar: null,
	},
	error: false,
	errorMessage: '',
	loader:false,
	status: false,
	gallery: [],
	darkTheme: localStorage.getItem('darkTheme', '0') === '1' ? true : false
}

const profileReducers = (state = initialState, action)=>{
    switch (action.type) {
		case LOADER_PROFILE:
			return { ...state, loader:true}
		case PROFILE_FETCH:
			return { ...state, loader:false, userDetail: action.payload }
		case PROFILE_UPDATE:
			return { ...state, loader:false, userDetail: action.payload }
		case PROFILE_ERROR:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage:'getting error fetching data'
			}
		case AVATAR_UPDATE: 
			return {...state, loader: false, userDetail: action.payload}
		case AVATAR_UPDATE_ERROR:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage:'getting error uploading profile image'
			}
		case PASSWORD_CHANGE: return {...state, loader: false, status: true}
		case PASSWORD_CHANGE_ERROR: return {...state, loader: false, status: false}

		case GET_GALLERY:
			return {...state, loader: true}
		case GET_GALLERY_SUCCESS:
			return {...state, loader: false, gallery: action.payload}
		case GET_GALLERY_ERROR:
			return {...state, loader: false, error: action.payload}

		case TOGGLE_THEME:
			return {...state, darkTheme: action.payload}
		default:
			return state
	}
}

export default profileReducers