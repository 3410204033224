import React from "react";
import IMG1 from "./images/img-1.jpg";
import IMG2 from "./images/img-2.jpg";
import IMG3 from "./images/img-3.jpg";
import IMG4 from "./images/img-4.jpg";
import IMG5 from "./images/img-5.jpg";
import IMG6 from "./images/img-6.jpeg";
import IMG7 from "./images/img-7.jpeg";
import IMG8 from "./images/img-8.jpg";
import IMG9 from "./images/img-9.jpg";
import { FormControlLabel, Switch } from "@mui/material";

export default function SimpleDialog(props) {
  const images = [IMG1, IMG2, IMG3, IMG4, IMG5, IMG6, IMG7, IMG8, IMG9];

  const changeBackgroundImage = (img, event) => {
    event.preventDefault();
    props.changeBackground("image", img);
  };

  const onChange = (e) => {
    if (e.target.checked) {
      props.changeBackground("blur", 10);
    } else {
      props.changeBackground("blur", 0);
    }
  };

  return (
    <div style={{ padding: "10px 28px 80px" }}>
      <FormControlLabel
        control={<Switch onChange={onChange} />}
        label="Blured Background"
      />

      <h4 className="mt-5">Choose Background Image</h4>
      <div style={{ flexWrap: "wrap" }} className="flex gap-3">
        <a href="#" onClick={(e) => { e.preventDefault(); props.changeBackground('original', null); }}>
          <img className="bg-img-thumb" src={'https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg'} />
        </a>
        {images.map((imgObj, i) => (
          <a key={i} href="#" onClick={(e) => changeBackgroundImage(imgObj, e)}>
            <img key={i} className="bg-img-thumb" src={imgObj} />
          </a>
        ))}
      </div>
    </div>
  );
}
