import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";


function Education(props) {

    const { myData } = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const allValues =
        Number(parsedData.education.tuition_payment) +
        Number(parsedData.education.room_board);

    props.sendDataedu(allValues);


    return (
        <div className="dhhejk">
            <div className="daildy_living">EDUCATION</div>
            <Form>
                <div className="living_state">
                    <Form.Label className="adksk">Room & Board</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="room_board"
                        value={parsedData.education.room_board}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Tuition Payment</Form.Label>
                    <input
                        type="text"
                        className=" input_style jkha "
                        name="tuition_payment"
                        value={parsedData.education.tuition_payment}
                    />
                </div>

                <div className="living_state">
                    <Form.Label className="lable_css_">Total Amount</Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    );
}

export default Education;
