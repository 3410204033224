import React from 'react'
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';
import { Link } from 'react-router-dom';

function financeHistory() {
  return (
    <div>
   
    <Header/>
      {/* history start */}
				<div className="history-area pd-top-40">
					<div className="container">
						<div className="section-title">
							<h3 className="title">Finance History</h3>
							<Link to={'/saving'}>View All</Link>
						</div>
						<div className="ba-history-inner">
							<div className="row custom-gutters-20">
								<div className="col-6">
									<div className="ba-single-history ba-single-history-one" style={{ backgroundImage: "url(assets/img/bg/3.png)" }}>
										<h6>Income</h6>
										<h5>$58,968.00</h5>
									</div>
								</div>
								<div className="col-6">
									<div className="ba-single-history ba-single-history-two" style={{ backgroundImage: "url(assets/img/bg/3.png)" }}>
										<h6>Expenses</h6>
										<h5>$50,968.00</h5>
									</div>
								</div>
								<div className="col-6">
									<div className="ba-single-history ba-single-history-three" style={{ backgroundImage: "url(assets/img/bg/3.png)" }}>
										<h6>Total Bills</h6>
										<h5>$50,968.00</h5>
									</div>
								</div>
								<div className="col-6">
									<div className="ba-single-history ba-single-history-four" style={{ backgroundImage: "url(assets/img/bg/3.png)" }}>
										<h6>Savings</h6>
										<h5>$58.00</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* history End */}

                <Footer/>
    </div>
  )
}

export default financeHistory;
