import React from 'react'
import Section from './layouts/Section';
import { Link } from 'react-router-dom';
import './prashant.css';

function BillsReport() {
    return (
        <div>
            <Section allNotification={false} title={'Bills Report'}>
                <div className="blog-area pd-top-36 pb-2 mg-top-40 _healthreport_head">
                    <div className="container">
                        <div className="row">
                            <div className="single-blog">
                                <div className="thumb text-center mb-4 mt-4">
                                    <p style={{ fontSize: "40px" }}>Coming</p>
                                    <p style={{ fontSize: "30px" }}>Soon</p>
                                </div>
                                <div className="back_button mt-4 mb-4">
                                    <Link to={'/report-page'}>Go Back</Link>
                                </div>
                                <div className="details">
                                    <p>The page you are looking for might have been Under Development, Live soon ASAP</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Section>
        </div>
    )
}

export default BillsReport;