import React, { useState, useEffect } from 'react';
import './custom.css';
import Section from './layouts/Section';
import TaskList1 from './Task/TaskList1';
import { connect } from 'react-redux';
import axios from 'axios';

function Task1(props) {
    const [tasks, setTasks] = useState([]);
    const [error, setError] = useState(null);
    const { user } = props;

    const userId = user ? user.id : null;
    console.log("user", tasks)

    useEffect(() => {
        const getTasks = async () => {
            if (!userId) {
                return;
            }

            const token = window.localStorage.getItem('AUTH_TOKEN');
            try {
                const response = await axios.get(`https://live.avertisystems.com/api/v1/task_new/get/show/${user.id}`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });
                setTasks(response.data);
                console.log("Tasks set successfully:", response.data); // Log tasks after they are set
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message === 'Unauthenticated.') {
                    setError('You are not authenticated. Please log in.');
                } else {
                    setError('API request error');
                    console.error("API request error:", error);
                }
            }
        };

        getTasks();
    }, [userId]);

    useEffect(() => {
        console.log("Tasks set successfully:", tasks);
    }, [tasks]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='' style={{ height: "100vh" }}>
            <Section allNotification={false} searchPopup={true} title={'Task'}>
                <div style={{ marginTop: "60px" }}>
                    <TaskList1 tasks={tasks} user={user} />
                </div>
            </Section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.userDetail,
})

export default connect(mapStateToProps)(Task1);
