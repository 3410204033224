import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Chart from "./Chart";
import './StockDetails.css'; // Custom CSS file

const StockDetails = ({ selectedSymbol, allstock }) => {
  const stockInfo = {
    previousClose: selectedSymbol && selectedSymbol.regularMarketPreviousClose?.fmt,
    open: selectedSymbol && selectedSymbol.regularMarketOpen?.fmt,
    bid: selectedSymbol && selectedSymbol.bid?.raw + " X " + "200",
    ask: selectedSymbol && selectedSymbol.ask?.raw + " X " + "100",
    DayRange: selectedSymbol && selectedSymbol.regularMarketDayRange?.fmt,
    fiftyTwoWeekRange: selectedSymbol && selectedSymbol.fiftyTwoWeekRange?.fmt,
    Volume: selectedSymbol && selectedSymbol.regularMarketVolume?.fmt,
    AvgVolume: selectedSymbol && selectedSymbol.averageDailyVolume3Month?.fmt,
  };

  const stockinfo_2 = {
    marketCap: selectedSymbol && selectedSymbol?.marketcap,
    Beta: selectedSymbol && selectedSymbol.regularMarketPreviousClose?.raw,
    PeRatio: selectedSymbol && selectedSymbol.trailingPE?.fmt,
    ESP: selectedSymbol && selectedSymbol.epsTrailingTwelveMonths?.fmt,
    EarningsDate: selectedSymbol && selectedSymbol.earningsTimestamp?.fmt,
    ForwardDividendYield: "N/A (N/A)",
    ExDividendDate: "N/A",
    oneYearTargetEst: "192.88",
  }

  return (
    <Container fluid>
      <Row className="stock-details">
        <Col xs={12} md={3} className="stock-info">
          {Object.entries(stockInfo).map(([label, value], index) => (
            <div key={index} className="stock-info-item">
              <div className="label">
                {label
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())
                  .slice(0, 15)}
              </div>
              <div className="value">
                {value}
              </div>
            </div>
          ))}
        </Col>
        <Col xs={12} md={3} className="stock-info">
          {Object.entries(stockinfo_2).map(([label, value], index) => (
            <div key={index} className="stock-info-item">
              <div className="label">
                {label
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())
                  .slice(0, 15)}
              </div>
              <div className="value">
                {value}
              </div>
            </div>
          ))}
        </Col>
        <Col xs={12} md={6}>
          <Chart />
        </Col>
      </Row>
    </Container>
  );
}

export default StockDetails;
