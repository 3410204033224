import React, { useEffect, useState, useCallback } from "react";
import './stockTickers.css';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { get_all_stock } from "../../../src/api/auth";

import Investment from './Investment'
import UsMarket from "./UsMarket";
import Tsp from "./Tsp";
import StockOpportunity from "./StockOpportunity";
import Reports from "./Reports";
import Section from "../layouts/Section";


const StockTickers = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const [searchOpen, setSearchOpen] = useState(false);
  
  //const [getRows, setRows] = useState('');
  const [searchText, setText] = useState({
    search: '',
  });

  const checkTest = () => {
    alert("test");
  }
  const columns = [
    { field: 'id', headerName: 'ID', width: 70, color: 'black', hide: true, disableColumnMenu: true },
    {
      field: 'assets',
      headerClassName: 'hideRightSeparator_1',
      headerName: 'ASSETS',
      width: 400,
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false
    },
    {
      field: 'irr',
      headerClassName: 'hideRightSeparator_2',
      headerName: 'IRR',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false
    },
    {
      field: 'value',
      headerClassName: 'hideRightSeparator_3',
      headerName: 'value',
      type: 'number',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false
    },
    {
      field: 'blank',
      headerName: '',
      sortable: false,
      width: 190,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (params) => {
        // return (<span className="clickable" onClick={checkTest}><FormatAlignLeftIcon /></span>)
        return (<span className="clickable" onClick={checkTest}><i class="bi bi-list-columns"></i></span>)
      },
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      headerClassName: 'hideRightSeparator_4'
    },
    {
      field: 'fullname',
      headerName: '',
      sortable: false,
      width: 190,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (params) => {
        // return (<span className="clickable" onClick={checkTest}><MoreVertIcon /></span>)
        return (<span className="clickable" onClick={checkTest}><i class="bi bi-three-dots-vertical"></i></span>)
      },
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      headerClassName: 'hideRightSeparator_4'
    },
  ];

  const rows = [
    { id: 1, assets: 'S&P 500', irr: '2', value: 35, blank: '', fullName: '' },
    { id: 2, assets: 'Dow Jones Industrial Average', irr: '3', value: 42 },
    { id: 3, assets: 'Nasdaq Composite', irr: '4', value: 45 },
    { id: 4, assets: 'Russell 2000 Index', irr: '5', value: 16 },
    { id: 5, assets: 'S&P/TSX composite Index', irr: '6', value: 48 },
    { id: 6, assets: 'IBOVESPA', irr: '7', value: 150 },
  ];

  const [getCompanyList, setCompanyList] = useState([]);
  const getAllstaock = async () => {
    try {
      const response = await get_all_stock();
      if (response) {
        setCompanyList(response);
      } else {
        setCompanyList([]);
      }
    } catch (error) {
      console.log(error);
      setCompanyList([]);
    }
  };
  

useEffect(() => {
  getAllstaock();
}, []);


  console.log("getCompanyList",getCompanyList)
  const getStockData = getCompanyList && getCompanyList[0] && getCompanyList[0].quotes ? getCompanyList[0].quotes : [];

  // const handleOnChange = (event) => {
  //   setText({
  //     ...searchText,
  //     [event.target.name]: event.target.value,
  //   });
  // }
  // Function to reset state values

const resetState = () => {

  // setInputValue('');
  // setSharesPerStock({});
  // setTotalValue(0);
  setShowAutocompleteFocus(true);
};



  const handleClickOpen = () => {
    setShow(true);
  };
  const handleClose = () => {
    resetState();
    setShow(false);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectStock, setSelectStock] = useState();
  console.log("selectStock",selectStock)

  const handleStockSelect = (item) => {
    setSelectStock(item);
  };

  const handleCloseB = () => setSelectStock(false)

  const [show, setShow] = useState(false);

  const handleClose_ = () => setShow(false);
  const handleShow_ = () => setShow(true);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStocks, setSelectedStocks] = useState([]);
  console.log("selectedStocksjb",selectedStocks)
  const [getSelectedstock, setGetSelectedstock] = useState()
  console.log("getSelectedstockji",getSelectedstock)


  const handleRemoveStock = (symbolToRemove) => {
    const updatedSelectedStocks = getSelectedstock.filter(
      (stock) => stock.symbol !== symbolToRemove
    );
    const updatedSelectedSymbols = updatedSelectedStocks.map((stock) => stock.symbol);
  
    console.log('Updated Selected Stocks:', updatedSelectedStocks);
    console.log('Updated Selected Symbols:', updatedSelectedSymbols);
  
    setGetSelectedstock(updatedSelectedStocks);
    setSelectedStocks(updatedSelectedSymbols);
  };
  

  const data = Array.isArray(getStockData)
  ? getStockData.map((stock) => stock.symbol)
  : [];
  
  const filteredData = data.length ? data.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];
  
  const handleAutocompleteChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
  
    const selectedStockData = getStockData.filter(stock =>
      selectedOptions.includes(stock.symbol)
    );
  
    const newSelectedStocks = [...selectedStockData];
    const newSelectedSymbols = [...selectedOptions];
  
    setGetSelectedstock((prevSelectedStocks) => {
      const uniqueStocks = new Map();
      (prevSelectedStocks || []).forEach(stock => uniqueStocks.set(stock.symbol, stock));
      newSelectedStocks.forEach(stock => uniqueStocks.set(stock.symbol, stock));
      return Array.from(uniqueStocks.values());
    });
  
    setSelectedStocks((prevSelectedSymbols) => {
      const uniqueSymbols = new Set([...prevSelectedSymbols, ...newSelectedSymbols]);
      return Array.from(uniqueSymbols);
    });
  };
  
  



const [showAutocompleteFocus, setShowAutocompleteFocus] = useState(true);

const handleAutocompleteFocus = () => {
  setShowAutocompleteFocus(true);
};

const handleAutocompleteBlur = () => {
  setShowAutocompleteFocus(false);
};

 const [numberOfShares, setNumberOfShares] = useState('');
 const [sharesPerStock, setSharesPerStock] = useState({});
 const [totalValue_2, setTotalValue_2] = useState(0);


 const handleNumberOfSharesChange = (event, stock) => {
  setSharesPerStock((prevSharesPerStock) => ({
    ...prevSharesPerStock,
    [stock.symbol]: event.target.value,
  }));
};

const calculateTotalValue = useCallback(
  (stock) => {
    if (!stock) {
      return 0;
    }
    const pricePerShare = stock.regularMarketPrice?.raw;
    // Check if the stock is the one selected by clicking on the card
    if (selectStock && stock.symbol === selectStock.symbol) {
      const shares = numberOfShares;
      if (shares !== undefined && !isNaN(pricePerShare) && !isNaN(shares)) {
        const calculatedValue = pricePerShare * parseInt(shares);
        return calculatedValue;
      }
    } else {
      // Handle the stocks selected from the Autocomplete component
      const shares = sharesPerStock[stock.symbol];
      if (shares !== undefined && !isNaN(pricePerShare) && !isNaN(shares)) {
        const calculatedValue = pricePerShare * parseInt(shares);
        return calculatedValue;
      }
    }

    return ;
  },
  [sharesPerStock, numberOfShares, selectStock]
);

useEffect(() => {
  if (Array.isArray(getSelectedstock)) {
    getSelectedstock.forEach((stock) => {
      const calculatedValue = calculateTotalValue(stock);
      setTotalValue_2(calculatedValue)
    });
  }
}, [calculateTotalValue, getSelectedstock, ]);


const [inputValue, setInputValue] = useState('');
const [totalValue, setTotalValue] = useState(0);

const handleInputChange = (event) => {
  setInputValue(event.target.value.toString());
};


const calculateTotalValueWithInput = useCallback(() => {
  if (selectStock && inputValue) {
    const stockPrice = selectStock.regularMarketPrice?.raw;
    const shares = parseInt(inputValue);

    if (!isNaN(stockPrice) && !isNaN(shares)) {
      return stockPrice * shares;
    }
  }
  return null;
}, [selectStock, inputValue]);


useEffect(() => {
  const calculatedValue = calculateTotalValueWithInput();
  if (calculatedValue !== null) {
    setTotalValue(calculatedValue);
  } else {
    setTotalValue(0);
  }
}, [calculateTotalValueWithInput]);

  return (
    <>
    
    <Section/>
    <div className="container juyuvuj">
      <div className='juyguygou'>
      <div className='huhih'>
      <button onClick={handleClickOpen} className="addAssetss">+ASSETS</button>
      </div>
        <div className="custom-tabs">
        <div className="tab-headers">
          <div
            className={`tab-header ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => handleTabClick(1)}
          >
            Investments
          </div>
          <div
            className={`tab-header ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => handleTabClick(2)}
          >
            US Market
          </div>
          <div
            className={`tab-header ${activeTab === 3 ? 'active' : ''}`}
            onClick={() => handleTabClick(3)}
          >
            Stock 
          </div>
          <div
            className={`tab-header ${activeTab === 4 ? 'active' : ''}`}
            onClick={() => handleTabClick(4)}
          >
          TSP
          </div>
          <div
            className={`tab-header ${activeTab === 5 ? 'active' : ''}`}
            onClick={() => handleTabClick(5)}
          >
            Other
          </div>
        </div>
        <div className="tab-contentitb">
          {activeTab === 1 &&
            <div>
               <Investment handleRemoveStock={handleRemoveStock} handleCloseB={handleCloseB} selectStock={selectStock} inputValue={inputValue} getSelectedstock={getSelectedstock} sharesPerStock={sharesPerStock}/>
            </div>}

          {activeTab === 2 &&
            <div>
               <UsMarket />
            </div>}

          {activeTab === 3 &&
            <div>
              <StockOpportunity />
            </div>}
          {activeTab === 4 && 
            <div>
              <Tsp />
            </div>}

          {activeTab === 5 &&
            <div>
              <Reports />
            </div>}
        </div>
      </div>

     
    
      </div>

      <Modal ce show={show} onHide={handleClose} size="md" centered>
        <Modal.Header closeButton>
          <Modal.Title id="customized-dialog-title" style={{ marginLeft: 20, padding: 10 }}>
            Stocks
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stock-title">Get Latest Value of Your Stock</div>
          <div className="autocomplete-wrapper">
          <Form.Control
  as="select"
  multiple
  size="lg"
  value={selectedStocks}
  onChange={handleAutocompleteChange}
  onFocus={handleAutocompleteFocus}
  onBlur={handleAutocompleteBlur}
>
  {filteredData.map((option) => (
    <option key={option} value={option}>
      {option}
    </option>
  ))}
</Form.Control>


            <div className="divider" />
          </div>

          {Array.isArray(getSelectedstock) &&
            getSelectedstock.map((list_search) => (
              <div key={list_search.symbol} className="stock-card">
                <div className="stock-header">
                <div style={{display:"flex"}}>
                  <div className="stock-symbol">{list_search.symbol}</div>
                  <div className="stock-name">{list_search.shortName}</div>
                  </div>
                   <i onClick={() => handleRemoveStock(list_search.symbol)} style={{float:"right"}} class="bi bi-x-circle"></i>

                </div>

                <div>
                  <Form.Control
                    type="number"
                    value={sharesPerStock[list_search.symbol] || ''}
                    onChange={(event) => handleNumberOfSharesChange(event, list_search)}
                    placeholder="No. of shares"
                    className="stock-input"
                  />
                </div>
                <div className="total-value">Total Value: {calculateTotalValue(list_search)}</div>
              </div>
            ))}


          {!selectStock && showAutocompleteFocus && (
            <div className="stock-list">
              {getStockData
                .filter((item) => !selectedStocks.includes(item.symbol))
                .map((item, i) => (
                  <div
                    key={i}
                    className={`stock-item ${selectStock === item ? 'selected' : ''}`}
                    onClick={() => handleStockSelect(item)}
                  >
                    <div className="stock-symbol">{item.symbol}</div>
                    <div className="stock-name">{item.shortName}</div>
                  </div>
                ))}
            </div>
          )}

          {selectStock && (
            <>
              <div className="stock-card">
                <div className="stock-header">
                  <div style={{display:"flex"}}>
                  <div className="stock-symbol">{selectStock.symbol}</div>
                  <div className="stock-name">{selectStock.shortName}</div>
                  </div>
                  <div
                    onClick={handleCloseB}
                  >
                    <i style={{float:"right"}} class="bi bi-x-lgcircle"></i>
                  </div>
                </div>
                <div>
                  <Form.Control
                    type="number"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder="No. of shares"
                    className="stock-input"
                  />
                </div>
                <div className="total-value">Total Value: {calculateTotalValueWithInput()}</div>
              </div>
            </>
          )}

        <div>
          {/* <div onClick={handleOnChange} className="bhvuo">Add more</div> */}
        </div>
        <div>
          <button className="guugul" onClick={handleClose}>Done</button>
        </div>
          
        </Modal.Body>
      </Modal>
    </div>
    </>
  )
}

export default StockTickers;
