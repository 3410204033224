import React, { useState } from 'react';
import './outfit.css';
import { Modal } from 'react-bootstrap';
import SelectItem from './SelectItem';
import Draggable from 'react-draggable';

function CreateOutfit(props) {
    const { handleclose_1, formData,setDragImageBox } = props;
    const [show, setShow] = useState(false);
    const [dragopen, setDragopen] = useState(false);
    const [dragdata, setDragData] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Track the currently selected image index

    const handleOpen = () => {
        setShow(true);
    }

    const handledragOpen = () => {
        setDragopen(true);
    }

    const handleClose = () => {
        setShow(false);
    }
    

    const handleDragClose = (index) => {
        const updatedData = dragdata.filter((_, i) => i !== index);
        setDragData(updatedData);
        console.log("dragdata",dragdata)
    
    }

    const handleImageClick = (index) => {
        setSelectedImageIndex(index); // Set the selected image index
    }
    const handleMouseOut = () => {
        setSelectedImageIndex(null); // Reset the selected image index when mouse moves out
    }
    const handlesetdropdowndata=()=>{
        setDragImageBox(dragdata)
        handleclose_1()
    }

    const handleClearImages = () => {
        setDragData([]); // Clear the dragdata array
    }

    return (
        <div className='out_fit'>
            <div className='header_outfit'>
            <i style={{color:"black"}} class="bi bi-arrow-left" onClick={handleclose_1}></i>
                <div className='name_ou'>Create Outfit</div>
                <i style={{color:"black"}} class="bi bi-tag-fill"></i>
            </div>
            
            <div className='body_outfit'>
                <h3 style={{cursor:"pointer", fontSize:"12px"}} onClick={handleClearImages}>Clear</h3>

                <div className='collection_space'>
                    {dragopen ? (
                        dragdata.map((data, index) => (
                            <Draggable bounds="parent" key={index}>
                                <div
                                    className='col-4'
                                    style={{ position: 'relative' }}
                                    onMouseOver={() => handleImageClick(index)} // Call handleImageClick when hovering over the image
                                    onMouseOut={handleMouseOut} // Call handleMouseOut when mouse moves out of the image
                                >
                                    <img src={data} alt={`item-${index}`}  />
                                    {/* Close icon for removing the image - conditionally rendered based on the selectedImageIndex */}
                                    {selectedImageIndex === index && (
                                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                            <i class="bi bi-arrow-right" onClick={() => handleDragClose(index)} ></i>
                                        </div>
                                    )}
                                </div>
                            </Draggable>
                        ))
                    ) : null}
                </div>

                <div className='line_'></div>
                <div className='button_create' onClick={handleOpen}>
                <i style={{color:"white"}} class="bi bi-plus-lg"></i>
                </div>
            </div>
            <div className='footer_outfit'>
                <div className='footer_bnt_f'  onClick={handlesetdropdowndata} >
                    <div className='button_name_f'>Save</div>
                    <i style={{ color: "#fff" }} class="bi bi-arrow-right"></i>
                </div>
            </div>
            <Modal id="lmkl" show={show} onHide={handleClose} centered>
                <SelectItem hadleClose={handleClose} formData={formData} setDragData={setDragData} handledragOpen={handledragOpen} />
            </Modal>
        </div>
    )
}

export default CreateOutfit;
