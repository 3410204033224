import React, { Component } from "react";
import Section from "./layouts/Section";
import "./viewmore.css"
class Viewmore extends Component {
    render() {
        return (
            <Section allNotification={false} searchPopup={true} title={'Doctor Information'}>
                <div className="doctor-head">
                    <div className="doctor-title">
                        <img src="/assets/img/doctor/doctor1.jpg" alt="doctor" style={{width:"100%"}}/>
                        <div className="container">
                            <div className="content">
                                <h6 className="about-doctor">Dr. Michelle Albert.</h6>
                                <p className="about-doctor">General Surgeon</p>
                            </div>
                        </div>
                    </div>
                    <div className="doctor-item">
                        <div className="personal-summary1">
                            <h4 className="personal-summary">Personal summary</h4>
                            <p className="doctor-summary">I've been working in healthcare for 15 years.
                                I have a deep love for people & delievering higher levels of care,
                                generally treat the following : Cold, Elective transplantation,
                                Skin condition, etc.</p>
                            <p className="doctor-summary">Able to have Consultations in English, Spanish & French</p>
                        </div>
                        <div className="personal-summary">
                            <h4 className="avliability">Avliability</h4>
                            <p className="doctor-summary">I typically respond in 30 minutes.
                                If you message me after hours, weekends or offline.
                                there is potential for a call as soon as possible.</p>
                        </div>
                        <div className="personal-summary">
                            <h4 className="_service-list">Service List</h4>
                            <ul>
                                <li>Skin care</li>
                                <li>Liver transplantation</li>
                                <li>Cold</li>
                                <li>Personal care</li>
                            </ul>
                        </div>
                        <div className="__Call_icons">
                            <ul className="advisor-ul">
                                <li className="advisor-li1 advisor-li"><i className="fa fa-phone-square advisor-li1" aria-hidden="true"></i></li>
                                <li className="advisor-li"><i className="fa fa-commenting advisor-li" aria-hidden="true"></i></li>
                                <li className="advisor-li"><i className="fa fa-calendar advisor-li" aria-hidden="true"></i></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </Section>
        );
    }
}

export default Viewmore;