import React, { useState } from 'react';

function CommentModel(props) {
     const { list } = props;
			const [showModal, setShowModal] = useState(false);
			const openModal = () => {
				setShowModal(true);
			};

			const closeModal = () => {
				setShowModal(false);
			};

  return (
		<div>
			<div>
				<button type="button" className="btn btn-sm" onClick={openModal}>
					<i class="bi bi-chat-left-text-fill"></i>
				</button>

				{showModal && (
					<div
						className="modal"
						tabIndex="-1"
						role="dialog"
						style={{ display: "block", background: "#111110a8" }}
					>
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Comments</h5>
									<button
										type="button"
										className="close _newclose"
										onClick={closeModal}
									>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<p style={{fontSize:15, lineHeight:1.7, wordSpacing:4}}>{list.comment}</p>
								</div>
								<div className="modal-footer">
									<button className="commonmodalbutton" onClick={closeModal}>
										Close
									</button>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default CommentModel
