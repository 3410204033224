import React from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { ValidateOtp, GenerateOtp } from "../actions";
import { connect } from "react-redux";
import Spinner from "./subcomponent/spinner/Spinner";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

const TIMER = 60;

class Otp extends React.Component {
  constructor() {
    super();
    this.state = {
      count: TIMER,
      phoneNumber: "",
      otp: "",
      enableBtn: false,
      listening: false,
      loader: false,
      resendOtp: false,
      otpError: "",
    };
    this.submitPhone = this.submitPhone.bind(this);
    this.toggleListen = this.toggleListen.bind(this);
    this.handleListen = this.handleListen.bind(this);
    this.reset = this.reset.bind(this);
  }
  // state = { otp: '' };

  decrementCount = () => {
    // console.log('count',this.state.count)
    if (this.state.count > 0) {
      this.setState({
        count: this.state.count - 1,
      });
    }
  };

  reset = () => {
    this.setState((state) => ({
      count: (state.count = TIMER),
    }));
    let params = {
      mobile: this.state.phoneNumber,
    };
    console.log(params);
    this.setState({
      resendOtp: true,
    });
    this.props.GenerateOtp(params);
  };

  componentDidMount() {
    this.setState({
      phoneNumber: localStorage.getItem("phoneNumber"),
    });
  }

  componentDidUpdate() {
    if (this.state.count > 0) {
      setTimeout(() => {
        this.decrementCount();
      }, 1000);
    }
  }

  handleChange = (otp) => {
    // console.log('handle change')
    this.setState({ otp, enableBtn: false });
    // console.log(this.state.otp,this.state.otp.length)
    if (otp.length === 4) {
      this.setState({
        enableBtn: true,
      });
    }
  };

  submitPhone(event) {
    event.preventDefault();
    console.log(this.state.otp);
    let params = {
      otp: this.state.otp,
      mobile: this.state.phoneNumber,
    };
    console.log(params);
    this.setState({
      loader: true,
    });
    this.props.ValidateOtp(params);
  }

  toggleListen() {
    this.setState(
      {
        listening: !this.state.listening,
      },
      this.handleListen,
    );
  }

  handleListen() {
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continous = true;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      if (this.state.listening) {
        recognition.start();
        recognition.onend = () => {
          console.log("...continue listening...");
          recognition.start();
        };
      } else {
        recognition.stop();
        recognition.onend = () => {
          console.log("Stopped listening per click");
        };
      }

      recognition.onstart = () => {
        console.log("Listening!");
      };

      let finalTranscript = "";
      recognition.onresult = (event) => {
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          // console.log('coming voice: ',transcript)
          // console.log('isFinel',event.results[i].isFinal)
          if (
            event.results[i].isFinal &&
            finalTranscript.length < 4 &&
            /^\d+$/.test(transcript)
          ) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        // console.log(interimTranscript)
        // console.log(finalTranscript)
        this.setState({
          ...this.state,
          otp: finalTranscript.trim(),
        });

        //-------------------------COMMANDS------------------------------------

        // const transcriptArr = finalTranscript.split(' ')
        // const stopCmd = transcriptArr.slice(-3, -1)
        // console.log('stopCmd', stopCmd)

        if (
          this.state.listening &&
          (interimTranscript === "stop" || interimTranscript === "done")
        ) {
          recognition.stop();
          recognition.onend = () => {
            console.log("Stopped listening per command");
            this.setState({
              listening: false,
            });
            if (this.state.otp.length === 4) {
              this.setState({
                enableBtn: true,
              });
            }
          };
        }
        if (interimTranscript === "clear" || interimTranscript === "remove") {
          this.setState({
            ...this.state,
            otp: "",
          });
          finalTranscript = "";
        }
      };

      //-----------------------------------------------------------------------

      recognition.onerror = (event) => {
        console.log("Error occurred in recognition: " + event.error);
      };
    } else {
      alert("Speech recognition not supported by the browser");
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.error) {
      return { ...state, loader: false, otpError: props.errorMessage };
    }
    return state;
  }

  render() {
    document.body.classList.add("otp-page");
    return (
      <React.Fragment>
        <div
          className="header-area"
          style={{ backgroundImage: "url(assets/img/bg/1.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-3"></div>
              <div className="col-sm-4 col-6 text-center">
                <div className="page-name">Otp</div>
              </div>
              <div className="col-sm-4 col-3 text-right"></div>
            </div>
          </div>
        </div>
        {/* this.state.resendOtp===true ? Alert.success({title:"OTP is sent successfully."}):'' */}
        <div className="container mt-5">
          <div className="row page-title mg-top-50">
            <div className="col-12 text-center">
              <ul className="header-right">
                <li>
                  <Link id="header-user" className="header-user" to={"/"}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/otpscreen.png"}
                      alt="img"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="widget-body sliding-tabs">
          {this.props.error ? (
            <div
              className="alert alert-danger alert-dissmissible fade show"
              role="alert"
            >
              <strong>Alert!</strong> {this.props.errorMessage}
            </div>
          ) : null}
          <div className="signin-area">
            <div className="container">
              <form onSubmit={this.submitPhone}>
                <div className="verify-text">
                  <h2>Verification Code</h2>
                  <p>
                    {" "}
                    please click on Mice to type your otp recently sent on your
                    mobile Number
                  </p>
                  <br />
                  <div className="mobile-number">
                    <Link to={"/"}>
                      <span>+{this.state.phoneNumber}</span>
                    </Link>
                  </div>
                </div>
                <OtpInput
                  value={this.state.otp}
                  onChange={this.handleChange}
                  numInputs={4}
                  className="otp-fields px-1"
                  separator={<span></span>}
                />

                <div className="d-flex px-2 mx-auto justify-content-between mb-3">
                  <div className="col-8">
                    <span className="text-success">
                      Resend OTP: {this.state.count}
                    </span>
                  </div>
                  <div className="col-4">
                    <button
                      type="button"
                      className="btn-c btn-primar"
                      onClick={this.reset}
                      disabled={this.state.count > 0}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#FFF",
                        padding: "5px",
                      }}
                    >
                      {" "}
                      Resend
                    </button>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn-c btn-success mr-1 mb-2"
                  disabled={!this.state.enableBtn}
                >
                  {" "}
                  Submit {this.state.loader ? (
                    <Spinner size="small" />
                  ) : null}{" "}
                </button>
                <div className="edit-icon margin">
                  <div className="mb-2">
                    <button
                      id="speech"
                      className="btn-voice type2"
                      onClick={this.toggleListen}
                    >
                      <div
                        className={this.state.listening ? "pulse-ring" : ""}
                      ></div>
                      {/* pulse-ring */}
                      <i className="fa fa-microphone" aria-hidden="true"></i>
                    </button>
                  </div>
                  {/* <span onClick={this.toggleListen}>
                    <img src={process.env.PUBLIC_URL + '/assets/img/google.png'} alt="microphone" />
                  </span> */}

                  {this.state.listening ? "listening" : ""}
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    loader: state.auth.loader,
  };
};

export default connect(mapStateToProps, { ValidateOtp, GenerateOtp })(Otp);

// export default Otp;

/* const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  button: {
    width: '60px',
    height: '60px',
    background: 'lightblue',
    borderRadius: '50%',
    margin: '6em 0 2em 0'
  },
  interim: {
    color: 'gray',
    border: '#ccc 1px solid',
    padding: '1em',
    margin: '1em',
    width: '300px'
  },
  final: {
    color: 'black',
    border: '#ccc 1px solid',
    padding: '1em',
    margin: '1em',
    width: '300px'
  }
}
const { container, button, interim, final } = styles */
