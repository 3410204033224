import React, { useState } from "react";
import { Form } from "react-bootstrap";

function DailyLiving(props) {
	const { myData } = props;

	let parsedData;
	try {
		parsedData = JSON.parse(myData.form_data);
	} catch (error) {
		console.error("Error parsing JSON data:", error);
	}

	const allValues =
		Number(parsedData.dailyLiving.groceries) +
		Number(parsedData.dailyLiving.eating_out) +
		Number(parsedData.dailyLiving.personal_supplies_care) +
		Number(parsedData.dailyLiving.salon_barber) +
		Number(parsedData.dailyLiving.babysitting_child_care) +
		Number(parsedData.dailyLiving.clothing) +
		Number(parsedData.dailyLiving.dry_cleaning) +
		Number(parsedData.dailyLiving.pet_food);

	props.sendDataDl(allValues)

	return (
		<div>
			<div className="styst">
				<div className="daildy_living">DAILY LIVING </div>
				<Form>
					<div className="living_state">
						<Form.Label className="adksk">Groceries</Form.Label>
						<input
							readOnly
							type="text"
							className=" input_style jadj"
							name="groceries"
							value={parsedData.dailyLiving.groceries}
						/>
					</div>
					<div className="living_state">
						<Form.Label className="lable_css">Eating Out</Form.Label>
						<input
							readOnly
							className="input_style jkha"
							type="text"
							name="eating_out"
							value={parsedData.dailyLiving.eating_out}
						/>
					</div>
					<div className="living_state">
						<Form.Label className="adksk">Personal Supplies/Care</Form.Label>
						<input
							readOnly
							className="input_style jadj"
							type="text"
							name="personal_supplies_care"
							value={parsedData.dailyLiving.personal_supplies_care}
						/>
					</div>
					<div className="living_state">
						<Form.Label className="lable_css">Salon/Barber</Form.Label>
						<input
							readOnly
							className="input_style jkha"
							type="text"
							name="salon_barber"
							value={parsedData.dailyLiving.salon_barber}
						/>
					</div>
					<div className="living_state">
						<Form.Label className="adksk">Clothing</Form.Label>
						<input
							readOnly
							className="input_style jadj"
							type="text"
							name="clothing"
							value={parsedData.dailyLiving.clothing}
						/>
					</div>
					<div className="living_state">
						<Form.Label className="lable_css">Dry Cleaning</Form.Label>
						<input
							readOnly
							className="input_style jkha"
							type="text"
							name="dry_cleaning"
							value={parsedData.dailyLiving.dry_cleaning}
						/>
					</div>
					<div className="living_state">
						<Form.Label className="adksk">Babysitting/Child Care</Form.Label>
						<input
							readOnly
							className="input_style jadj"
							type="text"
							name="babysitting_child_care"
							value={parsedData.dailyLiving.babysitting_child_care}
						/>
					</div>
					<div className="living_state">
						<Form.Label className="lable_css">Pet Food</Form.Label>
						<input
							readOnly
							className="input_style jkha"
							type="text"
							name="pet_food"
							value={parsedData.dailyLiving.pet_food}
						/>
					</div>

					<div className="living_state">
						<Form.Label className="lable_css_">Total Amount</Form.Label>
						<input
							readOnly
							className="input_style_ jadj jkdju"
							type="text"
							name="totalSum"
							value={allValues}
						/>
					</div>
				</Form>
			</div>
		</div>
	);
}

export default DailyLiving;
