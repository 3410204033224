import React, { useState } from 'react';
import { SITE_URL } from '../../../api/auth';

function CashVideoModal(props) {
	const [showModal, setShowModal] = useState(false);
	const { list } = props;

	const openModal = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	let myVideos;
	try {
		myVideos = JSON.parse(list.video);
	} catch (error) {
		console.error("Error parsing JSON data:", error);
	}

	return (
		<div>
			<button type="button" className="btn btn-sm" onClick={openModal}>
				<i className="bi bi-play-circle-fill"></i>
			</button>

			{showModal && (
				<div
					className="modal"
					tabIndex="-1"
					role="dialog"
					style={{ display: "block", background: "#111110a8" }}
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Videos</h5>
								<button
									type="button"
									className="close _newclose"
									onClick={closeModal}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<video controls autoPlay style={{ width: "100%" }}>
									<source src={`${SITE_URL}/${myVideos}`} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
							<div className="modal-footer">
								<button
									className="commonmodalbutton"
									onClick={closeModal}
								>
									Close
								</button>
								{/* Add more buttons or actions as needed */}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default CashVideoModal;
