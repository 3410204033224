import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import './health.css';

function Health(props) {
    const {myData} = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }
    const allValues =
        Number(parsedData.health.health_insurance) +
        Number(parsedData.health.medication_copay) +
        Number(parsedData.health.drs_visit_copay);

    props.sendDataHe(allValues);


    return (
        <div className='dhhejk'>
            <div className="daildy_living">HEALTH</div>
            <Form>
                <div className="living_state">
                    <Form.Label className=" lable_css ">Health Insurance</Form.Label>
                    <input
                        type="text"
                        className=" input_style jkha "
                        name="health_insurance"
                        value={parsedData.otherobligation.health_insurance}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Medication Copay</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="medication_copay"
                        value={parsedData.otherobligation.medication_copay}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Drs visit Copays</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="drs_visit_copay"
                        value={parsedData.otherobligation.drs_visit_copay}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css_">Total Amount</Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    )
}

export default Health;