import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function Utilities(props) {

  const { myData } = props;

  let parsedData;
  try {
    parsedData = JSON.parse(myData.form_data);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }
  const allValues =
    Number(parsedData.utility.phone) +
    Number(parsedData.utility.cable_satellite) +
    Number(parsedData.utility.electricity_water_gas) +
    Number(parsedData.utility.sewer_trash);

  props.sendDataUt(allValues)

  return (
    <div className="dhhejk">
      <div className="daildy_living">UTILITIES</div>
      <Form>
        <div className="living_state">
          <Form.Label className="adksk">Phone</Form.Label>
          <input
            type="text"
            className=" input_style jadj"
            name="phone"
            value={parsedData.utility.phone}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css">Cable/Satellite/Internet</Form.Label>
          <input
            className="input_style jkha"
            type="text"
            name="cable_satellite"
            value={parsedData.utility.cable_satellite}
          />
        </div>
        <div className="living_state">
          <Form.Label className="adksk">Electricity/Water/Gas</Form.Label>
          <input
            className="input_style jadj"
            type="text"
            name="electricity_water_gas"
            value={parsedData.utility.electricity_water_gas}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css">Sewer/Trash</Form.Label>
          <input
            className="input_style jkha"
            type="text"
            name="sewer_trash"
            value={parsedData.utility.sewer_trash}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css_">Total Amount</Form.Label>
          <input
            className="input_style_ jadj jkdju"
            type="text"
            name="totaleHousing"
            value={allValues}
          />
        </div>
      </Form>
    </div>
  );
}

export default Utilities;
