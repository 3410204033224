import moment from "moment";
import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";

import { addEvent, fetchEvents } from '../actions';

function Health(props) {

    const eventFormRef = useRef();

    const[date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const[stime] = useState();
    const[etime] = useState();

    const saveEvent = (e) => {
        const form = eventFormRef.current;
        let formError = false;

        if(form.title.value === '') {
            formError = true;
            window.toastr.error('Please enter event name');
            form.title.focus();
        }

        if(form.sdate.value === '') {
            formError = true;
            window.toastr.error('Please choose a date');
            form.sdate.focus();
        }

        if(!formError) {
            const requestData = {
                title: form.title.value,
                description: form.description.value,
                location: form.location.value,
                type: 1,
                start_time: date + ' ' + (form.stime.value !== '' ? form.stime.value : '00:00') + ':00',
                end_time: date + ' ' + (form.etime.value !== '' ? form.etime.value : '23:59') + ':00',
                user_id: null,
            }
               
            props.addEvent(requestData).then((data) => {
                window.toastr.success('Your event has been created successfully')
                form.reset()
                window.jQuery('#modal-centered').modal('hide')
                props.fetchEvents()
            })
            .catch((error) => {
                console.log('error', error)
                window.toastr.error('Something went wrong. Please check and submit your data again!')
            })
        }

    }

    const handleChange = (e) => {
        setDate(e.target.value);
    }

    const handleStartTime = (e) => {
        console.log(e.target.value);
        // setStartTime(e.target.value);
    }

    const handleEndTime = (e) => {
        // setEndTime(e.target.value);
    }

    return (
        <Fragment>
            <div id="_modal" className="modal fade">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <b style={{fontSize: '22px'}}>Request an Appointment</b>
                            <button type="button" className="closes btn-danger" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form ref={eventFormRef} className="form-horizontal">
                                <div className="row  mb-2">
                                        <label className="col-xs-4 col-4" htmlFor="stime">Start Time</label>
                                        <div className="col-xs-8 col-8">
                                            <input type="time" id="stime" className="form-control" name="stime" value={stime} onChange={handleStartTime}  />
                                        </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="col-xs-4 col-4" htmlFor="etime">End Time</label>
                                    <div className="col-xs-8 col-8">
                                        <input type="time" id="etime" name="etime" value={etime} onChange={handleEndTime} className="form-control" />
                                    </div>
                                </div>
                                <div className="row  mb-5">
                                    <label className="col-xs-4 col-4" htmlFor="sdate">Preferred  Date</label>
                                    <div className="col-xs-8 col-8">
                                        <input type="date" id="sdate" name="sdate" value={date} onChange={handleChange} className="form-control" />
                                    </div>
                                </div>
                                <div className="row  mb-4">
                                    <label className="col-xs-12 col-12 __healmodal" htmlFor="sdate">Preferred Doctor</label>
                                    <div className="col-xs-12 col-12">
                                        <input type="text" className="form-control" id="event_title" name="title" placeholder="Your Preferred doctor" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label htmlFor="event_title" className="col-xs-12 col-12 __healmodal">Allergies:</label>
                                    <div className="col-xs-12 col-12">
                                        <input type="text" className="form-control" id="" name="allergies" placeholder="If you have any" />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                <label htmlFor="event_title"  className="col-xs-12 col-12 __healmodal">Address:</label>
                                    <div className="col-12">
                                        <input type="text" className="form-control" name="location" placeholder="Location here ..." />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <div className="col-xs-12 col-12">
                                        <textarea className="form-control" id="description" name="description" rows="4" placeholder="Enter event note here..."></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                            <button onClick={saveEvent} type="button" className="btn button-save" id="save-event">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default connect(null, {addEvent, fetchEvents})(Health);