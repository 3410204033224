import { LOADER_AUTH, LOGIN_ERROR, OTP, OTP_ERROR, PASSWORD_CHANGE, PASSWORD_CHANGE_ERROR, SIGN_IN, SIGN_OUT } from "../store/redux-constant"

const initialState = {
	isSignedIn: null,
	userDetail: null,
	error: false,
	errorMessage: '',
	loader:false,
	status: false
}

const authReducers = (state = initialState, action) => {
	switch (action.type) {
		case LOADER_AUTH:
			return { ...state, loader:true }
		case SIGN_IN:
			return { ...state, isSignedIn: true, loader:false, userDetail: action.payload }
		case SIGN_OUT:
			return { ...state, isSignedIn: false, userDetail: null }
		case OTP:
			return { ...state, userDetail:null }
		case OTP_ERROR:
			return {...state, error:true, loader:false, isSignedIn: false, errorMessage:action.payload}
		case LOGIN_ERROR:
			return {
				...state,
				isSignedIn:false,
				error:true,
				loader:false,
				errorMessage:  'invalid username or password'
			}
		case PASSWORD_CHANGE: return {...state, loader: false, status: true}
		case PASSWORD_CHANGE_ERROR: return {...state, loader: false, status: false}
		default:
			return state
	}
}

export default authReducers;