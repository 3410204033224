import React from 'react'
import './ChildHomeMolecule.css'; 


export default function CHMfeatured() {
  const productt = [
    {
    id :1,
    productt_image: "https://img.freepik.com/free-photo/african-american-business-woman-by-window_1303-10869.jpg?t=st=1709208071~exp=1709211671~hmac=c743b7d525b909703f0126ec4f1c6c1b9b9105062f771c4a0b5aa48fb7161df2&w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",
  },
  {
    id :2,
    productt_image: "https://img.freepik.com/premium-photo/models-runway-clothes-fashion-show_124507-42085.jpg?w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",

  },
  {
    id :3,
    productt_image: "https://img.freepik.com/premium-photo/guy-plays-guitar-girl-sings-african-american-man-with-white-girl-rehearsing-studio-together_146671-42508.jpg?w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",

  },
  {
    id :4,
    productt_image: "https://img.freepik.com/free-photo/lovely-young-parisian-woman-with-brunette-hair-stylish-beret-beige-trench-coat-black-bag-standing-old-stairs-sensitively-posing-outdoors_197531-24472.jpg?t=st=1709208022~exp=1709211622~hmac=c4445f31f0942f3fca72da47041a3c8b2be53e3ca5d3183650d8040122a72a8f&w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",

  },
  {
    id :5,
    productt_image: "https://img.freepik.com/free-photo/woman-model-business-suit-wearing-hat_1303-17693.jpg?t=st=1709208052~exp=1709211652~hmac=110dc89aa99cde3c6ee575789d1081ebe01b8086bf2a222602fb6b57976e0241&w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",

  },
  {
    id :6,
    productt_image: "https://img.freepik.com/free-photo/woman-with-shopping-bags-studio-yellow-background-isolated_1303-14294.jpg?t=st=1709207874~exp=1709211474~hmac=98a78cdd48b2aaf4aaf73f6a16a21efb67ba4e3248e1ed6a9152c5ac787e1183&w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",

  },
  {
    id :7,
    productt_image: "https://img.freepik.com/free-photo/young-woman-with-shopping-bags-beautiful-dress-hat_1303-17524.jpg?t=st=1709207912~exp=1709211512~hmac=93219da7423cbe0eed35b0010eb2cae5984396d98c5291073be0cf49059bb3bd&w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",

  },
  {
    id :8,
    productt_image: "https://img.freepik.com/free-photo/perky-girl-stylish-glasses-stares-amazement-walking-pink-wall-brunette-culottes-orange-blouse-posing-with-red-handbag_197531-14254.jpg?t=st=1709208002~exp=1709211602~hmac=e63b7ea895f34b6319001d0e9510ad8185f2e1a7ca91db8066dc93b5b2d8053a&w=996",
    iconsnamep: "TheAnnaEdit",
    iconsname1: "FAVORITE",
    iconsname2: "AnnaNewton",
    iconsname3: "Classic - Polished - Undone",


  }
]

  return (
    <div style={{backgroundColor:"#fcfaed"}}>

    
    {Array.isArray(productt) && productt.map((item)=>{
      return(
    <div className='collie'>
      <div style={{display:"flex"}} className='colli'>
        <div className='coll'>
         <img className='featured-image' src={item.productt_image}/>
        </div>
        <div className='col'>
          <div className='namee1'>{item.iconsname1}</div>
          <div className='namee2'>{item.iconsname2}</div>
          <div className='namee3'>{item.iconsname3}</div>

        </div>
      </div>
    </div>
    )
  })}
  </div>
  )
}
