import { BILLS_ERROR, ERROR_CARDDETAILOPT, FETCH_BILLS, FETCH_CARDDETAILOPT, FETCH_PAYMENT, FETCH_VENDORLIST, INITIATE_PAYMENT, LOADER_PAYMENT, PAYMENT_ERROR, SAVE_BILLS, UPDATE_PAYMENT, UPLOAD_BILLS, VENDORLIST_ERROR } from "../store/redux-constant"

const initialState = {
	clientSecret:{},
	paymentHistory:{},
	bills:{},
	vendorList:[],
	cardListOpt:[],
    error: false,
	errorMessage: '',
	loader:false
}

const paymentReducers = (state = initialState, action) => {
	switch (action.type) {
		case LOADER_PAYMENT:
			return { ...state, loader:true }
		case INITIATE_PAYMENT:
			return { ...state, loader:false, clientSecret: action.payload }
		case UPDATE_PAYMENT:
			return {...state, loader:false}
		case FETCH_PAYMENT:
			return {...state, loader:false, paymentHistory:action.payload}
		case FETCH_BILLS:
			return {...state, loader:false, bills:action.payload}
		case FETCH_VENDORLIST:
			return {...state, loader:false, vendorList:action.payload}
		case FETCH_CARDDETAILOPT:
			return {...state, loader:false, cardListOpt:action.payload}
		case SAVE_BILLS:
			return {...state, loader:false}
		case UPLOAD_BILLS:
			return {...state, loader:false}
		case PAYMENT_ERROR:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage:'error getting payment detail'
			}
		case BILLS_ERROR:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage:'getting errors in bills'
			}
		case VENDORLIST_ERROR:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage:'getting errors in vendor list'
			}
		case ERROR_CARDDETAILOPT:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage:'getting errors in card detail option'
			}
		default:
		return state
	}
}

export default paymentReducers