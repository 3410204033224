import React, { useState,useEffect } from 'react';
import './ChildHomeMolecule.css'; 
// import './boxie.css'

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import StarBorderIcon from "@mui/icons-material/StarBorder";
// import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
// import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import Modal from 'react-bootstrap/Modal';
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// import { ArrowRightAlt } from '@material-ui/icons';

// import AddIcon from "@mui/icons-material/Add";
import { Button } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import Trialcam from "./Trialcam";
// import Spinner from 'react-bootstrap/Spinner';
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SelectItem from './SelectItem';
import Draggable from 'react-draggable';
// import CloseIcon from '@mui/icons-material/Close';



const CustomTab = ({ label, active, onClick }) => (
  <div className={`tabical ${active ? "active" : ""}`} onClick={onClick}>
    {label}
  </div>
);


const Collection = (props) => {
  const {setNewBoxes,newBoxes,setSelectedBoxIndex,selectedBoxIndex,handleCreateNewBox,setDragImageBox,formData,dragimgaebox,setFormData }=props;
  const [selecteddtBox, setselecteddtBox] = useState([]);
  const [hide, setHide] = useState(false);
  const [newBoxName, setNewBoxName] = useState('');
  const [newBoxColor, setNewBoxColor] = useState('#ffffff'); // Default color
  const [selectedColorBox, setSelectedColorBox] = useState(null);
  const [showing, setShowing] = useState(false);
  const colors = ['red', 'rgb(220, 180, 220)', 'rgb(238, 232, 170)', '#444913', 'rgb(118, 113, 113)', 'green', 'white',];
  const [selectedCategory, setSelectedCategory] = useState('TOP');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [show, setShow] = useState(false);
  const [dragopen, setDragopen] = useState(false);
  const [dragdata, setDragData] = useState([]);
  const [dragdataColl, setDragDataColl] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [boxImages, setBoxImages] = useState({});

  const [loading, setLoading] = useState(false);

  const handleShowing = (boxIndex) => {
    setSelectedBoxIndex(boxIndex);
    setShowing(true);
  }
  const handleClosing = () => {
    setShowing(false);
  };
  const [itemData, setItemData] = useState([]);

  const handleOpen = () => {
    setShow(true);
}

const handledragOpen = () => {
    setDragopen(true);
}

const handleClosse = () => {
    setShow(false);
}

const hasImages = dragdataColl.length > 0;
const [storedImages, setStoredImages] = useState([]);


useEffect(() => {
  if (hasImages) {
    setStoredImages(prevImages => [...prevImages, dragdataColl]);
  }
}, [dragdataColl, hasImages]);

const handleDragClose = (index) => {
    const updatedData = dragdata.filter((_, i) => i !== index);
    setDragData(updatedData);
    console.log("dragdata",dragdata)

}

const handleImageClick = (index) => {
    setSelectedImageIndex(index); // Set the selected image index
}
const handleMouseOut = () => {
    setSelectedImageIndex(null); // Reset the selected image index when mouse moves out
}
const handlesetdropdowndata = () => {
  setBoxImages((prevBoxImages) => ({
    ...prevBoxImages,
    [selectedBoxIndex]: dragdata,
  }));
  setDragImageBox(dragdata);
  setDragDataColl(dragdata);
  handleClo2();
};

const handleClearImages = () => {
    setDragData([]); // Clear the dragdata array
}

  
  const [currentItem, setCurrentItem] = useState({
    imageUrl: null,
    inputValue: '',
    categorydefine: '',
    value: '',
    inputValue1: '',
    selecteddtBoxie: '',
    name: '',
    itemLocation: '',
    shoppingUrl: '',
    iconsnamep: "PRIVATE",
    iconsnamef: "FAVORITE",
    iconsnamed: "DO NOT STYLE",
    iconsnamea: "ARCHIVE",
  });
  const handleBrandChange = (e) => {
    setCurrentItem((prevState) => ({ ...prevState, inputValue: e.target.value }));
  };
  
const handleCategoryChange = (e) => {
  const selectedCategory = e.target.value;
  setSelectedCategory(selectedCategory);
  setSelectedSubCategory(''); // Reset selected subcategorydefine
};

  
  const handleSubCategoryChange = (e) => {
    setCurrentItem((prevState) => ({ ...prevState, value: e.target.value }));
  };
  
  const handleSizeChange = (e) => {
    setCurrentItem((prevState) => ({ ...prevState, inputValue1: e.target.value }));
  };
  
  const handleColorChange = (selecteddtBoxie,index) => {
    setCurrentItem((prevState) => ({ ...prevState, selecteddtBoxie }));
    setNewBoxColor(selecteddtBoxie);
    setSelectedColorBox(index);
  };
  
  const handleNameChange = (e) => {
    setCurrentItem((prevState) => ({ ...prevState, name: e.target.value }));
  };
  
  const handleItemLocationChange = (e) => {
    setCurrentItem((prevState) => ({ ...prevState, itemLocation: e.target.value }));
  };
  
  const handleShoppingUrlChange = (e) => {
    setCurrentItem((prevState) => ({ ...prevState, shoppingUrl: e.target.value }));
  };
  

   const [activeTab, setActiveTab] = useState(0);
    const handleTabClick = (tabIndex) => {
      setActiveTab(tabIndex);
    };
    
    const [sh, setSh] = useState(false);
    const handleClo = () => {
      setCurrentItem({
        imageUrl: null,
        inputValue: '',
        categorydefine: '',
        value: '',
        inputValue1: '',
        selecteddtBoxie: '',
        name: '',
        itemLocation: '',
        shoppingUrl: '',
        
      });
      setSh(false);
      setImageUrl(null);
      setIsClicked(false);
      setOpencam(false);
    }
  
    const handleSh = () => setSh(true);
 
    const [sh2, setSh2] = useState(false);
    const handleClo2 = () =>{
      setDragData([]);
      setSh2(false);
    }
    const handleSh2 = () => setSh2(true);
    const [opencam,setOpencam ] = useState(false);
    const handleClickCam = () => {
      setOpencam(true);
    };
 

  const handleClose = () => {
    setNewBoxName('');
    setNewBoxColor('#ffffff');
    setSelectedColorBox(null);
    setHide(false);
  }
  const handleDivClick = () => {
    setIsClicked(true);
};

const handleCapture = (imageSrc) => {
  setImageUrl(imageSrc); // Update the imageUrl state with the captured image URL
  setCurrentItem((prevState) => ({ ...prevState, imageUrl: imageSrc }));

};

const handleCreateNewBoxes = () => {
  const newItem = { ...currentItem, categorydefine: selectedCategory, parentIndex: selectedBoxIndex };
  setItemData((prevState) => [...prevState, newItem]);
  setFormData((prevState) => [...prevState, newItem]);

  setNewBoxes((prevState) => {
    return prevState.map((box) =>
      box.parentIndex === selectedBoxIndex
        ? { ...box, items: [...(box.items || []), newItem] }
        : box
    );
  });

  handleClo();

  setCurrentItem({
    imageUrl: null,
    inputValue: '',
    categorydefine: '',
    value: '',
    inputValue1: '',
    selecteddtBoxie: '',
    name: '',
    itemLocation: '',
    shoppingUrl: '',
    iconsnamep: "PRIVATE",
    iconsnamef: "FAVORITE",
    iconsnamed: "DO NOT STYLE",
    iconsnamea: "ARCHIVE",
  });
};

console.log("itemData",itemData);

  const handleClosed = () => {
    if (newBoxName.trim() !== '' && newBoxColor !== '#ffffff') {
      // Add new box with the selected name and color
      setselecteddtBox(prevBoxes => [
        ...prevBoxes,
        { name: newBoxName, selecteddtBoxie: newBoxColor }
      ]);
      // Reset name and color
      setNewBoxName('');
      setNewBoxColor('#ffffff'); // Reset color to default
      setHide(false);
    }
  };

  const handleShow = () => setHide(true);

  const handleClick = (index) => {
    setSelectedBoxIndex(index === selectedBoxIndex ? null : index); // Toggle box border
  };

  const handleInputChange = (event) => {
    setNewBoxName(event.target.value);
  };

  // const handleColorChange = (color, index) => {
  //   setNewBoxColor(color);
  //   setSelectedColorBox(index);
  // };
  const handleImageUpload = () => {
    // Open the file picker dialog
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentItem((prevState) => ({ ...prevState, imageUrl: reader.result }));
          setLoading(false); // Set loading to false after the image is loaded
        };
        setLoading(true); // Set loading to true while the image is being loaded
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };
 


  // const handleCategoryChange = (event) => {
  //   setSelectedCategory(event.target.value);
  //   setSelectedSubCategory('');
  // };

  // const handleSubCategoryChange = (event) => {
  //   setSelectedSubCategory(event.target.value);
  // };

  const renderSubCategoriesOptions = () => {
    if (selectedCategory) {
      return subCategoriesMap[selectedCategory].map((value, index) => (
        <option key={index} value={value}>
          {value}
        </option>
      ));
    } else {
      return null; // Render nothing if no categorydefine is selected
    }
  };
  

  const subCategoriesMap = {
    TOP: ['Blouse', 'Bodysuit', 'Buttoned Shirt', 'Camisole', 'Cardigon', 'Hoodie', 'Jersey', 'Polo Shirt', 'Strapless', 'Sweater', 'Sweatshirt', 'T-Shirt', 'Tank Top', 'Tunic', 'Vest'],
    BOTTOM: ['DENIM', 'LEGGINGS', 'PANTS', 'SHORTS', 'SKIRT', 'SWEATPANTS', 'TIGHTS'],
    OUTWEAR: ['BLAZER','COAT','JACKET','VEST'],
    'ONE PIECE': ['DRESS','JUMPSUIT','ROMPER'],
    BAG: ['BACKPACK','BELT BAG','BRIEFCASE','CLUTCH','CROSSBODY','SHOULDER','TOP HANDLE','TOTE','WALLET'],
    SHOES: ['BOOTS','CLOGS','FLATS','HEELS','LOAFERS','OXFORD/DERBY','SANDALS','SNEAKERS','WEDGES'],
    ACCESSORY: ['BELT','GLASSES','GLOVES','HAIR ACCESSORIES','HAT','OTHER','POCKET SQUARE','SCARF','SOCKS','TIE'],
    JEWELRY: ['BRACLET','EARRINGS','NECKLACE','OTHER JEWELRY','RING','WATCH'],
    SWIM: ['COVER-UP','ONE-PIECE','SEPARATES','TRUNKS']
  };
  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorClick = (selecteddtBoxie) => {
    setSelectedColor(selecteddtBoxie);
  };

  // Define color options
  const colorOptions = ['#013701', 'rgb(220, 180, 220)', 'rgb(206, 206, 104)', '#444913', 'rgb(118, 113, 113)', 'red', 'white'];
  console.log("newBoxes",newBoxes)

  return (
    <div className='jbjbjiooi' style={{background:"#fcfaed",marginBottom:"40px",minHeight:"80vh"}}>
      <div style={{display:"flex", flexWrap:"wrap",width:"100%"}}>
        {/* Render static boxes */}
        <div
          style={{cursor:"pointer"}}
          className={`dibba dibba1 ${selectedBoxIndex === 'dibba1' ? 'selected-boxIE' : ''}`} // Add class if box is selected
          onClick={() => handleClick('dibba1')} // Handle box click
        >
          <div className='texttttti1'>Packing</div> 
        </div>
        <div
          style={{cursor:"pointer"}}
          className={`dibba dibba2 ${selectedBoxIndex === 'dibba2' ? 'selected-boxIE' : ''}`} // Add class if box is selected
          onClick={() => handleClick('dibba2')} // Handle box click
        >
          <div className='texttttti2'>Capsules</div>
        </div>
        <div
          style={{cursor:"pointer"}}
          className={`dibba dibba3 ${selectedBoxIndex === 'dibba3' ? 'selected-boxIE' : ''}`} // Add class if box is selected
          onClick={() => handleClick('dibba3')} // Handle box click
        >
          <div  className='texttttti3'>Packing</div>
        </div>

        {/* Render existing boxes */}
        {selecteddtBox.map((box, index) => (
          <div
            key={index}
            className={`dibba ${selectedBoxIndex === index ? 'selected-boxIE' : ''}`} // Add class if box is selected
            style={{ background: box.selecteddtBoxie , cursor:"pointer" }}
            onClick={() => handleClick(index)} // Handle box click
          >
            <div className='texttttti'>{box.name}</div>
          </div>
        ))}
        <div style={{cursor:"pointer"}} className="dibba4" onClick={handleShow}> 
          <div className='pluss'>+</div>
          <div className='texttttti4'>Folder</div> 
        </div>
      </div>

      {selectedBoxIndex !== null || selecteddtBox.length !== 0 ? (
        <div style={{textAlign:"center"}}>
          {selectedBoxIndex !== null && typeof selectedBoxIndex === 'number' ? (
            <div>
             
            </div>
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div style={{textAlign:"center"}}>
          <div className='coll1'>You don't have any collection yet.</div>
          <div className='coll2'>You can use collections to make a packing, shopping, or capsule list. Use the folder above the organize your collection based on your needs. You can use collections to make a packing, shopping, or capsule list. Use the folder above the organize your collection based on your needs.</div>
          <div className='coll3'>Press "=" below to create your first collection.</div>
        </div>
      )}
      {selectedBoxIndex !== null && (
      <div>
        <div style={{marginTop:"40px"}}></div>
        
        <div className="newboxcreted">
        {newBoxes.filter((box) => box.parentIndex === selectedBoxIndex).map((box, index) => (
            <div key={index} onClick={() => handleShowing(box.parentIndex)}>
              <div style={{cursor:"pointer"}} className='njbiuiu'>
                <div>
                  <img className='lnhug76f' src={box.imageUrl} />
                  <span className='kjiii'>{box.name}</span>
                </div>
                <div className='hgy'>{box.items ? `${box.items.length} items` : '0 items'}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )}
          <Modal centered show={showing} onHide={handleClosing} id="mnbvcxxc">
         <Modal.Body className="bodystylesad">
          <Modal.Header
            style={{ margin: "0", padding: "0", alignItems: "center", justifyContent:"space-between" }}
          >
            <i onClick={handleClosing} style={{cursor:"pointer"}} class="bi bi-arrow-left"></i>
            {/* <ArrowBackIcon
            /> */}
            <h6 className="calh6776">
             FLTX
            </h6>
            <div style={{color:"transparent"}}> .....</div>
          </Modal.Header>

          <div className="tab_container">
           <div className="tab_containerTabS">
            <CustomTab
              label="ITEMS"
              active={activeTab === 0}
              onClick={() => handleTabClick(0)}
            />
            <CustomTab
              label="OUTFITS"
              active={activeTab === 1}
              onClick={() => handleTabClick(1)}
            />
          </div>

          <div className="tab_content">
            
            {activeTab === 0 && (
              <>
              {itemData.length > 0 ? null : (
                <>
                  <div className='jkkl'>
                    <h2 style={{fontSize:"16px"}}>ITEMS</h2>
                    <p>This is the content for the ITEMS tab.</p>
                  </div>
                </>
              )}
              {itemData.length > 0 && (
              <div className='jkkl'>
                <div className="row " style={{justifyContent:"start"}}>
                {newBoxes
              .filter((box) => box.parentIndex === selectedBoxIndex)
              .flatMap((box) => box.items || [])
              .map((item, index) => (
                  <div key={index} className="col-md-3 border col-3 m-3">
                    <img src={item.imageUrl}/>
                    <div class="middle">
                          <div className="buttonnn">
                            <div class="texttt">
                            <i  style={{ marginBottom: "4px", fontSize: "15px" }} class="bi bi-eye-slash"></i>
                              {" "}
                              {/* <VisibilityOffOutlinedIcon
                               
                              />{" "} */}
                              <br /> {item.iconsnamep}
                            </div>
                          </div>
                          <div className="buttonnn">
                            <div class="texttt">
                              {" "}
                              <i style={{ marginBottom: "4px", fontSize: "15px" }} class="bi bi-star"></i>
                              {/* <StarBorderIcon
                              />{" "} */}
                              <br /> {item.iconsnamef}
                            </div>
                          </div>
                          <div className="buttonnn" onClick={handleShow}>
                            <div class="texttt">
                              <div className="lull">
                                {" "}
                                <div className="lul">!</div>{" "}
                              </div>
                              <br /> {item.iconsnamed}
                            </div>
                          </div>
                          <div className="buttonnn" onClick={handleShow}>
                            <div class="texttt">
                              {" "}
                              <i style={{ marginBottom: "4px", fontSize: "15px" }} class="bi bi-archive"></i>
                              {/* <Inventory2OutlinedIcon
                                
                              />{" "} */}
                              <br /> {item.iconsnamea}
                            </div>
                          </div>
                        </div>
                    
{/* //440 */}
                    <div style={{fontWeight:"500",fontSize:"13px"}}>{item.inputValue}</div>
                  </div>
                ))}
              </div> 
              </div>
              )}
                <div className="iconad" onClick={handleSh}> 
                <div style={{marginTop:"12px"}}><i  style={{ textAlign: "center", marginTop: "10px", cursor:"pointer" }} class="bi bi-plus-lg"></i> </div> 
                {/* <AddIcon onClick={() => setShowModal(true)} 
                /> */}
              </div>
{/*1 ////////////////      ITEMSSSSSSSSSSSSS */}
              <Modal id="mnbvcxxc"
                centered
                show={sh}
                onHide={handleClo}
              >
          
                <Modal.Body style={{overflow:"auto", scrollbarWidth:"none", borderTopLeftRadius:"40px", borderBottomRightRadius:"40px"}}>
                <Modal.Header style={{ margin: "0", padding: "0", alignItems: "center" }}>
                <i onClick={handleClo} style={{ marginBottom: "8px", cursor:"pointer" }} class="bi bi-arrow-left"></i>
                  {/* <ArrowBackIcon 
                  /> */}
                  <h6 className="calh6776CAL">
                    CREATE ITEM
                  </h6>
                  </Modal.Header>
                      
                  <div>
                {currentItem.imageUrl ? (
                <div className="gyf67f">
                {loading ? (
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
                    <div class="spinner-border text-dark" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    {/* <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner> */}
                  </div>
                ) : (
                  <img
                    src={currentItem.imageUrl}
                    alt="Uploaded"
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                  />
                )}
              </div>
          ) : opencam ? (
            <div className="whitieboixxcoll">
              <Trialcam onCapture={handleCapture} />
            </div>
          ) : (
            <div className="gyf67f" onClick={handleDivClick}>
              {isClicked ? (
                <div  className="vyfyfy">
                  <ButtonGroup orientation="vertical" aria-label="Vertical button group" variant="contained">
                    <Button onClick={handleClickCam}>Photo from Camera</Button>
                    <Button onClick={handleImageUpload}>Photo from Library</Button>
                  </ButtonGroup>
                </div>
              ) : (
                <div style={{cursor:"pointer"}} className="alboix">
                  <div className='lkoplkop'>Click to upload a photo</div>
                  <div className='oplkoplk'>We recommend using a product photo in a flat-layposition. We'll automatically remove the background for cleaner look.</div>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <h2 className="lolii">BRAND*</h2>
          <input
            className="inphmn"
            value={currentItem.inputValue}
            onChange={handleBrandChange}
          />
          <h2 className="lolii">CATEGORY*</h2>
          <select className="inphmn" value={selectedCategory} onChange={handleCategoryChange}>
            {Object.keys(subCategoriesMap).map((categorydefine, index) => (
              <option key={index} value={categorydefine}>
                {categorydefine}
              </option>
            ))}
          </select>

          <h2 className="lolii">SUB-CATEGORY*</h2>
          <select className="inphmn" value={currentItem.value} onChange={handleSubCategoryChange}>
            <option value="">Select Sub-Category</option>
            {renderSubCategoriesOptions()}
          </select>

          <h2 className="lolii">SIZE*</h2>
          <input
            className="inphmn"
            value={currentItem.inputValue1}
            onChange={handleSizeChange}
          />
          <h2 className="lolii">COLOR</h2>
          <div className="row color_items bnj">
            {colors.map((selecteddtBoxie, index) => (
              <div key={index} className="col-1">
                <div
                  className={`color_listing ${currentItem.selecteddtBoxie === selecteddtBoxie ? 'selectedborder' : ''}`}
                  style={{ background: selecteddtBoxie }}
                  onClick={() => handleColorChange(selecteddtBoxie)}
                ></div>
              </div>
            ))}
          </div>
          <h2 className="lolii">NAME</h2>
          <input
            className="inphmn"
            placeholder="Enter 2-3 word dexcription"
            value={currentItem.name}
            onChange={handleNameChange}
          />
          <h2 className="lolii">ITEM LOCATION</h2>
          <input
            className="inphmn"
            placeholder="Item Location"
            value={currentItem.itemLocation}
            onChange={handleItemLocationChange}
          />
          <h2 className="lolii">SHOPPING URL</h2>
          <input
            className="inphmn"
            placeholder="Enter Shopping URL"
            value={currentItem.shoppingUrl}
            onChange={handleShoppingUrlChange}
          />
        </div>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: "center" }}>
                  <div onClick={handleCreateNewBoxes}>
                    <div className="footer_bnt_coll" >
                      SAVE  <i class="bi bi-arrow-right"></i>
                      {/* <ArrowRightAltIcon /> */}
                    </div>
                  </div>

                </Modal.Footer> 
              </Modal>
              </>
 ////////
              
            )}
            {activeTab === 1 && (
              <>
              
              <div className="jkkl">
                {boxImages[selectedBoxIndex] && boxImages[selectedBoxIndex].length > 0 ? (
                  <div className="row" style={{ justifyContent: "start" }}>
                    <div className="col-4 columnthreeColl">
                      {boxImages[selectedBoxIndex].map((data, index) => (
                        <img
                          key={index}
                          className="nullimageclassColl"
                          src={data}
                          alt={`item-${index}`}
                        />
                      ))}
                    </div>
                  </div>
                ) : (
                  <div>
                    <h3 className='padh3' style={{ fontSize: "16px" }}>No Outfit Created</h3>
                  </div>
                )}
              </div>

              <div className="iconad" onClick={handleSh2}>
             <div style={{marginTop:"12px", cursor:"pointer"}}><i   style={{ textAlign: "center", marginTop: "10px" }} class="bi bi-plus-lg"></i></div> 
                {/* <AddIcon onClick={() => setShowModal(true)} 
                /> */}
              </div>
{/* 2///////// */}
              <Modal id="mnbvcxxcxoiop"
                centered 
                show={sh2}
                onHide={handleClo2}
              >
              
           
                <div className='out_fit'>
            <div className='header_outfit'>
            <i onClick={handleClo2} style={{cursor:"pointer"}} class="bi bi-arrow-left"></i>
                {/* <KeyboardBackspaceIcon  /> */}
                <div className='name_ou'>Create Outfit</div>
                <i class="bi bi-bookmark"></i>
                {/* <LocalOfferIcon /> */}
            </div>
            
            <div className='body_outfit'>
                <h3 style={{cursor:"pointer", fontSize:"12px"}} onClick={handleClearImages}>Clear</h3>

                <div className='collection_space'>
                    {dragopen ? (
                        dragdata.map((data, index) => (
                            <Draggable bounds="parent" key={index}>
                                <div
                                    className='col-4'
                                    style={{ position: 'relative' }}
                                    onMouseOver={() => handleImageClick(index)} // Call handleImageClick when hovering over the image
                                    onMouseOut={handleMouseOut} // Call handleMouseOut when mouse moves out of the image
                                >
                                    <img src={data} alt={`item-${index}`}  />
                                    {/* Close icon for removing the image - conditionally rendered based on the selectedImageIndex */}
                                    {selectedImageIndex === index && (
                                        <div style={{ position: 'absolute', top: 0, right: 0 }}>
                                            <i onClick={() => handleDragClose(index)} class="bi bi-x-lg"></i>
                                            {/* <CloseIcon  /> */}
                                        </div>
                                    )}
                                </div>
                            </Draggable>
                        ))
                    ) : null}
                </div>

                <div className='line_'></div>
                <div className='button_create' onClick={handleOpen}>
                    <i style={{ color: "#fff", cursor:"pointer" }} class="bi bi-plus-lg"></i>
                    {/* <AddIcon  /> */}
                </div>
            </div>
           <div>
           <div className='footer_outfit'>
                <div className='footer_bnt_f'  onClick={handlesetdropdowndata} >
                    <div className='button_name_f'>Save</div>
                    <i style={{ color: "#fff" }} class="bi bi-arrow-right"></i>
                    {/* <ArrowRightAlt  /> */}
                </div>
            </div>
           </div>
            <Modal id="lmkl" show={show} onHide={handleClosse} centered>
                <SelectItem hadleClose={handleClosse} formData={formData} setDragData={setDragData} handledragOpen={handledragOpen} />
            </Modal>
        </div>
            
               
              </Modal>
              </>
   ///
            )}
        
       </div>
      </div>

        </Modal.Body>
      </Modal>

      

      <Modal centered
        show={hide}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{overflow:"hidden"}}
        id="idy"
      >
        <Modal.Body className='bodystyle' closeButton>
          <Modal.Header style={{margin:"0", padding:"0"}} closeButton>
          <div style={{width:"100%", display:"flex",justifyContent:"center"}}>
            <h2 className='llpllp'>CREATE FOLDER</h2>
            </div>
          </Modal.Header>
          <h2 className='helh2'>NAME*</h2>
          <input className='inph2' placeholder='Enter Name' value={newBoxName} onChange={handleInputChange}/>
          <h2 className='helh2'>FOLDER COLOR</h2>
          <div style={{display:"flex"}}>
            {colorOptions.map((selecteddtBoxie, index) => (
              <div
                key={index}
                className={`oo${index + 1}boxx ${selectedColorBox === index ? 'selected-color' : ''}`}
                style={{ background: selecteddtBoxie }}
                onClick={() => handleColorChange(selecteddtBoxie, index)}
              ></div>
            ))}
          </div>
          
          <div className='margi'></div>
          <div className='line121'></div>
           <>
              {newBoxName.trim() !== '' && newBoxColor !== '#ffffff' ? (
                <div className='footer_bnt_coll'>
                  <div className='button_name_f' onClick={handleClosed}>DO NOT STYLE</div>
                  <i class="bi bi-arrow-right"></i>
                  {/* <ArrowRightAltIcon /> */}
                </div>
              ) : (
                <div className='footer_bnt_disabled'>
                  <div className='button_name_f '>DO NOT STYLE</div>
                  <i class="bi bi-arrow-right"></i>
                  {/* <ArrowRightAltIcon /> */}
                </div>
              )}
           </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Collection;