import React from 'react'
import { useState, useEffect } from 'react'
import ReactApexChart from 'react-apexcharts';
import { stockgraphdata } from "../../../src/api/auth";

function Chart() {

  const [data, setData]= useState();
  const[range,setRange]=useState("1d");
  // const[symbol, setSymbol] = useState()

  const graphValue = async () => {
    try {
      const list = await stockgraphdata(range);
      setData(list);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRangeChange = (selectedRange) => {
    setRange(selectedRange);
  };

  // const handleSymbol = (selectedSymbol) => {
  //   setSymbol(selectedSymbol)
  // }
  
  useEffect(() => {
    if (range) {
      graphValue();
    }
  }, [range]);
console.log("data", data)

  const rows = data && data[0] && data[0].timestamp ? data[0].timestamp : [];
  const row_1 = data && data[0] && data[0].indicators.quote[0].open ? data[0].indicators.quote[0].open.map(value => parseFloat(value).toFixed(0)) : [];

const combineArray=[];
const minimumLength=Math.min(rows.length,row_1.length);
for(let i=0;i<=minimumLength;i++){
  combineArray.push({value1:rows[i],value2:row_1[i]})
}

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed (0-Jan, 1-Feb, ...)
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad single-digit day, month, hours, minutes, and seconds with leading zero if necessary
  const formattedDate = `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

// Map the timestamps array to formatted date strings
const formattedTimestamps = rows.map((timestamp) => formatTimestamp(timestamp));

// console.log(formattedTimestamps);
const seriesData = rows.map((x, index) => [x, row_1[index]]);
// ApexCharts series object
const series = [
    {
      name: 'Series 1',
      data: row_1
    }
  ];

  // Prepare options for the chart
  const options = {
    chart: {
      type: 'area',
      height: 400,
      toolbar: {
        show: false
      }
    },
    xaxis: {
      tickAmount: 6,
      type: 'category',
      categories: formattedTimestamps,
      labels: {
        rotate: -45,
        style: {
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
          
        },
        formatter: function (val) {
          // Custom formatting for timestamps (e.g., '2024-05-16 12:00:00' to 'May 16')
          const date = new Date(val);
          return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}`;
        }
      }
    },
    yaxis: {
      title: {
        text: 'Stock Value'
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: {
        formatter: function (val) {
          const date = new Date(val);return date.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });  return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}`;
        }
      }
    }
  };

  return (
    <>
     <div>
      <div id="chart">
        <div style={{display:"flex"}}>
        <button style={{background:"grey", color:"black"}}  onClick={() => handleRangeChange("1d")}>1D</button>
        <button style={{background:"grey", color:"black"}} onClick={() => handleRangeChange("5d")}>5D</button>
        <button style={{background:"grey", color:"black"}} onClick={() => handleRangeChange("1mo")}>1M</button>
        <button style={{background:"grey", color:"black"}} onClick={() => handleRangeChange("3mo")}>3M</button>
        <button style={{background:"grey", color:"black"}} onClick={() => handleRangeChange("1y")}>1Y</button>
        <button style={{background:"grey", color:"black"}} onClick={() => handleRangeChange("5y")}>5Y</button>
        <button style={{background:"grey", color:"black"}} onClick={() => handleRangeChange("max")}>All</button>
       
        </div>
        <div id="chart-timeline">
          <ReactApexChart options={options} series={series} type="area" height={450} />
        </div>
      </div>
      <div id="html-dist"></div>
    </div>
    </>
  )
}

export default Chart