import React, { useEffect, useState } from "react";
import "../Housing/housing.css";
import { Form } from "react-bootstrap";

function Entertainment(props) {

  const { myData } = props;
  let parsedData;
  try {
    parsedData = JSON.parse(myData.form_data);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }

  const allValues =
    Number(parsedData.Entertainment.streaming) +
    Number(parsedData.Entertainment.concerts_play) +
    Number(parsedData.Entertainment.games_mobile) +
    Number(parsedData.Entertainment.books_magazine) +
    Number(parsedData.Entertainment.activities) +
    Number(parsedData.Entertainment.membership);

  props.sendDataEt(allValues);


  return (
    <div className="dhhejk">
      <div className="daildy_living">ENTERTAINMMENT</div>
      <Form>
        <div className="living_state">
          <Form.Label className="adksk">Streaming Subscription</Form.Label>
          <input
            type="text"
            className=" input_style jadj"
            name="streaming"
            value={parsedData.Entertainment.streaming}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css">Concerts/Plays/Theater</Form.Label>

          <input
            className="input_style jkha"
            type="text"
            name="concerts_play"
            value={parsedData.Entertainment.concerts_play}
          />
        </div>
        <div className="living_state">
          <Form.Label className="adksk">Games/Mobile Apps</Form.Label>
          <input
            className="input_style jadj"
            type="text"
            name="games_mobile"
            value={parsedData.Entertainment.games_mobile}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css">Books/Magazine</Form.Label>
          <input
            className="input_style jkha"
            type="text"
            name="books_magazine"
            value={parsedData.Entertainment.books_magazine}
          />
        </div>
        <div className="living_state">
          <Form.Label className="adksk">Activities/Hobbies/Sports</Form.Label>
          <input
            className="input_style jadj"
            type="text"
            name="activities"
            value={parsedData.Entertainment.activities}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css">Membership Dues</Form.Label>
          <input
            className="input_style jkha"
            type="text"
            name="membership"
            value={parsedData.Entertainment.membership}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css_">Total Amount</Form.Label>
          <input
            className="input_style_ jadj jkdju"
            type="text"
            name="totaleHousing"
            value={allValues}
          />
        </div>
      </Form>
    </div>
  );
}

export default Entertainment;
