import auth from "../api/auth";
import { BILLS_ERROR, ERROR_CARDDETAILOPT, FETCH_BILLS, FETCH_CARDDETAILOPT, FETCH_PAYMENT, FETCH_VENDORLIST, INITIATE_PAYMENT, LOADER_CARDDETAILOPT, LOADER_PAYMENT, LOADER_VENDORLIST, PAYMENT_ERROR, SAVE_BILLS, UPDATE_PAYMENT, UPLOAD_BILLS, VENDORLIST_ERROR } from "../store/redux-constant";

var headers = {
	'Content-Type': 'application/json',
}

export const InitiatePayment = (formData) => {
	// console.log(formData)
	return async (dispatch, getState) => {
		// console.log(getState)
		headers['X-Skyflow-Authorization']=process.env.REACT_APP_SKYFLOW_KEY
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_PAYMENT, payload: {} })
			const response = await auth.post('/sky/create/payment', { ...formData },{headers})
			// console.log(response)
			if (response.status === 200) {
				dispatch({type:INITIATE_PAYMENT,payload:response.data.data})
			} else {
				// console.log(response.error)
				dispatch({type:PAYMENT_ERROR,payload:{}})
			}
		} catch (error) {
			dispatch({type:PAYMENT_ERROR,payload:{}})
			// console.log(error)
		}
	}
}

export const updatePayment = (formData)=>{
	// console.log(formData)
	return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_PAYMENT, payload: {} })
			const response = await auth.put('/payment/update',formData,{headers})
			if(response.status === 200){
				dispatch({type:UPDATE_PAYMENT,payload:response.data.data})
			}else{
				dispatch({type:PAYMENT_ERROR,payload:{}})
			}
		} catch (error) {
			dispatch({type:PAYMENT_ERROR,payload:{}})
			// console.log(error)
		}
	}
}

export const getPaymentHistory = (formData)=>{
	return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_PAYMENT, payload: {} })
			const response = await auth.get('/payment/history',{headers})
			if(response.status === 200){
				dispatch({type:FETCH_PAYMENT,payload:response.data.data})
			}else{
				dispatch({type:PAYMENT_ERROR,payload:{}})
			}
		} catch (error) {
			dispatch({type:PAYMENT_ERROR,payload:{}})
			// console.log(error)
		}
	}
}

export const getBills = (user)=>{
	return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_PAYMENT, payload: {} })
			const response = await auth.get(`/payables/${user.monite_entity_id}`,{headers})
			if(response.status === 200){
				dispatch({type:FETCH_BILLS,payload:response.data.data})
			}else{
				dispatch({type:BILLS_ERROR,payload:{}})
			}
		} catch (error) {
			dispatch({type:BILLS_ERROR,payload:{}})
		}
	}
}

export const addBills = (formData)=>{
	return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_PAYMENT, payload: {} })
			const response = await auth.post('/bill/create',formData,{headers})
			if(response.status === 200){
				dispatch({type:SAVE_BILLS,payload:response.data.data})
				dispatch(getBills())
			}else{
				dispatch({type:BILLS_ERROR,payload:{}})
			}			
		} catch (error) {
			dispatch({type:BILLS_ERROR,payload:{}})
			// console.log(error)
		}
	}
}
export const uploadBills = (formData)=>{
	return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_PAYMENT, payload: {} })
			const response = await auth.post('bill/create',formData,{headers})
			if(response.status === 200){
				dispatch({type:UPLOAD_BILLS,payload:response.data.data})
				dispatch(getBills())
			}else{
				dispatch({type:BILLS_ERROR,payload:{}})
			}			
		} catch (error) {
			dispatch({type:BILLS_ERROR,payload:{}})
			// console.log(error)
		}
	}
}

export const getVendorList = ()=>{
	return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_VENDORLIST, payload: {} })
			const response = await auth.get('/vendor/get',{headers})
			if(response.status === 200){
				// console.log(response.data.data)
				dispatch({type:FETCH_VENDORLIST,payload:response.data.data})
			}else{
				dispatch({type:VENDORLIST_ERROR,payload:{}})
			}
		} catch (error) {
			dispatch({type:VENDORLIST_ERROR,payload:{}})
			// console.log(error)
		}
	}
}

export const getCardListOption = ()=>{
	return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER_CARDDETAILOPT, payload: {} })
			const response = await auth.get('/sky/card-details',{headers})
			// console.log(response)
			if(response.status === 200){
				dispatch({type:FETCH_CARDDETAILOPT,payload:response.data.data})
			}else{
				dispatch({type:ERROR_CARDDETAILOPT,payload:[]})
			}
		} catch (error) {
			dispatch({type:ERROR_CARDDETAILOPT,payload:[]})
			// console.log(error)
		}
	}
}


export const getInvoicePDF = ()=>{
	return async (dispatch)=>{
		try {
			const header = {
				'Content-Type': 'application/pdf',
				'Authorization': 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			}
			
			fetch(`${process.env.REACT_APP_BASE_URL}/pdf/download`,{headers:header}).then(response => {
				response.blob().then(blob => {
					// Creating new object of PDF file
					const fileURL = window.URL.createObjectURL(blob);
					// Setting various property values
					let alink = document.createElement('a');
					alink.href = fileURL;
					let d = new Date();
					alink.download = 'invoice_'+d.getTime()+'.pdf';
					alink.click();
				})
			})
		} catch (error) {
			dispatch({type:ERROR_CARDDETAILOPT,payload:[]})
			// console.log(error)
		}
	}
}

