import React from "react";
import ReactApexChart from "react-apexcharts";

function AnnualActucalGraphP(props) {
	const { myData } = props;
	let allData;
	try {
		allData = JSON.parse(myData.form_data);
	} catch (error) {
		console.error("Error parsing JSON data:", error);
    }

	const values_1_ = Object.values(allData.dailyLiving);
	const totaleAllSumDailyliving = values_1_.reduce(
		(accumulator, currentValue) =>
			accumulator + (parseFloat(currentValue) || 0),
		0,
	);
	const values_2 = Object.values(allData.Entertainment);
	const totaleAllSumEntertain = values_2.reduce(
		(accumulator, currentValue) =>
			accumulator + (parseFloat(currentValue) || 0),
		0,
	);
	const values_3 = Object.values(allData.housing);
	const totaleAllSumHousing = values_3.reduce(
		(accumulator, currentValue) =>
			accumulator + (parseFloat(currentValue) || 0),
		0,
	);

	const montlyineries = [
		totaleAllSumDailyliving,
		totaleAllSumEntertain,
		totaleAllSumHousing,
	];
   const montlybaroption = {
			chart: {
				type: "pie",
				toolbar: {
					show: false,
				},
			},
			labels: ["Dailyliving", "Entertainment", "Housing"],
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
						legend: {
							position: "bottom",
						},
					},
				},
			],
		};

	return (
		<div>
			<h5>Annual Actual Expenses Details % </h5>
			<hr />
			<ReactApexChart
				options={montlybaroption}
				series={montlyineries}
				type="pie"
				height={250}
			/>
		</div>
	);
}

export default AnnualActucalGraphP;
