import { combineReducers } from 'redux';

import authReducers from './authReducers';
import chatReducers from './chatReducer';
import eventReducers from './eventReducer';
import paymentReducers from './paymentReducer';
import profileReducers from './profileReducers';
import notificationReducer from './notificationReducer';
export default combineReducers({
    user:profileReducers,
    auth:authReducers,
    payment:paymentReducers,
    chat:chatReducers,
    event:eventReducers,
    notification: notificationReducer
})