import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import auth from '../../../api/auth';
import AnnualActucalGraph from './annualActualgraph\$';
import AnnualActucalGraphP from './annualActualgraph%';
import AnnualExpensesDetail from './AnnualExpensesDetail';
import AnnualIncomeDetail from './AnnualIncomeDetail';
import ProjectZero from './ProjectZero';

function CashReportPdf(props) {
	const { list, selectedYear, dailyvalue, entertainment, housing } = props;

	let myData;
	try {
		myData = JSON.parse(list.report_data);
	} catch (error) {
		console.error("Error parsing JSON data:", error);
	}

	const [showModal, setShowModal] = useState(false);
	const [post, setPosts] = useState("");

	const openModal = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const getReports = async () => {
		const token = window.localStorage.getItem("AUTH_TOKEN");
		const response = await auth.get(
			`/cashflow/graph/${selectedYear}/${list.user_id}`,
			{
				headers: {
					Authorization: "Bearer " + token,
					"Content-Type": "Application/json",
					Accept: "Application/json",
				},
			},
		);
		setPosts(response.data);
	};
	useEffect(() => {
		getReports();
	}, [selectedYear]);

	let data = props.yearllyData;
	const linedata = [];
	const expensedata = [];
	const incomedata = [];
	for (let x in data) {
		let obj = {};
		let name = "";
		let barExpObj = {};
		let barIncomeObj = {};
		switch (x - 1) {
			case 0:
				name = "JAN";
				// code block
				break;
			case 1:
				name = "FEB";
				break;
			case 2:
				name = "MAR";
				// code block
				break;
			case 3:
				name = "APR";
				break;
			case 4:
				name = "MAY";
				// code block
				break;
			case 5:
				name = "JUN";
				break;
			case 6:
				name = "JUL";
				// code block
				break;
			case 7:
				name = "AUG";
				break;
			case 8:
				name = "SEP";
				// code block
				break;
			case 9:
				name = "OCT";
				break;
			case 10:
				name = "NOV";
				// code block
				break;
			case 11:
				name = "DEC";
				break;

			// code block

			default:
			// code block
		}
		obj.name = name;
		obj.expense = data[x].expense;
		obj.income = data[x].income;
		linedata.push(obj);
		barExpObj.name = name;
		barExpObj.expense = data[x].expense;
		expensedata.push(barExpObj);
		barIncomeObj.name = name;
		barIncomeObj.income = data[x].income;
		incomedata.push(barIncomeObj);
	}

	// ----------------------------------------------------------------------------linegraph--------------------------------
	const lineoptions = {
		chart: {
			type: "line",
			toolbar: {
				show: false,
			},
		},
		xaxis: {
			categories: [
				"JAN",
				"FEB",
				"MAR",
				"APR",
				"MAY",
				"JUN",
				"JUL",
				"AUG",
				"SEP",
				"OCT",
				"NOV",
				"DEC",
			],
		},
		colors: ["#8884d8", "#82ca9d"],
		markers: {
			size: 8,
		},
		legend: {
			position: "top",
		},
	};
	const lineseries = [
		{
			name: "Income",
			data: Object.values(post).map((item) => item.income),
		},
		{
			name: "Expenses",
			data: Object.values(post).map((item) => item.expense),
		},
	];

	return (
		<div>
			<button type="button" className="btn btn-sm" onClick={openModal}>
				<i className="bi bi-bar-chart-fill"></i>
			</button>

			{showModal && (
				<div
					className="modal"
					tabIndex="-1"
					role="dialog"
					style={{ display: "block" }}
				>
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Graph</h5>
								<button
									type="button"
									className="close _newclose"
									onClick={closeModal}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-md-12">
										<AnnualActucalGraph myData={myData} />
									</div>
									<div className="col-md-12">
										<AnnualActucalGraphP myData={myData} />
									</div>

									<div className="col-md-12">
										<h5>Annual Income & Expenses Details</h5>
										<hr />
										<ReactApexChart
											options={lineoptions}
											series={lineseries}
											type="line"
											height={200}
										/>
									</div>
									<div className="col-md-12">
										<AnnualExpensesDetail post={post} myData={myData} />
									</div>
									<div className="col-md-12">
										<AnnualIncomeDetail post={post} />
									</div>
									<div className='col-md-12'>
										<ProjectZero/>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-secondary"
									onClick={closeModal}
								>
									Close
								</button>
								{/* Add more buttons or actions as needed */}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default CashReportPdf;
