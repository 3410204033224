import React, { Component } from "react";
import { Link } from "react-router-dom";

import Section from "../../layouts/Section";

class Error404 extends Component{
    render(){
        return(
        <Section allNotification={false} title={'404'}>
                <div className="blog-area pd-top-36 pb-2 mg-top-40">
                    <div className="container">
                        <div className="section-title">
                            <h3 className="title">Page Not Found!</h3>
                            <Link to={'/'}>Go Back</Link>
                        </div>
                        <div className="row">
                                <div className="single-blog">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/other/404.png'} alt="img" />
                                    </div>
                                    <div className="details">
                                        <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </Section>
        );
    }
}

export default Error404;