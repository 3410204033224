import React from "react";
import { currency } from "../../data/currency";

function CurrencySelectBox(props) {
  return (
    <select ref={props.ref} defaultValue={currency[123]} {...props} className="form-control" data-live-search="true">
      <option value="">Select Currency</option>
      {currency.map((c, i) => (
        <option key={"currency-" + i} value={c}>{c}</option>
      ))}
    </select>
  );
}

export default CurrencySelectBox
