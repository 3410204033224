import '../Document/document.css'
import React, { useState, useEffect } from "react";
import Section from '../layouts/Section';
import DocViewer, { DocViewerRenderers, } from "react-doc-viewer";
import { Modal } from "react-bootstrap";
import { Button } from "rsuite/";
import auth from '../../api/auth';


export default function Informationmodel(props) {
  const [showModal, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    async function getDocuments(folderPath) {
      const token = window.localStorage.getItem('AUTH_TOKEN');
      const response = await auth.get(`/documents/${folderPath}`, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'Application/json',
          'Accept': 'Application/json',
        },
      });

      setDocuments(response.data.data);
    }

    if (props.match.params.folder) {
      getDocuments(props.match.params.folder);
    }

    // cleanup
    return () => { }
  }, [props.match.params.folder]);


  return (
    <>
      <Section allNotification={false} searchPopup={true} title={'Documents'} />
      <div className='information container'>
        <div className='_information'>
          <div className='_information-head'>
            <h4 className="_information_head">Documents <i className="fa fa-file-pdf-o pdf-file" aria-hidden="true"></i></h4>
            <Modal show={showModal} onHide={handleClose}>
              <Modal.Header >
                <button type="button" onClick={handleClose} className="document-close" data-dismiss="modal">
                  <span aria-hidden="true">×</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={documents.map(doc => ({ uri: process.env.REACT_APP_MAIN_URL + doc.upload.filepath }))}

                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: false,
                      retainURLParams: false
                    }
                  }}
                  style={{ height: 500 }}
                />

              </Modal.Body>
              <Modal.Footer>
                <Button style={{ backgroundColor: "red", fontWeight: "600", color: "white" }} onClick={handleClose}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <div className='pdf-content'>
              {documents.map((item, k) =>
                <div key={k}>
                  <div className="pdf-inner">
                    <div className="pdf-active" onClick={handleOpen}>
                      <img src={process.env.PUBLIC_URL + '/assets/img/file1.png'} alt="img" style={{ marginLeft: "10px" }} width="70" height="40" />
                      <div>
                        <div className='_fileName' >{item.upload.original_filename}</div>
                        <div className='_fileName' style={{ fontSize: "11px", lineClamp: "10px" }}>{item.upload.size} KB</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ height: '50px' }}></div>
        </div>
      </div>
    </>
  )
}
