
import React from 'react';
import './boxie.css'; // Import the CSS file

const Dynamicpage = (props) => {
  const { selectProduct } = props;
  return (
    <div className="dynamicpage-container">
      <img
        src={selectProduct && selectProduct.imageUrl}
        alt="Product"
        className="dynamicpage-img"
      />
    </div>
  );
};

export default Dynamicpage;
