const data = [
    {
        title: "Doctor Meeting",
        to: "John Alex",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "25/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "John Alex",
        occupation: "General Physician",
        image: '/assets/img/cal2.avif',
        date: "23/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "John Alex",
        occupation: "General Physician",
        image: '/assets/img/cal3.avif',
        date: "23/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "John Alex",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "25/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "John Alex",
        occupation: "General Physician",
        image: '/assets/img/cal2.avif',
        date: "26/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Finance Meeting",
        to: "Adam smith",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "26/08/2022",
        time: "01:30 - 02:00",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "John Alex",
        occupation: "General Physician",
        image: '/assets/img/cal3.avif',
        date: "28/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "John Alex",
        occupation: "General Physician",
        image: '/assets/img/cal3.avif',
        date: "29/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "Bill quinton",
        occupation: "General Physician",
        image: '/assets/img/cal2.avif',
        date: "30/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "Bill quinton",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "30/08/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "Bill carla",
        occupation: "General Physician",
        image: '/assets/img/cal2.avif',
        date: "13/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Advisor Meeting",
        to: "Alex smith",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "13/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "Bill carla",
        occupation: "General Physician",
        image: '/assets/img/cal2.avif',
        date: "13/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Advisor Meeting",
        to: "Alex smith",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "14/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Advisor Meeting",
        to: "Alex smith",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "14/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Doctor Meeting",
        to: "Bill carla",
        occupation: "General Physician",
        image: '/assets/img/cal2.avif',
        date: "15/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Advisor Meeting",
        to: "Alex smith",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "15/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Advisor Meeting",
        to: "Alex smith",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "15/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    },
    {
        title: "Advisor Meeting",
        to: "Alex smith",
        occupation: "General Physician",
        image: '/assets/img/cal1.avif',
        date: "15/09/2022",
        time: "12:30 - 12:45",
        address: "DLF Phase V, Gurgaon, Haryana, 202020"
    }
]


export default data