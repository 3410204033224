import React from "react";
import io from "socket.io-client";
export const socket = io(process.env.REACT_APP_SOCKET_URL, {reconnection: false});
export const SocketContext = React.createContext();

export const iceServers = [
    { 
        urls: "stun:stun.l.google.com:19302"
    },
    {
        username: "efK3IP6JC3EX3GIAKL",
        credential: "wSEIFe8hQB0tBc1s",
        url: "turn:relay1.expressturn.com:3478?transport=udp",
        urls: "turn:relay1.expressturn.com:3478?transport=udp"
    },
    {
        username: "efK3IP6JC3EX3GIAKL",
        credential: "wSEIFe8hQB0tBc1s",
        url: "turn:relay1.expressturn.com:3478?transport=tcp",
        urls: "turn:relay1.expressturn.com:3478?transport=tcp"
    },
    {
        username: "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
        credential: "tE2DajzSJwnsSbc123",
        url: "turn:global.turn.twilio.com:3478?transport=udp",
        urls: "turn:global.turn.twilio.com:3478?transport=udp"
    },
    {
        username: "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
        credential: "tE2DajzSJwnsSbc123",
        url: "turn:global.turn.twilio.com:3478?transport=tcp",
        urls: "turn:global.turn.twilio.com:3478?transport=tcp"
    },
    {
        username: "dc2d2894d5a9023620c467b0e71cfa6a35457e6679785ed6ae9856fe5bdfa269",
        credential: "tE2DajzSJwnsSbc123",
        url: "turn:global.turn.twilio.com:443?transport=tcp",
        urls: "turn:global.turn.twilio.com:443?transport=tcp"
    },
];