import React from 'react';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';

function savingGoals() {
    return (
        <div>
            <Header />

            {/* goal area Start */}
				<div className="goal-area pd-top-36 pd-bottom-40">
					<div className="container">
						<div className="section-title">
							<h3 className="title">Saving Goals</h3>
							<Link to={'/saving'}>View All</Link>
						</div>
						<div className="single-goal single-goal-one">
							<div className="row">
								<div className="col-5 pr-0">
									<div className="details">
										<h6>Finance</h6>
										<p>savings</p>
									</div>
								</div>
								<div className="col-2 pr-0">
									<div className="details-chart">
										<i className="fa fa-line-chart" aria-hidden="true"></i>
									</div>
								</div>

								<div className="col-5 pl-0">
									<div className="circle-inner circle-inner-one">
										<h6 className="goal-amount">$750</h6>
										<div className="chart-circle" data-value="0.50">
											<canvas width="52" height="52" />
											<div className="chart-circle-value text-center">50%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="single-goal single-goal-two">
							<div className="row">
								<div className="col-5 pr-0">
									<div className="details">
										<h6>Health Status</h6>
										<p>Health</p>
									</div>
								</div>
								<div className="col-2 pr-0">
									<div className="details-heartbeat">
										<i className="fa fa-heartbeat heartbeats" aria-hidden="true"></i>
									</div>
								</div>
								<div className="col-5 pl-0">
									<div className="circle-inner circle-inner-two">
										<h6 className="goal-amount">Good</h6>
										<div className="chart-circle" data-value="0.90">
											<canvas width="52" height="52" />
											<div className="chart-circle-value text-center">90%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="single-goal single-goal-three">
							<div className="row">
								<div className="col-5 pr-0">
									<div className="details">
										<h6>Monthely Planning</h6>
										<p>Planning</p>
									</div>
								</div>
								<div className="col-2 pr-0">
									<div className="details-calendar">
										<i className="fa fa-calendar-check-o fa-detail" aria-hidden="true"></i>
									</div>
								</div>
								<div className="col-5 pl-0">
									<div className="circle-inner circle-inner-three">
										<h6 className="goal-amount">$580</h6>
										<div className="chart-circle" data-value="0.60">
											<canvas width="52" height="52" />
											<div className="chart-circle-value text-center">60%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="single-goal single-goal-four">
							<div className="row">
								<div className="col-5 pr-0">
									<div className="details">
										<h6>Personal Documents</h6>
										<p>Documents</p>
									</div>
								</div>
								<div className="col-2 pr-0">
									<div className="details-text">
										<i className="fa fa-file-text-o" aria-hidden="true"></i>
									</div>
								</div>
								<div className="col-5 pl-0">
									<div className="circle-inner circle-inner-four">
										<h6 className="goal-amount">Done</h6>
										<div className="chart-circle" data-value="0.60">
											<canvas width="52" height="52" />
											<div className="chart-circle-value text-center">60%</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* goal area End */}

            <Footer />
        </div>
    )
}

export default savingGoals;
