import React from 'react';
import { Document, Page, Text, Image, StyleSheet } from '@react-pdf/renderer';

import Medical from './Video/Medical.jpg';

const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal:35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign:"justify",
      fontFamily:"Times-Roman",
    },
    image: {
      marginVerticle: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign:"center",
      color:"grey"
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom:30,
      left: 0,
      right: 0,
      textAlign:"center",
      color:"grey",
    },
  });
  
  // Create Document Component
  const PdfFile = () => (
    <Document>
      <Page size="A4" style={styles.body}>
      <Text style={styles.header} fixed>Health Demo</Text>
      <Image style={styles.image} src={Medical}/>
      <Text style={styles.text}> Health, according to the World Health Organization, is "a state of complete physical, mental and social well-being and not merely the absence of disease and infirmity". A variety of definitions have been used for different purposes over time</Text>
      <Text style={styles.pageNumber}
        fixed
        render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}
      />
      </Page>
    </Document>
  );

  export default PdfFile;