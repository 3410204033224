import React, { Component, createRef } from "react";
import "./chatContent.css";
import Avatar from "../ChatList/Avatar";
import ChatItem from "./ChatItem";
import { getChats } from "../../../actions/chat";
import { connect } from "react-redux";
import { SocketContext } from "../../../context/socket";
import moment from "moment";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import { FilePicker } from "react-file-picker";
import auth, { SITE_URL } from "../../../api/auth";
import History from "../../../History";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class ChatContent extends Component {
  messagesEndRef = createRef(null);

  static contextType = SocketContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      userProfile: JSON.parse(localStorage.getItem("userDetail")),
      chatsData: [],
      msg: "",
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
      file: null,
      progress: 0,
      cameras: null,
      stream: null,
      screenshotImage: null,
      facingMode: "user",
      typing: null,
    };

    this.send = this.send.bind(this);
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current !== null) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    const socket = this.context;

    setTimeout(() => {
      this.initSocket(socket);
    }, 1000);

    window.addEventListener("keydown", (e) => {
      if (e.keyCode === 13) {
        this.send();
      }
    });

    this.props.getChats();

    setTimeout(() => {
      this.scrollToBottom();
    }, 2000);

    const $this = window.jQuery;
    const self = this;

    $this("#chat__content__body").on("scroll", function () {
      const scrollTop = $this(this).scrollTop();
      if (scrollTop === 0) {
        self.getGoupChat();
      }
    });

    if (
      "mediaDevices" in navigator &&
      "getUserMedia" in navigator.mediaDevices
    ) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const videoDevices = devices.filter(
            (device) => device.kind === "videoinput",
          );
          self.setState({
            cameras: videoDevices,
          });
        })
        .catch((error) => {
          console.log("Device Error", error);
          toast.error("Device not supported");
        });
    }

    window.jQuery("#__camera-modal").on("shown.bs.modal", function () {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            // deviceId: self.state.cameras[0].deviceId,
            facingMode: { exact: self.state.facingMode },
          },
        })
        .then((stream) => {
          const video = document.getElementById("camera-video");
          video.srcObject = stream;
          video.play();
          self.setState({ stream: stream });
        })
        .catch((error) => {
          console.log("Device Error", error);
        });
    });

    window.jQuery("#__camera-modal").on("hidden.bs.modal", function () {
      if (self.state.stream !== null || self.state.stream !== undefined) {
        self.state.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      self.setState({ screenshotImage: null });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  capture = (e) => {
    e.preventDefault();
    const canvas = document.createElement("canvas");
    canvas.id = "imagec";
    // canvas.style.position = 'fixed'
    canvas.style.border = "1px solid #222";
    canvas.style.zIndex = 999;
    canvas.style.left = 0;
    canvas.style.top = 0;

    document.body.append(canvas);
    const video = document.getElementById("camera-video");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0);
    const screenshot = canvas.toDataURL("image/png");
    this.setState({
      screenshotImage: screenshot,
    });
  };

  getGoupChat = () => {
    const { chatsData } = this.state;

    let last = 0;
    let group = chatsData[0]["id"];
    // console.log('chats', chatsData[0])
    if (chatsData[0].messages.length > 0) {
      last = chatsData[0].messages[0].id;
    }

    const token = window.localStorage.getItem("AUTH_TOKEN");
    const self = this;

    auth
      .get(`/messages/group/${group}/${last}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // console.log(res.data.data)
        let messages = res.data.data;
        for (let message of messages) {
          chatsData[0].messages.unshift(message);
        }
        self.setState({ chatsData: [...chatsData] });
      })
      .catch((error) => {
        // console.error(error)
      });
  };

  send() {
    const socket = this.context;

    const { userProfile } = this.state;

    if (this.state.msg !== "") {
      const { chatsData, msg } = this.state;
      chatsData[0].messages.push({
        sender: userProfile,
        message: msg,
        mime_type: "text/html",
        created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        upload_id: null,
        upload: null,
      });

      socket.emit("message", {
        message: msg,
        mime_type: "text/html",
        group_id: chatsData[0].id,
        room: "room_" + chatsData[0].id,
        token: localStorage.getItem("AUTH_TOKEN"),
      });

      this.setState({ chatsData: [...chatsData] });
      this.setState({ msg: "" });

      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    }
  }

  initSocket(socket) {
    socket
      .on("message", (message) => {
        console.log("socket message");
        if (this._isMounted) {
          const { chatsData } = this.state;
          console.log(chatsData[0]["id"], message.data.group_id);
          if (chatsData[0]["id"] === message.data.group_id) {
            chatsData[0].messages.push(message.data);
            this.setState({
              chatsData,
            });
            this.scrollToBottom();
          }
        }
      })
      .on("typing", (data) => {
        if (this._isMounted) {
          this.setState({
            typing: data,
          });

          setTimeout(() => {
            this.setState({
              typing: null,
            });
          }, 2000);
        }
      });
  }

  onStateChange = (e) => {
    this.setState({ msg: e.target.value });
  };

  static getDerivedStateFromProps(props, state) {
    if (props) {
      let chats = { ...state.chatsData };
      chats = props.chats;
      // const _chats = chats.filter((chat) => chat.users.length === 1 && chat.users[0].id === props.uid )
      // alert(window.atob(props.uid))
      const _chats = chats.filter((chat) => chat.id == window.atob(props.uid));
      return { ...state, chatsData: _chats };
    }
    return state;
  }

  setCall = (type) => {
    const socket = this.context;
    const { chatsData, userProfile } = this.state;
    socket.emit("call-room", {
      room: "room_" + chatsData[0].id,
      user: userProfile,
      type: "video",
    });
    const room = ("room_" + chatsData[0].id);
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
    window.open(
      `/call/${room}/?CALLER=${userProfile.id}`,
      "video-call",
      params,
    );
  };

  handleAudioStop(data) {
    // console.log(data)
    this.setState({ audioDetails: data });
  }

  handleAudioUpload(file) {
    if (file === null) {
      alert("Record your voice first");
      return;
    }

    // file = file.slice(0, file.size, "audio/webm")
    const newFile = new File([file], "record_" + Math.random() + ".webm", {
      type: "audio/webm",
      lastModified: new Date().toTimeString(),
    });

    const formData = new FormData();
    formData.append("file", newFile);
    formData.append("mime_type", "audio/webm");

    const socket = this.context;

    const { chatsData, userProfile } = this.state;

    const token = window.localStorage.getItem("AUTH_TOKEN");

    auth
      .post(`/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: (data) => {
          // console.log('progress', data);
          this.setState({
            progress: Math.round((100 * data.loaded) / data.total),
          });
        },
      })
      .then((data) => {
        const file = data.data.data;
        // console.log('file', file)
        const message = `${SITE_URL}${file.filepath}`;
        socket.emit("message", {
          message: message,
          upload_id: file.id,
          mime_type: file.mime_type,
          group_id: chatsData[0].id,
          room: "room_" + chatsData[0].id,
          token: token,
        });

        chatsData[0].messages.push({
          sender: userProfile,
          message: message,
          mime_type: "audio/webm",
          created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
          upload_id: file.id,
          upload: file,
        });

        this.setState({ chatsData: [...chatsData], progress: 0 });
        this.handleReset();
        window.jQuery("#__voice-madal").modal("hide");
      })
      .catch((error) => {
        // console.error(error)
      });
  }

  handleCountDown(data) {
    // console.log(data);
  }

  handleReset() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    this.setState({ audioDetails: reset });
  }

  voiceMsg = (e) => {
    window.jQuery("#__voice-madal").modal("show");
  };

  url2File = async (url, fileName) => {
    const blob = await (await fetch(url)).blob();
    return new File([blob], fileName, { type: blob.type });
  };

  fileOnChange = (file) => {
    // console.log(file)
    this.setState({
      file: file,
    });
  };

  fileUpload = () => {
    // e.preventDefault();
    const { file } = this.state;
    if (file !== null) {
      const formData = new FormData();
      formData.append("file", file);

      const socket = this.context;

      const { chatsData, userProfile } = this.state;

      const token = window.localStorage.getItem("AUTH_TOKEN");

      auth
        .post(`/uploads`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: (data) => {
            this.setState({
              progress: Math.round((100 * data.loaded) / data.total),
            });
          },
        })
        .then((data) => {
          const file = data.data.data;
          // console.log('file', file)
          const message = `${SITE_URL}${file.filepath}`;
          socket.emit("message", {
            message: message,
            upload_id: file.id,
            mime_type: file.mime_type,
            group_id: chatsData[0].id,
            room: "room_" + chatsData[0].id,
            token: token,
          });

          chatsData[0].messages.push({
            sender: userProfile,
            message: message,
            mime_type: file.mime_type,
            created_at: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
            upload_id: file.id,
            upload: file,
          });

          this.setState({ chatsData: [...chatsData], progress: 0, file: null });
          window.jQuery("#__file-madal").modal("hide");
        })
        .catch((error) => {
          // console.error(error)
        });
    }
  };

  cancelScreenshot = (e) => {
    this.setState({ screenshotImage: null });
  };
  sendScreenshot = async (e) => {
    window.jQuery("#__camera-modal").modal("hide");
    document.getElementById("imagec").remove();
    const file = await this.url2File(
      this.state.screenshotImage,
      "screenshot.png",
    );

    this.setState({ file: file });
    this.fileUpload();
  };

  cancelCapture = () => {
    window.jQuery("#__camera-modal").modal("hide");
  };

  changeCamera = () => {
    const self = this;
    const supports = navigator.mediaDevices.getSupportedConstraints();
    if (!supports["facingMode"]) {
      alert("Browser Not supported!");
      return;
    }

    let f = "user";

    if (self.state.facingMode === "user") {
      f = "environment";
    }

    self.setState({ facingMode: f });

    if (self.state.stream) {
      const tracks = self.state.stream.getTracks();
      tracks.forEach((track) => track.stop());
    }

    navigator.mediaDevices
      .getUserMedia({
        video: {
          facingMode: { exact: f },
        },
      })
      .then((stream) => {
        const video = document.getElementById("camera-video");
        video.srcObject = stream;
        video.play();
        self.setState({ stream: stream });
      })
      .catch((error) => {
        console.log("Device Error", error);
        toast.error("Camera not supported");
      });
  };

  handleGallery = (e) => {
    const file = e.target.files[0];
    if (file !== null) {
      this.setState({
        file: file,
      });
      this.cancelCapture();
      setTimeout(() => {
        this.fileUpload();
      }, 1000);
    }
  };

  chatHeadText(chatsData) {
    if (chatsData.length > 0 && chatsData[0].users.length === 1) {
      return (
        <>
          <Avatar
            isOnline="active"
            image={chatsData[0].users[0].avatar}
            className="mr-1"
          />
          <p>{chatsData[0].users[0].name}</p>
        </>
      );
    } else {
      return (
        <>
          <Avatar
            isOnline="active"
            image={"/assets/img/group.png"}
            className="mr-1"
          />
          <p>Lifenavigator - Professional</p>
        </>
      );
    }
  }

  render() {
    const { chatsData, userProfile, typing } = this.state;

    if (this.props.location.search === "?call") {
      if (this.state.chatsData.length > 0) {
        this.setCall("video");
        setTimeout(() => {
          History.push(`/chat/${window.btoa(chatsData[0]["id"])}`);
        }, 2000);
      }
    }

    return (
      <>
        <div id="__camera-modal" className="modal fade">
          <div className="modal-dialog  modal-fullscreen" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <video id="camera-video" autoPlay></video>
                {this.state.screenshotImage ? (
                  <img id="screenshot" src={this.state.screenshotImage} />
                ) : (
                  ""
                )}
                {this.state.screenshotImage ? (
                  <div className="camera-action-s-buttons">
                    <button
                      onClick={this.cancelScreenshot}
                      className="btn-cancel"
                    >
                      &#10006;
                    </button>
                    <button onClick={this.sendScreenshot} className="btn-ok">
                      &#10004;
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div className="camera-action-buttons">
                  <button onClick={this.cancelCapture} className="btn-click">
                    &#10006;
                  </button>
                  <button onClick={this.capture} className="btn-click">
                    Click
                  </button>
                  <button onClick={this.changeCamera} className="btn-click">
                    <i className="fa fa-refresh"></i>
                  </button>
                  <div className="gallery-btn">
                    <input
                      onChange={this.handleGallery}
                      type="file"
                      accept="image/*"
                    />
                    <i className="fa fa-photo"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="__file-madal" className="modal fade">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <b>Upload File</b>
                <button
                  onClick={() => window.jQuery("#__file-madal").modal("hide")}
                  type="button"
                  className="closes btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.file !== null ? (
                  <p>
                    You have selected <b>{this.state.file.name}</b> to uplaod
                  </p>
                ) : (
                  ""
                )}
                <FilePicker
                  extensions={[
                    "jpg",
                    "jpeg",
                    "png",
                    "gif",
                    "bmp",
                    "pdf",
                    "doc",
                    "docx",
                    "mp3",
                    "mp4",
                    "mov",
                    "avi",
                    "webp",
                    "csv",
                    "xls",
                    "xlsx",
                  ]}
                  onChange={(FileObject) => this.fileOnChange(FileObject)}
                  maxSize={50}
                  onError={(errMsg) => alert(errMsg)}
                  className="file-picker-styd"
                >
                  <button className="btn new_btn_vo">Choose File</button>
                </FilePicker>

                {this.state.file !== null && this.state.progress === 0 ? (
                  <button
                    onClick={this.fileUpload}
                    className="btn btn-success mt-2"
                  >
                    Upload
                  </button>
                ) : (
                  ""
                )}
                {this.state.file !== null && this.state.progress > 0 ? (
                  <button disabled className="btn btn-secondary mt-2">
                    Sending... {this.state.progress}% done
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div id="__voice-madal" className="modal fade">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <b>Record Your Voice</b>
                <button
                  onClick={() => window.jQuery("#__voice-madal").modal("hide")}
                  type="button"
                  className="closes btn-danger"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.progress > 0 ? (
                  <p>Please wait... {this.state.progress}% done</p>
                ) : (
                  ""
                )}

                <Recorder
                  record={true}
                  title={"New Recording"}
                  audioURL={this.state.audioDetails.url}
                  showUIAudio
                  handleAudioStop={(data) => this.handleAudioStop(data)}
                  handleAudioUpload={(data) => this.handleAudioUpload(data)}
                  handleCountDown={(data) => this.handleCountDown(data)}
                  handleReset={() => this.handleReset()}
                  mimeTypeToUseWhenRecording={`audio/webm`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="main__chatcontent">
          <div className="content__header">
            <div className="blocks">
              <div className="current-chatting-user">
                <Link
                  onClick={(e) => History.goBack()}
                  style={{ color: "#222", textDecoration: "none" }}
                  to={"/chats"}
                >
                  <i className="fa fa-arrow-left"></i> &nbsp;
                </Link>
                {this.chatHeadText(chatsData)}
              </div>
            </div>

            <div className="blocks">
              <div className="settings">
                <button
                  onClick={(e) => this.setCall("video")}
                  className="btn-nobg"
                >
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
          <div id="chat__content__body" className="content__body">
            <div className="chat__items">
              {chatsData.length > 0 &&
                chatsData[0].messages.map((message, i) => {
                  return <ChatItem key={i} user={userProfile} msg={message} />;
                })}
              <div className="typing-block">
                {typing ? (
                  <>
                    {typing.user.name} is typing
                    <div className="wave">
                      <span style={{ animationDelay: "1.0s" }}></span>
                      <span style={{ animationDelay: "1.1s" }}>.</span>
                      <span style={{ animationDelay: "1.2s" }}>.</span>
                      <span style={{ animationDelay: "1.3s" }}>.</span>
                    </div>
                  </>
                ) : null}
              </div>
              <div ref={this.messagesEndRef} />
            </div>
          </div>
          <div className="content__footer">
            <div className="sendNewMessage">
              <button
                onClick={(e) => this.props.history.push("/main")}
                className="addFiles"
              >
                <i className="fa fa-home"></i>
              </button>
              <input
                type="text"
                placeholder="Type a message here"
                onChange={this.onStateChange}
                value={this.state.msg}
              />
              <button
                onClick={(e) => window.jQuery("#__camera-modal").modal("show")}
                className="camera mr-1"
              >
                <i className="fa fa-camera"></i>
              </button>
              <button
                onClick={(e) => window.jQuery("#__file-madal").modal("show")}
                className="addFiles mr-1"
              >
                <i className="fa fa-paperclip"></i>
              </button>
              <button
                onClick={this.voiceMsg}
                className="btnSendMsg mr-1"
                id="voiceMsgBtn"
              >
                <i className="fa fa-microphone"></i>
              </button>
              <button
                onClick={this.send}
                className="btnSendMsg"
                id="sendMsgBtn"
              >
                <i className="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    chats: state.chat.chats,
    loader: state.chat.loader,
  };
};

export default connect(mapStateToProps, { getChats })(ChatContent);
