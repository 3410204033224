import React, { Component } from "react";
import { connect } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./subcomponent/stripe/stripeCheckoutForm";
import Section from "./layouts/Section";
import { InitiatePayment } from '../actions'
import Spinner from "./subcomponent/spinner/Spinner";
import RechargeList from "./subcomponent/payment/RechargeList";
const STRIPE_P_KEY = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(STRIPE_P_KEY);


class PaymentHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userDetail: JSON.parse(localStorage.getItem('userDetail')),
            stripeForm: {},
            errors: {},
            stripeOptions: {
                clientSecret: '',
                appearance: {
                    theme: 'stripe'
                }
            }
        }
        this.handleInput = this.handleInput.bind(this)
    }

    handleInput(e, field) {
        let _stripeForm = this.state.stripeForm;
        _stripeForm[field] = e.target.value;
        this.setState({ stripeForm: _stripeForm });
    }

    handleValidation() {
        let fields = this.state.stripeForm;
        // console.log(Number(fields["amount"], 10))
        let errors = {};
        let formIsValid = true;

        if (fields["amount"] === null || (typeof fields["amount"] !== "undefined" && Number(fields["amount"], 10) <= 0)) {
            formIsValid = false;
            errors["amount"] = "Please Enter valid amount.";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    InitiatePayment(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            // console.log('form',this.state.stripeForm)
            this.props.InitiatePayment({ ...this.state.stripeForm, currency: 'usd' })
        } else {
            // console.log('invalid')
        }
    }

    static getDerivedStateFromProps(props, state) {
        // console.log(props)
        if (props) {
            let stripe_option = { ...state.stripeOptions }
            stripe_option.clientSecret = props.client_secret.clientSecret
            return { ...state, stripeOptions: stripe_option };
        }
    }

    render() {
        // console.log(this.state.historyList)
        return (
            <Section allNotification={false} searchPopup={true} title={'Payment History'}>
                <div className="balance-area pd-top-40 mg-top-35">
                    <div className="container">
                        <div className="balance-area-bg balance-area-bg-home">
                            <div className="balance-title text-center">
                                <h6>Welcome! <br />Mr {this.state.userDetail?.name}</h6>
                            </div>
                            <div id="balance-section" className="ba-balance-inner text-center">
                                <div className="icon">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/icon/12.png'} alt="img" />
                                </div>
                                <h5 className="title">Current Account</h5>
                                <h5 className="amount">${this.state.userDetail?.balance}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-title d-flex justify-content-between align-items-center mt-4 mb-0">
                        <h6 className="font-weight-normal">Payment History</h6>
                        <div className="d-flex"></div>
                    </div>

                    <div className="bill-pay-area mg-bottom-35">
                        {/* Payment Modal Open Start */}
                        <div className="add-balance-inner-wrap">
                            <div className="container">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title">Add Balance</h5>
                                        </div>
                                        <div className="modal-body">
                                            <div className="action-sheet-content">

                                                <div className="form-group basic">
                                                    <label className="label">Enter Amount</label>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text" id="input1">$</span>
                                                        </div>
                                                        <input
                                                            type="number"
                                                            onChange={(e) => this.handleInput(e, 'amount')}
                                                            className="form-control form-control-lg"
                                                            placeholder="Enter your amount"
                                                        />
                                                    </div>
                                                    {this.state.errors.amount !== undefined ? <p className="text-center text-danger">{this.state.errors.amount}</p> : null}
                                                </div>

                                                <div className="form-group basic">

                                                    {this.state.stripeOptions.clientSecret !== undefined && this.state.stripeOptions.clientSecret.length > 0 ?
                                                        <Elements options={this.state.stripeOptions} stripe={stripePromise}>
                                                            <CheckoutForm />
                                                        </Elements>
                                                        :
                                                        <button type="button" className="btn-c btn-primary btn-block btn-lg" onClick={this.InitiatePayment.bind(this)} >Initiat Payment {this.props.loader ? <Spinner size="mini" /> : null}</button>
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Payment Modal Open End */}

                        <RechargeList />
                    </div>
                </div>
            </Section>
        );
    }
}

const mapStateToProps = state => {
    // console.log(state.payment)
    return {
        client_secret: state.payment.clientSecret,
        payment_history: state.payment.paymentHistory,
        loader: state.payment.loader
    }
}

export default connect(mapStateToProps, { InitiatePayment })(PaymentHistory);