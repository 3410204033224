import React, { useEffect, useState } from "react";
import './Tsp.css'
import CommonTable from "./CommonTable";
function Tsp(){
    const columns = [
        { field: 'id',
          headerName: 'Tickers',
          width: 100,
          color: 'black',
          headeralign: 'center',
          align: 'center',
          headerClassName:'hideRightSeparator side-border',
        },
        { 
          field: 'sprice',
          headerName: 'Balance at 31/12',
          width: 230,
          headeralign: 'center',
          align: 'center',
          headerClassName:'hideRightSeparator side-border_2',
          disableColumnMenu:true , 
          disableColumnFilter:false,
          disableColumnSelector:false
      },
      { 
          field: 'cstock',
          headerName: 'Current Balance', 
          width: 230, 
          headeralign: 'center',
          align: 'center',
          headerClassName:'side-border_2',
          disableColumnMenu:true, 
          disableColumnFilter:false,
          disableColumnSelector:false
      },
      {
          field: 'fmv',
          headerName: 'Delta Value',
          width: 230,
          headeralign: 'center',
          align: 'center',
          headerClassName:'hideRightSeparator side-border_3',
          disableColumnMenu:true,
          disableColumnFilter:false,
          disableColumnSelector:false
      },
      {
          field: 'ytd',
          headerName: 'Delta % from 31/12 ',
          sortable: false,
          width: 150,
          headeralign: 'center',
          align: 'center',
          headerClassName:'hideRightSeparator side-border_3',
          disableClickEventBubbling: true,
          disableColumnMenu:true,
          disableColumnFilter:true,
          disableColumnSelector:true,
        },
        { 
          field: 'potential',
          headerName: '% to recover from 31/12', 
          width: 176, 
          headeralign: 'center',
          align: 'center',
          headerClassName:'hideRightSeparator side-border_4',
          disableColumnMenu:true, 
          disableColumnFilter:false,
          disableColumnSelector:false
        },
        ];

        const rows = [
            { id: 'Fund 10', sprice: '$ 75.000', cstock: '$7.600.000', fmv: '$7.525.000', ytd: '10033%', potential: '0%' },
            { id: 'Fund 9', sprice: '$ 60.000', cstock: '$6.300.000', fmv: '$6.240.000', ytd: '10400%', potential: '0%' },
            { id: 'Fund 6', sprice: '$ 456.000', cstock: '$4.500.000', fmv: '$4.044.000', ytd: '887%', potential: '0%' },
            { id: 'Fund 5', sprice: '$ 4.200.000', cstock: '$3.500.000', fmv: '$-700.000', ytd: '-17%', potential: '20%' },
            { id: 'Fund 7', sprice: '$ 6.400.000', cstock: '$2.400.000', fmv: '$-4.000.000', ytd: '-63%', potential: '167%' },
            { id: 'Fund 8', sprice: '$ 7.650.000', cstock: '$1.445.000', fmv: '$-6.205.000', ytd: '-81%', potential: '429%' },
            { id: 'Fund 4', sprice: '$ 3.250.000', cstock: '$975.000', fmv: '$-2.275.000', ytd: '-70%', potential: '233%' },
            { id: 'Fund 3', sprice: '$ 2.400.000', cstock: '$360.000', fmv: '$2.040.000', ytd: '-85%', potential: '567%' },
            { id: 'Fund 2', sprice: '$ 1.650.000', cstock: '$275.000', fmv: '$1.375.000', ytd: '-83%', potential: '500%' },
            { id: 'Fund 1', sprice: '$ 215000', cstock: '$200.000', fmv: '$-15.000', ytd: '-7%', potential: '8%' },
          ];
          const checkTest = ()=>{
            alert("test");
          }
    return (<>
    <CommonTable 
        columns={columns}
        rows={rows}
        height={650}
        />
    </>)
}

export default Tsp;