import React, { useEffect, useState } from "react";

const getData = () => {
    var showdate = new Date();
    var _displaytime = showdate.getHours().toString().padStart(2,'0')+':'+showdate.getMinutes().toString().padStart(2,'0')+':'+showdate.getSeconds().toString().padStart(2,'0');
    return _displaytime;
}

const Time = (props) =>
{
    const[displaytime, setDisplatTime] = useState(getData());

    useEffect(() => {
        const timer = setTimeout(() => {
            setDisplatTime(getData());
        }, 1000);
        return () => clearTimeout(timer);
    }, [displaytime]);

    
    return(
        <div>
            {displaytime}
        </div>
    );

}
 
export default Time;