import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Skyflow from "../skyflow";
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import RechargeList from "./subcomponent/payment/RechargeList";
import Spinner from "./subcomponent/spinner/Spinner";
import CheckoutForm from "./subcomponent/stripe/stripeCheckoutForm";

const STRIPE_P_KEY = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(STRIPE_P_KEY);

class SkyflowTest extends Component {

    constructor(props) {
		super(props)
		this.state = {
			userDetail: JSON.parse(localStorage.getItem('userDetail')),
			stripeForm: {},
			errors: {},
			stripeOptions: {
				clientSecret: '',
				appearance: {
					theme: 'stripe'
				}
			}
		}
    }

    initPayment(e) {
		e.preventDefault();
		if (this.handleValidation(e)) {
			// console.log('form',this.state.stripeForm)
			this.props.InitiatePayment({ ...this.state.stripeForm, currency: 'usd' })
		} else {
			console.log('invalid')
		}
	}

    render() {
        return(
            <>
                {/* Navigation Bar Imported from Layouts */}
				<Navbar />

                {/* Header Starts */}
                <div id="header-section" className="header-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 col-3">
                                <div className="menu-bar">
                                    <i className="fa fa-bars" />
                                </div>
                            </div>
                            <div className="col-sm-4 col-4 text-center">
                                <Link to={'/'} className="logo">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/averti2.png'} alt="logo" />
                                </Link>
                            </div>
                            <div className="col-sm-4 col-5 text-right">
                                <ul className="header-right">
                                    <li>
                                        <Link to={'/'}>
                                            <i className="fa fa-envelope" />
                                            <span className="badge">9</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/notification'}>
                                            <i className="fa fa-bell animated infinite swing" />
                                            <span className="badge">6</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link id="header-user" className="header-user" to={'/user-setting'}>
                                            <img src={this.state.userDetail?.profile_image ? this.state.userDetail?.profile_image : process.env.PUBLIC_URL + '/assets/img/user.png'} alt="img" width={25} height={25} />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Header Ends */}

                <div className="add-balance-inner-wrap">
					<div className="container">
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Add Balance</h5>
								</div>
								<div className="modal-body">
									<div className="action-sheet-content">

										<div className="form-group basic">
											<label className="label">Enter Amount</label>
											<div className="input-group mb-3">
												<div className="input-group-prepend">
													<span className="input-group-text" id="input1">$</span>
												</div>
												<input
													type="number"
													onChange={(e) => this.handleInput(e, 'amount')}
													className="form-control form-control-lg"
													placeholder="Enter your amount"
												/>
											</div>
											{this.state.errors.amount !== undefined ? <p className="text-center text-danger">{this.state.errors.amount}</p> : null}
										</div>

										<div className="form-group basic">

											{this.state.stripeOptions.clientSecret !== undefined && this.state.stripeOptions.clientSecret.length > 0 ?
												<Elements options={this.state.stripeOptions} stripe={stripePromise}>
													<CheckoutForm />
												</Elements>
												:
												<button type="button" className="btn-c btn-primary btn-block btn-lg" onClick={this.initPayment.bind(this)} >Initiate Payment {this.props.loader ? <Spinner size="mini" /> : null}</button>
											}
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

                <div className="balance-area pd-top-40 mg-top-35">
					<div className="container">
						<div className="balance-area-bg balance-area-bg-home">
							<div className="balance-title text-center">
								<h6>Welcome! <br />Mr {this.state.userDetail?.name}</h6>
							</div>
							<div id="balance-section" className="ba-balance-inner text-center" style={{ backgroundImage: "url(assets/img/bg/2.png)" }}>
								<div className="icon">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/1.png'} alt="img" />
								</div>
								<h5 className="title">Total Balance</h5>
								<h5 className="amount">${this.state.userDetail?.balance}</h5>
							</div>
						</div>
					</div>
				</div>
                <Skyflow />

                {/* add balance start */}
				<div className="add-balance-area pd-top-40">
					<div className="container">
						<div className="ba-add-balance-title ba-add-balance-btn">
							<h5>Add Balance</h5>
							<i className="fa fa-plus" />
						</div>

						{/* add balance End */}


						{/* Payment History */}
						<RechargeList />
						{/* Payment History */}
					</div>
				</div>

                <Footer />
            </>       
        )
    }
}

// const mapStateToProps = state => {
// 	console.log(state.payment)
// 	return {
// 		client_secret: state.payment.clientSecret,
// 		loader: state.payment.loader
// 	}
// }

export default (SkyflowTest)