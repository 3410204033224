import React, { useEffect, useState } from 'react';
import Footer from './layouts/Footer';
import Section from './layouts/Section';
import './Gallery.css';
import Masonry from "react-responsive-masonry"
import { connect } from 'react-redux';
import { galleryAction } from '../actions';
import { Spinner } from 'react-bootstrap';

function Gallery(props) {

    const {gallery, user, loading, galleryAction} = props;

    useEffect(() => {
        if(user.id !== ''){
            galleryAction(user.oid)
        }

        return () => {}

    }, [user, galleryAction]) 


    const images = gallery.map(g => `${process.env.REACT_APP_MAIN_URL}${g.upload.filepath}`);

    const [data, setData] = useState({ img: '', i: 0 });

    const viewImage = (img, i) => {
        setData({ img, i });
    }

    const imgAction = (action) => {
        let i = data.i;
        if (action === 'next-img') {
            setData({ img: images[i + 1], i: i + 1 })
        }
        if (action === 'prev-img') {
            setData({ img: images[i - 1], i: i - 1 })
        }
        if (!action) {
            setData({ img: '', i: 0 });
        }
    }

    return (
        <>
            <Section allNotification={false} searchPopup={false} title={'Gallery'} />

            {
                data.img &&
                <div style={{
                    width: "100%",
                    height: "100vh",
                    background: "#000000e8",
                    position: "fixed",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    zIndex: "999"
                }}>
                    <button className='button_mason' onClick={() => imgAction()} style={{ position: "absolute", top: "10px", right: "10px",width: "40px"}}><i className="fa fa-times" aria-hidden="true"></i></button>
                    <button className='button_mason mr-auto' onClick={() => imgAction('prev-img')} style={{ width: "40px" }}><span>&larr;</span></button>
                    <img src={data.img} style={{ width: "auto", maxWidth: "80%", maxHeight: "80%" }} alt='' />
                    <button className='button_mason ml-auto' onClick={() => imgAction('next-img')} style={{ width: "40px" }}> <span>&rarr;</span></button>
                </div>
            }
            <div className='gallery'>
                <div style={{ padding: "20px" }} className='container gallerycontainer'>
                        <Masonry gutter='20px' className='masonary_img'>
                            {gallery.map((image, i) => (
                                <img
                                    key={i}
                                    src={`${process.env.REACT_APP_MAIN_URL}${image.upload.filepath}`}
                                    style={{ width: "100%", display: "block", cursor: "pointer" }}
                                    alt=""
                                    onClick={() => viewImage(`${process.env.REACT_APP_MAIN_URL}${image.upload.filepath}`, i)}
                                    className='gallery-image'
                                    loading='lazy'
                                />
                            ))}
                        </Masonry>
                </div>
            </div>
            <div className='d-flex justify-content-center'>{loading?<Spinner />:''}</div>
            {/* Footer section */}
            <Footer />
        </>
    )
}

const mapStateToProps = (state) => ({
    gallery: state.user.gallery,
    user: state.user.userDetail,
    loading: state.user.loader
})

const actionDispatcher = {
    galleryAction
}

export default connect(mapStateToProps, actionDispatcher) (Gallery);
