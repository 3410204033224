import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function OtherExpenses(props) {

  const { myData } = props;
  let parsedData;
  try {
    parsedData = JSON.parse(myData.form_data);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }

  const allValues =
    Number(parsedData.OtherExpenses.homeRepair) +
    Number(parsedData.OtherExpenses.skinCare) +
    Number(parsedData.OtherExpenses.catCare);


  props.sendDataOt(allValues);

  return (
    <div className="dhhejk">
      <div className="daildy_living">OTHER EXPENSES</div>
      <Form>
        <div className="living_state">
          <Form.Label className="adksk">Home Repair</Form.Label>
          <input
            type="text"
            className=" input_style jadj"
            name="homeRepair"
            value={parsedData.OtherExpenses.homeRepair}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css">Skin Care</Form.Label>
          <input
            className="input_style jkha"
            type="text"
            name="skinCare"
            value={parsedData.OtherExpenses.skinCare}
          />
        </div>
        <div className="living_state">
          <Form.Label className="adksk">Cat Care</Form.Label>
          <input
            className="input_style jadj"
            type="text"
            name="catCare"
            value={parsedData.OtherExpenses.catCare}
          />
        </div>
        <div className="living_state">
          <Form.Label className="lable_css_">Total Amount</Form.Label>
          <input
            className="input_style_ jadj jkdju"
            type="text"
            name="total_value"
            value={allValues}
          />
        </div>
      </Form>
    </div>
  );
}

export default OtherExpenses;
