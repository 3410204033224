import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CalendatTab.css";
import Spinner from 'react-bootstrap/Spinner';
import { ButtonGroup } from "react-bootstrap";
import Trialcam from "./Trialcam";


const CustomTab = ({ label, active, onClick }) => (
  <div className={`tabical ${active ? "active" : ""}`} onClick={onClick}>
    {label}
  </div>
);

const localizer = momentLocalizer(moment);

const CalanderTab = (props) => {
  const { calanderdata,setSelfieData } = props;
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);
  const [hasimages, setHasImages] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setImageUrl(null);
    setIsClicked(false);
    setOpencam(false);
  };
  const handleShow = () => {
    setImageUrl(null);
    setShow(true)
    setIsClicked(false);

  };

  const [date, setDate] = useState(false);
  const handleClosed = () => setDate(false);

  //////////////////////////////////////////////
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [opencam, setOpencam] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [imagedata, setImagedata] = useState([]);
  const [capturedImages, setCapturedImages] = useState([]);
  const [imageslocal,setImagesLocal]=useState([]);
  
  const handleClickCam = () => {
    setOpencam(true);
  };
  const handleCapture = (imageSrc) => {
    setImageUrl(imageSrc); // Update the imageUrl state with the captured image URL
  };
  const handleDivClick = () => {
    setIsClicked(true);
  };

  const handleImageUpload = () => {
    // Open the file picker dialog
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  ////////////////////////////////////////////////

  // Function to handle item selection
  const handleItemClick = (item) => {
    if (selectedItems.includes(item)) {
      // Item is already selected, deselect it
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      // Item is not selected, select it
      setSelectedItems([...selectedItems, item]);
    }
  };
  useEffect(() => {
    // Check if session storage is available
    if (typeof window !== "undefined" && window.sessionStorage) {
      const eventsFromStorage = JSON.parse(sessionStorage.getItem("events"));
      if (eventsFromStorage) {
        // If there are events in storage, set them in the state
        setEvents(eventsFromStorage);
      }
    }
  }, []);

  useEffect(() => {
    // Save events to session storage whenever events state changes
    //hide this to remove ses storage
    if (typeof window !== "undefined" && window.sessionStorage) {
      sessionStorage.setItem("events", JSON.stringify(events));
    }
  }, [events, ]);
  const handleClearEvents = () => {
    // Clear events from session storage
    if (typeof window !== "undefined" && window.sessionStorage) {
      sessionStorage.removeItem("events");
      // Clear events from state
      setEvents([]);
    }
  };

  // Function to handle adding outfits to the selected date
  const handleAddOutfitsToDate = () => {
    if (selectedDate && selectedItems.length > 0) {
      const existingEvent = events.find((event) =>
        moment(event.start).isSame(selectedDate, "day")
      );
      if (existingEvent) {
        // Update existing event with new outfits
        existingEvent.outfits.push(selectedItems);
        setEvents([...events]);
      } else {
        // Create a new event
        const newEvent = {
          title: "Outfits",
          start: selectedDate,
          end: selectedDate,
          allDay: true,
          outfits: [selectedItems],
        };
        setEvents([...events, newEvent]);
      }
      handleCloseModal();
    }
  };
  console.log("eventiy", events);
  const handleSelectSlot = ({ start }) => {
    const hasImagesForDate = events.some(
      (event) =>
        moment(event.start).isSame(start, "day") &&
        event.outfits.some((outfit) => outfit.length > 0)
    );
    if (!hasImagesForDate) {
      setSelectedDate(start);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setSelectedDate(null);
    setShowModal(false);
    setSelectedItems([]); // Reset selected items when modal is closed
  };

  const Toolbar = ({ label }) => (
    <div className="toolbar-container">
      <div className="center-label" style={{ textAlign: "center" }}>
        {/* Always display the month in the center */}
        {label}
      </div>
    </div>
  );

  const CustomDay = ({ children, date }) => {
    // Check if there are images for the selected date
    const hasImagesForDate = events.some(
      (event) =>
        moment(event.start).isSame(date, "day") &&
        event.outfits.some((outfit) => outfit.length > 0)
    );

    // Handle click event only if the cell contains images
    const handleClick = () => {
      if (hasImagesForDate) {
        setSelectedDate(date);
        setShowModal(true);
      }
    };
    
   
   
    return (
      <div
        className={`date-cellofmybox ${
          hasImagesForDate ? "has-hdhdhimages" : ""
        }`}
        onClick={handleClick}
      >
        {children}
      </div>
    );
  };

  const dayPropGetter = (date) => {
    return {
      className: "date-cellofmybox",
    };
  };

  const eventImage =
    events.length > 0
      ? {
          outfits: events[0]?.outfits ?? [],
          start: events[0]?.start ? new Date(events[0]?.start) : null,
        }
      : {
          outfits: [],
          start: null,
        };

  console.log("eventImage", eventImage);
  const handleShowed = (image) => {
    setSelectedImage(image);
    setSelectedDate(image.start);
    setDate(true);
  };

  const CustomEvent = ({ event, date, setHasImages }) => {
    useEffect(() => {
      const hasImages = event.outfits.some((outfit) => outfit.length > 0);
      if (hasImages) {
        console.log(`evencustom`, event);
        setHasImages(true);
      }
    }, [event, date, setHasImages]);

    return (
      <div style={{ width: "39px", maxHeight: "60px" }}>
        {event.outfits.map((outfit, index) => (
          <div key={index} onClick={() => handleShowed(event)}>
            {outfit.flat().map((image, idx) => (
              <img key={idx} src={image} alt={`Outfit ${index + 1}`} />
            ))}
          </div>
        ))}
      </div>
    );
  };

  console.log("imagedata", imagedata);
  const handleClickSaved = () => {
    // Add the temporary image URL to the imagedata state
    if (imagedata) {
      setCapturedImages((prevImagedata) => [...prevImagedata, imageUrl]);
    }
    if(setImagesLocal){
      setImagesLocal((prevImagedata) => [...prevImagedata, imageUrl])
    }
    if (imageUrl && selectedDate) {
      setSelfieData(prevSelfieData => [
        ...prevSelfieData,
        { date: selectedDate, imageUrl: imageUrl }
      ]);
    }
    setShow(false);
  };
  

  return (
    <>
      {/* {hasimages && (
        <div>
          There are images on selected dates:
          {events.map((event, index) => (
            <div key={index}>{moment(event.start).format('MMMM DD, YYYY')}</div>
          ))}
        </div>
      )} */}

      <div style={{ marginBottom: "3rem" }}>
        <style>{`
       .rbc-event, .rbc-day-slot .rbc-background-event{
        background-color: transparent !important;
       }
       `}</style>
        <div>
      {/* <Button variant="danger" onClick={handleClearEvents}>
        Clear Events
      </Button> */}
      </div>
      <div className="calendar-wrapper">

        <Calendar

          localizer={localizer}
          events={events}
          selectable
          onSelectSlot={handleSelectSlot}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, width: "100%", color:"black"}}
          components={{
            dayWrapper: CustomDay,
            toolbar: Toolbar,
            event: (props) => (
              <CustomEvent {...props} setHasImages={setHasImages} />
            ),
          }}
          dayPropGetter={dayPropGetter}
        />
      </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered id="mnbvcx">
        <Modal.Body
          className="bodystylesa"
          closeButton
          selectedDate={selectedDate}
        >
          <Modal.Header style={{ margin: "0", padding: "0" }} closeButton>
            <div style={{ display: 'flex',justifyContent:"space-between", width: '100%', alignItems: 'center' }}>
                  <div style={{fontSize:"7px"}}>
                    {selectedDate ? moment(selectedDate).format('MMMM DD, YYYY') : 'Modal heading'}
                  </div>
                  <h3 style={{ margin: 0 , textAlign:"center"}}>LINK AN OUTFIT</h3>
                  <p></p>
            </div>
          </Modal.Header>
          <div className="row" style={{ justifyContent: "start" }}>
            {calanderdata.map((items, index) => {
              return (
                <div
                  key={index}
                  className={`box-item ${
                    selectedItems.includes(items)
                      ? "col-4 columnthreee d-flex border seleimgbox007"
                      : "col-4 columnthreee d-flex border"
                  }`}
                  onClick={() => handleItemClick(items)}
                >
                  {items.map((subItems, sIndex) => {
                    return (
                      <img
                        className="nullimageclasse"
                        key={sIndex}
                        src={subItems}
                        alt={`item-${sIndex}`}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <div>
            <div className="footer_bnt_coll" onClick={handleAddOutfitsToDate}>
              ADD OUTFITS TO DATE <i style={{color:"white"}} class="bi bi-arrow-right"></i>
            </div>
          </div>
        </Modal.Footer> 
      </Modal>

      {/* calander date model */}
      <Modal centered show={date} onHide={handleClosed} id="mnbvcxxc">
        <Modal.Body className="bodystylesad" selectedDate={selectedDate}>
        <Modal.Header
            style={{ margin: "0", padding: "0", alignItems: "center", justifyContent:"space-between" }}
          >
            <i  onClick={handleClosed}  style={{ marginBottom: "8px" }} class="bi bi-arrow-left"></i>
            <h6 className="">
              {selectedDate
                ? moment(selectedDate).format("MMMM DD, YYYY")
                : " "}
            </h6>
            <div></div>
          </Modal.Header>

          {/* Custom tab and content */}
          <div className="tab_containerTab">
            <CustomTab
              label="OUTFIT"
              active={activeTab === 0}
              onClick={() => handleTabClick(0)}
            />
            <CustomTab
              label="SELFIE"
              active={activeTab === 1}
              onClick={() => handleTabClick(1)}
            />
          </div>

          <div className="tab-contentCalTab">
            {activeTab === 0 ? (
              <div>
                <div
                  className="row"
                  style={{ justifyContent: "start", minHeight: "410px" }}
                >
                  {selectedImage &&
                    selectedImage.outfits &&
                    selectedImage.outfits.flat().map((items, index) => {
                      return (
                        <div
                          key={index}
                          className={`col-4 columnthreee d-flex border box-item`}
                        >
                          {items.map((subItems, sIndex) => {
                            return (
                              <img
                                className="nullimageclasse"
                                key={sIndex}
                                src={subItems}
                                alt={`item-${sIndex}`}
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
                <div className="iconad">
                  <div style={{marginTop:"12px"}}>
                <i style={{ textAlign: "center", marginTop: "10px",cursor:"pointer" }} onClick={() => setShowModal(true)} class="bi bi-plus-lg"></i>
                </div>
                </div>
              </div>
            ) : (
                <div>
                  <div style={{ minHeight: "410px" , maxHeight:"410px", overflowX:"hidden",overflowY:"auto",scrollbarWidth:"none"}}>
                    {(capturedImages.concat(imageslocal)).length > 0 ? (
                      <div className="row" style={{ display: "flex", justifyContent: "start" }}>
                        {[...new Set(capturedImages.concat(imageslocal))].map((imageSrc, index) => (
                          <div key={index} className="col-4 columnthreee d-flex border box-item">
                            <img
                              className="imgcls12"
                              src={imageSrc}
                              alt={`item-${index}`}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <h2>Hello</h2>
                        <h4>No Image Data.</h4>
                      </>
                    )}
                  </div>


                <div className="iconad">
                <div style={{marginTop:"12px"}}>
                <i  onClick = {handleShow}  style={{ textAlign: "center", marginTop: "10px", cursor:"pointer"}} class="bi bi-plus-lg"></i>
                </div>
                </div>

                <Modal show={show} onHide={handleClose} centered id="mnbvcxLOP">
                  <Modal.Body style={{ overflow: "hidden" }}>
                    <Modal.Header
                      style={{
                        margin: "0",
                        padding: "0",
                        alignItems: "center",
                      }}
                    >
                        <i  onClick={handleClose} style={{ marginBottom: "8px" }} class="bi bi-arrow-left"></i>
                      <h6 className="calh66">TAKE A SELFIE</h6>
                    </Modal.Header>
                    <div className="cvbnCAL">
                      We will automatically remove the background for a cleaner
                      look.
                    </div>

                    <div>
                      {imageUrl ? (
                        <div className="whitieboixxcTAB">
                          {loading ? (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 1,
                              }}
                            >
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </div>
                          ) : (
                            <>
                              <img
                                src={imageUrl}
                                alt="Uploaded"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            </>
                          )}
                        </div>
                      ) : opencam ? (
                        <div className="whitieboixxc">
                          <Trialcam
                            onCapture={handleCapture}
                            setImagedata={setImagedata}
                            imagedata={imagedata}
                          />
                        </div>
                      ) : (
                        <div
                          className="whitieboixxcTAB"
                          onClick={handleDivClick}
                        >
                          {isClicked ? (
                            <div className="bgroupmar">
                              <ButtonGroup
                                orientation="vertical"
                                aria-label="Vertical button group"
                                variant="contained"
                              >
                                <Button onClick={handleClickCam}>
                                  Photo from Camera
                                </Button>
                                <Button onClick={handleImageUpload}>
                                  Photo from Library
                                </Button>
                              </ButtonGroup>
                            </div>
                          ) : (
                            <div className="alboix">CHOOSE PHOTO</div>
                          )}
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer style={{ padding: "6px" }}>
                    <div className="footer_bnt_collieCAL" onClick={handleClickSaved}>
                      <div className="button_name_fnextCAL" >SAVE</div>
                      <i style={{color:"white"}} class="bi bi-arrow-right"></i>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            )}
          </div>
        </Modal.Body>
        {/* <Modal.Footer style={{justifyContent:"center"}}>
          <AddIcon onClick={() => setShowModal(true)} style={{textAlign:"center"}}/>
        </Modal.Footer> */}
      </Modal>
     
    </>
  );
};

export default CalanderTab;
