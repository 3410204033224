import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";


function OtherObligation(props) {

    const { myData } = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const allValues =
        Number(parsedData.otherobligation.alimony) +
        Number(parsedData.otherobligation.child_support) +
        Number(parsedData.otherobligation.legal_fees);

    props.sendDataOb(allValues);

    return (
        <div className="dhhejk">
            <div className="daildy_living">OTHER OBLIGATION</div>
            <Form>
                <div className="living_state">
                    <Form.Label className=" lable_css ">Alimony</Form.Label>
                    <input
                        type="text"
                        className=" input_style jkha "
                        name="alimony"
                        value={parsedData.otherobligation.alimony}

                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Child Support</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="child_support"
                        value={parsedData.otherobligation.child_support}

                    />
                </div>
                <div className="living_state">
                    <Form.Label className=" lable_css ">Legal Fees</Form.Label>
                    <input
                        type="text"
                        className=" input_style jkha "
                        name="legal_fees"
                        value={parsedData.otherobligation.legal_fees}

                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css_">Total Amount</Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    );
}

export default OtherObligation;
