import auth from "../api/auth"
import { CHATS_ERROR, FETCH_CHATS, LOADER } from "../store/redux-constant"

const headers = {
    'Accept': 'application/json',
	'Content-Type': 'application/json'
}

export const getChats = () => {
    return async (dispatch)=>{
		try {
			headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
			dispatch({ type: LOADER, payload: {} })
			const response = await auth.get('/messages/all',{headers})
			if(response.status === 200){
				dispatch({type:FETCH_CHATS,payload:response.data.data})
			}else{
				dispatch({type:CHATS_ERROR,payload:{}})
			}
		} catch (error) {
			dispatch({type:CHATS_ERROR,payload:{}})
			console.log(error)
		}
	}
}