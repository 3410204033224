import React, { Component } from "react";
import { Link } from "react-router-dom";

class Error403 extends Component{
    render(){
        return(
        <React.Fragment>
                <div className="header-area" style={{ backgroundImage: 'url(assets/img/bg/1.png)' }}>
					<div className="container">
						<div className="row">
							<div className="col-sm-4 col-3"></div>
							<div className="col-sm-4 col-6 text-center">
								<div className="page-name">403</div>
							</div>
							<div className="col-sm-4 col-3 text-right"></div>
						</div>
					</div>
				</div>
                <div className="blog-area pd-top-36 pb-2 mg-top-40">
                    <div className="container">
                        <div className="section-title">
                            <h3 className="title">Access denied!</h3>
                            <Link to={'/'}>Go Back</Link>
                        </div>
                        <div className="row">
                                <div className="single-blog">
                                    <div className="thumb">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/other/403.png'} alt="img" />
                                    </div>
                                    <div className="details">
                                        <p>The page you are looking for is not authorised to see you. You need to <Link to={'/'}>login </Link>access this page.</p>
                                    </div>
                                </div>
                            
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Error403;