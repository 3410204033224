import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Logout } from '../../actions';
import '../prashant.css';
import '../../components/main.css';
import History from "../../History";
import { getNotification } from "../../actions/notification";

class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userDetail: {}
        }
        this.logout = this.logout.bind(this)
    }

    componentDidMount() {
        this.setState({
            userDetail: this.state.userDetail
        })

        this.props.getNotification();
    }

    logout() {
        this.props.Logout()
    }

    static getDerivedStateFromProps(props, state) {

        if (props) {
            return { ...state, userDetail: props.userDetail };
        }

        return state;
    }

    componentDidUpdate() {
        if (this.props.userDetail === null) {
            History.push('/')
        }
    }

    render() {
        return (
            <div className="ba-navbar" id="ba-navbar">
                <div className="ba-navbar-user">
                    <div className="menu-close">
                        <i className="la la-times" />
                    </div>
                    <div className="thumb">
                        <img src={this.state.userDetail?.profile_image ? this.state.userDetail?.profile_image : process.env.PUBLIC_URL + '/assets/img/user.png'} alt="user" width={50} height={50} />
                    </div>
                    <div className="details">
                        <h5>{this.state.userDetail?.first_name + ' ' + this.state.userDetail?.last_name}</h5>
                        <p>{this.state.userDetail?.mobile}</p>
                    </div>
                </div>
                <div className="ba-main-menu">
                    <h5>Menu</h5>
                    <ul>
                        <li className="nav_a"><Link to={'/report'}>Report</Link></li>
                        <li className="nav_a"><Link to={'/upload-documents'}>Upload Documents</Link></li>
                        <li className="nav_a"><Link to={'/gcalendar'}>Google Calendar</Link></li>
                        <li className="nav_a"><Link to={'/user-setting'}>Settings</Link></li>
                        <li className="nav_a"><Link onClick={this.logout} to={'/'}>Logout</Link></li>
                    </ul>
                    <Link className="btn btn-ivory" to={'/user-setting'}>View Profile</Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { userDetail: state.user.userDetail };
}


export default connect(mapStateToProps, { Logout, getNotification })(Navbar);
