import React from 'react';
import Section from '../layouts/Section';

function fiananceBlog() {
  return (
    <Section allNotification={false} searchPopup={true} title={'Finance Blog'}>
      <div className="blog-details-area pd-top-40 mg-bottom-40">
        <div className="container">
          <div className="blog-details-inner">
            <div className="section-title">
              {/* <h3 className="title">Blog Details</h3> */}
            </div>
            <div className="thumb">
              <img src={process.env.PUBLIC_URL + '/assets/img/blog/7.png'} alt="img" />
            </div>
            <div className="details">
              <h6>Why Should I Consider Investing? - Averti Systems</h6><br></br>
              <p>Smart investing may allow your money to outpace inflation and increase in value. The greater growth potential of investing is primarily due to the power of compounding and the risk-return tradeoff.</p>
              <p>Compounding occurs when an investment generates earnings or dividends which are then reinvested. These earnings or dividends then generate their own earnings. So, in other words, compounding is when your investments generate earnings from previous earnings.</p>
              <p>The amount of risk you carry depends on your appetite — or tolerance — for risk. Only you can decide how much risk you’re willing to take for the potential of higher returns. But if you’re seeking to outpace inflation, taking on some risk may be necessary. An increase in risk may provide more potential for your money to grow.</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default fiananceBlog;

