import React, { useState } from "react";
import { Form } from "react-bootstrap";

function RiskManagemnet(props) {

    const { myData } = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const allValues =
        Number(parsedData.riskManagement.life_insurance) +
        Number(parsedData.riskManagement.disability_insurance) +
        Number(parsedData.riskManagement.long_term_care) +
        Number(parsedData.riskManagement.chronic_illness) +
        Number(parsedData.riskManagement.liabiliy_policy);

    props.sendDatarm(allValues);

    return (
        <div className="dhhejk">
            <div className="daildy_living">RISK MANAGEMENT</div>
            <Form>
                <div className="living_state">
                    <Form.Label className="adksk">Life Insurance</Form.Label>
                    <input
                        type="text"
                        className=" input_style jadj"
                        name="life_insurance"
                        value={parsedData.riskManagement.life_insurance}

                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Disability Insurance</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="disability_insurance"
                        value={parsedData.riskManagement.disability_insurance}

                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Long Term Care</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="long_term_care"
                        value={parsedData.riskManagement.long_term_care}

                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Chronic Illness</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="chronic_illness"
                        value={parsedData.riskManagement.chronic_illness}

                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Umbrella/Liabiliy Policy</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="liabiliy_policy"
                        value={parsedData.riskManagement.liabiliy_policy}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css_">Total Amount</Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    );
}

export default RiskManagemnet;
