import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { CardHolderNameElement, CardNumberElement, CVVElement, ExpirationMonthElement, ExpirationYearElement, InputFieldElement, useCollectContainer, useMakeSkyflowStyles } from 'skyflow-react-js'
import Spinner from './components/subcomponent/spinner/Spinner';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const AUTH_TOKEN = window.localStorage.getItem('AUTH_TOKEN');
const SKYFLOW_TOKEN = process.env.REACT_APP_SKYFLOW_KEY;
const headers = {
	'Content-Type': 'application/json',
	'Authorization': `Bearer ${AUTH_TOKEN}`,
    'X-Skyflow-Authorization':`${SKYFLOW_TOKEN}`
}

function Skyflow() {

    const [isWaiting, setIsWaiting] = useState(false)
    const container = useCollectContainer()

    const handleCollect = () => {
        setIsWaiting(true)
      const response = container.collect();
      response.then((res) => {
        let amount = JSON.parse(localStorage.getItem('amount'))
        let cvv = JSON.parse(localStorage.getItem('cvv'))
        // console.log('Amount',JSON.parse(amount))
        // console.log(res.records);
        // console.log(res.records[0].fields.card_number,res.records[0].fields.cardholder_name,res.records[0].fields.expiry_year,res.records[0].fields.expiry_month)
        let param = {
            card_number:res.records[0].fields.card_number,
            card_exp_month:res.records[0].fields.expiry_month,
            card_exp_year:res.records[0].fields.expiry_year,
            amount:amount.value,
            card_cvc:cvv.value,
            skyflowid:res.records[0].fields.skyflow_id,
            card_holder:res.records[0].fields.cardholder_name
        }
        // console.log(param)
        submitPaymentDetail(param)
      }).catch((e) => { 
        setIsWaiting(false)
        console.log(e) 
      })
    }

    const handleAmount = (changeState) => {
        // console.log("Change", changeState)
        localStorage.setItem('amount',JSON.stringify(changeState))
        // setAmount(changeState)
    }
    const handleCvv = (changeState) => {
        // console.log("Change", changeState)
        localStorage.setItem('cvv',JSON.stringify(changeState))
        // setAmount(changeState)
    }

    /* const handleOnBlur = (changeState) => {
        console.log("Blur", changeState)
    } */

    const useStyles = useMakeSkyflowStyles({
        inputStyles: {
            base: {
                border: '1px solid black',
                borderRadius: '4px',
                color: '#1d1d1d',
                padding: '10px 16px',

            },
            complete: {
                color: '#4caf50',
            },
            empty: {},
            focus: {},
            invalid: {
                color: '#f44336',
            },
        },
        labelStyles: {
            base: {
                fontSize: '16px',
                fontWeight: 'bold',
            },
        },
        errorTextStyles: {
            base: {
                color: 'blue',
            },
        },
    })

    const options = {
        enableCopy: false,
    };

    const classes = useStyles()

    const submitPaymentDetail = async (data) =>{
        const response = await axios.post(BASE_URL+'/sky/create/intent', data, {headers})
        localStorage.removeItem('amount')
        localStorage.removeItem('cvv')
        setIsWaiting(false);
        console.log(response)
    }

    return (
        <React.Fragment>
            <InputFieldElement
                container={container}
                table={'credit_cards'}
                classes={classes}
                column={'amount'}
                label={'Amount'}
                options={options}
                onChange={(e)=>handleAmount(e)}
            />
            
            <CardNumberElement
                container={container}
                table={'credit_cards'}
                classes={classes}
                column={'card_number'}
                label={'Collect Card Number'}
                options={options}
            />

            <ExpirationMonthElement
                container={container}
                table={'credit_cards'}
                classes={classes}
                column={'expiry_month'}
                label={'Card Expiry Month'}
                options={options}
            />

            <ExpirationYearElement
                container={container}
                classes={classes}
                table='credit_cards'
                column={'expiry_year'}
                label='Card Expiry Year'
                placeholder='YY'
            />

            <CVVElement
                container={container}
                classes={classes}
                table='credit_cards'
                column={'card_pin'}
                label='CVV'
                placeholder='cvv'
                onChange={(e)=>handleCvv(e)}
            />

            <CardHolderNameElement
                container={container}
                table='credit_cards'
                classes={classes}
                column={'cardholder_name'}
                label='Card Name'
            />

            <button style={{backgroundColor:'blue',padding:'10px',color:'white'}} onClick={handleCollect}>Collect {isWaiting?<Spinner size="mini" />:''}</button>

        </React.Fragment>
    )
}

export default Skyflow