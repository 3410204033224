import React, { memo } from "react";
import { Loader } from 'semantic-ui-react'

// Documentation: https://react.semantic-ui.com/elements/loader/#variations-inline
 
const Spinner = memo((props) => {
  return (
    <Loader active inline size={props.size} />
  );
})

export default Spinner;