import React, { useEffect, useState } from "react";
import Section from "./layouts/Section";
import "./prashant.css";
import AvertiLoader from "../loader";
import './prashant.css';
import { Modal } from "bootstrap";
import auth
    from "../api/auth";
import moment from "moment";


function EstateReport() {

    const [loader] = AvertiLoader();
    const handleToggleModal = () => {
        setIsOpen(!isOpen);
    };

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const d = new Date();
    const currentMonth = (new Date().getMonth() + 1).toString();
    const [selectedValues, setSelectedValues] = useState(currentMonth.toString());
    const [isOpen, setIsOpen] = useState(month[d.getMonth()]);
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const endYear = currentYear;
    const years = Array.from(
        { length: endYear - startYear + 1 },
        (_, index) => startYear + index,
    );

    const [selectedYear, setSelectedYear] = useState(currentYear.toString());

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value)
    }

    const [posts, setPosts] = useState([]);
    const [audioUrl, setAudioUrl] = useState("");
    const [videoUrl, setVideoUrl] = useState("");
    const [isModalOpen, setIsModalOpen] = useState("");
    const [cModalOpen, setCModalOpen] = useState(false);

    const getReports = async () => {
        const token = window.localStorage.getItem('AUTH_TOKEN');
        const response = await auth.get(`/reports/show/estate/${selectedYear}/${selectedValues}`, {
            headers: {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'Application/json',
                'Accept': 'Application/json',
            },
        });
        setPosts(response.data.data);
    }
    useEffect(() => {
        getReports();
    }, [selectedYear, selectedValues]);


    const openCModal = () => {
        setCModalOpen(true)
    }

    const closeCModal = () => {
        setCModalOpen(false)
    }

    const openPdfModal = (pdf) => {
        setIsModalOpen(pdf);
    };

    const closePdfModal = () => {
        setIsModalOpen(false);
    };

    const openVideoModal = (video) => {
        const videoArray = JSON.parse(video);
        setVideoUrl(videoArray);
        const videoModal = new window.bootstrap.Modal(document.getElementById('videoModal'));
        videoModal.show();
    };

    const openModal = (audio) => {
        const audioArray = JSON.parse(audio);
        setAudioUrl(audioArray);
        const modal = new Modal(document.getElementById('audioModal'));
        modal.show();
    };

    const closeModal = () => {
        const modal = new Modal(document.getElementById('audioModal'));
        modal.hide();
    };
    return (
        <>
            {loader}
            <Section allNotification={false} searchPopup={true} title={"Estate Report"} />
            <div className="cart-area pd-top-36 mg-bottom-40" id="_report_css">
                <div className="cart-area" id="_report_css">
                    <div className={`container ${isOpen ? 'modal-open' : 'modal-closed'}`}>
                        {isOpen ? (
                            <div className="buttons_cross">
                                <select
                                    id="yearSelector"
                                    className="year_btn"
                                    onChange={handleYearChange}
                                >
                                    <option value="">{selectedYear}</option>
                                    {years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                                </select>
                                <i className="bi bi-x _xicon" onClick={handleToggleModal}></i>
                            </div>
                        ) : (
                            <div className="buttons_dot">
                                <i className="bi bi-filter _dicon" onClick={handleToggleModal}></i>
                            </div>
                        )}
                    </div>
                    <div className="container">
                        <div className="col-12">
                            <div className="radiogroup_1">
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        id="a"
                                        checked={selectedValues === "1"}
                                        value="1"
                                    />
                                    <label className="label_m" htmlFor="a">
                                        <div className="indicator">
                                            <span className="text">JAN</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        id="b"
                                        checked={selectedValues === "2"}
                                        value="2"
                                    />
                                    <label className="label_m" htmlFor="b">
                                        <div className="indicator">
                                            <span className="text">FEB</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        id="c"
                                        checked={selectedValues === "3"}
                                        value="3"
                                    />
                                    <label className="label_m" htmlFor="c">
                                        <div className="indicator">
                                            <span className="text">MAR</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        checked={selectedValues === "4"}
                                        id="d"
                                        value="4"
                                    />
                                    <label className="label_m" htmlFor="d">
                                        <div className="indicator">
                                            <span className="text">APR</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        id="e"
                                        checked={selectedValues === "5"}
                                        value="5"
                                    />
                                    <label className="label_m" htmlFor="e">
                                        <div className="indicator">
                                            <span className="text">MAY</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        id="f"
                                        value="6"
                                        checked={selectedValues === "6"}
                                    />
                                    <label className="label_m" htmlFor="f">
                                        <div className="indicator">
                                            <span className="text">JUNE</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        id="g"
                                        checked={selectedValues === "7"}
                                        value="7"
                                    />
                                    <label className="label_m" htmlFor="g">
                                        <div className="indicator">
                                            <span className="text">JULY</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        checked={selectedValues === "8"}
                                        id="h"
                                        value="8"
                                    />
                                    <label className="label_m" htmlFor="h">
                                        <div className="indicator">
                                            <span className="text">AUG</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                        type="radio"
                                        name="app"
                                        checked={selectedValues === "9"}
                                        id="i"
                                        value="9"
                                    />
                                    <label className="label_m" htmlFor="i">
                                        <div className="indicator">
                                            <span className="text">SEPT</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        type="radio"
                                        name="app"
                                        id="j"
                                        checked={selectedValues === "10"}
                                        value="10"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                    />
                                    <label className="label_m" htmlFor="j">
                                        <div className="indicator">
                                            <span className="text">OCT</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        type="radio"
                                        name="app"
                                        id="k"
                                        checked={selectedValues === "11"}
                                        value="11"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                    />
                                    <label className="label_m" htmlFor="k">
                                        <div className="indicator">
                                            <span className="text">NOV</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="wrapper">
                                    <input
                                        className="state"
                                        type="radio"
                                        name="app"
                                        id="l"
                                        checked={selectedValues === "12"}
                                        value="12"
                                        onChange={(e) => setSelectedValues(e.target.value)}
                                    />
                                    <label className="label_m" htmlFor="l">
                                        <div className="indicator">
                                            <span className="text">DEC</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {posts.map((report, index) => {
                        return (
                            <div key={index} className="section-title mb-2 _reportbgsha">
                                <p id="p_cashflow" className="mb-2" style={{ paddingLeft: "10px" }}>{moment(report.created_at).format('YYYY-MM-DD')}</p>
                                <div className="d-flex p-3 bg-white justify-content-between align-items-center rounded-4">
                                    {report.comment && (
                                        <div className="_report_name">{report.comment.slice(0, 15)}</div>
                                    )}
                                    <div className="d-flex _icon_button">
                                        <button type="button" className="btn btn-sm" onClick={() => openPdfModal(report.pdf)}><i className="bi bi-eye-fill"></i></button>
                                        <button type="button" className="btn btn-sm" onClick={() => openVideoModal(report.video)}><i className="bi bi-camera-video-fill"></i></button>
                                        <button type="button" className="btn btn-sm" onClick={() => openModal(report.audio)}><i className="bi bi-mic-fill"></i></button>
                                        <button type="button" className="btn btn-sm" onClick={openCModal}><i className="bi bi-chat-left-dots-fill"></i></button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* Bootstrap Modal */}
            <div className="modal fade" id="audioModal" tabIndex="-1" aria-labelledby="audioModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="audioModalLabel">Audio Player</h5>
                            <button type="button" className="btn-close" onClick={closeModal} aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {Array.isArray(audioUrl) ? (
                                audioUrl.map((audio, index) => (
                                    <audio key={index} controls autoPlay style={{ width: "100%" }}>
                                        <source src={`${process.env.REACT_APP_MAIN_URL}${audio}`} type="audio/mp3" />
                                        Your browser does not support the video tag.
                                    </audio>
                                ))
                            ) : (
                                <p>No Audio Here</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Video modal */}

            <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header _videoclose">
                            <h5 className="modal-title" id="videoModalLabel">Video Player</h5>
                            <button
                                type="button"
                                className="btn-close report_clodebtn close report_close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            // onClick={() => setVideoUrl("")}
                            ></button>
                        </div>
                        <div className="modal-body">
                            {Array.isArray(videoUrl) ? (
                                videoUrl.map((video, index) => (
                                    <video key={index} controls autoPlay style={{ width: "100%" }}>
                                        <source src={`${process.env.REACT_APP_MAIN_URL}${video}`} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                ))
                            ) : (
                                <p>No Video Here</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Bootstrap Modal htmlFor PDF */}
            <div className={`modal fade ${isModalOpen ? "show" : ""}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? "block" : "none" }} >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">View PDF</h5>
                            <button
                                type="button"
                                className="close report_clodebtn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closePdfModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {posts.map((report, index) => {
                                return (
                                    <iframe
                                        key={index}
                                        title="PDF Viewer"
                                        src={`${process.env.REACT_APP_MAIN_URL}${report.pdf}`}
                                        width="100%"
                                        height="500px"
                                    ></iframe>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal-backdrop fade show"></div>
            )}

            {/* Bootstrap Modal htmlFor Comments */}
            <div
                className={`modal fade ${cModalOpen ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{ display: cModalOpen ? "block" : "none" }}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Comments</h5>
                            <button
                                type="button"
                                className="close report_clodebtn"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeCModal}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul id="report_ul">
                                {posts &&
                                    posts.map((list, index) => (
                                        <>
                                            <li key={index}>{list.comment}</li>
                                        </>
                                    ))}
                            </ul >
                        </div>
                    </div>
                </div>
            </div>
            {cModalOpen && (
                <div className="modal-backdrop fade show"></div>
            )}

        </>
    );
}

export default EstateReport;
