import React from "react";
import ChatContent from "../ChatContent/ChatContent";

export default function ChatBody(props) {

  return (
    <div className="main__chatbody ">
      <ChatContent {...props} />
    </div>  
  )
}