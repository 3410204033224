const doctordata = [ {
      "name":"Dr. Michelle Albert.",
      "profession":"General Surgeon",
      "time":"• Respond in 30 min",
      "image":"/assets/img/doctor/doctor1.jpg",
      "view":'/viewmore'
   },
   {
      "name":"Dr. Michelle Albert.",
      "profession":"Numerologist",
      "time":"• Respond in 30 min",
      "image":"/assets/img/doctor/doctor6.jpg",
      "view":'/viewmore'
   },
   {
      "name":"Dr. Smith alex.",
      "profession":"Dermatologist",
      "time":"• Respond in 30 min",
      "image":"/assets/img/doctor/doctor3.jpg",
      "view":'/viewmore'
   },
]


export default doctordata