import React from "react";

function Location() {
   
   
    return (
        <div className="Location">

        </div>
    );
}

export default Location;