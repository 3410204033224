import React, { Component } from "react";
import Avatar from "../ChatList/Avatar";
import moment from "moment";

// const fileSize = (size) => {
//   const kb = size / 1024
//   return Math.round(kb)
// }

export default class ChatItem extends Component {
  message(item) {
    const mime_type = item.mime_type.split("/");
    const type1 = mime_type[0];
    const type2 = mime_type[1];
    let message;

    if (item.mime_type === "text/html") {
      message = (
        <div
          className="plain"
          dangerouslySetInnerHTML={{ __html: item.message }}
        ></div>
      );
    }

    if (type1 === "image") {
      message = (
        <>
          <a className="preview-img" data-href={item.message} href={"/#"}>
            <img
              loading="lazy"
              src={item.message}
              width="220"
              height="220"
              alt={Date.now()}
            />
          </a>
        </>
      );
    }

    if (type1 === "audio") {
      message = (
        <>
          <audio
            controls
            src={item.message}
            width="185"
            style={{ width: "185px" }}
          />
        </>
      );
    }

    if (type1 === "video") {
      message = (
        <>
          <video controls src={item.message} width="220" />
        </>
      );
    }

    if (type1 === "application" || type2 === "csv" || type2 === "plain") {
      let icon = <img src={"/assets/icons/file.png"} width="40" alt="file" />;

      if (type2 === "pdf") {
        icon = <img src={"/assets/icons/pdf.png"} width="40" alt="pdf" />;
      }

      if (type2 === "csv" || type2 === "plain") {
        icon = <img src={"/assets/icons/csv.png"} width="40" alt="csv" />;
      }

      if (
        type2 ===
          "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        type2 === "msword"
      ) {
        icon = <img src={"/assets/icons/doc.png"} width="40" alt="doc" />;
      }

      if (
        type2 === "vnd.ms-excel" ||
        type2 === "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        icon = <img src={"/assets/icons/xls.png"} width="40" alt="xls" />;
      }

      if (
        type2 === "vnd.ms-powerpoint" ||
        type2 ===
          "vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        icon = <img src={"/assets/icons/ppt.png"} width="40" alt="ppt" />;
      }

      if (type2 === "zip" || type2 === "gzip" || type2 === "x-bzip") {
        icon = <img src={"/assets/icons/zip.png"} width="40" alt="zip" />;
      }

      message = (
        <>
          <a
            className="__attachment"
            href={item.message}
            download={item.upload.original_filename}
            rel="noopener noreferrer"
            target="_blank"
          >
            {icon} <span>{item.upload.original_filename}</span>
          </a>
        </>
      );
    }

    return message;
  }

  render() {
    const { msg, user } = this.props;

    return (
      <div
        style={{ animationDelay: `0.8s` }}
        className={`chat__item ${msg.sender.id === user.id ? "" : "other"}`}
      >
        <div className="chat__item__content">
          <div className="chat__msg">{this.message(msg)}</div>
          <div className="chat__meta">
            <span>{moment.utc(msg.created_at).fromNow()}</span>
            <span>&#10003;</span>
          </div>
        </div>
        <Avatar isOnline="active" image={msg.sender.avatar} />
      </div>
    );
  }
}
