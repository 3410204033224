import React, {Component} from "react";
import { Fragment } from "react";

class CallNotify extends Component {


    acceptCall = (callingData) => {
        const roomObj = callingData.room
        const type = callingData.type
        const roomData = roomObj.split('_')
        const room = btoa('webcall_' + roomData[1])        
        const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000`;
        window.open(`/call/${roomObj}/?CALLER=${callingData.user.id}`, 'video-call', params)
        this.props.hideCallNotify()

    }


    denyCall = () => {
        this.props.hideCallNotify()
    }

    render() {
        const { data, show } = this.props;

        return (
            <Fragment>
                {
                show 
                ? <div className="modal fade fade-modal-nitification in show" id="overlay2" style={{display:'block'}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="ba-bill-pay-inner __notify">
                                <div className="ba-single-bill-pay">
                                    <div className="thumb">
                                        <img src={data.user.avatar} alt="img" />
                                    </div>
                                    <div className="details">
                                        <h5>{data.user.name} is calling ...</h5>
                                    </div>
                                </div>
                                <div className="amount-inner">
                                    <button className="btn btn-secondary" onClick={(e) => this.denyCall()}>Deny</button>
                                    <button className="btn btn-primary" onClick={(e) => this.acceptCall(data)}>Accept</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : ''
                }
            </Fragment>
        );
    }
}

export default CallNotify;