import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Login, GenerateOtp } from "../actions";
import History from "../History";
import Spinner from "./subcomponent/spinner/Spinner";
import Switchmode from "./Switchmode";
import { withAvertiLoader } from "../loaderHoc";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginForm: {
        username: "",
        password: "",
      },
      loginError: {},
      phone: "",
      phoneError: "",
      enableBtn: false,
      listening: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
    this.submitPhone = this.submitPhone.bind(this);
    this.toggleListen = this.toggleListen.bind(this);
    this.handleListen = this.handleListen.bind(this);
  }

  handleValidation() {
    let fields = this.state.loginForm;
    let errors = {};
    let formIsValid = true;

    if (!fields["username"]) {
      formIsValid = false;
      errors["username"] = "Please Enter the username/email.";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Please Enter the password";
    }
    this.setState({ loginError: errors });
    return formIsValid;
  }

  onInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      loginForm: {
        ...this.state.loginForm,
        [name]: value,
      },
    });
  }
  submit(event) {
    event.preventDefault();

    if (this.handleValidation()) {
      this.props.Login(this.state.loginForm);
    } else {
      // console.log('Invalid form')
    }
  }

  handlePhoneNumber(event) {
    const value = event.target.value;
    this.setState({
      phone: value,
    });
  }

  submitPhone(event) {
    event.preventDefault();
    if (this.handlePhoneValidation()) {
      // console.log(this.state.phone)
      localStorage.setItem("phoneNumber", this.state.phone);
      let params = {
        mobile: this.state.phone,
      };
      // console.log(params)
      this.props.GenerateOtp(params);

      History.push("/otp");
    } else {
      // console.log('Invalid number')
    }
  }

  handlePhoneValidation() {
    let errorsPhone = "";
    let formIsValid = true;

    if (this.state.phone === null && !this.state.phone) {
      formIsValid = false;
      errorsPhone = "Please Enter phone number.";
    }
    if (this.state.phone.length < 10 || this.state.phone.length > 12) {
      formIsValid = false;
      errorsPhone = "Please Enter valid phone number length.";
    }
    // console.log(this.state.phone)
    this.setState({ phoneError: errorsPhone });
    return formIsValid;
  }

  toggleListen() {
    this.setState(
      {
        listening: !this.state.listening,
      },
      this.handleListen,
    );

    /* this.setState({
      active: !this.state.active
    }) */
  }

  handleListen() {
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.continous = true;
      recognition.interimResults = true;
      recognition.lang = "en-US";

      if (this.state.listening) {
        recognition.start();
        recognition.onend = () => {
          // console.log("...continue listening...")
          recognition.start();
        };
      } else {
        recognition.stop();
        recognition.onend = () => {
          // console.log("Stopped listening per click")
        };
      }

      recognition.onstart = () => {
        // console.log("Listening!")
      };

      let finalTranscript = "";
      recognition.onresult = (event) => {
        let interimTranscript = "";

        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          // console.log('coming voice: ', transcript)
          // console.log('isFinel', event.results[i].isFinal)
          if (event.results[i].isFinal && /^\d+$/.test(transcript)) {
            finalTranscript += transcript;
          } else {
            interimTranscript += transcript;
          }
        }
        // console.log(interimTranscript)
        // console.log(finalTranscript)
        this.setState({
          ...this.state,
          phone: finalTranscript.trim(),
        });

        //-------------------------COMMANDS------------------------------------

        // const transcriptArr = finalTranscript.split(' ')
        // const stopCmd = transcriptArr.slice(-3, -1)
        // console.log('stopCmd', stopCmd)

        if (
          this.state.listening &&
          (interimTranscript === "stop" || interimTranscript === "done")
        ) {
          recognition.stop();
          recognition.onend = () => {
            // console.log('Stopped listening per command')
            this.setState({
              listening: false,
            });
            if (
              this.state.phone.length === 10 ||
              this.state.phone.length === 11
            ) {
              this.setState({
                enableBtn: true,
              });
            }
          };
        }
        if (interimTranscript === "clear" || interimTranscript === "remove") {
          this.setState({
            ...this.state,
            phone: "",
          });
          finalTranscript = "";
        }
      };

      //-----------------------------------------------------------------------

      recognition.onerror = (event) => {
        // console.log("Error occurred in recognition: " + event.error)
      };
    } else {
      alert("Speech recognition not supported by the browser");
    }
  }

  componentDidUpdate() {
    if (this.props.userLoader) {
      this.props.showLoader();
    } else {
      this.props.hideLoader();
    }

    if (this.props.user !== null) {
      History.push("/");
    }

    if (this.props.userDetail && this.props.userDetail.id !== "") {
      History.push("/");
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loaderDiv}
        <div
          className="header-area"
          style={{ backgroundImage: "url(assets/img/bg/1.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-3"></div>
              <div className="col-sm-4 col-6 text-center">
                <div className="page-name">Login</div>
              </div>
              <div className="col-sm-4 col-3 text-right"></div>
            </div>
          </div>
        </div>
        <div className="container mt-5">
          <div className="page-title mg-top-50">
            <div className="container">
              <div className="d-flex justify-content-between">
                {/* <a className="home-clicked mt-2" href="#/"><strong>Login</strong></a> */}
                <h6 className="header-content py-0 my-0">
                  Welcome
                  <br />
                  <span className="mt-2" id="dark_span">
                    To your Life Management
                    account. Please sign in to continue.
                  </span>
                </h6>
                <span className="">
                  <Link id="header-user" className="header-user" to={"/"}>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/img/flag.jpg"}
                      alt="img"
                      width={40}
                    />
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="widget-body sliding-tabs">
            <ul
              className="nav nav-tabs widget has-shadow px-0"
              id="example-one"
              role="tablist"
            >
              <li className="nav-item w-50">
                <a
                  className="nav-link active"
                  id="base-tab-1"
                  data-toggle="tab"
                  href="#tab-1"
                  role="tab"
                  aria-controls="tab-1"
                  aria-selected="true"
                >
                  <button type="button" className="btn-c btn-secondary w-100">
                    Email
                  </button>
                </a>
              </li>
              <li className="nav-item w-50">
                <a
                  className="nav-link"
                  id="base-tab-2"
                  data-toggle="tab"
                  href="#tab-2"
                  role="tab"
                  aria-controls="tab-2"
                  aria-selected="false"
                >
                  <button type="button" className="btn-c btn-secondary w-100">
                    Phone Number
                  </button>
                </a>
              </li>
            </ul>
            <div className="tab-content pt-3">
              {this.props.error ? (
                <div
                  className="alert alert-danger alert-dissmissible fade show"
                  role="alert"
                >
                  <strong>Alert!</strong> {this.props.errorMessage}
                </div>
              ) : null}

              <div
                className="tab-pane fade show active"
                id="tab-1"
                role="tabpanel"
                aria-labelledby="base-tab-1"
              >
                <div className="signin-area">
                  <div className="container px-0">
                    <form
                      className="contact-form-inner px-0"
                      onSubmit={this.submit}
                    >
                      <label className="single-input-wrap">
                        <span id="dark_span">Email*</span>
                        <input
                          type="text"
                          name="username"
                          value={this.state.loginForm["username"]}
                          onChange={this.onInputChange}
                        />
                        <small className="btn-danger">
                          {this.state.loginError["username"]}
                        </small>
                      </label>
                      <label className="single-input-wrap">
                        <span id="dark_span">Password*</span>
                        <input
                          type="password"
                          name="password"
                          value={this.state.loginForm["password"]}
                          onChange={this.onInputChange}
                        />
                        <small className="btn-danger">
                          {this.state.loginError["password"]}
                        </small>
                      </label>
                      <div className="single-checkbox-wrap">
                        <label htmlFor="remember">
                          <input
                            id="remember"
                            type="checkbox"
                            name="remember"
                            value="1"
                          />{" "}
                          <span id="dark_span">Remember password</span>
                        </label>
                      </div>
                      <button type="submit" className="btn-c btn-success">
                        Login{" "}
                        {this.props.loader ? <Spinner size="mini" /> : null}
                      </button>
                    </form>
                    {/* <div className="edit-icon margin">
										<img src={process.env.PUBLIC_URL + '/assets/img/google.png'} alt="microphone" />
									</div> */}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="tab-2"
                role="tabpanel"
                aria-labelledby="base-tab-2"
              >
                <div className="signin-area">
                  <div className="container px-0">
                    <form
                      className="contact-form-inner px-0"
                      onSubmit={this.submitPhone}
                    >
                      <label className="single-input-wrap">
                        <span id="dark_span">Phone*</span>
                        <span className="phone-login">
                          <input
                            type="text"
                            id="phone"
                            placeholder="e.g. 702 123 4567"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handlePhoneNumber}
                          />
                          <small className="btn-danger">
                            {this.state.phoneError}
                          </small>
                        </span>
                      </label>
                      {/* <Link to={'/otp'}><button type="button" className="btn-c btn-success mr-1 mb-2" >Request OTP</button></Link> */}
                      <button type="submit" className="btn-c btn-success">
                        Request OTP
                      </button>
                    </form>
                    <div className="edit-icon margin">
                      <div className="mb-2">
                        <button
                          id="speech"
                          className="btn-voice type2"
                          onClick={this.toggleListen}
                        >
                          <div
                            className={this.state.listening ? "pulse-ring" : ""}
                          ></div>
                          {/* pulse-ring */}
                          <i
                            className="fa fa-microphone"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>

                      {this.state.listening ? "listening" : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex align-items-center justify-content-center">
            <Switchmode />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    loader: state.auth.loader,
    user: state.auth.userDetail,
    userDetail: state.user.userDetail,
    userLoader: state.user.loader,
  };
};

export default connect(mapStateToProps, { Login, GenerateOtp })(
  withAvertiLoader(Signin),
);
