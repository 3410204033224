import React from 'react';
import './style.css';
import FinanceVideo from '../assets/finance.mp4';
import HealthVideo from '../assets/health.mp4';
import EventsVideo from '../assets/events.mp4';
import BillVideo from '../assets/bills.mp4';
import DocumentVideo from '../assets/document.mp4';

const VideoGallery = () => {
  const videos = [
    { id: 1, src: FinanceVideo, title: 'Finance' },
    { id: 2, src: HealthVideo, title: 'Health' },
    { id: 3, src: EventsVideo, title: 'Events' },
    { id: 4, src: BillVideo, title: 'Bills' },
    { id: 5, src: DocumentVideo, title: 'Document' },
    // Add more videos as needed
  ];

  return (
    <div className=''>
      {videos.map((video) => (
        <div key={video.id}>
          <h5 className='video-title'>{video.title}</h5>
          <video controls style={{width:"100%"}}>
            <source src={video.src} type="video/mp4"/>
          </video>
        </div>
      ))}
    </div>
  );
};

export default VideoGallery;
