import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import AvertiLoader from "../loader";
import Section from './layouts/Section';
import Footer from './layouts/Footer';
import Links from './Links';



function EventFrame(props) {

    const { loader, user } = props;
    const EventLoader = AvertiLoader();

    return (
        <>
            {EventLoader}
            <Section
                allNotification={false}
                searchPopup={false}
                title={"Events"}
            >
                <iframe
                    id="frame"
                    name="frame"
                    width="100%"
                    style={{ height: "100vh" }}
                    src={Links.eventUrl}
                ></iframe>
            </Section>
            <Footer />
        </>
    )
}


const mapStateToProps = (state) => ({
    loader: state.event.loader,
    user: state.user.userDetail,
});

export default connect(mapStateToProps)(EventFrame);
