import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function ProjectZero() {
    const [series, setSeries] = useState([5000,87.5,12.5]);
    const [options, setOptions] = useState({
        chart: {
            type: "donut",
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                    legend: {
                        position: "bottom",
                    },
                },
            },
        ],
    });
    return (
        <div>
            <h5>Project Zero</h5>
            <hr />
            <ReactApexChart options={options} series={series} type="donut" />
        </div>
    )
}

export default ProjectZero;