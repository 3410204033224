import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Section from '../layouts/Section';
import '../prashant.css';

function CashFlow() {
	return (
		<div>
			<Header />
			{/* cart start */}
			<Section allNotification={false} searchPopup={true} title={'Cashflow'}>
				<div className="transaction-area pd-top-36">
					<div className="container">
						<div className="section-title transactionnew">
							<h3 className="title">Today Cashflow</h3>
							<Link to={'/transaction'}><i className="fa fa-bell" /></Link>
						</div>
						<ul className="transaction-inner">
							<li className="ba-single-transaction style-two">
								<div className="thumb">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/3.png'} alt="img" />
								</div>
								<div className="details">
									<h5>Loan Due</h5>
									<p>Finance Services</p>
									<h5 className="amount">$160</h5>
								</div>
							</li>
						</ul>
					</div>
				</div>
				{/* transaction End */}

				{/* transaction start */}
				<div className="transaction-area pd-top-36">
					<div className="container">
						<div className="section-title">
							<h3 className="title">Yesterday Cashflow</h3>
						</div>
						<ul className="transaction-inner">
							<li className="ba-single-transaction style-two">
								<div className="thumb">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/4.png'} alt="img" />
								</div>
								<div className="details">
									<h5>Health Medicine</h5>
									<p>Health Services</p>
									<h5 className="amount">-$890</h5>
								</div>
							</li>
						</ul>
					</div>
				</div>
				{/* transaction End */}

				{/* transaction start */}
				<div className="transaction-area pd-top-36">
					<div className="container">
						<div className="section-title">
							<h3 className="title">03 Mar 2020</h3>
						</div>
						<ul className="transaction-inner">
							<li className="ba-single-transaction style-two">
								<div className="thumb">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/2.png'} alt="img" />
								</div>
								<div className="details">
									<h5>Namecheap Inc.</h5>
									<p>Domain Purchase</p>
									<h5 className="amount">-$130</h5>
								</div>
							</li>
							<li className="ba-single-transaction style-two">
								<div className="thumb">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/3.png'} alt="img" />
								</div>
								<div className="details">
									<h5>Namecheap Inc.</h5>
									<p>Domain Purchase</p>
									<h5 className="amount">-$160</h5>
								</div>
							</li>
							<li className="ba-single-transaction style-two">
								<div className="thumb">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/4.png'} alt="img" />
								</div>
								<div className="details">
									<h5>Namecheap Inc.</h5>
									<p>Domain Purchase</p>
									<h5 className="amount">-$890</h5>
								</div>
							</li>
							<li className="ba-single-transaction style-two">
								<div className="thumb">
									<img src={process.env.PUBLIC_URL + '/assets/img/icon/5.png'} alt="img" />
								</div>
								<div className="details">
									<h5>Namecheap Inc.</h5>
									<p>Domain Purchase</p>
									<h5 className="amount">-$1,000</h5>
								</div>
							</li>
						</ul>
					</div>
				</div>
				{/* transaction End */}

				<div className="btn-wrap mg-top-40 mg-bottom-40">
					<div className="container">
						<Link to={"/"} className="btn btn-ivory __approvebtn">Approve <i className="fa fa-angle-double-right" /></Link>
						<Link to={"/"} className="btn btn-grey __denybtn">Deny <i className="fa fa-angle-double-right" /></Link>
					</div>
				</div>
			</Section>
			{/* cart End */}
			<div style={{ height: '5px' }}></div>
			<Footer />
		</div>
	)
}

export default CashFlow;
