import React, { Component } from "react";
import "./chatList.css"

export default class Avatar extends Component {
  /* constructor(props) {
    super(props);
  } */
  render() {
    return (
      <div className="avatar mr-1">
        <div className="avatar-img">
          <img loading="lazy" src={this.props.image} alt="#" />
        </div>
        <span className={`isOnline ${this.props.isOnline}`}></span>
      </div>
    );
  }
}
