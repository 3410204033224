import React, { useState } from 'react';
import './homes.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Innerpage = (props) => {
  const { handleclose, imageUrl, inputValue, inputValue1, selecteddtBoxie, categorydefine, setItemName, setFormData, formData} = props;
  const [selecteddtBox, setselecteddtBox] = useState(null);

  const handleClick = (boxNumber, value) => {
    setselecteddtBox(boxNumber);
    console.log("Brand Name:", inputValue ? inputValue : "");
    console.log("Size of Item:", inputValue1 ? inputValue1 : "");
    console.log("Selected Box Color:", selecteddtBoxie ? selecteddtBoxie : " ");
    console.log("Category", categorydefine ? categorydefine : " ");
    console.log("SubCategory:", value ? value : "no data");  
    setItemName(value);
    const newData = {
      id: formData.length + 1,
      inputValue,
      inputValue1,
      selecteddtBoxie,
      categorydefine,
      value,
      imageUrl,
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
    };
    setFormData([...formData, newData]);
    handleclose();
  };
  console.log("formdata", formData);

  const nameData = [
    { id: '1', name: 'Blouse' },
    { id: '2', name: 'Bodysuit' },
    { id: '3', name: 'Buttoned Shirt' },
    { id: '4', name: 'Camisole' },
    { id: '5', name: 'Cardigon' },
    { id: '6', name: 'Hoodie' },
    { id: '7', name: 'Jersey' },
    { id: '8', name: 'Polo Shirt' },
    { id: '9', name: 'Strapless' },
    { id: '10', name: 'Sweater' },
    { id: '11', name: 'Sweatshirt' },
    { id: '12', name: 'T-Shirt' },
    { id: '13', name: 'Tank Top' },
    { id: '14', name: 'Tunic' },
    { id: '15', name: 'Vest' },
  ];

  return (
//     <div>
//       <h2 className='htwoing'>What subcategory is your item?</h2>
//       <div style={{ marginBottom: "30px" }}></div>
//       <div className="oo1boxie-container">
//         {nameData.map((item) => (
//           <div
//             key={item.id}
//             className={`oo1boxie ${selecteddtBox === item.id ? 'selecteddt' : ''}`}
//             onClick={() => handleClick(item.id, item.name)}
//           >
//             <div className='liopl'>{item.name}</div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
<div className="container my-4">
<h2 className='htwoing' style={{marginBottom:"30px"}}>What subcategory is your item?</h2>
<div className="row md-6 ">
  {nameData.map((item) => (
    <div className=' col-6 col-md-6'>
    <div
      key={item.id}
      className={`oo1boxie ${selecteddtBox === item.id ? 'selecteddt' : ''}`}
      onClick={() => handleClick(item.id, item.name)}
    >
      <div className='liopl'>{item.name}</div>
    </div>
    </div>
  ))}
</div>
</div>
);
};


export default Innerpage;