import React, { useEffect, useState, useContext } from "react";
import Section from "./layouts/Section";
import { Link } from "react-router-dom";
import "./custom.css";
import doctordata from "./data/Doctordata";
import { SocketContext } from "../context/socket";
import Footer from "./layouts/Footer";
import HealthS from "./HealthS";
import HealthForm from "./HealthForm";
import PdfFile from "./PdfFile";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Button } from "semantic-ui-react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import "./prashant.css";
import HealthImg from './assets/health.jpg';
import AvertiLoader from '../loader';
import '../darkmode.css';


function Health(props) {


  const { userDetail, chats } = props;

  const chatLink = (user) => {

    for (let i in chats) {
      if (chats[i]['users'].length === 1 && chats[i]['users'][0]['id'] === user.uid) {
        return '/chat/' + window.btoa(chats[i]['id']) + '?call'
      }
    }
    return '/chats'
  }

  const [loader, showLoader, hideLoader] = AvertiLoader()
  const [doctors, setDoctors] = useState([]);


  useEffect(() => {
    document.body.classList.add("health-page");
    setDoctors(doctordata);
    if (doctors.length > 0) {
      window.jQuery(".blog-slider").owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        smartSpeed: 1500,
        stagePadding: 20,
        responsive: {
          0: {
            items: 2,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 5,
          },
        },
      });
    }

    return () => {
      document.body.classList.remove("health-page");
    };
  }, [doctors]);

  return (
    <>
      {loader}
      <Section allNotification={false} searchPopup={true} title={"Health"}>
        <div className="container">
          <div className="health-area md-top-5">
            <div className="containe">
              <div className="health-title">
                <img src={HealthImg} alt="health" style={{ boxShadow: "none" }} />
              </div>
            </div>
          </div>

          <div className="health-area3">
            <div className="container">
              <div className="health-title3">
                <h6 className="health-head">
                  If you are experiencing a life threatening emergency, please
                  call 911.
                </h6>
                <div className="row __healthrow">
                  <div className="col col-health-section">
                    <button
                      className="__neweeventbtn"
                      data-toggle="modal"
                      data-target="#__healthform"
                    >
                      <i
                        className="fa fa-medkit medikits __iprocess"
                        aria-hidden="true"
                      ></i>
                      <h6 className="__processwork ">
                        Are you having any health problems?
                      </h6>
                    </button>
                    <HealthForm />
                  </div>

                  {userDetail["mappings"] !== undefined &&
                    userDetail?.mappings.map((user) => {
                      if (user.role === "ADVISOR") return null;
                      return (
                        <Link
                          key={user}
                          className="col col-health-section"
                          to={chatLink(user)}
                        >
                          <button className="__neweeventbtn">
                            <i
                              className="fa fa-phone fa-phones __iprocess"
                              aria-hidden="true"
                            ></i>
                            <h6 className="__processwork ">
                              Call your Life Navigator
                            </h6>
                          </button>
                        </Link>
                      );
                    })}

                  <div className="col col-health-section">
                    <button
                      className="__neweeventbtn"
                      data-toggle="modal"
                      data-target="#_modal"
                    >
                      <i
                        className="fa fa-calendar-check-o fa-calendars __iprocess"
                        aria-hidden="true"
                      ></i>
                      <h6 className="__processwork ">Make an Appointment</h6>
                    </button>
                    <HealthS />
                  </div>

                  <div className="col col-health-section">
                    <button
                      type="button"
                      className="__neweeventbtn"
                      data-toggle="modal"
                      data-target="#basic-modal"
                    >
                      <i
                        className="fa fa-ambulance fa-ambulances __iprocess"
                        aria-hidden="true"
                      ></i>
                      <h6 className="__processwork">Don't worry we are here</h6>
                    </button>

                    {/* Begin Centered Modal */}
                    <div id="basic-modal" className="modal">
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h6 className="modal-title">Problems & Solutions</h6>
                            <button
                              type="button"
                              className="document-close"
                              data-dismiss="modal"
                            >
                              <span aria-hidden="true">×</span>
                              <span className="sr-only">close</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="health-are">
                              <div className="container health">
                                <div className="row">
                                  <div className="col-md-6 justify-contenet-center paragraphs">
                                    <h4 className="client-problems">Problems</h4>
                                    <p className="paragraph">
                                      <span className="number">1. </span> How
                                      healthy do you consider yourself on a scale
                                      of 1 to 10?
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">2. </span> How
                                      often do you get a health checkup?
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">3. </span> What do
                                      you say about your overall health?
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">4. </span> Over the
                                      past 2 weeks, how often have you felt
                                      nervous, anxious?
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">5. </span> How
                                      often do you have trouble taking medicines
                                      the way you have told to take them?
                                    </p>
                                  </div>
                                  <div className="col-md-6 justify-contenet-center consultimage">
                                    <img
                                      src="/assets/img/consult.svg"
                                      alt="consultant"
                                      style={{ boxShadow: "none" }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="container health1">
                                <div className="row">
                                  <div className="col pd-bottom-10">
                                    <h4 className="doctor-solution">
                                      Doctor Solutions
                                    </h4>
                                    <p className="paragraph">
                                      <span className="number">1. </span>I
                                      consider Myself on a scale 7 out of 10.{" "}
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">2. </span>I checkup
                                      my health once in a monthely.
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">3. </span>My Health
                                      condition is so good. There is no trouble in
                                      body
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">4. </span>Over the
                                      past 2 weeks, i didn't face
                                      nervousness,anxious
                                    </p>
                                    <p className="paragraph">
                                      <span className="number">5. </span>Sometimes
                                      i have trouble in taking medicines .
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* End Centered Modal */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* New health report section */}
          <div id="send-money-sections" className="send-money-area">
            <div className="container">
              <div className="section-title style-two">
                <h4 className="_health-report ">Address Book</h4>
              </div>
            </div>
            <div className="address-book container">
              <div className="row">
                <div className="col-6">
                  <div className="address_book_main">
                    <div className="address_book_com">
                      <h6 className="address_book_h6">Home Address:</h6>
                      <p >59 west 47th street <br />Towers MD 39212</p>
                    </div>
                  </div>
                  <div className="address_book_main">
                    <div className="address_book_com">
                      <h6 className="address_book_h6">Phone:</h6>
                      <p >(291)191-1923</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="address_book_com">
                    <h6 className="address_book_h6">Primary Contact:</h6>
                    <p><strong>Emily</strong>, Daughter <br />(231)121-1223</p>
                  </div>
                  <div className="address_book_com">
                    <h6 id="red_emergency" className="address_book_h6">In Case of an Emergency:</h6>
                    <p><strong>Call 911</strong></p>
                  </div>
                </div>
                <hr />

                <div className="col-6">
                  <div className="address_book_main">
                    <div className="address_book_com">
                      <h6 className="address_book_h6">Doctors:</h6>
                      <p>Primary Care Doctor, <strong>Dr. Jay rice</strong>
                        <br />Office Phone: (234)323-0989</p>
                      <p>Primary Care Doctor, <strong>Dr. Jay rice</strong> <br />Office Phone: (234)323-0989</p>
                      <p>Primary Care Doctor, <strong>Dr. Jay rice</strong> <br />Office Phone: (234)323-0989</p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="address_book_com">
                    <h6 className="address_book_h6">Hospitals:</h6>
                    <p>MassGeneral, <strong>Main Office</strong>
                      <br />Office Phone: (283)392-3021</p>
                    <p>MassGeneral, <strong>Emergency Room</strong>, <br />Office Phone: (283)392-3021</p>
                  </div>
                </div>
                <hr />

                <div className="col-12">
                  <div className="address_book_com">
                    <h6 className="address_book_h6">Others:</h6>
                    <p>Neighbor, <strong>Mary Fowler</strong> <br />Phone: (909)123-1523</p>
                    <p>Note: She lives in red house accross the street.</p>
                  </div>
                </div>
                <hr />

                <div className="col-12 medical_diagnosis">
                  <p class="fw-bold">Medical Diagnoses:</p>
                  <ul className="diagnosis">
                    <li>Diabetes</li>
                    <li>Heart Disease</li>
                    <li>Depression</li>
                    <li>Sugar</li>
                    <li>Asthama</li>
                  </ul>
                </div>
                <hr />

                <div className="container">
                  <h4 className="_health-report" style={{marginBottom:"10px"}}>Known Allergies</h4>
                </div>
                <div className="col-6 known_all_col">
                  <div className="address_book_main">
                    <div className="address_book_com">
                      <h6 className="address_book_h6">Doctors:</h6>
                      <p>Primary Care Doctor, <strong>Dr. Jay rice</strong>
                        <br />Office Phone: (234)323-0989</p>
                      <p>Primary Care Doctor, <strong>Dr. Jay rice</strong> <br />Office Phone: (234)323-0989</p>
                      <p>Primary Care Doctor, <strong>Dr. Jay rice</strong> <br />Office Phone: (234)323-0989</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 known_all_col">
                  <div className="address_book_com">
                    <h6 className="address_book_h6">Hospitals:</h6>
                    <p>MassGeneral, <strong>Main Office</strong>
                      <br />Office Phone: (283)392-3021</p>
                    <p>MassGeneral, <strong>Emergency Room</strong>, <br />Office Phone: (283)392-3021</p>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>

          {/* blog-area start */}
          {/* <div className="container">
            <div className="section-title">
              <h4 className="get-care ">Get Care now</h4>
            </div>
            <div className="blog-slider owl-carousel">
              {doctors.map((index) => (
                <div key={index}>
                  <div className="blog-slider-item1">
                    <div className="single-blog1">
                      <div className="thumb-health">
                        <img
                          src={index.image}
                          alt="img"
                          style={{ height: "220px", objectFit: "cover", boxShadow: "none" }}
                        />
                      </div>
                      <div className="doctor-details">
                        <h6 className="doctor-detailsh6">{index.name}</h6>
                        <p className="doctor-detailsp">{index.profession}</p>
                        <p className="doctor-detailsp1">{index.time}</p>
                        <div className="doctorlink">
                          <Link className="doctor-link" to={index.view}>
                            View More..
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}

          {/* New health report section */}
          {/* <div id="send-money-sections" className="send-money-area">
            <div className="container">
              <div className="section-title style-two">
                <h4 className="_health-report ">Watch Video</h4>
              </div>
            </div>
            <div className="report-list">
              <div className="report-item container">
                <div>
                  <ReactPlayer
                    className="react-player _react_player"
                    url="https://www.youtube.com/embed/0zHEUrg3uCI"
                    width="100%"
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1 },
                      },
                      facebook: {
                        appId: "12345",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div style={{ height: "15px" }}></div>
        <Footer />
      </Section>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userDetail: state.user.userDetail,
    chats: state.chat.chats,
  };
};

export default connect(mapStateToProps)(Health);

//export default Health;
