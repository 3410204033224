import React, { useState } from 'react'
import './ChildHomeMolecule.css'; 


const Itemm5 = () => {
    const [imageUrl, setImageUrl] = useState(null);
    const handleImageUpload = () => {
      // Open the file picker dialog
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageUrl(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
      input.click();
    };


    const productt = [
        {
        id :1,
        iconsname1: <i style={{color:"black"}} class="bi bi-tv"></i>,
        iconsname2: "WARDOBE STATS",
        iconsname3: "What you have and how you are using it",
      },
      {
        id :2,
        iconsname1: <i style={{color:"black"}} class="bi bi-bounding-box-circles"></i>,
        iconsname2: "STYLE HUB",
        iconsname3: "What you have and how you  are using it",
    
      },
      {
        id :3,
        iconsname1: <i style={{color:"black"}} class="bi bi-door-closed"></i>,
        iconsname2: "WARDOBE SETTINGS",
        iconsname3: "Tools to share more about your style",
    
      },
      {
        id :4,
        iconsname1: <i style={{color:"black"}} class="bi bi-gear"></i>,
        iconsname2: "ACCOUNT SETTINGS",
        iconsname3: "Update your personal and payment information",
    
      },
      {
        id :5,
        iconsname1: <i style={{color:"black"}} class="bi bi-people"></i>,
        iconsname2: "REFERAL",
        iconsname3: "Invite your friends and earn indyx credit",
    
      },
      {
        id :6,
        iconsname1: <i style={{color:"black"}} class="bi bi-telephone"></i>,
        iconsname2: "CONTACT US",
        iconsname3: "Have a question? We're here for you",
    
      },
     
    ]

  return (
    <div className='container'>
  
<div style={{background:"#fcfaed",marginBottom:"15px"}}>
<div className='item5div1'>
<div className="item5div2" onClick={handleImageUpload}>
{!imageUrl && <div className='alboix'>Choose Image</div>}
      {imageUrl && <img src={imageUrl} alt="Uploaded" style={{ width: '100%', height: '100%', borderTopLeftRadius: '20px', borderBottomRightRadius: '20px' }} />}
      <input type="file" style={{ display: 'none' }} onChange={handleImageUpload} accept="image/*" />      </div>
      {/* <button  onClick={handleImageUpload} >Upload Image</button> */}
 <div className='item5div3'>
     <div className='sttyy'><div className='divvv4'>kakutyagi990</div>
     <div className='divvv5'>tushar tyagi</div></div>

     <div className='divvv6'><div className='div77'>$0.00 INDYX CREDIT</div></div>
 </div>
</div>
      
      {Array.isArray(productt) && productt.map((item)=>{
      return(
      <div className='lio'>
      <div className='hirow'>
        <div className='twocol' > <div>{item.iconsname1}</div></div>
        <div className='tencol '>
            <p className='pone'>{item.iconsname2}</p>
            <p className='ptwo'>{item.iconsname3}</p>
        </div>
      </div>
      </div>
      )
     })}
   
    <div className='lastitem5'>
        <div className='item5las'>Version 5.2.0(305)</div>
    </div>

    </div>
    </div>
  )
}

export default Itemm5
