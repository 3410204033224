import React, { useEffect, useState } from "react";
import './stockTickers.css'
import CommonTable from "./CommonTable";
import Button from 'react-bootstrap/Button';
import  { googleFinanceData, googleFinanceMarketData } from "../../../src/api/auth";
import { Row, Col, Container } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';


function UsMarket(){
    const [getGoogleFinanceApiData, setGoogleFinanceApiData] = useState('');
    const [getGoogleFinanceMarketApiData, setGoogleFinanceMarketApiData] = useState('');
    const [getNewsData, setNewsData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isMarketDataLoading, setIsMarketDataLoading] = useState(true);

    
    useEffect(() => {
        let mounted = true;
      
        googleFinanceData().then(function (response) {
          if (mounted) {
            setGoogleFinanceApiData(response);
            setIsLoading(false);
          }
        }).catch(error => {
          console.error("Error fetching Google Finance data:", error);
          setIsLoading(false);
        });
      
        googleFinanceMarketData('indexes').then(function (response) {
          if (mounted && response) {
            if (response['news_results']) {
              setNewsData(response['news_results']);
            } else {
              console.warn("No 'news_results' in response:", response);
            }
            if (response['market_trends'] && response['market_trends'][0]) {
              setGoogleFinanceMarketApiData(
                response['market_trends'][0].results.map((item, index) => {
                  let obj = {};
                  obj.id = index;
                  obj.assets = item.name;
                  obj.irr = item.price;
                  obj.movement = item.price_change ? item.price_change.movement : '';
                  obj.value = item.price_change ? item.price_change.value : '';
                  obj.percentage = item.price_change_percentage || '';
                  return obj;
                })
              );
            } else {
              console.warn("No 'market_trends' in response:", response);
            }
            setIsMarketDataLoading(false);
          }
        }).catch(error => {
          console.error("Error fetching Google Finance market data:", error);
          setIsMarketDataLoading(false);
        });
      
        return () => (mounted = false);
      }, []);
    
    const checkTest = () => {
        alert("test");
    }

    const usMarketcolumns = [
        { field: 'id', headerName: 'ID', width: 70, color: 'black',hide: true,disableColumnMenu:true },
        { field: 'assets', headerName: 'Americas', width: 700, fontWeight:'700',disableColumnMenu:true , disableColumnFilter:false,
        disableColumnSelector:false,
        renderCell: (params) => {
          return (
            <span className="clickable" onClick={checkTest}><Button variant="contained"  className="index">INDEX</Button>{params.formattedValue}</span>
          )
        },
        disableColumnMenu:true,
        disableColumnFilter:true,
        disableColumnSelector:true,
        headerClassName: 'hideRightSeparator',
      },
        { field: 'irr', headerName: '', width: 130, headerAlign: 'center',
        align: 'center',   disableColumnMenu:true,
        disableColumnFilter:true,
        disableColumnSelector:true,
        sortable:false,
        headerClassName: 'hideRightSeparator', },
// value column
{
  field: 'value',
  headerName: '',
  type: 'number',
  width: 90,
  headerAlign: 'center',
  align: 'center',
  disableColumnMenu: true,
  disableColumnFilter: true,
  disableColumnSelector: true,
  sortable: false,
  headerClassName: 'hideRightSeparator',
  renderCell: (params) => {
    const { movement, value } = params.row;
    return (
      <span className="clickable" onClick={checkTest}>
        {movement === 'Up' ? (
          <span className="add">
            <i className="bi bi-plus-lg"></i>
            {value}
          </span>
        ) : movement === 'Down' ? (
          <span className="minus">
            <i className="bi bi-dash"></i>
            {value}
          </span>
        ) : (
          <span className="arrowfor">
            <i className="bi bi-arrow-right"></i>
            {value}
          </span>
        )}
      </span>
    );
  },
},

// fullName column
{
  field: 'fullName',
  headerName: '',
  sortable: false,
  width: 110,
  headerAlign: 'center',
  align: 'center',
  disableClickEventBubbling: true,
  disableColumnMenu: true,
  disableColumnFilter: true,
  disableColumnSelector: true,
  sortable: false,
  headerClassName: 'hideRightSeparator',
  renderCell: (params) => {
    const { movement, percentage } = params.row;
    return (
      <span className="clickable" onClick={checkTest}>
        {movement === 'Up' ? (
          <span className="arrowupicon">
            <i className="bi bi-arrow-up"></i>
            {percentage}%
          </span>
        ) : movement === 'Down' ? (
          <span className="arrowdownicon">
            <i className="bi bi-arrow-down"></i>
            {percentage}%
          </span>
        ) : (
          <span className="arrowfor">
            <i className="bi bi-arrow-right"></i>
            {percentage}%
          </span>
        )}
      </span>
    );
  },
},
        {
            field: 'blank',
            headerName: '',
            sortable: false,
            width: 90,
            headerAlign: 'center',
            align: 'center',
            disableClickEventBubbling: true,
            renderCell: (params) => {
              return (
                <span className="addclickable" onClick={checkTest}>
                  <i style={{color:"black", fontSize:"17px"}} className="bi bi-plus-circle"></i>
                </span>
              )
              
            },
            disableColumnMenu:true,
            disableColumnFilter:true,
            disableColumnSelector:true,
            sortable:false,
            headerClassName: 'hideRightSeparator'
          },
      ];
      
    return (
      <>   
        <Container>
          <div>
           {isLoading ? (
              <div className="text-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              <Row className="mb-3">
                {getGoogleFinanceApiData && getGoogleFinanceApiData['markets']['us'].map((item, i) =>
                  <Col key={i} xs={6} sm={6} md={3} lg={2} className="mb-2">
                    <Button variant="contained" className="stockbtn">
                      {item.price_movement.movement === 'Up' ?
                        <span className="arrowup"><i className="bi bi-arrow-up"></i></span> :
                        item.price_movement.movement === 'Down' ?
                          <span className="arrowdown"><i className="bi bi-arrow-down"></i></span> :
                          <span className="arrowfor"><i className="bi bi-arrow-right"></i></span>}
                      <span className="titlee">{item.name}<br />{item.price}</span>
                      <span className="price">{item.price_movement.percentage} %<br />{item.price_movement.value}</span>
                    </Button>
                  </Col>
                )}
              </Row>
            )}
          </div>

          {isMarketDataLoading ? (
              <div className="text-center my-5">
                <Spinner animation="border" variant="primary" />
              </div>
            ) : (
              Array.isArray(getGoogleFinanceMarketApiData) && (
                <CommonTable
                  columns={usMarketcolumns}
                  rows={getGoogleFinanceMarketApiData}
                  height={"100%"}
                />
              )
            )}

          <div className="mt-5">
            <Row className="mb-2">
              <h2>Today's financial news</h2>
            </Row>
            <Row className="news-container">
              {getNewsData && getNewsData.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="row mb-2" style={{display:"flex", border:"1px solid rgb(232, 234, 237)", padding:"10px", borderRadius:"9px"}}>
                    <div className="col-md-10 col-9">
                      <div className="news-header">
                        <div className="news-source">{item.source}</div>
                        <div className="news-date">{item.date}</div>
                      </div>
                      <div className="news-content">
                        <a href={item.link} className="newslink" target="_blank" rel="noopener noreferrer">{item.snippet}</a>
                      </div>
                    </div>
                    <div className="col-md-2 col-3">
                      <div className="news-thumbnail">
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                          <img className="huhiuhiul"  src={item.thumbnail} alt="news thumbnail" />
                        </a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </Row>
          </div>
        </Container>
      </>
    );
}

export default UsMarket;
