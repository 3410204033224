import moment from "moment";
import React, { Fragment, useRef, useState } from "react";
import { connect } from "react-redux";
import { addTask, fetchEvents } from '../actions';
import AvertiLoader from "../loader";
import { Card, Form, Button, Col, Row } from "react-bootstrap";
import Reminders from './assets/reminders.png';
import Accordion from 'react-bootstrap/Accordion';
import './calendar.css';
import Select, { components } from "react-select";

const unassign_btn = {
    height: "50%",
    width: "30%",
    border: "none",
    margin: 0,
    padding: 0,
    fontWeight: 700,
    color: "blue",
    opacity: 0.6
}

const styles = {
    backgroundColor: "#fdf9f7"
}

const closesbtn = {
    fontSize: "18px",
    fontWeight: "900",
    background: "transparent",
    boxShadow: "none"
}

const blurbg = {
    background: "#f8f7fc",
    backdropFilter: "blur(20px )",
    webkitBackdropFilter: "blur(20px)",
    boxShadow: "rgba(50, 50, 93, 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.1) 0px 18px 36px -18px inset",
}

const btnsave = {
    margin: 0,
    height: "30px",
    fontWeight: "600",
}

const btnsave1 = {
    margin: 0,
    height: "30px",
    fontWeight: "600",
    background: "#fff",
    border: "none",
    color: "#000"
}

const event_input = {
    fontSize: "12px",
    fontWeight: "600"
}

const event_input1 = {
    fontSize: "12px",
    fontWeight: "600",
    height: "30px",
    width: "85px"
}

const event_input2 = {
    fontSize: "12px",
    fontWeight: "600",
    height: "30px",
    width: "85px"
}

const event_input_btn = {
    fontSize: "18px",
    fontWeight: "bold",
    padding: "0px",
    margin: "0px",
    backgroundColor: "gray",
    color: "#fff",
    height: "27px",
    width: "20px",
}

const accordianItem = {
    background: "transparent",
    border: "none"
}

function TaskEventModal(props) {

    const { userDetail } = props;

console.log(userDetail, "userDetail -------")


    const printDocument = () => {
        window.print();
    };

    const sendEmail = () => {
        // implement email sending logic here
    };

    const eventFormRef = useRef();
    const [calendarLoader, showCalendarLoader, hideCalendarLoader] = AvertiLoader()

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    const [sdate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [stime, setStartTime] = useState();
    const [edate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [etime, setEndTime] = useState();
    
    const [selectedOption, setSelectedOption] = useState(null);
    const options = [
        { label: 'Finance', value: 'finance' },
        { label: 'Health', value: 'health' },
        { label: 'Business', value: 'business' },
        { label: 'Goals', value: 'goals' },
        { label: 'Children', value: 'children' }
    ];
    const [formErrors, setFormErrors] = useState({});

    // 

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    }
    const convertAssigneesToString = (assignees) => {
        return assignees.map(assignee => assignee.value).join(',');
    };

    const handleStartTimeChange = (e) => {
        setStartTime(e.target.value);
    }

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    }

    const handleEndTimeChange = (e) => {
        setEndTime(e.target.value);
    }


    const saveEvent = (e) => {
        const form = eventFormRef.current;
        let formError = false;

        if (form.title.value === '') {
            formError = true;
            window.toastr.error('Please enter event name');
            form.title.focus();
        }

        if (form.description.value === '') {
            formError = true;
            window.toastr.error('Please enter event Description');
            form.description.focus();
        }

        if (!formError) {
            showCalendarLoader();
            const inputStartDate = moment.utc(`${form.sdate.value} ${form.stime.value || '00:00'}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss');
            const inputEndDate = moment.utc(`${form.edate.value} ${form.etime.value || '23:59'}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss');

            const assigneesString = convertAssigneesToString(selectedAssignees);

            // Format the date without the time zone indicator
            const requestData = {
                title: form.title.value,
                description: form.description1.value, // Use a default value or handle this case accordingly
                priority: "High",
                status: "0",
                start_time: inputStartDate, // Assign the formatted start time
                end_time: inputEndDate, // Assign the formatted end time
                assign_to: assigneesString,
                req_type: selectedOption ? selectedOption.value : null
            };

            console.log(requestData, "requestdata")

            requestData.start_time = moment.utc(new Date(requestData.start_time));
            requestData.end_time = moment.utc(new Date(requestData.end_time));

            const savebutton = window.jQuery('#save-event');
            savebutton.attr('disabled', true);
            savebutton.html('Please wait ...');

            props.addTask(requestData)
                .then((data) => {
                    window.toastr.success('Your task has been created successfully');
                    form.reset();
                    savebutton.attr('disabled', false);
                    savebutton.html('Save');
                    window.jQuery('#modal-task').modal('hide');
                    props.fetchEvents();
                    hideCalendarLoader();

                })
                .catch((error) => {
                    window.toastr.error('Something went wrong. Please check and submit your data again!');
                    savebutton.attr('disabled', false);
                    savebutton.html('Save');
                    hideCalendarLoader();
                });
        }
    };


    const handleChange = (e) => {
        setDate(e.target.value);
    }




    const [reminders, setReminders] = useState([]);

    const handleAddReminder = () => {
        setReminders([...reminders, {}]); // Add an empty object to the reminders array
    };

    const [selectedAssignees, setSelectedAssignees] = useState([]);

    let assigneeOptions = [];

if (userDetail && userDetail.mappings && Array.isArray(userDetail.mappings)) {
  assigneeOptions = userDetail.mappings.map(mapping => ({
    label: `${mapping.first_name}`,
    value: mapping.id
  }));
} else if (userDetail) {
  assigneeOptions = [
    {
      label: `${userDetail.first_name}`,
      value: userDetail.id
    }
  ];
}

console.log(assigneeOptions, "assigneeOptions-------");
    

    // const assigneeOptions = userDetail && userDetail.mappings
    //     ? userDetail.mappings.map(mapping => ({
    //         label: mapping.first_name,
    //         value: mapping.id
    //     }))
    //     : [];
    //       console.log(userDetail.mappings, "Mappings in userDetail");

//     const assigneeOptions = userDetail.mappings
//   ? userDetail.mappings.map(mapping => ({
//       label: `${mapping.first_name}`, 
//     }))
//   : [];

    // const assigneeOptions = {
    //     name: userDetail.first_name,
    //     id: userDetail.id
    // }

    console.log(assigneeOptions , "assigneeOptions-------")



    const handleSelectChange = (selectedOptions) => {
        setSelectedAssignees(selectedOptions);
    };

    const unassign = () => {
        setSelectedAssignees([]);
    };

    const MultiValueLabel = (props) => (
        <components.MultiValueLabel {...props}>
            {props.data.isActive && (
                <span
                    className="dot"
                    style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "#28a745",
                        borderRadius: "50%",
                        display: "inline-block",
                        marginRight: "5px",
                        verticalAlign: "middle",
                    }}
                ></span>
            )}
            {props.data.label}
        </components.MultiValueLabel>
    );



    return (
        <Fragment>
            {calendarLoader}
            <div id="modal-task" className="modal fade">
                <div className="modal-dialog" role="document">
                    <div className="modal-content" style={{ background: "transparent" }}>
                        <div className="modal-header pb-0 pt-0" style={styles}>
                            <b className="create_event" style={{ fontSize: '16px' }}>Create a Task</b>
                            <button type="button" className="closes" onClick={() => window.jQuery('#modal-task').modal('hide')} style={closesbtn}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={blurbg}>
                            <form ref={eventFormRef} className="form-horizontal">
                                <div className="row mb-2">
                                    <label htmlFor="event_title" className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Service*</label>
                                    <div className="col-xs-9 col-9">
                                        <input type="text" style={event_input} className="form-control event_input" id="event_title" name="title" placeholder="Event Service name here" />
                                        {formErrors.title && <div className="error-message">{formErrors.title}</div>}
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Description*</label>
                                    <div className="col-xs-9 col-9">
                                        <input type="text" style={event_input} className="form-control event_input" name="description1" placeholder="description here ..." />
                                    </div>
                                </div>
                                <div className="row  mb-2">
                                    <label className="label_event col-xs-3 col-3" htmlFor="stime" style={{ fontWeight: "600" }}>Start Date</label>
                                    <div className="col-xs-9 col-9 d-flex" style={{ gap: "10px" }}>
                                        <input type="date" id="sdate" name="sdate" value={sdate} onChange={handleStartDateChange} className="form-control event_input" />
                                        <input type="time" id="stime" style={event_input} className="form-control event_input" name="stime" value={stime} onChange={handleStartTimeChange} />
                                        {formErrors.sdate && <div className="error-message">{formErrors.sdate}</div>}
                                    </div>
                                </div>

                                <div className="row  mb-2">
                                    <label className="label_event col-xs-3 col-3" htmlFor="stime" style={{ fontWeight: "600" }}>End Time</label>
                                    <div className="col-xs-9 col-9 d-flex" style={{ gap: "10px" }}>
                                        <input type="date" id="edate" name="edate" value={edate} onChange={handleEndDateChange} className="form-control event_input" />
                                        <input type="time" id="etime" style={event_input} className="form-control event_input" name="stime" value={etime} onChange={handleEndTimeChange} />
                                    </div>
                                </div>

                                <div className="row  mb-3">
                                    <label className="label_event col-xs-3 col-3" htmlFor="sdate" style={{ fontWeight: "600" }}>Special Info</label>
                                    <div className="col-xs-9 col-9">
                                        <textarea className="form-control event_input" style={event_input} id="description" name="description" rows="3" placeholder="Enter Request note here..."></textarea>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <label className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Category*</label>
                                    <div className="col-xs-9 col-9">
                                        <Select
                                            options={options}
                                            value={selectedOption}
                                            onChange={setSelectedOption}
                                            placeholder="Select a category..."
                                        />
                                    </div>
                                </div>

                                <div className="row  mb-2 mt-4">
                                    <label className="label_event col-xs-3 col-3" htmlFor="stime" style={{ fontWeight: "600" }}>Assigned to</label>
                                    <div className="col-xs-9 col-9 d-flex align-items-center" style={{ gap: "10px" }}>
                                        {assigneeOptions && (
                                           <Select
                                           isMulti
                                           name="assignees"
                                           options={assigneeOptions}
                                           className="basic-multi-select"
                                           classNamePrefix="select"
                                           onChange={handleSelectChange}
                                           value={selectedAssignees}
                                           placeholder="Select assignees..."
                                           components={{ MultiValueLabel }}
                                         />
                                        )}
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            onClick={unassign}
                                            style={unassign_btn}
                                        >
                                            Set Unassigned
                                        </Button>
                                    </div>
                                </div>

                                <hr className="mb-3" />
                            </form>
                            <div>
                                <Accordion>
                                    <Accordion.Item eventKey="0" style={accordianItem} id="accordian_item_div">
                                        <Accordion.Header id="accordian_head_btn" style={{ gap: "20px" }}>
                                            <img src={Reminders} alt="calendar" width="20px" height="20px" style={{ boxShadow: "none" }} />
                                            Reminders (1)
                                        </Accordion.Header>
                                        <Accordion.Body className="accordian_body_div">
                                            <div className="row  mb-2">
                                                <label className="label_event" htmlFor="stime" style={{ fontWeight: "600", padding: "0px", margin: "0px" }}>Date</label>
                                                {reminders.map((reminder, index) => (
                                                    <div key={index}>
                                                        <div className="col-xs-12 col-12 d-flex mt-2 justify-content-between" style={{ padding: "0px" }}>
                                                            <input type="date" id={`sdate_${index}`} name={`sdate_${index}`} className="form-control event_input" style={{ height: "30px", width: "125px" }} />
                                                            <input type="time" id={`stime_${index}`} className="form-control event_input" name={`stime_${index}`} style={event_input1} />
                                                            <input type="text" className="form-control event_input" name={`location_${index}`} style={event_input2} placeholder="Location here ..." />
                                                            <button style={event_input_btn} type="button" className="btn btn-sm" onClick={() => setReminders(reminders.filter((_, i) => i !== index))}><i className="bi bi-x"></i></button>
                                                        </div>
                                                        <hr className="mt-3" style={{ width: "95%", marginBottom: "0px" }} />
                                                    </div>
                                                ))}
                                                <Button variant="link" className="d-flex align-items-center" style={{ textDecoration: "none", gap: "3px", fontWeight: "500", margin: "0px", padding: "0px" }} onClick={handleAddReminder}>
                                                    <i class="bi bi-plus"></i>
                                                    Add Reminder
                                                </Button>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                        <div className="modal-footer" style={blurbg}>
                            <div>
                                <button onClick={saveEvent} style={btnsave} type="button" className="btn button-save" id="save-event">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        client_secret: state.payment.clientSecret,
        loader: state.payment.loader,
        userDetail: state.user.userDetail,
        chats: state.chat.chats,
        events: state.event.events,
        count: state.notification.count,
    };
};

export default connect(mapStateToProps, { addTask, fetchEvents })(TaskEventModal);