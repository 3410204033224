import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from "react-bootstrap";
import { connect } from "react-redux";
import Section from "./layouts/Section";
import "../components/custom.css";
import "bootstrap/dist/css/bootstrap.min.css";
import auth from "../api/auth";
import BasicInformation from "./counterparts/form-basic";
import AddressInformation from "./counterparts/form-address";
import TaxIdInformation from "./counterparts/form-taxid";
import BankInformation from "./counterparts/form-bank";
import ThemeLoader from "../theme-loader";

const initialState = {
  counterpart: {
    type: "individual",
    individual: {
      first_name: "",
      last_name: "",
      title: "",
      is_vendor: false,
      is_customer: false,
      phone: "",
      email: "",
      residential_address: {
        country: "",
        city: "",
        postal_code: "",
        state: "",
        line1: "",
        line2: "",
      },
    },
    organization: {
      legal_name: "",
      is_vendor: true,
      is_customer: true,
      phone: "",
      email: "",
      registered_address: {
        country: "",
        city: "",
        postal_code: "",
        state: "",
        line1: "",
        line2: "",
      },
    },
    created_automatically: false,
  },
  tax_id: {
    type: "",
    value: "",
  },
  bank_account: {
    name: "",
    account_holder_name: "",
    iban: "",
    bic: "",
    account_number: "",
    sort_code: "",
    routing_number: "",
    currency: "",
    country: "",
  },
};

// Validation rules
const validEmailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const isEmail = (email) => {
  if (!email.match(validEmailRegex)) {
    return true;
  }
  return false;
};

const validMobileRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const isMobile = (mobile) => {
  if (!mobile.match(validMobileRegex)) {
    return true;
  }
  return false;
};

function AddVendor(props) {
  const { user } = props;
  const [open, setOpen] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [formState, setFormState] = useState(initialState);
  const [step, setStep] = useState(1);
  const [type, setType] = useState("organization");
  const [loading, setLoading] = useState(false);

  const openUploader = (e) => {
    e.preventDefault();
    setFormState(initialState);
    setOpen(true);
  };

  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const callApi = useCallback((x_monite_entity_id) => {
    if (x_monite_entity_id === undefined || x_monite_entity_id === null) {
      return;
    }

    setLoading(true);

    auth
      .get(`counterparts/${x_monite_entity_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("AUTH_TOKEN")}`,
          "X-Monite-Token": `${window.localStorage.getItem("x_monite_token")}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setVendors(res.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    const x_monite_entity_id = user.userDetail.monite_entity_id;
    if (x_monite_entity_id !== undefined || x_monite_entity_id !== null) {
      callApi(x_monite_entity_id);
    }

    return () => { };
  }, [user, callApi]);

  const addVendor = (e) => {
    e.preventDefault();

    if (step !== 4) {
      setStep((step) => step + 1);
      return;
    }

    formState.counterpart.type = type;

    const x_monite_entity_id = user.userDetail.monite_entity_id;
    setLoading(true);
    auth
      .post(`counterparts/${x_monite_entity_id}`, formState, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("AUTH_TOKEN")}`,
          "X-Monite-Token": `${window.localStorage.getItem("x_monite_token")}`,
        },
      })
      .then((res) => {
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
          setStep(1);
          callApi(x_monite_entity_id);
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const [errors, setErrors] = useState({});

  const validate = (step) => {
    const formErrors = {};

    switch (step) {
      case 1:
        if (type === "individual") {
          if (formState.counterpart.individual.first_name === "") {
            formErrors["first_name"] = "First name is required";
          }
          if (isMobile(formState.counterpart.individual.phone)) {
            formErrors["phone"] = "A valid phone number is required";
          }
          if (isEmail(formState.counterpart.individual.email)) {
            formErrors["email"] = "A valid email is required";
          }
        } else {
          if (formState.counterpart.organization.legal_name === "") {
            formErrors["legal_name"] = "Legal name is required";
          }
          if (isMobile(formState.counterpart.organization.phone)) {
            formErrors["phone"] = "A valid phone number is required";
          }
          if (isEmail(formState.counterpart.organization.email)) {
            formErrors["email"] = "A valid email is required";
          }
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0 ? true : false;

      case 2:
        if (type === "individual") {
          if (formState.counterpart[type].residential_address.line1 === "") {
            formErrors["line1"] = "Address line1 is required";
          }
          if (formState.counterpart[type].residential_address.country === "") {
            formErrors["country"] = "Country is required";
          }
          if (formState.counterpart[type].residential_address.city === "") {
            formErrors["city"] = "City is required";
          }
          if (formState.counterpart[type].residential_address.state === "") {
            formErrors["state"] = "State is required";
          }
          if (
            formState.counterpart[type].residential_address.postal_code === ""
          ) {
            formErrors["postal_code"] = "Postal code is required";
          }
        } else {
          if (formState.counterpart[type].registered_address.line1 === "") {
            formErrors["line1"] = "Address line1 is required";
          }
          if (formState.counterpart[type].registered_address.country === "") {
            formErrors["country"] = "Country is required";
          }
          if (formState.counterpart[type].registered_address.city === "") {
            formErrors["city"] = "City is required";
          }
          if (formState.counterpart[type].registered_address.state === "") {
            formErrors["state"] = "State is required";
          }
          if (
            formState.counterpart[type].registered_address.postal_code === ""
          ) {
            formErrors["postal_code"] = "Postal code is required";
          }
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0 ? true : false;

      case 3:
        if (formState.tax_id.type === "") {
          formErrors["tax_type"] = "Tax type is required";
        }
        if (formState.tax_id.value === "") {
          formErrors["tax_value"] = "Tax value is required";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0 ? true : false;

      case 4:
        if (formState.bank_account.name === "") {
          formErrors["bank_name"] = "Bank name is required";
        }
        if (formState.bank_account.account_holder_name === "") {
          formErrors["account_holder_name"] = "Account holder name is required";
        }
        if (formState.bank_account.country === "") {
          formErrors["country"] = "Country is required";
        }
        if (formState.bank_account.currency === "") {
          formErrors["currency"] = "Currency is required";
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0 ? true : false;
      default:
        return true;
    }
  };

  const editVendor = (e, vendor) => {
    e.preventDefault();
    // console.log("vendor", vendor);
  };

  const prevStep = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep((step) => step - 1);
    }
  };

  const nextStep = (e) => {
    e.preventDefault();

    if (!validate(step)) {
      return;
    }

    if (step < 4) {
      setStep((step) => step + 1);
    }

    if (step === 4) {
      addVendor(e);
    }
  };

  return (
    <Section allNotification={false} title={"Vendors"}>
      {loading ? <ThemeLoader /> : null}
      <div className="cart-area pd-top-36 mg-bottom-40">
        <div className="container">
          <div className="section-title mb-2 carts-section d-flex">
            <h3 className="title d-flex w-100">
              <span>Vendors</span>
              <a
                href={"/#"}
                style={{
                  height: "auto",
                  position: "relative",
                  top: "5px",
                  color: "#222",
                  lineHeight: "unset",
                }}
                className="ml-2 p-0 btn btn-xs ml-auto d-flex justify-content-center align-items-center"
                onClick={openUploader}
                color="primary"
                size="small"
              >
                <i className="fa fa-plus-circle"></i> New Vendor
              </a>
            </h3>
          </div>

          {vendors.map((vendor, i) => (
            <div
              key={"counterpart-" + i}
              id={"bg1" + (i % 4)}
              className="ba-cart-inner mb-3"
              style={{
                backgroundImage: "url(assets/img/bg/10.png)",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={(e) => editVendor(e, vendor)}
            >
              {vendor.type === "individual" ? (
                <>
                  <p className="mb-0">Name</p>
                  <h5>
                    {vendor.individual.first_name}&nbsp;
                    {vendor.individual.last_name}
                  </h5>
                  <div className="row mt-4">
                    <div className="col-8">
                      <p className="mb-0">Email</p>
                      <b>{vendor.individual.email}</b>
                    </div>
                    <div className="col-4">
                      <p className="mb-0">Phone</p>
                      <b>{vendor.individual.phone}</b>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p className="mb-0">Legal Name</p>
                  <h5>{vendor.organization.legal_name}</h5>
                  <div className="row mt-4">
                    <div className="col-8">
                      <p className="mb-0">Email</p>
                      <b>{vendor.organization.email}</b>
                    </div>
                    <div className="col-4">
                      <p className="mb-0">Phone</p>
                      <b>{vendor.organization.phone}</b>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
          <div className="pt-3"></div>
        </div>
      </div>

      <Modal show={open} onHide={handleClose} backdrop="static">
        <ModalHeader className="form_header" closeButton={"abc"}>
          <ModalTitle>Add Vendor</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {step === 1 ? (
            <BasicInformation
              formState={formState}
              setFormState={setFormState}
              type={type}
              setType={setType}
              errors={errors}
            />
          ) : null}
          {step === 2 ? (
            <AddressInformation
              formState={formState}
              setFormState={setFormState}
              type={type}
              errors={errors}
            />
          ) : null}
          {step === 3 ? (
            <TaxIdInformation
              formState={formState}
              setFormState={setFormState}
              errors={errors}
            />
          ) : null}
          {step === 4 ? (
            <BankInformation
              formState={formState}
              setFormState={setFormState}
              errors={errors}
            />
          ) : null}

          <div className="d-flex justify-content-center">
            <Button
              disabled={step === 1}
              variant="secondary"
              onClick={prevStep}
            >
              Prev
            </Button>
            <Button variant="primary" onClick={nextStep}>
              Next
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Section>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userDetail,
  ...state,
});

export default connect(mapStateToProps)(AddVendor);