import React,{useState} from 'react'
import './homes.css'

const Innerpage6 = (props) => {
  const { handleclose,inputValue,inputValue1,selecteddtBoxie,categorydefine,setItemName,setFormData,formData, imageUrl} = props;
    const [selecteddtBox, setselecteddtBox] = useState(null);
  
    const handleClick = (boxNumber,value) => {
      setselecteddtBox(boxNumber);
      // Example of setting itemName using setItemName function
      // setItemName(value);
      console.log("Brand Name:", inputValue ? inputValue : "");
      console.log("Size of Item:", inputValue1 ? inputValue1 : "");
      console.log("Selected Box Color:", selecteddtBoxie ? selecteddtBoxie : " ");
      console.log("Category",categorydefine ? categorydefine:" ");
      console.log("SubCategory:", value ? value : "no data");  
      setItemName(value);
      const newData = {
        id: formData.length + 1,
        inputValue,
        inputValue1,
        selecteddtBoxie,
        categorydefine,
        value,
        imageUrl,
        iconsnamep: "PRIVATE",
        iconsnamef: "FAVORITE",
        iconsnamed: "DO NOT STYLE",
        iconsnamea: "ARCHIVE",
      };
      setFormData([...formData, newData]);
      
  
      handleclose();
    };
      return (
        <div>
           <h2 className='htwoing'>What subcategory is your item?</h2>
           <div className='container my-4'>
                
           
                <div className="row md-6">
                   <div className="col-6 col-md-6">
                   <div className={`oo1boxie ${selecteddtBox === 1 ? 'selecteddt' : ''}`} onClick={() => handleClick(1,"BOOTS")}><div className='liopl'>BOOTS</div></div>
                   </div>
                   <div className="col-6 col-md-6">
                   <div className={`oo1boxie ${selecteddtBox === 2 ? 'selecteddt' : ''}`} onClick={() => handleClick(2,"CLOGS")}><div className='liopl'>CLOGS</div></div>
                   </div>
                 </div>    


                 <div className="row md-6">
                    <div className="col-6 col-md-6">
                    <div className={`oo1boxie ${selecteddtBox === 3 ? 'selecteddt' : ''}`} onClick={() => handleClick(3,"FLATS")}><div className='liopl'>FLATS</div></div>
                    </div>
                    <div className="col-6 col-md-6">
                    <div className={`oo1boxie ${selecteddtBox === 4 ? 'selecteddt' : ''}`} onClick={() => handleClick(4,"HEELS")}><div className='liopl'>HEELS</div></div>
                    </div>
                 </div>    


                <div className="row md-6">
                    <div className="col-6 col-md-6">
                    <div className={`oo1boxie ${selecteddtBox === 5 ? 'selecteddt' : ''}`} onClick={() => handleClick(5,"LOAFERS")}><div className='liopl'>LOAFERS</div></div>
                    </div>
                    <div className="col-6 col-md-6">
                    <div className={`oo1boxie ${selecteddtBox === 6 ? 'selecteddt' : ''}`} onClick={() => handleClick(6,"OXFORD/DERBY")}><div className='liopl'>OXFORD/DERBY</div></div>
                    </div>
                 </div>    


                <div className="row md-6">
                    <div className="col-6 col-md-6">
                    <div className={`oo1boxie ${selecteddtBox === 7 ? 'selecteddt' : ''}`} onClick={() => handleClick(7,"SANDALS")}><div className='liopl'>SANDALS</div></div>
                    </div>
                    <div className="col-6 col-md-6">
                    <div className={`oo1boxie ${selecteddtBox === 8 ? 'selecteddt' : ''}`} onClick={() => handleClick(8,"SNEAKERS")}><div className='liopl'>SNEAKERS</div></div>
                    </div>
                </div>    


                <div className="row md-6">
                    <div className="col-6 col-md-6">
                    <div className={`oo1boxie ${selecteddtBox === 9 ? 'selecteddt' : ''}`} onClick={() => handleClick(9,"WEDGES")}><div className='liopl'>WEDGES</div></div>
                    </div>
                </div>   

               </div>      

        </div>
  )
}

export default Innerpage6
