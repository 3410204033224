import auth from "../api/auth";
import * as type from "../store/redux-constant";

const headers = {
	'Content-Type': 'application/json',
}

export const getNotification = () => {
    return async (dispatch) => {
        try {
            headers['Authorization'] = 'Bearer '+ window.localStorage.getItem('AUTH_TOKEN')
            dispatch({ type: type.LOADER, payload: {} })
            const response = await auth.get('/notifications', {headers})
            dispatch({type: type.GET_NOTIFICATION_SUCCESS, payload: response.data})
        } catch (error) {
            dispatch({type: type.GET_NOTIFICATION_ERROR, payload: {}})
        }
    }
}