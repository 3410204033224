import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from "history";
import thunk from 'redux-thunk'
import 'semantic-ui-css/semantic.min.css'

import './index.css';
import App from './App';
import reducers from './reducers'

import { SocketContext, socket } from './context/socket';

import * as serviceWorker from './serviceWorker'; 

import { SkyflowElements, LogLevel, Env } from "skyflow-react-js";

import { GoogleOAuthProvider } from '@react-oauth/google';

window.process = {
  ...window.process,
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(thunk))
)

const history = createBrowserHistory();

const config = {
  vaultID: 'e2afcf95194b472f9f9fbe43cb6363bc',
  vaultURL: 'https://ebfc9bee4242.vault.skyflowapis.com',
  getBearerToken: () => {
    return new Promise((resolve, reject) => {
      resolve('eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2MiOiJlM2EyZDIwNTJmMTA0MzgxODBlNjQ3N2Q3OGUxNWU4MCIsImF1ZCI6Imh0dHBzOi8vbWFuYWdlLnNreWZsb3dhcGlzLmNvbSIsImV4cCI6MTY3NTk2MjU1NiwiaWF0IjoxNjczMzcwNTU2LCJpc3MiOiJzYS1hdXRoQG1hbmFnZS5za3lmbG93YXBpcy5jb20iLCJqdGkiOiJwMGYyNGU1MGUyOGQ0Y2YyOGViOWYzZGI1NThlZjBlOCIsInN1YiI6Im80M2UwOWFkMDI5NjRlYmZhZGNkYTEyN2M5Y2ZkMjZlIn0.M1PLK-5AAGasTxx_iLai6PlYwl_wO9FTONZJGOCTjEr7Oxqgk3sGiqhe5VCkEKmxQbmgfW33LCfgESxV4zyyaaII261gVfYi4CgMfKsb3o48KV83V6DGTyLozpIqs8vgqz8LIOYBr7sT7fRpnY6ZTgR1xeY5oDcpqDUHlM1TI-UzkCntPZhiQtbn72btHpEroXM3E1K8JvYB80FvvdzmBrcYMcZX5p4Dvi84dfZSGvjOOvTQrpaqBo9BSTHt0R3y0xUr7RmWbeKHT8Zvz8JK9krkireYuAqYQcgvhjcB-47Ibk1j0vgxRTfh2K_H96bZMTj0E9AqBdy3u8NYDNhnhw')
    })
  },  //helper function that retrieves a Skyflow bearer token from your backend
  options: {
    logLevel: LogLevel.DEBUG, // optional, if not specified default is ERROR 
    env: Env.DEV          // optional, if not specified default is PROD 
  }
}

ReactDOM.render(
  <SocketContext.Provider value={socket}>
    <Provider store={store}>
      <Router history={history}>
        <SkyflowElements config={config}>
          <GoogleOAuthProvider clientId="327847313047-io790r1qfffop52aj1btrhvcgqfajvmo.apps.googleusercontent.com">
            <App />
          </GoogleOAuthProvider>
        </SkyflowElements>
      </Router>
    </Provider>
  </SocketContext.Provider>,
  document.getElementById('root')
);


serviceWorker.register();