import React, { Fragment, useEffect, useRef, useState } from "react";
import "./call.css";
import Peer from "simple-peer";
import VideoCard from "./VideoCard";
import styled from "styled-components";
import Tooltip from "./tooltip";
import { useContext } from "react";
import { SocketContext, iceServers } from "../context/socket";
import { useCallback } from "react";

function WebCall(props) {

  const userVideoRef = useRef();
  const peersRef = useRef([]);
  const userStream = useRef();
  const screenTrackRef = useRef();
  const alertVideoRef = useRef();

  const room = props.match.params.id;
  // const type = props.match.params.type;

  const videoEnabled = true;

  const [userAudioVideo, setUserAudioVideo] = useState({
    localUser: { audio: true, video: videoEnabled },
  });

  const [peers, setPeers] = useState([]);
  const [onCall, setOnCall] = useState(true);
  const [chat] = useState(false);
  const [screenShare, setScreenShare] = useState(false);
  const [joined, setJoined] = useState(false);

  const roomId = atob(room);

  const user = JSON.parse(localStorage.getItem('userDetail'))
  const socket = useContext(SocketContext)

  const [enabled, setEnabled] = useState(false);

  const createPeer = useCallback((userId, caller, stream) => {

    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      config: { iceServers: iceServers },
    });

    peer.on("signal", (signal) => {
      socket.emit("call-user", {
        userToCall: userId,
        from: caller,
        signal,
      });
    });

    peer.on("disconnect", () => {
      peer.destroy();
    });

    return peer;
  }, [socket]);

  const addPeer = useCallback((incomingSignal, callerId, stream) => {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
      config: { iceServers: iceServers },
    });

    peer.on("signal", (signal) => {
      socket.emit("accept-call", { signal, to: callerId });
    });

    peer.on("disconnect", () => {
      peer.destroy();
    });

    peer.signal(incomingSignal);

    return peer;
  }, [socket]);

  function findPeer(id) {
    return peersRef.current.find((p) => p.peerID === id);
  }

  const socketEvents = useCallback((stream) => {

    socket.on("user-join-room", (users) => {

      const peers = [];
      users.forEach(({ userId, info }) => {
        let { video, audio } = info;
        const infoUser = info.user;
        if (infoUser.id !== user.id) {

          const peer = createPeer(userId, socket.id, stream);
          peer.user = infoUser;
          peer.userName = infoUser.name;
          peer.peerID = userId;
          peer.user_id = infoUser.id;
          const userName = infoUser.name;

          peersRef.current.push({
            peerID: userId,
            peer,
            userName,
            user_id: infoUser.id
          });
          peers.push(peer);

          setUserAudioVideo((preList) => {
            return {
              ...preList,
              [peer.user_id]: { video, audio },
            };
          });
        }

      });

      setPeers(peers);
    });
    // socket receive-call event
    socket.on("receive-call", ({ signal, from, info }) => {

      let { video, audio } = info;
      const peerIdx = findPeer(from);

      if (!peerIdx && user.id !== info.user.id) {
        let user = info.user
        const peer = addPeer(signal, from, stream);
        peer.userName = user.name;
        peer.user = user;
        peer.user_id = user.id;
        peersRef.current.push({
          peerID: from,
          peer,
          userName: user.name,
          user_id: user.id 
        });
        setPeers((users) => {
          return [...users, peer];
        });
        setUserAudioVideo((preList) => {
          return {
            ...preList,
            [peer.user_id]: { video, audio },
          };
        });
      }
    });
    // socket on call-accepted
    socket.on("call-accepted", ({ signal, answerId }) => {
      const peerIdx = findPeer(answerId);
      peerIdx.peer.signal(signal);
    });

    // socket on leave
    socket.on("user-leave", ({ userId, user }) => {

      window.toastr.warning(`${user.name} has left this conversation`);

      const peerIdx = findPeer(userId);
      if(peerIdx){
        setPeers((users) => {
          users = users.filter((user) => user.peerID !== peerIdx.peer.peerID);
          return [...users];
        });
        peersRef.current = peersRef.current.filter(
          ({ peerID }) => peerID !== userId
        );
        peerIdx.peer.destroy();
      }
    });
    // Socket on toggle camera
    socket.on('toggle-camera', ({ userId, switchTarget }) => {

      const peerIdx = findPeer(userId);

      setUserAudioVideo((preList) => {
        let video = preList[peerIdx.user_id].video;
        let audio = preList[peerIdx.user_id].audio;

        if (switchTarget === 'video') video = !video;
        else audio = !audio;

        return {
          ...preList,
          [peerIdx.user_id]: { video, audio },
        };
      });

    });
  }, [addPeer, createPeer, user, socket]);

  useEffect(() => {

    if (!user) {
      return;
    }

    document.body.classList.add('webcall')

    if(!joined){
      navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        userVideoRef.current.srcObject = stream;
        alertVideoRef.current.srcObject = stream;
        userStream.current = stream;
        setEnabled(true)
        setTimeout(() => { setJoined(true) }, 100)
      })
    }
    else {

      const stream = userStream.current;
      userVideoRef.current.srcObject = stream;
      socket.emit("join-room", { roomId, user, audio: userAudioVideo.localUser.audio, video: userAudioVideo.localUser.video });
      socketEvents(stream)
    }

    return () => {
      console.log('This will be logged on unmount');
      document.body.classList.remove('webcall')
      socket.off('user-join-room')
      .off('receive-call')
      .off('call-accepted')
      .off('user-leave')
      .off('disconnected')
    };

  }, [joined]);


  const toggleCameraAudioVideo = (e) => {
    const target = e;
    setUserAudioVideo((preList) => {
      
      let videoSwitch = preList["localUser"].video;
      let audioSwitch = preList["localUser"].audio;

      if (target === "video") {

        const userVideoTrack = userVideoRef.current.srcObject.getVideoTracks()[0];
        
        videoSwitch = !videoSwitch;
        if(!screenShare) {
          if(videoSwitch){
            /**
             * Replace Video
             */
            navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                const newStreamTrack = stream.getTracks().find((track) => track.kind === 'video')
                const oldStreamTrack = userStream.current.getTracks().find((track) => track.kind === 'video')
                if(joined){
                  userVideoRef.current.srcObject = stream
                }
                else {
                  alertVideoRef.current.srcObject = stream
                }
                userStream.current.removeTrack(oldStreamTrack)
                userStream.current.addTrack(newStreamTrack)
                peersRef.current.forEach(({ peer }) => {
                  peer.replaceTrack(
                    oldStreamTrack,
                    newStreamTrack,
                    userStream.current
                  );
                });                      
            })
            .catch(err => console.error(err))
          }
          else {
            userVideoTrack.stop()
          }
        }

      } else {

        const userAudioTrack = userVideoRef.current.srcObject.getAudioTracks()[0];
        audioSwitch = !audioSwitch;
        if (userAudioTrack) {
          userAudioTrack.enabled = audioSwitch;
        } else {
          userStream.current.getAudioTracks()[0].enabled = audioSwitch;
        }
      }

      return {
        ...preList,
        localUser: { video: videoSwitch, audio: audioSwitch },
      };
    });

    if(joined) socket.emit("toggle-camera-audio", { roomId, switchTarget: target });
  };

  const clickScreenSharing = () => {
    if (!screenShare) {
      navigator.mediaDevices
        .getDisplayMedia({ cursor: true })
        .then((stream) => {
          const screenTrack = stream.getTracks()[0];
          peersRef.current.forEach(({ peer }) => {
            peer.replaceTrack(
              peer.streams[0]
                .getTracks()
                .find((track) => track.kind === "video"),
              screenTrack,
              userStream.current
            );
          });

          // Listen click end
          screenTrack.onended = () => {
            peersRef.current.forEach(({ peer }) => { 
              peer.replaceTrack(
                screenTrack,
                peer.streams[0]
                  .getTracks()
                  .find((track) => track.kind === "video"),
                userStream.current
              );
            });
            userVideoRef.current.srcObject = userStream.current;
            setScreenShare(false);
          };

          userVideoRef.current.srcObject = userStream.current;
          screenTrackRef.current = screenTrack;
          setScreenShare(true);
        });
    } else {
      screenTrackRef.current.onended();
    }
  };

  const handleCall = (event) => {
    setOnCall(!onCall);
    if (onCall) {
      socket.emit('leave-room', {roomId: roomId, 'leaver': null})
      setTimeout(() => {
        window.close('','_parent','');
        window.location.reload();
      }, 100)
    }
  };

  const setJoinedStatus = (status) => {
    userVideoRef.current = userStream.current
    setJoined(status)
  }

  const setAudioVideo = (target) => {
    
    setUserAudioVideo((preList) => {

      let audio = preList.localUser.audio
      let video = preList.localUser.video

      if(target === 'video') {
        video = !video
        const userVideoTrack = userVideoRef.current.srcObject.getVideoTracks()[0];
        if(video) {
          navigator.mediaDevices.getUserMedia({ video: true })
          .then((stream) => {
              const newStreamTrack = stream.getTracks().find((track) => track.kind === 'video')
              const oldStreamTrack = userStream.current.getTracks().find((track) => track.kind === 'video')
              if(joined){
                userVideoRef.current.srcObject = stream
              }
              else {
                alertVideoRef.current.srcObject = stream
              }
              userStream.current.removeTrack(oldStreamTrack)
              userStream.current.addTrack(newStreamTrack)
              peersRef.current.forEach(({ peer }) => {
                peer.replaceTrack(
                  oldStreamTrack,
                  newStreamTrack,
                  userStream.current
                );
              });                      
          })
          .catch(err => console.error(err))
        }
        else {
          userVideoTrack.stop()
        }
      }
      else {
        
        const userAudioTrack = userVideoRef.current.srcObject.getAudioTracks()[0];
        audio = !audio;
        if (userAudioTrack) {
          userAudioTrack.enabled = audio;
        } else {
          userStream.current.getAudioTracks()[0].enabled = audio;
        }
      }

      return {
        ...preList,
        localUser: { video: video, audio: audio },
      };
    })
  }

  return (

    <Fragment>

      { !joined 
      ?
      <div className="alertbox">
        <p><b>How would you like to join?</b></p>
        <div className="videobox">
          <video ref={alertVideoRef} autoPlay playsInline muted />
        </div>
        <div className="meetingchoice">
          { enabled ?
          <div className="choicebtn-group">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={userAudioVideo.localUser.audio} onChange={() => setAudioVideo('audio')} />
              <label className="custom-control-label" htmlFor="customSwitch1">Audio</label>
            </div>
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="customSwitch2" checked={userAudioVideo.localUser.video} onChange={() => setAudioVideo('video')}  />
              <label className="custom-control-label" htmlFor="customSwitch2">Video</label>
            </div>
          </div>
          : ''}
          { enabled ? <button onClick={(e) => setJoinedStatus(true)} variant="contained" color="primary" className={'btn btn-primary'}>Enter</button> : <button disabled variant="contained" color="secondary" className={'btn btn-secondary'}>Please Wait</button> }
        </div>
      </div>
      : '' 
      }
      <Fragment>
          <div style={!joined?{display:'none'}:null} className="video-chat-container">
            {/* JSON.stringify(userAudioVideo) */}
            <div className={"videos"}>
              {peers &&
                peers.map((peer) => {
                  return (
                    <VideoBox
                      key={peer}
                      className={
                        "width-peer" + (peers.length > 8 ? "" : peers.length)
                      }
                    >
                      <div className="video-bg">
                        <div className="user-avatar">
                          <img src={peer.user.avatar} width="90" alt="avtar" />
                        </div>
                      </div>
                      <VideoCard peer={peer} display={userAudioVideo[peer.user_id] && userAudioVideo[peer.user_id]['video'] ? 'inherit': 'none' } />
                      <div className="userName">
                        <span>{peer.userName}</span>
                        {userAudioVideo[peer.user_id] && userAudioVideo[peer.user_id]['audio'] ? '' : <i className="fa fa-microphone-slash"></i>}
                      </div>
                    </VideoBox>
                  );
                })}
              <VideoBox
                className={"width-peer" + (peers.length > 8 ? "" : peers.length)}
              >
                <div className="video-bg">
                    <div className="user-avatar">
                      <img src={user !== null ? user.avatar: ''} width="90" alt="avtar" />
                    </div>
                </div>
                <video
                  autoPlay
                  playsInline
                  ref={userVideoRef}
                  className="local"
                  muted="muted"
                  style={{display: userAudioVideo['localUser'] && userAudioVideo['localUser']['video'] ? 'inherit': 'none'}}
                ></video>
                <div className="userName">
                  <span>Me</span>
                  {userAudioVideo['localUser'] && userAudioVideo['localUser']['audio'] ? '' : <i className="fa fa-microphone-slash"></i>}
                </div>
              </VideoBox>
            </div>
            <div className="chat">{user !== null ? user.name : ""}</div>
          </div>
          <div style={!joined?{display:'none'}:null} className="bottom-panel">
            {userAudioVideo.localUser.video ? (
              <Tooltip title="Video On" placement="top">
                <button
                  onClick={(e) => toggleCameraAudioVideo("video")}
                  data-switch="video"
                  size="small"
                  color="secondary"
                  aria-label="Video On"
                  className={'btn btn-enabled'}
                >
                  <i className="fa fa-video-camera"></i>
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Video Off" placement="top">
                <button
                  onClick={(e) => toggleCameraAudioVideo("video")}
                  data-switch="video"
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-disabled'}
                >
                  <i className="fa fa-video-camera"></i>
                </button>
              </Tooltip>
            )}

            {userAudioVideo.localUser.audio ? (
              <Tooltip title="Mic On" placement="top">
                <button
                  onClick={(e) => toggleCameraAudioVideo("audio")}
                  data-switch="audio"
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-enabled'}
                >
                  <i className="fa fa-microphone"></i>
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Mic Off" placement="top">
                <button
                  onClick={(e) => toggleCameraAudioVideo("audio")}
                  data-switch="audio"
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-disabled'}
                >
                  <i className="fa fa-microphone"></i>
                </button>
              </Tooltip>
            )}

            {onCall ? (
              <Tooltip title="End Call" placement="top">
                <button
                  onClick={handleCall}
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-danger'}
                >
                  <i className="fa fa-phone"></i>
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Start Call" placement="top">
                <button
                  onClick={handleCall}
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-success'}
                >
                  <i className="fa fa-phone"></i>
                </button>
              </Tooltip>
            )}

            {/* 

            {chat ? (
              <Tooltip title="Chat" placement="top">
                <button
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-enabled'}
                >
                  <i className="fa fa-comment"></i>
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Chat" placement="top">
                <button
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-disabled'}
                >
                  <i className="fa fa-comment"></i>
                </button>
              </Tooltip>
            )}
            {!screenShare ? (
              <Tooltip title="Share Screen" placement="top">
                <button
                  onClick={() => clickScreenSharing()}
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-disabled'}
                >
                  <i className="fa fa-desktop"></i>
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Strop Share Screen" placement="top">
                <button
                  onClick={() => clickScreenSharing()}
                  size="small"
                  color="secondary"
                  aria-label="add"
                  className={'btn btn-enabled'}
          
                >
                  <i className="fa fa-desktop"></i>
                </button>
              </Tooltip>
            )}

             */}
          </div>
      </Fragment>
    </Fragment>
  );
}

const VideoBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #777;
  margin-bottom: 2px;
  > video {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1
  }
  :hover {
    > i {
      display: block;
    }
  }
`;

export default WebCall
