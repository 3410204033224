import React from "react";
import { Form } from "react-bootstrap";
import TaxIdsSelectBox from "../widget/taxid-select";

function TaxIdInformation(props) {
  const { formState, setFormState, errors } = props;
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>
          <b>Tax Information:</b>
        </Form.Label>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Tax Type</Form.Label>
        <TaxIdsSelectBox {...props} />
        <span className="form-error text-danger">{errors.tax_type}</span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Tax Value</Form.Label>
        <Form.Control
          type="text"
          name="tax_value"
          value={formState.tax_id.value}
          onChange={(e) =>
            setFormState({
              ...formState,
              tax_id: { ...formState.tax_id, value: e.target.value },
            })
          }
          placeholder="Enter Tax Value"
          required
        />
        <span className="form-error text-danger">{errors.tax_value}</span>
      </Form.Group>
    </>
  );
}

export default TaxIdInformation;
