import React, { Component } from 'react';
import Routes from "./Routes";
import "./App.css";
import CallNotify from './components/layouts/CallNotify';
import { SocketContext } from './context/socket';
import { connect } from 'react-redux';

import { getChats, GetProfile } from './actions';

class App extends Component {

    static contextType = SocketContext;
    _isMounted = false;

    constructor(props) {
        super(props)
        this.state = {
            callingData: null,
            show: false,
            reconnected: false,
        }

        this.loaded = false;
        this.user = null;

    }

    tryReconnect() {
        console.log('Retrying to connect ...')
        setTimeout(() => {
            this.context.io.open((err) => {
                if (err) {
                    this.tryReconnect();
                }
                else {
                    // console.log('connected')
                    this.loaded = false
                    this.setState({
                        reconnected: true
                    })
                }
            });
        }, 5000);
    }

    componentDidMount() {

        this._isMounted = true;

        if (this._isMounted) {

            this.props.GetProfile();
            this.props.getChats()

            this.context.off('call-room').on('call-room', (data) => {
                this.setState({
                    callingData: data,
                    show: true
                })
            })

            this.context.off('message').on('message', (message) => {
                if (message.data.sender.id !== this.user.id) {

                    this.audioPlay('/assets/audio/notification.wav')

                    if (message.data.mime_type === 'text/html') {
                        window.toastr.info(message.data.message, message.data.sender.name + ' has messaged you')
                    }
                    else {
                        window.toastr.info('', message.data.sender.name + ' has sent you a file')
                    }
                }
            })

            this.context.on('close', () => {
                this.tryReconnect()
            })

            this.context.on('disconnect', () => {
                this.tryReconnect()
            })
        }

    }

    async audioPlay(url) {
        const context = new AudioContext();
        const source = context.createBufferSource();
        const audioBuffer = await fetch(url)
            .then(res => res.arrayBuffer())
            .then(ArrayBuffer => context.decodeAudioData(ArrayBuffer));
        source.buffer = audioBuffer;
        source.connect(context.destination);
        source.start();
    };

    hideCallNotify() {

        this.setState({
            show: false
        })
    }

    render() {

        const { callingData, show } = this.state
        const { chats, userDetail } = this.props

        console.log('state', this.state);

        if (!this.loaded && chats.length > 0 && userDetail.id !== '') {
            this.user = userDetail;
            chats.forEach((chat) => {
                this.context.emit('join', { room: 'room_' + chat.id, user: userDetail })
            });
            this.loaded = true;
        }

        return (
            <>

                {callingData ?
                    <>
                        <CallNotify hideCallNotify={() => this.hideCallNotify()} show={this.state.show} data={callingData} />
                    </>
                    : ''
                }
                { show && <audio id="callnotification" src="/assets/audio/call.mp3" autoPlay={true} style={{ visibility: 'hidden' }} /> }
                <Routes />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    chats: state.chat.chats,
    userDetail: state.user.userDetail
})

export default connect(mapStateToProps, { getChats, GetProfile })(App);