// import React, { useEffect, useState } from "react";
// import Time from "./Time";
// import Location from "./Location";
// import Calendar from "react-calendar";
// import CalendarEventModal from "./CalendarEventModal";
// import DateView from "./DateView";
// import { fetchEvents } from "../actions";
// import moment from "moment";
// import { connect } from "react-redux";
// import Section from "./layouts/Section";
// import Modal from "react-bootstrap/Modal";
// import AvertiLoader from "../loader";
// import { Accordion, Spinner } from "react-bootstrap";
// import Reminders from './assets/reminders.png';
// import TaskEventModal from "./TaskEventModal";


// const styles = {
//   backgroundColor: "#fdf9f7"
// }

// const closesbtn = {
//   fontSize: "18px",
//   fontWeight: "900",
//   background: "transparent",
//   boxShadow: "none"
// }

// const blurbg = {
//   background: "#f8f7fc",
//   backdropFilter: "blur(20px )",
//   webkitBackdropFilter: "blur(20px)",
//   boxShadow: "rgba(50, 50, 93, 0.1) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.1) 0px 18px 36px -18px inset",
// }

// const btnsave = {
//   margin: 0,
//   height: "30px",
//   fontWeight: "600",
// }

// const btnsave1 = {
//   margin: 0,
//   height: "30px",
//   fontWeight: "600",
//   background: "#fff",
//   border: "none",
//   color: "#000"
// }

// const event_input = {
//   fontSize: "12px",
//   fontWeight: "600"
// }

// const event_input1 = {
//   fontSize: "12px",
//   fontWeight: "600",
//   height: "30px",
//   width: "85px"
// }

// const event_input2 = {
//   fontSize: "12px",
//   fontWeight: "600",
//   height: "30px",
//   width: "120px"
// }

// const event_input_btn = {
//   fontSize: "18px",
//   fontWeight: "bold",
//   padding: "0px",
//   margin: "0px",
//   backgroundColor: "gray",
//   color: "#fff",
//   height: "27px",
//   width: "20px",
// }

// const accordianItem = {
//   background: "transparent",
//   border: "none"
// }


// function EventCalendar(props) {

//   const { events, loader } = props;

//   const [date, setDate] = useState(new Date());
//   const [show, setShow] = useState({
//     open: false,
//     event: null,
//   });

//   const today = moment(date).format("YYYY-MM-DD");
//   const todayMoment = moment(date, "YYYY-MM-DD");

//   const currentEvents = events.filter((event) => {
//     return (
//       today === moment(event.start_time).format("YYYY-MM-DD") ||
//       today === moment(event.end_time).format("YYYY-MM-DD") ||
//       todayMoment.isBetween(
//         moment(event.start_time, "YYYY-MM-DD"),
//         moment(event.end_time, "YYYY-MM-DD")
//       )
//     );
//   });

//   const showDetails = (event) => {
//     const _show = {
//       open: true,
//       event: event,
//     };
//     setShow(_show);
//   };

//   useEffect(() => {
//     props.fetchEvents();
//   }, []);


//   return (
//     <div className="event-calendar-wrapper">
//       {loader ? <div className="fix-loader"><Spinner /></div> : ''}
//       {/* Body content are started from here */}
//       <Section
//         allNotification={false}
//         searchPopup={false}
//         title={"Event Calendar"}
//       />

//       <div className="calendar-area">
//         <div className="container calendar-title">
//           <>
//             <div className="container __calendarHeading">
//               <h5 className="calendar-heading5">My Schedule</h5>
//               <button
//                 type="button"
//                 className="addevent-calenda"
//                 onClick={(e) => { e.preventDefault(); window.jQuery('#modal-task').modal('show') }}
//               >
//                 <i className="fa fa-plus plus-icon" aria-hidden="true"></i>Add Task 
//               </button>
//               <TaskEventModal />
//             </div>
//             <div className="__calendarContent">
//               <button
//                 type="button"
//                 className="addevent-calendar"
//                 onClick={(e) => { e.preventDefault(); window.jQuery('#modal-centered').modal('show') }}
//               >
//                 <i className="fa fa-plus plus-icon" aria-hidden="true"></i>Add Request
//               </button>
//               <CalendarEventModal />
//             </div>
//             <div className="calender-paragraph __calendarContent">
//               <Time className="eventime" />
//               <i className="fa fa-map-marker" aria-hidden="true">
//                 <Location />
//               </i>
//             </div>
//             <div id="calender-section">
//               <Calendar
//                 className="calendar_react"
//                 onClickDay={(d) => setDate(d)}
//                 value={date}
//                 onChange={(d) => setDate(d)}
//                 formatDay={(locale, date) => (
//                   <DateView date={date} events={events} />
//                 )}
//               />
//             </div>

//             {currentEvents.map((data, i) => {
//               return (
//                 <div
//                   onClick={(e) => showDetails(data)}
//                   style={{ cursor: "pointer" }}
//                   key={data.id}
//                   className={"calender-item myevent cal-" + (i % 5)}
//                 >
//                   <div className="main-row calender-row">
//                     <div className="health-area-2-row1">
//                       <p className="previous-meeting2">{data.title}</p>
//                     </div>
//                     {data.participants.length > 0 ? (
//                       <>
//                         <div className="health-area-2-row2">
//                           <div className="health-section-area">
//                             <img
//                               src={data.image}
//                               alt="img"
//                               width="40"
//                               className="avatar"
//                             />
//                             <p className="advisorname">{data.to}</p>
//                           </div>
//                         </div>
//                         <div className="health-area-2-row1">
//                           <p className="previous-meeting">
//                             {data.participants[0]["first_name"]}
//                           </p>
//                         </div>
//                       </>
//                     ) : (
//                       ""
//                     )}
//                     <div className="row health-area-2-row">
//                       <div className="col-6">
//                         <p className="previous-meeting">
//                           {moment.utc(data.start_time).local().format("LTS")}
//                         </p>
//                       </div>
//                       <div className="col-6">
//                         <p className="previous-meeting previous-meeting1">
//                           {moment
//                             .utc(data.start_time)
//                             .local()
//                             .format("ddd, ll")}
//                         </p>
//                       </div>
//                     </div>
//                     <div className="health-area-2-row2 area2-row-2">
//                       <p className="previous-meeting">
//                         <span className="previous-meeting1">
//                           {" "}
//                           <i
//                             className="fa fa-map-marker"
//                             aria-hidden="true"
//                           ></i>
//                         </span>
//                         {data.location}
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               );
//             })}
//             <div style={{ height: "50px" }}></div>
//           </>
//         </div>
//       </div>
//       {show.open ? (
//         <Modal
//           show={show.open}
//           onHide={() => setShow({ ...show, ["open"]: false })}
//         >
//           <Modal.Header closeButton style={styles}>
//             <Modal.Title>Request</Modal.Title>
//           </Modal.Header>
//           <Modal.Body style={blurbg}>
//             <form className="form-horizontal">
//               <div className="row mb-2">
//                 <label htmlFor="event_title" className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Service*</label>
//                 <div className="col-xs-9 col-9">
//                   <input type="text" style={event_input} className="form-control event_input" id="event_title" name="title" placeholder="Event Service name here" value={show.event.title} />
//                 </div>
//               </div>
//               <div className="row  mb-2">
//                 <label className="label_event col-xs-3 col-3" style={{ fontWeight: "600" }}>Location*</label>
//                 <div className="col-xs-9 col-9">
//                   <input value={show.event.location} type="text" style={event_input} className="form-control event_input" name="location" placeholder="Location here ..." />
//                 </div>
//               </div>
//               <div className="row  mb-2">
//                 <label className="label_event col-xs-3 col-3" htmlFor="stime" style={{ fontWeight: "600" }}>Due Date</label>
//                 <div className="col-xs-9 col-9 d-flex" style={{ gap: "10px" }}>
//                   <p>
//                     <i className="fa fa-clock-o"></i>{" "}
//                     {moment.utc(show.event.start_time).local().format("LLL")}
//                   </p>
//                 </div>
//               </div>
//               <div className="row  mb-3">
//                 <label className="label_event col-xs-3 col-3" htmlFor="sdate" style={{ fontWeight: "600" }}>Special Info</label>
//                 <div className="col-xs-9 col-9">
//                   {show.event.comment !== null ? (
//                     <textarea value={show.event.comment} className="form-control event_input" style={event_input} id="description" name="description" rows="3" placeholder="Enter Request note here..."></textarea>
//                   ) : (
//                     ""
//                   )}
//                 </div>
//               </div>

//               <hr className="mb-3" />
//             </form>
//             <div>
//               <Accordion>
//                 <Accordion.Item eventKey="0" style={accordianItem} id="accordian_item_div">
//                   <Accordion.Header id="accordian_head_btn" style={{ gap: "20px" }}>
//                     <img src={Reminders} alt="calendar" width="20px" height="20px" style={{ boxShadow: "none" }} />
//                     Reminders (1)
//                   </Accordion.Header>
//                   <Accordion.Body>
//                     <div className="row  mb-2">
//                       <label className="label_event" htmlFor="stime" style={{ fontWeight: "600", padding: "0px", margin: "0px" }}>Date</label>
//                       <div className="col-xs-12 col-12 d-flex mt-2 justify-content-between" style={{ padding: "0px" }}>
//                         <input type="date" id="sdate" name="sdate" className="form-control event_input" style={{ height: "30px", width: "125px" }} />
//                         <input type="time" id="stime" className="form-control event_input" name="stime" style={event_input1} />
//                         <input type="text" className="form-control event_input" name="location" style={event_input2} placeholder="Location here ..." />
//                       </div>
//                     </div>
//                   </Accordion.Body>
//                 </Accordion.Item>
//               </Accordion>
//             </div>
//           </Modal.Body>
//         </Modal>
//       ) : (
//         ""
//       )}
//     </div>
//   );
// }

// const mapStateToProps = (state) => ({
//   loader: state.event.loader,
//   events: state.event.events,
// });

// export default connect(mapStateToProps, { fetchEvents })(EventCalendar);

import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Eventcalendermain from "./Eventcalendermain";
import Taskcalendar from "./Taskcalendar";
import Section from "./layouts/Section";

import { Accordion, Spinner } from "react-bootstrap";

const EventCalendar = (props) => {
  const { events, loader } = props;
  const [activeTab, setActiveTab] = useState('event');

  const style_nav = {
    background: "#E6E6E9",
    fontWeight: "700",
    color: "#000000",
    fontFamily: "'Playfair Display', serif"
  }

  const style_ico = {
    color: "#000",
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  }

  return (
    <div className="event-calendar-wrapper">
      {loader ? <div className="fix-loader"><Spinner /></div> : ''}
      {/* Body content are started from here */}
      <Section
        allNotification={false}
        searchPopup={false}
        title={"Event Calendar"}
      />

      <div className="calendar-area">
        <div className="container mt-2">
          <div className="row">
            <Nav fill variant="tabs" style={{ gap: "7px" }}>
              <Nav.Item style={{ display: "flex" }}>
                <Nav.Link
                  style={style_nav}
                  active={activeTab === 'event'}
                  onClick={() => handleTabClick('event')}
                >
                  <i className="fa fa-list" aria-hidden="true" style={style_ico}></i> Event Calendar
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  style={style_nav}
                  active={activeTab === 'task'}
                  onClick={() => handleTabClick('task')}
                >
                  <i className="bi bi-person-circle" style={style_ico}></i> Task Calendar
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          {activeTab === 'event' && (
            <div>
              <Eventcalendermain events={events} loader={loader} />
            </div>
          )}
          {activeTab === 'task' && (

            <>
              <Taskcalendar events={events} loader={loader} />
            </>

          )}
        </div>
      </div>
    </div>

  )
}


const mapStateToProps = (state) => ({
  loader: state.event.loader,
  events: state.event.events,
});

export default connect(mapStateToProps,)(EventCalendar);
