import React, {Component} from "react";
import { Image } from "semantic-ui-react";

class AutoNotification extends Component {
    render() {
        return (
            <div className="modal fade fade-modal-nitification" id="overlay">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="ba-bill-pay-inner">
                            <div className="ba-single-bill-pay">
                                <Image src={process.env.PUBLIC_URL + '/assets/img/right-tick.jpeg'} size='medium' centered />
                            </div>
                            <div className="amount-inner">
                                <h3>Your payment is successfull.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AutoNotification;