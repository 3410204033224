import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import './housing.css';

function Housing(props) {

    const {myData} = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }
    const allValues =
        Number(parsedData.housing.rentPayment) +
        Number(parsedData.housing.homeRent) +
        Number(parsedData.housing.houseFees) +
        Number(parsedData.housing.lownCare)+
        Number(parsedData.housing.houseHold)+
        Number(parsedData.housing.maintenance)+
        Number(parsedData.housing.security);

    props.sendDataHo(allValues);


    return (
        <div className='dhhejk'>
            <div className="daildy_living">Housing</div>
            <Form>
                <div className="living_state">
                    <Form.Label className="adksk">Rent Payment</Form.Label>
                    <input
                        type="text"
                        className=" input_style jadj"
                        name="rentPayment"
                        value={parsedData.housing.rentPayment}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Home/Rental Insurance</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="homeRent"
                        value={parsedData.housing.homeRent}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">HOA Fees</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="houseFees"
                        value={parsedData.housing.houseFees}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Lawn Care</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="lownCare"
                        value={parsedData.housing.lownCare}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Household Help</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="houseHold"
                        value={parsedData.housing.houseHold}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Maintenance (Regular)</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="maintenance"
                        value={parsedData.housing.maintenance}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Security</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="security"
                        value={parsedData.housing.security}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css_"></Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    )
}

export default Housing;