import React, {Component} from "react";
// import {Link} from "react-router-dom";
import History from "../../History";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {search: false};
    }

    render() {
        const searchPopup = (
            <>
                <div className={this.state.search ? 'body-overlay active' : 'body-overlay'} id="body-overlay" onClick={() => this.setState({search: false})} />
                <div className={this.state.search ? 'search-popup active' : 'search-popup'} id="search-popup">
                    <form className="search-form">
                        <div className="form-group">
                            <input type="text" className="form-control" placeholder="Search....." />
                        </div>
                        <button type="button" className="submit-btn"><i className="fa fa-search" /></button>
                    </form>
                </div>
            </>
        );

        return (
            <>
                {this.props.searchPopup ? searchPopup : ''}
                <div id="header-section" className="header-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 col-3">
                                <a onClick={(e) => { e.preventDefault(); History.goBack() }} className="menu-back-page home-clicked" href={'/#'}>
                                    <i className="fa fa-angle-left" />
                                </a>
                            </div>
                            <div className="col-sm-4 col-6 text-center">
                                <div className="page-name">{this.props.title}</div>
                            </div>
                            <div className="col-sm-4 col-3 text-right">
                                {this.props.searchPopup ? (
                                    <div className="search header-search" onClick={() => this.setState({search: true})}>
                                        <i className="fa fa-search" />
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Header;