import React from "react";
import ReactApexChart from "react-apexcharts";

function AnnualActucalGraph(props) {
	const { myData } = props;
	let allData;
	try {
		allData = JSON.parse(myData.form_data);
	} catch (error) {
		console.error("Error parsing JSON data:", error);
    }

	const values_1_ = Object.values(allData.dailyLiving);
	const totaleAllSumDailyliving = values_1_.reduce(
        (accumulator, currentValue) =>
			accumulator + (parseFloat(currentValue) || 0),
            0,
    );
    const values_2 = Object.values(allData.Entertainment);
		const totaleAllSumEntertain = values_2.reduce(
			(accumulator, currentValue) =>
				accumulator + (parseFloat(currentValue) || 0),
			0,
    );
    const values_3 = Object.values(allData.housing);
		const totaleAllSumHousing = values_3.reduce(
			(accumulator, currentValue) =>
				accumulator + (parseFloat(currentValue) || 0),
			0,
		);

	const incomeData = [totaleAllSumDailyliving, totaleAllSumEntertain, totaleAllSumHousing];
	const chartincomeOptions = {
		chart: {
			toolbar: {
				show: false,
			},
		},
		labels: ["Dailyliving", "Entertainment", "Housing"],
		dataLabels: {
			enabled: false,
		},
		plotOptions: {
			pie: {
				donut: {
					labels: {
						show: true,
						total: {
							show: true,
							label: "Total",
							formatter: function (w) {
								let total = w.config.series.reduce(
									(accumulator, currentValue) =>
										accumulator + (parseFloat(currentValue) || 0),
									0,
								);
								return "$" + total;
							},
							fontSize: "22px",
						},
					},
				},
			},
		},
	};



	return (
		<div>
			<h5>Annual Actual Expenses Details $</h5>
			<hr />
			<ReactApexChart
				options={chartincomeOptions}
				series={incomeData}
				type="donut"
				height={200}
			/>
		</div>
	);
}

export default AnnualActucalGraph;
