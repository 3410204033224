import React from 'react'
import Section from './layouts/Section';
import Footer from './layouts/Footer';
import Links from './Links';

function ChatGpt() {
    return (
        <div>
            <Section
                allNotification={false}
                searchPopup={false}
                title={"ChatGpt"}
            >
                <iframe
                    id="frame"
                    name="frame"
                    width="100%"
                    style={{ height: "100vh" }}
                    src={Links.gptUrl}
                ></iframe>
            </Section>
            <Footer />
        </div>
    )
}

export default ChatGpt;
