import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function MonthlyPosition(props) {
    const { getDataOt_, getDataOb_, getDatarm_, getTc_, getDl_, getDo_, getEdu_, getEt_, getHo_, getHe_, getIn_, getIr_, getTr_, getUt_ } = props;

    const totaleValue = {
        getDataOt_,
        getDataOb_,
        getDatarm_,
        getTc_,
        getDl_,
        getDo_,
        getEdu_,
        getEt_,
        getHo_,
        getHe_,
        getIr_,
        getTr_,
        getUt_
    }
    const values_ = Object.values(totaleValue);
    const totaleAllSum = values_.reduce(
        (accumulator, currentValue) =>
            accumulator + (parseFloat(currentValue) || 0),
        0
    );

    const totalIncome = getIn_ - totaleAllSum;


    return (
        <div className="main_adkj">
            <h5 className="title_cash">Total Income</h5>
            <div className="daildy_living">Total Income</div>
            <div className="living_state">
                <Form.Label className="lable_css_">Net Income</Form.Label>
                <input
                    className="input_style_ jadj jkdju"
                    type="text"
                    name="totaleHousing"
                    value={getIn_}
                />
            </div>
            <div className="living_state">
                <Form.Label className="adksk">Net Expense</Form.Label>
                <input
                    className="input_style_ jadj jkdju"
                    type="text"
                    name="totaleHousing"
                    value={totaleAllSum}
                />
            </div>
            <div className="living_state">
                <Form.Label className="lable_css_">Total Income</Form.Label>
                <input
                    className="input_style_ jadj jkdju"
                    type="text"
                    name="totaleHousing"
                    value={totalIncome}
                />
            </div>
        </div>
    );
}

export default MonthlyPosition;
