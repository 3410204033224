import React from "react";
import { taxTypes } from "../../data/tax-types";
import { Form } from "react-bootstrap";

function TaxIdsSelectBox(props) {
  const { formState, setFormState } = props;

  return (
    <Form.Select
      name="tax_type"
      aria-label="Tax Type"
      value={formState.tax_id.type}
      onChange={(e) =>
        setFormState({
          ...formState,
          tax_id: { ...formState.tax_id, type: e.target.value },
        })
      }
    >
      <option value="">Select Tax Type</option>
      {taxTypes.map((taxType) => (
        <option value={taxType}>{taxType}</option>
      ))}
    </Form.Select>
  );
}

export default TaxIdsSelectBox;
