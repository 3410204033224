import React from 'react';
import moment from 'moment';

function GoogleEventModal (props) {

    const { event } = props;

    return (
        <div className="modal fade" id="eventModal" tabIndex="-1" aria-labelledby="eventModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div>
                            <h6 className="modal-title fs-5" id="eventModalLabel"> 
                                <span><i className="bi bi-circle-fill _circleicon"></i></span>
                                { event && event.title}
                            </h6>
                            <div>
                                <ul id="_bsmodal">
                                    <li className="_bsmodalli"><h6>{event && moment(event.start).format('dddd, MMMM Do YYYY, h:mm:ss a')}</h6></li>
                                </ul>
                            </div>
                        </div>                       
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="container">
                            {event && event.location ?
                            <div className="row align-items-start">
                                <div className="col-3">
                                    <i className="bi bi-geo-alt-fill _bsiconbi"></i>
                                </div>
                                <div className="col-9">
                                    <p className="_bsmodalp">{event.location}</p>
                                </div>
                            </div>
                            : ''}
                            <div className="row align-items-start">
                                <div className="col-3">
                                    <i className="bi bi-text-left _bsiconbi"></i>
                                </div>
                                <div className="col-9">
                                    <p className="_bsmodalp">{ event && event.description }</p>
                                </div>
                            </div>
                            <div className="row align-items-start">
                                <div className="col-3">
                                    <i className="bi bi-alarm _bsiconbi"></i>
                                </div>
                                <div className="col-9">
                                    <p className="_bsmodalp"> {event && event.status} </p>
                                </div>
                            </div>
                            <div className="row align-items-start">
                                <div className="col-3">
                                    <i className="bi bi-person-check-fill _bsiconbi"></i>
                                </div>
                                <div className="col-9">
                                    <p className="_bsmodalp"> { event && event.creator.email } </p>
                                </div>
                            </div>
                            <div className="row align-items-start">
                                <div className="col-3">
                                    <i className="bi bi-link-45deg _bsiconbi"></i>
                                </div>
                                <div className="col-9">
                                    <p className="_bsmodalp"><a href={ event && event.link}> Click here to connect</a> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GoogleEventModal;