import React, { useRef, useLayoutEffect, forwardRef } from "react";

const width = "100%";
const height = "100%";

const AgoraVideoPlayer = forwardRef((props, ref) => {
  const { videoTrack, audioTrack, config, onClick = () => {} } = props;

  const vidDiv = useRef(null);

  useLayoutEffect(() => {
    if (vidDiv.current !== null) {
      videoTrack?.play(vidDiv.current, config);
    }
    return () => {
      videoTrack?.stop();
    };
  }, [videoTrack]);

  useLayoutEffect(() => {
    audioTrack?.play();
    return () => {
      audioTrack?.stop();
    };
  }, [audioTrack]);

  return (
    <div
      ref={vidDiv}
      style={{
        width,
        height,
        minWidth: "320px",
        minHeight: "180px",
      }}
      onClick={onClick}
    />
  );
});

export default AgoraVideoPlayer;
