import React, { useState } from 'react';
import Section from './layouts/Section';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './gcalendar.css';
import auth from '../api/auth';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleEventModal from './google-event-modal';
import { Loader } from 'semantic-ui-react';

const localizer = momentLocalizer(moment);

function GoogleCalendar() {

    const [date, setDate] = useState(moment().format('LL'));
    const [events, setEvents] = useState([]);
    const [currentEvent, setCurrentEvent] = useState();

    const [loading, setLoading] = useState(true);
    const [initializing, setInitializing] = useState(false);
    const [queryParameters, setQueryParameters] = useState();

    const location = useLocation()

    useEffect(() => {

        const queryParameters = new URLSearchParams(location.search)
        setQueryParameters(queryParameters);

        fetchEvents();
        window.addEventListener('message', function (e) {
            if (e.data === 'fetchEvents') {
                fetchEvents();
            }
        }, false);

        const link = document.createElement("link");
        link.async = true;
        link.defer = true;
        link.rel = 'stylesheet';
        link.href = "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css";
        document.head.appendChild(link);

        return () => { }
    }, [location])

    const setCurrentDate = (type) => {
        switch (type) {
            case 'prev':
                setDate(moment(date).add(-1, 'days').format('LL'))
                break;
            case 'next':
                setDate(moment(date).add(1, 'days').format('LL'))
                break;
            default:
                setDate(moment().format('LL'));
        }
    }

    useEffect(() => {

        function initialize() {

            if (queryParameters === undefined) {
                return;
            }
            else {

                if (queryParameters.get('code') !== null) {
                    setInitializing(true);
                    const AUTH_TOKEN = window.localStorage.getItem('AUTH_TOKEN');
                    const redirect_uri = window.location.href.split("?")[0];
                    auth.post('/calendar/initialize?code=' + queryParameters.get('code'), {redirect_uri:redirect_uri}, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${AUTH_TOKEN}`
                        }
                    }).then((res) => {
                        window.opener.postMessage('fetchEvents', "*");
                        setInitializing(false);
                        closeMe()
                    }).catch(e => {
                        setInitializing(false);
                        console.log('error', e);
                        closeMe();
                    })
                }
            }
        }

        initialize();

        return () => { }


    }, [queryParameters])

    const closeMe = () => {
        var win = window.open("", "_self");
        win.close();
    }

    const handleAuthClick = (e) => {

        e.preventDefault();
        const AUTH_TOKEN = window.localStorage.getItem('AUTH_TOKEN');
        const redirect_uri = window.location.href;

        auth.post('/calendar/authurl', {redirect_uri:redirect_uri}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        }).then((res) => {
            window.open(res.data.url, 'Google Authentication', 'width=400,height=400,toolbar=no,menubar=no,location=no')
        }).catch(e => {
            console.error(e);
        })
    }

    const fetchEvents = async () => {

        document.getElementById('authwrap').style.display = 'none';

        const AUTH_TOKEN = window.localStorage.getItem('AUTH_TOKEN');

        auth.get('/calendar/events', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${AUTH_TOKEN}`
            }
        }).then((res) => {
            const events = res.data.items;
            const _events = events.map((e) => ({
                id: e.id,
                title: e.summary,
                description: e.description,
                location: e.location,
                start: e.start.dateTime === null ? new Date() : new Date(e.start.dateTime),
                end: e.end.dateTime === null ? new Date() : new Date(e.end.dateTime),
                link: e.htmlLink,
                status: e.status,
                creator: e.creator
            }))

            setEvents(_events);
            setLoading(false);

        }).catch(e => {
            document.getElementById('authwrap').style.display = 'block';
            setLoading(false);
            console.error(e);
        })
    }

    return (
        <div className=''>
            <Section allNotification={false} searchPopup={false} title={'Google Calendar'}>
                {initializing ? <div style={{ position: 'fixed', height: '100vh', width: '100%', zIndex: '999', background: 'rgba(255, 255, 255, .8)' }}><Loader /></div> : ''}
                <div style={{ height: '60px' }}></div>
                <div style={{ padding: '10px' }} className='container'>
                    {loading ? 'Please wait while we are loading ...' : ''}
                    <div id="authwrap" style={{ display: 'none' }}>
                        <p>If you are visiting this page for the first time or your previous login expired, you need to authorize your google calendar access. Click on below button to authorize.</p>
                        <button id="authorize_button" onClick={handleAuthClick} className="btn btn-primary">Authorize</button>
                    </div>
                    <div className="c-toolbar">
                        <div className="c-date">
                            {date}
                        </div>
                        <div className="c-navigation">
                            <button onClick={(e) => setCurrentDate('prev')} className="btn"><i className="fa fa-arrow-left"></i></button>
                            <button onClick={(e) => setCurrentDate('today')} className="btn today_cal">Today</button>
                            <button onClick={(e) => setCurrentDate('next')} className="btn"><i className="fa fa-arrow-right"></i></button>
                        </div>
                    </div>
                    <Calendar
                        localizer={localizer}
                        defaultView={"day"}
                        toolbar={false}
                        events={events}
                        onNavigate={(_date) => setDate(_date)}
                        defaultDate={moment().format('LL')}
                        date={date}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 650 }}
                        onSelectEvent={(e) => {
                            setCurrentEvent(e);
                            window.jQuery('#eventModal').modal();
                        }}
                    />
                </div>
                <div style={{ height: '20px' }}></div>
                <GoogleEventModal event={currentEvent} />
            </Section>
        </div>
    )
}

export default GoogleCalendar;