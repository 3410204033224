export const FETCH_EVENTS = 'FETCH_EVENTS'
export const EVENTS_ERRORS = 'EVENT_ERRORS'
export const ADD_EVENT = 'ADD_EVENT'
export const ADD_ERROR = 'ADD_ERROR'
export const FETCH_TASKS = 'FETCH_TASKS';
export const TASKS_ERRORS = 'TASKS_ERRORS';

export const fetchTasksSuccess = (tasks) => ({
    type: FETCH_TASKS,
    payload: tasks,
  });
  