import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { connect } from 'react-redux';
import Section from '../layouts/Section';
import '../../components/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import auth from '../../api/auth';
import AvertiLoader from '../../loader';
import BankAuth from '../Bank Auth/BankAuth';
import { toast } from 'react-toastify';

function BankAccount(props) {

  const [loader, showLoader, hideLoader] = AvertiLoader()

  const { user } = props;
  const [open, setOpen] = useState(false)
  const [modalopen, setModalOpen] = useState(false)
  const [accounts, setAccounts] = useState([]);
  const [loading, setloading] = useState(false);

  const [errors, setErrors] = useState({});

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.bank_name === '') {
      errors.bank_name = "Please Enter Your Bank Name";
    }
    if (inputValues.account_holder_name === '') {
      errors.account_holder_name = "Please Enter Your Account Holder Name";
    }
    if (inputValues.account_number === '') {
      errors.account_number = "Please Enter Your Accunt Number";
    }
    if (inputValues.routing_number === '') {
      errors.routing_number = "Please Enter Your Rounting Number";
    }
    return errors;
  };
  const handleChange = (e) => {
    setFormData({ ...formdata, [e.target.name]: e.target.value });
  };

  const openUploader = (e) => {

    e.preventDefault();

    setFormData({
      iban: '',
      bic: '',
      bank_name: '',
      account_holder_name: '',
      account_number: '',
      routing_number: '',
      currency: 'USD',
      country: 'US',
      display_name: '',
      is_default: false
    })
    setOpen(true)
  }

  const [id, setId] = useState(null)

  const openBankUpdate = (e, account) => {

    e.preventDefault();

    setFormData({
      iban: account.iban,
      bic: account.bic,
      bank_name: account.bank_name,
      account_holder_name: account.account_holder_name,
      account_number: account.account_number,
      routing_number: account.routing_number,
      currency: 'USD',
      country: 'US',
      display_name: account.display_name,
      is_default: false
    })

    setId(account.id)
    setOpen(true)
  }

  const handleClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return
    setId(null)
    setOpen(false)
    setErrors(false)
    setloading(false)
  }
  const modalClose = (e, reason) => {
    if (reason && reason === 'backdropClick') return
    setModalOpen(false)
    setErrors(false)
    setloading(false)
  }

  useEffect(() => {

    const x_monite_entity_id = user.userDetail.monite_entity_id;
    callApi(x_monite_entity_id)

  }, [user])

  const callApi = (x_monite_entity_id) => {

    if (x_monite_entity_id === undefined) {
      return;
    }

    showLoader()

    auth.get(`bank-accounts/${x_monite_entity_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`,
        'X-Monite-Token': `${window.localStorage.getItem('x_monite_token')}`,
      }
    })
      .then((res) => {
        setAccounts(res.data.data);
        hideLoader()
      })
      .catch((error) => {
        console.error('error', error);
        hideLoader()
      })
    console.log(accounts);
  }


  const addAccount = (event) => {
    //setErrors(validateValues(formdata));
    const x_monite_entity_id = user.userDetail.monite_entity_id;

    showLoader()

    auth.post(`bank-accounts/${x_monite_entity_id}`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`,
        'X-Monite-Token': `${window.localStorage.getItem('x_monite_token')}`,
      }
    })
      .then((res) => {
        setTimeout(() => {
          setOpen(false)
          hideLoader()
          callApi(x_monite_entity_id)
        }, 2000)
      })
      .catch((error) => {
        console.error('error', error);
        hideLoader()
      })
  }

  const updateAccount = (event) => {

    setErrors(validateValues(formdata));
    const x_monite_entity_id = user.userDetail.monite_entity_id;

    showLoader()

    auth.patch(`bank-accounts-update/${id}/${x_monite_entity_id}`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`,
        'X-Monite-Token': `${window.localStorage.getItem('x_monite_token')}`,
      }
    })
      .then((res) => {
        setTimeout(() => {
          setOpen(false)
          hideLoader()
          callApi(x_monite_entity_id)
        }, 2000)
      })
      .catch((error) => {
        console.error('error', error);
        hideLoader()
      })
  }


  const startVerification = (event) => {

    const x_monite_entity_id = user.userDetail.monite_entity_id;
    const payload = {
      client_name: user.userDetail.name
    }

    showLoader()

    auth.post(`/bank-accounts/verification-start/${x_monite_entity_id}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`
      }
    })
      .then((res) => {
        plaidAccess(res.data.airwallex_plaid.link_token);
        hideLoader()
      })
      .catch((error) => {
        hideLoader()
        console.error('error', error);
      })
  }

  const makeDefault = (account) => {
    const x_monite_entity_id = user.userDetail.monite_entity_id;

    auth.post(`bank-accounts-default/${account.id}/${x_monite_entity_id}`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`,
        'X-Monite-Token': `${window.localStorage.getItem('x_monite_token')}`,
      }
    })
      .then((res) => {
        callApi(x_monite_entity_id)
      })
      .catch((error) => {
        console.error('error', error);
      })
  }


  const [formdata, setFormData] = useState({
    iban: '',
    bic: '',
    bank_name: '',
    account_holder_name: '',
    account_number: '',
    routing_number: '',
    currency: 'USD',
    country: 'US',
    display_name: '',
    is_default: false
  });

  const [plaidData, setPlaidData] = useState(null);

  const plaidAccess = (public_token) => {

    const handler = window.Plaid.create({
      token: public_token,
      onSuccess: function (public_token, metadata) {
        console.log("public token is:", public_token);
        console.log('metadata', metadata)
        //History.push('/bank-authorize')
        setModalOpen(true)
        setPlaidData(metadata)

      },
      onExit: function (err, metadata) {
        console.log("onexit invoked");
      },
      onEvent: function (eventName, metadata) {
        console.log("event name is:", eventName);
      },
    });

    handler.open();
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    console.log('>', plaidData)
    const x_monite_entity_id = user.userDetail.monite_entity_id;
    const payload = {

      "type": "airwallex_plaid",
      "airwallex_plaid": {
        "public_token": plaidData.public_token,
        "institution": {
          "id": plaidData.institution.institution_id,
          "name": plaidData.institution.name
        },
        "account": {
          "id": plaidData.account.id,
          "name": plaidData.account.name,
          "mask": plaidData.account.mask
        },
        "mandate": {
          "email": "vishal@gmail.com",
          "signatory": "Vishal",
          "type": "us_ach_debit",
          "version": "1.0"
        }
      }
    }

    showLoader()

    auth.post(`/bank-accounts/verification-complete/${x_monite_entity_id}`, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`
      }
    })
      .then((res) => {
        toast.success('Bank complete verification has been done successfully')
        hideLoader()
        setModalOpen(false)
        callApi(x_monite_entity_id)
      })
      .catch((error) => {
        hideLoader()
        console.error('error', error);
      })
  }

  const deleteBank = (e) => {
    alert("Delete Process will be start");
  }

  return (

    <Section allNotification={false} title={'Bank Account'}>

      {loader}

      <div className="cart-area pd-top-36 mg-bottom-40">
        <div className="container">
          <div className="section-title mb-2 carts-section">
            <h3 className="title d-flex">
              <span>Bank Accounts</span>
            </h3>
            <div className="title w-auto p-3 float-right">
              <Button variant="primary" onClick={startVerification}>
                <i className='fa fa-plus'></i> New Account
              </Button>
            </div>
          </div>

          {accounts.map((account, i) =>

            <div class="d-flex justify-content-center container text-white mt-5">
              <div class="bank card p-2 px-3 py-3">
                <div class="d-flex justify-content-between align-items-center"><img className='no-boxshadow' src="https://i.imgur.com/8ANWXql.png" width="20" height="20" /><img className='no-boxshadow' src="https://www1.bac-assets.com/homepage/spa-assets/images/assets-images-global-logos-bac-logo-v2-CSX3648cbbb.svg" width="150" /></div>
                <div class="mt-3"><span class="mr-3">****</span><span class="mr-3">****</span><span class="mr-3">****</span><span class="mr-2">{account.account_number.substr(-4)}</span><a href={'/#'} style={{ height: 'auto', position: 'relative', top: '0px', right: '0px', boxShadow: 'none' }} className='ml-2 p-0 btn btn-xs' onClick={(e) => openBankUpdate(e, account)} color='primary' size='small' ><i className='fa fa-pencil'></i></a>
                  <span style={{ height: 'auto', position: 'relative', top: '0px', right: '0px', boxShadow: 'none' }} className='ml-2 p-0 btn btn-xs' onClick={(e) => deleteBank()} color='primary' size='small' ><i className='fa fa-trash'></i></span></div>
                <div class="d-flex justify-content-between card-details mt-3 mb-3">
                  <div class="d-flex flex-column"><span class="light">Account Holder</span><span>{account.account_holder_name}</span></div>
                  <div class="d-flex flex-row">
                    <div class="d-flex flex-column mr-3"><span class="light">Country</span><span>US</span></div>
                    <div class="d-flex flex-column"><span class="light">Currency</span><span>USD</span></div>
                  </div>
                </div>
              </div>
            </div>

          )}
          <div className='pt-3'></div>
        </div>
      </div>


      <Modal size="lg" show={modalopen} onHide={modalClose} backdrop="static">
        <ModalBody><BankAuth bank={plaidData} handleClose={modalClose} handleSubmit={handleSubmit} /></ModalBody>
      </Modal>

      <Modal size='lg' show={open} onHide={handleClose}>
        <ModalHeader className='form_header' closeButton={'abc'}>
          <ModalTitle>{id ? 'Update' : 'Add'} Account</ModalTitle>
        </ModalHeader>
        <ModalBody>

          <div className='row'>
            <div className='col'>
              <Form.Group className="mb-3">
                <Form.Label>Bank Name*</Form.Label>
                <Form.Control style={{ border: errors.bank_name ? "1px solid red" : null }} type="text" name='bank_name' value={formdata.bank_name} onChange={handleChange} placeholder="Name" required />
                {errors.bank_name ? (
                  <p style={{ color: 'red', fontSize: '12px' }} className="error">Please Enter Your Bank Name</p>
                ) : null}
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Group className="mb-3">
                <Form.Label>Account Holder Name*</Form.Label>
                <Form.Control style={{ border: errors.account_holder_name ? "1px solid red" : null }} type="text" name='account_holder_name' value={formdata.account_holder_name} onChange={handleChange} placeholder="Account Holder Name" required />
                {errors.account_holder_name ? (
                  <p style={{ color: 'red', fontSize: '12px' }} className="error">Please Enter Your Account Holder Name</p>
                ) : null}
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <Form.Group className="mb-3">
                <Form.Label>Account Number*</Form.Label>
                <Form.Control style={{ border: errors.account_number ? "1px solid red" : null }} type="text" name='account_number' value={formdata.account_number} onChange={handleChange} placeholder="Account Number" required />
                {errors.account_number ? (
                  <p style={{ color: 'red', fontSize: '12px' }} className="error">Please Enter Your Account Number</p>
                ) : null}
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Group className="mb-3">
                <Form.Label>Routing Number*</Form.Label>
                <Form.Control style={{ border: errors.routing_number ? "1px solid red" : null }} type="text" name='routing_number' value={formdata.routing_number} onChange={handleChange} placeholder="Routing Number" required />
                {errors.routing_number ? (
                  <p style={{ color: 'red', fontSize: '12px' }} className="error">Please Enter Your Rounting Number</p>
                ) : null}
              </Form.Group>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <Form.Group className="mb-3">
                <Form.Label>IBAN</Form.Label>
                <Form.Control type="text" name='iban' value={formdata.iban} onChange={(e) => setFormData({ ...formdata, 'iban': e.target.value })} placeholder="Enter IBAN" required />
              </Form.Group>
            </div>
            <div className='col'>
              <Form.Group className="mb-3">
                <Form.Label>BIC</Form.Label>
                <Form.Control type="text" name='bic' value={formdata.bic} onChange={(e) => setFormData({ ...formdata, 'bic': e.target.value })} placeholder="BIC" required />
              </Form.Group>
            </div>
          </div>
          <Form.Group className="mb-3 ml-3">
            <Form.Label><Form.Check type="checkbox" name='is_default' checked={formdata.is_default} onChange={(e) => setFormData({ ...formdata, 'is_default': e.target.value })} required />&nbsp;Sat as Default</Form.Label>
          </Form.Group>
        </ModalBody>
        <ModalFooter>
          {id ? <Button onClick={updateAccount} variant="primary">
            Update
          </Button> :
            <Button onClick={addAccount} variant="primary">
              Submit
            </Button>}
        </ModalFooter>
      </Modal>
    </Section>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userDetail,
  ...state,
});

export default connect(mapStateToProps)((BankAccount));