import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { connect } from 'react-redux';
import Section from './layouts/Section';
import '../components/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import auth from '../api/auth';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AddPayable(props) {

  const { user } = props;
  const [open, setOpen] = useState(false)
  const [vendors, setVendors] = useState([]);
  const [payables, setPayables] = useState([]);
  const [vendortype, setVendorType] = useState([]);
  const [currencytype, setCurrencyType] = useState([]);

  const [processing, setProcessing] = useState(false)

  const openUploader = () => {

    setFormData({
      counterpart: {
        type: "individual",
        individual: {
          first_name: "",
          last_name: "",
          title: "",
          is_vendor: false,
          is_customer: true,
          phone: "",
          email: "",
          residential_address: {
            country: "",
            city: "",
            postal_code: "",
            state: "",
            line1: "",
            line2: ""
          }
        },
        created_automatically: false
      },
      tax_id: {
        type: "",
        value: ""
      },
      bank_account: {
        name: "",
        iban: "",
        bic: ""
      }
    })
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return

    setOpen(false)
  }

  useEffect(() => {

    const x_monite_entity_id = user.userDetail.monite_entity_id;

    callApi(x_monite_entity_id)

  }, [user])

  const callApi = (x_monite_entity_id) => {

    if (x_monite_entity_id === undefined) {
      return;
    }

    auth.get(`payables/${x_monite_entity_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`,
        'X-Monite-Token': `${window.localStorage.getItem('x_monite_token')}`,
      }
    })
      .then((res) => {
        setPayables(res.data.data);
      })
      .catch((error) => {
        console.error('error', error);
      })

    auth.get(`monite/vendors`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`,
      }
    })
      .then((res) => {
        setVendors(res.data);
      })
      .catch((error) => {
        console.error('error', error);
      })
  }

  const addBills = (event) => {
    const x_monite_entity_id = user.userDetail.monite_entity_id;
    setProcessing(true)
    event.preventDefault();
    const formdata = new FormData(event.target);
    const vendor = vendors.filter((vendor) => vendor.id === formdata.get('counterpart_id'));
    console.log("list", vendor);
    const v = vendor[0];
    formdata.append('counterpart_name', v.first_name + ' ' + v.last_name);
    formdata.append('counterpart_tax_id', v.tax_ids[0].tax_value);
    formdata.append('counterpart_address[country]', v.addresses[0].country.toUpperCase());
    formdata.append('counterpart_address[state]', v.addresses[0].state);
    formdata.append('counterpart_address[city]', v.addresses[0].city);
    formdata.append('counterpart_address[line1]', v.addresses[0].line1);
    formdata.append('counterpart_address[line2]', v.addresses[0].line2);
    formdata.append('counterpart_address[postal_code]', v.addresses[0].postal_code);

    formdata.delete('counterpart_id');

    auth.post(`payables/upload-with-data/${x_monite_entity_id}`, formdata, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${window.localStorage.getItem('AUTH_TOKEN')}`,
        'X-Monite-Token': `${window.localStorage.getItem('x_monite_token')}`,
      }
    })
      .then((res) => {
        setProcessing(false)
        setTimeout(() => {
          setOpen(false)
          callApi(x_monite_entity_id)
        }, 2000)
      })
      .catch((error) => {
        setProcessing(false)
        console.error('error', error);
      })
  }

  const form_data = {
    id: null,
    invoice: '',
    currency: '',
    issued_at: '',
    due_date: '',
    document_id: '',
    counterpart_tax_id: '',
    counterpart_name: '',
    country: '',
    city: '',
    state: '',
    line1: '',
    line2: '',
    postal_code: ''
  }
  const [formdata, setFormData] = useState(form_data);




  return (

    <Section allNotification={false} title={'Payables(Bills)'}>

      <div className="cart-area pd-top-36 mg-bottom-40">
        <div className="container">
          <div className="section-title mb-2 carts-section">
            <h3 className="title d-flex"><span>Bills List</span> <a style={{ height: 'auto', position: 'relative', top: '5px' }} className='ml-2 p-0 btn btn-xs' onClick={openUploader} color='primary' size='small' ><i className='fa fa-plus-circle'></i></a></h3>
          </div>

          {payables.map((payable, i) =>
            <div key={payable} id={"bg1" + i % 4} className="ba-cart-inner mb-3" style={{ backgroundImage: "url(assets/img/bg/10.png)", position: 'relative' }}>
              <p>Biller</p>
              <h5>{payable.counterpart_name}</h5>
              <div className="row">
                <div className="col-6">
                  <p>Due Date</p>
                  <h5>{payable.due_date}</h5>
                </div>
                <div className="col-6">
                  <p>Amount</p>
                  <h5>{payable.amount}</h5>
                </div>
              </div>
            </div>
          )}
          <div className='pt-3'></div>
        </div>
      </div>

      <Modal show={open} onHide={handleClose}>
        <Form method='post' onSubmit={addBills}>
          <ModalHeader className='form_header' closeButton={'abc'}>
            <ModalTitle>Add Bill</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Form.Group className="mb-3">
              <Form.Label>Vendor</Form.Label>
              <Form.Control
                as="select"
                required
                name="counterpart_id"
                value={vendortype}
                onChange={e => {
                  setVendorType(e.target.value);
                }}
              ><option value={''}>Choose Vendor</option>{
                  vendors.map((vendor) => {
                    return (
                      <option key={vendor} value={vendor.id}>{vendor.first_name}&nbsp;{vendor.last_name}</option>
                    )
                  })
                }
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Currency</Form.Label>
              <Form.Control
                as="select"
                name="currency"
                required
                value={currencytype}
                onChange={e => {
                  setCurrencyType(e.target.value);
                }}
              >
                <option value={''}>Choose Currency</option>
                <option value={'EUR'}>EUR</option>
                <option value={'USD'}>USD</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Invoice Number</Form.Label>
              <Form.Control type="text" name='document_id' value={formdata.document_id} onChange={(e) => setFormData({ ...formdata, 'document_id': e.target.value })} placeholder="Invoice Number" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Invoice Document</Form.Label>
              <Form.Control type="file" name='invoice' accept='pdf' />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="text" name='amount' value={formdata.amount} onChange={(e) => setFormData({ ...formdata, 'amount': e.target.value })} placeholder="Amount" pattern='\d{1,5}' required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Due Date</Form.Label>
              {/* <Form.Control type="text" name='due_date' value={formdata.due_date} onChange={(e) => setFormData({...formdata, ['due_date']: e.target.value})} placeholder="Due Date" required /> */}
              <DatePicker dateFormat='yyyy-MM-dd' name='due_date' value={formdata.due_date} selected={formdata.due_date} onChange={(date) => setFormData({ ...formdata, 'due_date': date })} className='form-control' placeholderText='YYYY-MM-DD' required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Issued At</Form.Label>
              {/* <Form.Control type="text" name='issued_at' value={formdata.issued_at} onChange={(e) => setFormData({...formdata, ['issued_at']: e.target.value})} placeholder="Issued At" required /> */}
              <DatePicker dateFormat='yyyy-MM-dd' name='issued_at' value={formdata.issued_at} selected={formdata.issued_at} onChange={(date) => setFormData({ ...formdata, 'issued_at': date })} className='form-control' placeholderText='YYYY-MM-DD' />
            </Form.Group>
          </ModalBody>
          <ModalFooter>
            {
              processing ?
                <Button disabled type='button' variant="secondary">
                  Please wait ...
                </Button>
                :
                <Button type='submit' variant="primary">
                  Submit
                </Button>
            }
          </ModalFooter>
        </Form>
      </Modal>
      {/* <div ref={loadingRef} class="linear-activity">
        <div class="indeterminate"></div>
      </div>
      <DataTableScript editRow={editRow} data={data} /> */}
    </Section>
  );
}

AddPayable.propTypes = {
  intl: PropTypes.object.isRequired
};
const mapStateToProps = (state) => ({
  user: state.user.userDetail,
  ...state,
});

export default connect(mapStateToProps)((AddPayable));