import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function Income(props) {

    const { myData } = props;
    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const allValues =
        Number(parsedData.income.salary_a) +
        Number(parsedData.income.salary_b) +
        Number(parsedData.income.comission_a) +
        Number(parsedData.income.comission_b) +
        Number(parsedData.income.social_security_a) +
        Number(parsedData.income.social_security_b) +
        Number(parsedData.income.pension_a) +
        Number(parsedData.income.pension_b) +
        Number(parsedData.income.annual_bonus_a) +
        Number(parsedData.income.annual_bonus_b) +
        Number(parsedData.income.rental);


    props.sendDataIn(allValues);

    return (
        <div className="main_adkj">
            <h5 className="title_cash">MONTHLY-AFTER-TAX</h5>
            <div className="askwl">
                <div className="daildy_living" style={{ background: "#658b4d" }}>
                    INCOME (Take Home Pay)
                </div>
                <Form>
                    <div className="living_state">
                        <Form.Label className="adksk">Salary</Form.Label>
                        <input
                            type="text"
                            className=" input_style jadj"
                            name="salary_a"
                            value={parsedData.income.salary_a}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="lable_css">Salary</Form.Label>

                        <input
                            className="input_style jkha"
                            type="text"
                            name="salary_b"
                            value={parsedData.income.salary_b}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="adksk">Comission (Average)</Form.Label>
                        <input
                            className="input_style jadj"
                            type="text"
                            name="comission_a"
                            value={parsedData.income.comission_a}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="lable_css">Comission (Average)</Form.Label>
                        <input
                            className="input_style jkha"
                            type="text"
                            name="comission_b"
                            value={parsedData.income.comission_b}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="adksk">Social Security</Form.Label>
                        <input
                            className="input_style jadj"
                            type="text"
                            name="social_security_a"
                            value={parsedData.income.social_security_a}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="lable_css">Social Security</Form.Label>
                        <input
                            className="input_style jkha"
                            type="text"
                            name="social_security_b"
                            value={parsedData.income.social_security_b}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="adksk">Pension</Form.Label>
                        <input
                            className="input_style jadj"
                            type="text"
                            name="pension_a"
                            value={parsedData.income.pension_a}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="lable_css">Pension</Form.Label>
                        <input
                            className="input_style jkha"
                            type="text"
                            name="pension_b"
                            value={parsedData.income.pension_b}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="adksk">Annual Bonus</Form.Label>
                        <input
                            className="input_style jadj"
                            type="text"
                            name="annual_bonus_a"
                            value={parsedData.income.annual_bonus_a}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="lable_css">Annual Bonus</Form.Label>
                        <input
                            className="input_style jkha"
                            type="text"
                            name="annual_bonus_b"
                            value={parsedData.income.annual_bonus_b}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="adksk">Rental(After Expense)</Form.Label>
                        <input
                            className="input_style jadj"
                            type="text"
                            name="rental"
                            value={parsedData.income.rental}

                        />
                    </div>
                    <div className="living_state">
                        <Form.Label className="lable_css_">Total Amount</Form.Label>
                        <input
                            className="input_style_ jadj jkdju"
                            type="text"
                            name="totaleHousing"
                            value={allValues}

                        />
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default Income;
