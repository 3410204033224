import React,{useState} from 'react'
import './homes.css'

const Innerpage2 = (props) => {
  const { handleclose,inputValue,inputValue1,selecteddtBoxie,categorydefine,setItemName,setFormData,formData, imageUrl} = props;
    const [selecteddtBox, setselecteddtBox] = useState(null);
  
    const handleClick = (boxNumber,value) => {
      setselecteddtBox(boxNumber);
      console.log("Brand Name:", inputValue ? inputValue : "");
      console.log("Size of Item:", inputValue1 ? inputValue1 : "");
      console.log("Selected Box Color:", selecteddtBoxie ? selecteddtBoxie : " ");
      console.log("Category",categorydefine ? categorydefine:" ");
      console.log("SubCategory:", value ? value : "no data");  
      setItemName(value);  
      const newData = {
        id: formData.length + 1,
        inputValue,
        inputValue1,
        selecteddtBoxie,
        categorydefine,
        value,
        imageUrl,
        iconsnamep: "PRIVATE",
        iconsnamef: "FAVORITE",
        iconsnamed: "DO NOT STYLE",
        iconsnamea: "ARCHIVE",
      };
      setFormData([...formData, newData]);


      handleclose();

    };
    console.log("formdata",formData)

      return (
        <div>
           <h2 className='htwoing'>What subcategory is your item?</h2>
   
              <div className="container my-4">
              <div className="row md-6">
                        <div className="col-6 col-md-6">
                        <div className={`oo1boxie ${selecteddtBox === 1 ? 'selecteddt' : ''}`} onClick={() => handleClick(1,"DENIM")}>
                            <div className='liopl'>DENIM</div>
                        </div>
                        </div>
                        <div className="col-6 col-md-6">
                        <div className={`oo1boxie ${selecteddtBox === 2 ? 'selecteddt' : ''}`} onClick={() => handleClick(2,"LEGGINGS")}>
                            <div className='liopl'>LEGGINGS</div>
                        </div>
                        </div>
                </div>
                <div className="row md-6">
                        <div className="col-6 col-md-6">
                        <div className={`oo1boxie ${selecteddtBox === 3 ? 'selecteddt' : ''}`} onClick={() => handleClick(3,"PANTS")}>
                            <div className='liopl'>PANTS</div>
                        </div>
                        </div>
                        <div className="col-6 col-md-6">
                        <div className={`oo1boxie ${selecteddtBox === 4 ? 'selecteddt' : ''}`} onClick={() => handleClick(4,"SHORTS")}>
                            <div className='liopl'>SHORTS</div>
                        </div>
                        </div>
                 </div>
                 <div className="row md-6">
                        <div className="col-6 col-md-6">
                        <div className={`oo1boxie ${selecteddtBox === 5 ? 'selecteddt' : ''}`} onClick={() => handleClick(5,"SKIRT")}>
                            <div className='liopl'>SKIRT</div>
                        </div>
                        </div>
                        <div className="col-6 col-md-6">
                        <div className={`oo1boxie ${selecteddtBox === 6 ? 'selecteddt' : ''}`} onClick={() => handleClick(6,"SWEATPANTS")}>
                            <div className='liopl'>SWEATPANTS</div>
                        </div>
                        </div>
                 </div>
                 <div className="row md-6">
                        <div className="col-6 col-md-6">
                        <div className={`oo1boxie ${selecteddtBox === 7 ? 'selecteddt' : ''}`} onClick={() => handleClick(7,"TIGHTS")}>
                            <div className='liopl'>TIGHTS</div>
                        </div>
                        </div>
                    </div>
                    </div>
                    
           
        </div>
  )
}

export default Innerpage2
