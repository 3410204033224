import React, { useState } from "react";
import "./boxie.css";

import Newpage from "./Newpage";
import Modal from "react-bootstrap/Modal";
// import Filter from "../../public/assets/img/flag.jpg"
// import Filter_pro from "./filter_pro";


import TabUnModal from "./TabUnModal";

import Dynamicpage from "./Dynamicpage";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

export default function 
Boxie(props) {
  const {imageUrl,inputValue,itemname, formData,updateFormData,setFormData} = props;
  const [show, setShow] = React.useState(false);
  const [selectProduct, setSelectProduct] = React.useState(null);
  const [selectdynamicProduct, setSelectDynamicProduct] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [showFloatingElements, setShowFloatingElements] = useState(false);


  console.log("selectProduct",selectProduct);
  console.log("selectdynamicProduct",selectdynamicProduct);
  console.log("show",show);
  //fifth modal inside span
  const [dlt, setDlt] = useState(false);
  const handleCuted = () => setDlt(false);
  const handleseted = () => setDlt(true);

  // const handleDeleteConfirmation = () => {
  //   const confirmDelete = window.confirm("Are you sure you want to delete this item?");
  //   if (confirmDelete) {
  //     handleDelete(selectProduct);
  //   }
  // };
// Function to delete item
const handleDelete = (itemToDelete) => {
  const updatedFormData = formData.filter((item) => item !== itemToDelete);
  setFormData(updatedFormData);
  setSelectProduct(null); // Reset selectProduct
  setSelectDynamicProduct(null);
  setShow(false); // Reset show state to false
};


const handleDeleteConfirmation = () => {
  document.getElementById("confirmationnn-box").style.display = "block";
};

const confirmDelete = () => {
  console.log("Item to delete:", selectProduct);
  handleDelete(selectProduct);
  console.log("User clicked Yes");
  document.getElementById("confirmationnn-box").style.display = "none";
};

const cancelDelete = () => {
  // Code to execute if user clicks No
  console.log("User clicked No");
  document.getElementById("confirmationnn-box").style.display = "none";
};


  

  // Function to delete item
  // const handleDelete = (itemToDelete) => {
  //   const updatedFormData = formData.filter((item) => item !== itemToDelete);
  //   setFormData(updatedFormData);
  //   handleclose(); // Close the modal after deletion
  // };

  //fourth modal inside span
  const [hanger, setHanger] = useState(false);
  const handleCuting = () => setHanger(false);
  const handleseing = () => setHanger(true);

  //third modal inside span
  const [dollar, setDollar] = useState(false);
  const handleCut = () => setDollar(false);
  const handlesee = () => setDollar(true);

  //second modal inside span
  const [graph, setGraph] = useState(false);
  const handleCloseing = () => setGraph(false);
  const handleShowing = () => setGraph(true);

  // model inside pic span
  const [showing, setShowing] = useState(false);
  const handletoClose = () => setShowing(false);
  const handletoShow = () => setShowing(true);
  //

  const [hide, setHide] = useState(false);
  const handleClose = () => setHide(false);
  const handleShow = () => setHide(true);

  const handleOpenmodal = () => {
    setOpenModal(true);
  };
  const handleClosemodal = () => {
    setOpenModal(false);
  };

  const handleopen = (value1,value2) => {
    setShow(true);
    setSelectProduct(value1);
    setSelectDynamicProduct(value2)
  };
  const handleclose = () => {
    setShow(false);
    setSelectProduct(null);
    setSelectDynamicProduct(null)
  };
  const toggleFloatingElements = () => {
    setShowFloatingElements(!showFloatingElements);
  };
  const product = [
    {
      id: 1,
      product_image:
        "https://img.freepik.com/free-photo/blue-t-shirt_125540-727.jpg?t=st=1719298421~exp=1719302021~hmac=e0c62f99a60236220f45b9534fcc99d1ba159b1fcfea6c88bb0096022908326d&w=996",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "T-Shirt",
    },
    {
      id: 2,
      product_image:
        "https://img.freepik.com/free-photo/stack-men-shirt_1203-2615.jpg?t=st=1719299010~exp=1719302610~hmac=467e438c4ae320498716704f998726a55881866bae0171ad61c38ea2c76f0a3c&w=996",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "Shirt",
    },
    {
      id: 3,
      product_image:
        "https://img.freepik.com/free-photo/beautiful-men-fashion-fleece-jacket_1203-7648.jpg?t=st=1719299257~exp=1719302857~hmac=90a6f0bf161a1961d1575a4d09e3f8a61b321605533c49e791d6d4456a7b1703&w=996",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "Jacket",
    },
    {
      id: 4,
      product_image:
        "https://img.freepik.com/free-photo/new-socks-isolated-white_1203-2880.jpg?t=st=1719298842~exp=1719302442~hmac=5c9188f27478cc00d81cf38e7f7992d5bd78c22dca474668f477f27446c69ff0&w=996",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "Socks",
    },
    {
      id: 5,
      product_image:
        "https://img.freepik.com/free-photo/fashion-polo-shirt-men_74190-4858.jpg?t=st=1719299135~exp=1719302735~hmac=82eff3561a12901d4d75fe00e733c37ebe0c8203711320c3a5be8f398cb8cb7e&w=996",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "Tshirt",
    },
    {
      id: 6,
      product_image:
        "https://img.freepik.com/free-psd/fashionable-pants-isolated_23-2151336726.jpg?t=st=1719299061~exp=1719302661~hmac=65909f7bfd2dd36c56ff69cea42dbd7a8578ec80fefdd4b1dd5f9e49b69586e2&w=740",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "Trousers",
    },
    {
      id: 7,
      product_image:
        "https://img.freepik.com/free-photo/blue-dress-sleeve-boy-clothing_1203-6478.jpg?t=st=1719298640~exp=1719302240~hmac=6d372415728c2c2add15a5d23d3c596a3fc9985dbb74b0bae2a31f2be049633d&w=996",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "Shirt",
    },
    {
      id: 8,
      product_image:
        "https://img.freepik.com/free-photo/pink-sweater-front_125540-746.jpg?t=st=1719298488~exp=1719302088~hmac=9b50dc2f49025c13855417e9583ee957ef64781fc936f0bd84f5e787f0bcaacd&w=996",
      iconsnamep: "PRIVATE",
      iconsnamef: "FAVORITE",
      iconsnamed: "DO NOT STYLE",
      iconsnamea: "ARCHIVE",
      product_name: "Hood",
    },

  ];
console.log("selectProduct",selectProduct);

// const handleDelete = (itemToDelete) => {
//   const updatedFormData = formData.filter((item) => item !== itemToDelete);
//   setFormData(updatedFormData);
//   handleclose();
// };



// const handleDelete = (itemToDelete) => {
//   const updatedFormData = formData.filter((item) => item !== itemToDelete);
//   setFormData(updatedFormData);
//   setSelectProduct(null); // Reset selectProduct
//   setSelectDynamicProduct(null); 
//   setShow(false); // Reset show state to false
//   handleclose();
// };


console.log("FormData",formData);


  return (
    <div className="maindivie">

      {/* <div className="flier_p">
        <i class="bi bi-filter" className="filter_i" onClick={handleOpenmodal}></i>
          
        
      </div> */}
    

      {Array.isArray(product) &&
        product.map((item) => {
          return (
            
            <div className="col-md-3 col-6 takei">
              <div className="chdiv">
              <div className="imgdiv">
                <img style={{boxShadow:"none"}}
                  src={item.product_image}
                  onClick={() => handleopen(item, 'data1')} 
                />
                <div class="middle">
                  <div className="buttonnn">
                    <div class="texttt">
                      {" "}
                      <i style={{ marginBottom: "4px", fontSize: "15px" }} class="bi bi-eye-slash-fill"></i>

                      <br /> {item.iconsnamep}
                    </div>
                  </div>
                  <div className="buttonnn">
                    <div class="texttt">
                      {" "}
                      <i style={{ marginBottom: "4px", fontSize: "15px" }} class="bi bi-star"></i>

                      <br /> {item.iconsnamef}
                    </div>
                  </div>
                  <div className="buttonnn" onClick={handleShow}>
                    <div class="texttt">
                      <div className="lull">
                        {" "}
                        <div className="lul">!</div>{" "}
                      </div>
                      <br /> {item.iconsnamed}
                    </div>
                  </div>
                  <div className="buttonnn" onClick={handleShow}>
                    <div class="texttt">
                      {" "}
                      <i  style={{ marginBottom: "4px", fontSize: "15px" }} class="bi bi-archive"></i>
                       
                      <br /> {item.iconsnamea}
                    </div>
                  </div>
                </div>
              </div>
              <div>
               <p className="pp">{item.product_name}</p>
              </div>
              </div>
            </div>
            
          );
        })}
      


      <div className="maindivie">
          
        {Array.isArray(formData) &&
        formData.map((item) => {
          return (
            <div className="col-md-3 col-6 takei chdiv" >
  <div className="imgdiv">
    <img style={{boxShadow:"none"}}
      src={item.imageUrl}
      onClick={() => handleopen(item, 'data2')} 
    />
    <div className="middle">
      <div className="buttonnn">
        <div className="texttt">
          <i style={{ marginBottom: "4px", fontSize: "15px" }} className="bi bi-eye-slash-fill"></i>
          <br /> {item.iconsnamep}
        </div>
      </div>
      <div className="buttonnn">
        <div className="texttt">
          <i style={{ marginBottom: "4px", fontSize: "15px" }} className="bi bi-star"></i>
          <br /> {item.iconsnamef}
        </div>
      </div>
      <div className="buttonnn" onClick={handleShow}>
        <div className="texttt">
          <div className="lull">
            <div className="lul">!</div>
          </div>
          <br /> {item.iconsnamed}
        </div>
      </div>
      <div className="buttonnn" onClick={handleShow}>
        <div className="texttt">
          <i style={{ marginBottom: "4px", fontSize: "15px" }} className="bi bi-archive"></i>
          <br /> {item.iconsnamea}
        </div>
      </div>
    </div>
  </div>
  <div className="njnj">
    <p className="pp">{item.inputValue}</p>
  </div>
</div>

          );
        })}

        </div>
        <div className="lioplkjhgf"></div>
      <Modal
        centered
        show={hide}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        style={{ overflow: "hidden" }}
        id="idy"
      >
        <Modal.Body className="bodystyle">
          <div className="item1">
            Are you sure you want to mark this item as
          </div>
          <div className="item2">"DO NOT STYLE"?</div>
          <div className="item1">
            Your stylist will no loner see this item in your
          </div>
          <div className="item2">wardobe.</div>
          <div className="margi"></div>
          <div>
            <div className="footer_bnt_ff">
              <div className="button_name_f">DO NOT STYLE</div>
              <i style={{color:"black"}} class="bi bi-arrow-right"></i>
            </div>
          </div>

          <div className="marg"></div>

          <div onClick={handleClose}>
            <div className="footer_bntt_f">
              <div className="button_name_foot">CANCEL</div>
              <i style={{color:"black"}} class="bi bi-arrow-right"></i>     
             </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        id = "vbnm"
        style={{ overflow: "hidden" }}
        centered
        className="footerid"
        show={show}
        onHide={handleclose}
      >
        {selectdynamicProduct=== 'data1' && (
            <Newpage selectProduct={selectProduct} />
          )}
          {selectdynamicProduct === 'data2' && (
            <Dynamicpage selectProduct={selectProduct} formData={formData} />
          )}
        <Modal.Footer style={{ height: "0px", padding: "0px" }}>
          <div class="floating-container">
            <div class="floating-button" onClick={toggleFloatingElements}>
              {/* Change the plus icon to toggle the floating elements */}
              {showFloatingElements ? (
                <i style={{fontSize:"22px"}} class="bi bi-x"></i>
              ) : (
                <i style={{fontSize:"22px"}} class="bi bi-three-dots-vertical"></i>
              )}
            </div>
            {showFloatingElements && ( // Conditionally render floating elements
              <div class="element-container">
                <span onClick={handletoShow} class="float-element tooltip-left">
                  <i class="material-ic">
                  <i style={{fontSize:"22px", color:"black"}} class="bi bi-pencil-fill"></i>
                  </i>
                </span>

                <span
                  onClick={handleShowing}
                  class="float-element tooltip-left"
                >
                  <i class="material-ic">
                  <i style={{color:"black"}} class="bi bi-graph-up"></i>
                  </i>
                </span>

                <span onClick={handlesee} class="float-element tooltip-left">
                  <i class="material-ic">
                  <i style={{color:"black"}} class="bi bi-currency-dollar"></i>
                  </i>
                </span>

                <span onClick={handleseing} class="float-element">
                  <i class="material-ic">
                  <i style={{fontSize:"22px"}} class="bi bi-option"></i>
                  </i>
                </span>

                <span onClick={handleDeleteConfirmation} class="float-element">
                  <i class="material-ic">
                  <i style={{fontSize:"22px"}} class="bi bi-trash3-fill"></i>
                  </i>
                </span>
                <div id="confirmationnn-box">
                  <div className="diviie234">
                  Are you sure you want to delete this item?
                  </div>                  
                  <div className="dltop">
                    <div className="footer_dltop" onClick={confirmDelete}>
                      <div>DELETE</div>
                      <i style={{color:"black"}} class="bi bi-arrow-right"></i>
                    </div>
                  </div>
                  <div className="dltop1">
                    <div onClick={cancelDelete} className="footer_dltop1">
                      <div>CANCEL</div>
                      <i style={{color:"black"}} class="bi bi-arrow-right"></i>
                    </div>
                 </div>
               </div>
              </div>
            )}
          </div>
          <Modal id="tabunmod" show={showing} onHide={handletoClose}>
            <Modal.Header closeButton>
              <Modal.Title>EDIT ITEM</Modal.Title>
            </Modal.Header>
            <div>
              {" "}
              <TabUnModal setFormData={setFormData} formData={formData}  itemname={itemname} selectProduct={selectProduct} setSelectProduct={setSelectProduct} updateFormData={updateFormData} handletoClose={handletoClose}/>{" "}
            </div>
          </Modal>
          {/* diff model ^i model inside pic span*/}

          <Modal id="qwertyuo" centered show={graph} onHide={handleCloseing}>
            <Modal.Header>
              <div className="cname1">
                <div className="cname11">
                  <div className="cname13">-1</div>
                </div>
                <div className="cname12">Worn 0 times in 0 days</div>
                <div className="cname11">
                  <div className="cname13">+1</div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "hidden" }}>
              <div className="cname2">
                <h2 className="loliilo">PURCHASE DATE</h2>
                <input className="inptyu" placeholder="Purchased Date" />
              </div>
              <div className="cname2">
                <h2 className="loliilo">PURCHASE PRICE</h2>
                <input className="inptyu" />
              </div>
              <div className="cname2">
                <h2 className="loliilo">ORIGIAL PRICE</h2>
                <input className="inptyu" />
              </div>
              <div className="cname2">
                <h2 className="loliilo">SECONDHAND</h2>
                <input className="inptyu" />
              </div>
              <div className="cname2">
                <h2 className="loliilo">PURCHASE DATE</h2>
                <input
                  className="inptyu"
                  placeholder="Enter purchase location"
                />
              </div>
              <div className="cname2">
                <h2 className="loliilo">TIMES WORN</h2>
                <input className="inptyu" />
              </div>
              <div className="cname2">
                <h2 className="loliilo">RESALE VALUE</h2>
                <input className="inptyu" />
              </div>
            </Modal.Body>
          </Modal>
          {/* //second modal inside span */}

          <Modal centered id="mnbvc" show={dollar} onHide={handleCut}>
            <Modal.Body style={{ overflowY: "hidden" }}>
              <div className="tipopo">COMING SOON!</div>
              <div className="tipopo1">We arerevamping our marketplace.</div>
              <div className="tipopo2">
                Come back soon to list an item for sale
              </div>
              <div className="tickiop">
                <div onClick={handleCut} className="footer_flioop">
                  <div>OK</div>
                  <i style={{color:"black"}} class="bi bi-arrow-right"></i>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* //third modal inside span */}

          <Modal centered id="lkjh" show={hanger} onHide={handleCuting}>
            <Modal.Header style={{ display:"flex", justifyContent:"space-between" }}>
              <div onClick={handleCuting} handleCuting>
              <i style={{color:"black"}} class="bi bi-arrow-left"></i>
              </div>
              <div>OUTFITS BY ITEM</div>
              <div></div>
            </Modal.Header>

            <Modal.Body style={{ overflowY: "hidden" }}>
              <div className="zxcvfds">
                You don't have any outfit for the item just yet
              </div>
            </Modal.Body>
          </Modal>
          {/* //fourth modal inside span */}

          <Modal centered id="lkiopl" show={dlt} onHide={handleCuted}>
            <Modal.Body style={{ overflowY: "hidden" }}>
              <div className="diviie234">
                Are you sure you want to delete this item?
              </div>
              <div className="dltop">
                <div className="footer_dltop" onClick={() => handleDelete(selectProduct)}>
                  <div>DELETE</div>
                  <i style={{color:"black"}} class="bi bi-arrow-right"></i>
                </div>
              </div>
              <div className="dltop1">
                <div onClick={handleCuted} className="footer_dltop1">
                  <div>CANCEL</div>
                  <i style={{color:"black"}} class="bi bi-arrow-right"></i>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* //fifth modal inside span */}
        </Modal.Footer>
      </Modal>

  

      {/* filer Modale */}

      <Modal id="qwertyu" show={openModal} centered onHide={handleClosemodal}>
        {/* <Filter_pro handleClosemodal={handleClosemodal} /> */}
      </Modal>

    
    </div>
  );
}
