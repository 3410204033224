import React from "react";
import { Form } from "react-bootstrap";
import CountrySelectBox from "../widget/country-select";
import CurrencySelectBox from "../widget/currency-select";

function BankInformation(props) {
  const { formState, setFormState, errors } = props;
  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>
          <b>Bank Details:</b>
        </Form.Label>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Account Holder</Form.Label>
        <Form.Control
          name="bank_name"
          value={formState.bank_account.account_holder_name}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                account_holder_name: e.target.value,
              },
            })
          }
          type="text"
          placeholder="Account holder name"
        />
        <span className="form-error text-danger">
          {errors.account_holder_name}
        </span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          name="bank_name"
          value={formState.bank_account.name}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                name: e.target.value,
              },
            })
          }
          type="text"
          placeholder="Bank Name"
        />
        <span className="form-error text-danger">{errors.bank_name}</span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>IBAN</Form.Label>
        <Form.Control
          name="iban"
          value={formState.bank_account.iban}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                iban: e.target.value,
              },
            })
          }
          type="text"
          placeholder="IBAN"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>BIC</Form.Label>
        <Form.Control
          name="bic"
          value={formState.bank_account.bic}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                bic: e.target.value,
              },
            })
          }
          type="text"
          placeholder="BIC"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Account Number</Form.Label>
        <Form.Control
          name="account_number"
          value={formState.bank_account.account_number}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                account_number: e.target.value,
              },
            })
          }
          type="text"
          placeholder="Account number"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Sort Code</Form.Label>
        <Form.Control
          name="sort_code"
          value={formState.bank_account.sort_code}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                sort_code: e.target.value,
              },
            })
          }
          type="text"
          placeholder="Sort code"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Routing Number</Form.Label>
        <Form.Control
          name="routing_number"
          value={formState.bank_account.routing_number}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                routing_number: e.target.value,
              },
            })
          }
          type="text"
          placeholder="Routing number"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Country</Form.Label>
        <CountrySelectBox
          value={formState.bank_account.country}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                country: e.target.value,
              },
            })
          }
        />
        <span className="form-error text-danger">{errors.country}</span>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Currency</Form.Label>
        <CurrencySelectBox
          value={formState.bank_account.currency}
          onChange={(e) =>
            setFormState({
              ...formState,
              bank_account: {
                ...formState.bank_account,
                currency: e.target.value,
              },
            })
          }
        />
        <span className="form-error text-danger">{errors.currency}</span>
      </Form.Group>
    </>
  );
}

export default BankInformation;
