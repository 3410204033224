import React, { useEffect, useRef } from 'react';

const VideoCard = (props) => {

  const ref = useRef();
  const peer = props.peer;
  const display = props.display;

  useEffect(() => {
    peer.on('stream', (stream) => {
      ref.current.srcObject = stream;
    });
    peer.on('track', (track, stream) => {
      ref.current.srcObject = stream;
    });
  }, [peer]);

  return (
    <>
      <video
        playsInline
        autoPlay
        ref={ref}
        width="320"
        style={{display: display}}
      />
    </>
  );
};

export default VideoCard;