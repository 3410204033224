import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function DebtObligation(props) {

    const {myData} = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const allValues =
        Number(parsedData.obligation.auto_loan) +
        Number(parsedData.obligation.student_loan) +
        Number(parsedData.obligation.personal_loan) +
        Number(parsedData.obligation.medical_loan) +
        Number(parsedData.obligation.mortgage) +
        Number(parsedData.obligation.irs_penalty) +
        Number(parsedData.obligation.credit_card_payment);

    props.sendDataObs(allValues);

    return (
        <div className="dhhejk">
            <div className="daildy_living">DEBT OBLIGATIONS</div>
            <Form>
                <div className="living_state">
                    <Form.Label className="adksk">Auto Loan/Lease</Form.Label>
                    <input
                        type="text"
                        className=" input_style jadj"
                        name="auto_loan"
                        value={parsedData.obligation.auto_loan}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Student Loan</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="student_loan"
                        value={parsedData.obligation.student_loan}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Personal Loan</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="personal_loan"
                        value={parsedData.obligation.personal_loan}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Medical Loan</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="medical_loan"
                        value={parsedData.obligation.medical_loan}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Mortgage</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="mortgage"
                        value={parsedData.obligation.legal_fees}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">IRS Penalty</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="irs_penalty"
                        value={parsedData.obligation.mortgage}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Min. Credit Card Payment</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="credit_card_payment"
                        value={parsedData.obligation.credit_card_payment}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css_">Total Amount</Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    );
}

export default DebtObligation;
