import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function CalendarButton() {

    const [isOpen, setIsOpen] = useState(false);

    function toggleMenu() {
        setIsOpen(!isOpen);
    }

    const currentDate = new Date();
    const dayOfWeek = currentDate.toLocaleString('en-US', { weekday: 'long' }).slice(0, 3);
    const dayOfMonth = currentDate.getDate();

    

    return (
        <div style={{position: 'relative'}}>
            <button onClick={toggleMenu} id='calendar_btn'>
                <div className='calendarIcon'>
                    <h2>{dayOfWeek}</h2>
                    <h3>{dayOfMonth}</h3>
                </div>
                <h6 className='ta-center'>Calendar</h6>
            </button>
            {isOpen && (
                <ul className='menu'>
                    <li className='menu_1'><Link className="linkbtn1" to={'/event-calendar'}>Request calendar</Link></li>
                    <li><Link className="linkbtn2" to={'/gcalendar'}>Google calendar</Link></li>
                </ul>
            )}
        </div>
    )
}

export default CalendarButton;
