import React from "react";
import moment from "moment";

function DateView(props) {

    const { date, events } = props

    const getEventsCount = (day) => {

        const today = moment(day).format('YYYY-MM-DD')
        const todayMoment = moment(day, 'YYYY-MM-DD')
        const dayEvents = events.filter((event) => {
            return today === moment(event.start_time).format('YYYY-MM-DD') || today === moment(event.end_time).format('YYYY-MM-DD') || todayMoment.isBetween(moment(event.start_time, 'YYYY-MM-DD'), moment(event.end_time, 'YYYY-MM-DD'))
        })
        return dayEvents.length;
    }

    const showDots = (length) => {

        let html = '';
        if (length >= 3) {
            html += '<i class="fa fa-circle"></i><i class="fa fa-circle"></i><i class="fa fa-circle"></i>&nbsp;<i class="fa fa-plus"></i>';
        }
        else for (let i = 0; i < length; i++) {
            html += '<i class="fa fa-circle"></i>';
        }
        return html
    }

    return (
        <div className="day-view">
            <span>{moment(date).date()}</span>
            <div className="point-view" dangerouslySetInnerHTML={{ __html: showDots(getEventsCount(date)) }} />
        </div>
    )
}

export default DateView;