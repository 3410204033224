import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import './TabUnModal.css';
import { Row } from 'react-bootstrap';


const TabUnModal = (props) => {
  const { formData, selectProduct,setSelectProduct,setFormData,handletoClose } = props;
  const [activeTab, setActiveTab] = useState(1);
  const [selecteddtBox, setSelecteddtBox] = useState({boxNumber:null,color:null});
  const colors = ['red', 'rgb(220, 180, 220)', 'rgb(238, 232, 170)', '#444913', 'rgb(118, 113, 113)', 'green', 'white',];
  const matchColor = colors.find((item) => item === selectProduct.selecteddtBoxie);

  const [brand, setBrand] = useState(selectProduct.inputValue);
  const [category, setCategory] = useState(selectProduct.categorydefine);
  const [subCategory, setSubCategory] = useState(selectProduct.value);
  const [size, setSize] = useState(selectProduct.inputValue1);

//  
  const subCategoriesMap = {
    TOP: ['Blouse', 'Bodysuit', 'Buttoned Shirt', 'Camisole', 'Cardigon', 'Hoodie', 'Jersey', 'Polo Shirt', 'Strapless', 'Sweater', 'Sweatshirt', 'T-Shirt', 'Tank Top', 'Tunic', 'Vest'],
    BOTTOM: ['DENIM', 'LEGGINGS', 'PANTS', 'SHORTS', 'SKIRT', 'SWEATPANTS', 'TIGHTS'],
    OUTWEAR: ['BLAZER','COAT','JACKET','VEST'],
    'ONE PIECE': ['DRESS','JUMPSUIT','ROMPER'],
    BAG: ['BACKPACK','BELT BAG','BRIEFCASE','CLUTCH','CROSSBODY','SHOULDER','TOP HANDLE','TOTE','WALLET'],
    SHOES: ['BOOTS','CLOGS','FLATS','HEELS','LOAFERS','OXFORD/DERBY','SANDALS','SNEAKERS','WEDGES'],
    ACCESSORY: ['BELT','GLASSES','GLOVES','HAIR ACCESSORIES','HAT','OTHER','POCKET SQUARE','SCARF','SOCKS','TIE'],
    JEWELRY: ['BRACLET','EARRINGS','NECKLACE','OTHER JEWELRY','RING','WATCH'],
    SWIM: ['COVER-UP','ONE-PIECE','SEPARATES','TRUNKS']
  };

  const handleupdate = () => {
  
    setSelectProduct(prevProduct => ({
      ...prevProduct,
      id: selectProduct.id ,// Update only the ID
      inputValue: brand,
      categorydefine: category,
      value: subCategory,
      inputValue1: size,
      selecteddtBoxie:selecteddtBox.color
     }));
  
    // Update session storage
    const updatedFormData = formData.map(item => {
      if (item.id === selectProduct.id) {
        return {
          ...item,
          id: selectProduct.id // Update only the ID
        };
      }
      return item;
    });
    setFormData(updatedFormData);
    handletoClose();
  };
  
  console.log("selectedproduct",selectProduct);
  console.log("formdata",formData);

  const [name, setName] = useState('');
  const [itemLocation, setItemLocation] = useState('');
  const [shoppingUrl, setShoppingUrl] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  
  
   

  const handleClicked = (boxNumber, color) => {
    // Find the index of the clicked color in the colors array
    const colorIndex = colors.indexOf(color);
  
    // Set the selected box with the box number and color
    setSelecteddtBox({ color: colors[colorIndex] });
  
    // Log the index and color
    console.log(colorIndex + ": " + colors[colorIndex]);
  };
  

  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
   
  };

  

  const handleBrandChange = (e) => {
    setBrand(e.target.value);
  };

  // const handleCategoryChange = (e) => {
  //   setCategory(e.target.value);
  // };
  const handleCategoryChange = (e) => {
    // const selectedCategory = e.target.value;
    setCategory(e.target.value);
    // Reset sub-category when category changes
    setSubCategory('');
  };
  



  const handleSubCategoryChange = (e) => {
    setSubCategory(e.target.value);
  };

  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleItemLocationChange = (e) => {
    setItemLocation(e.target.value);
  };

  const handleShoppingUrlChange = (e) => {
    setShoppingUrl(e.target.value);
  };

  const renderSubCategoriesOptions = () => {
    if (category && subCategoriesMap[category]) {
      return subCategoriesMap[category].map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ));
    }
    return null;
  };
  console.log("selecteddtBox",selecteddtBox)

  return (
    <div className="custom_tab22">
      <div className="tab_header22">
        <div
          className={activeTab === 1? "tab_item22 active" : "tab_item22"}
          onClick={() => handleClick(1)}
        >
          BASIC INFO
        </div>
        <div
          className={activeTab === 2? "tab_item22 active" : "tab_item22"}
          onClick={() => handleClick(2)}
        >
          MORE INFO
        </div>
        <div
          className={activeTab === 3? "tab_item22 active" : "tab_item22"}
          onClick={handleShow}
        >
          MEASUREMENT
        </div>
      </div>
      <div className="tab_content22">
        {activeTab === 1 && (
          <div className="tab_block22">
            <div>
              <h2 className="lolii">BRAND*</h2>
              <input
                className="inphmn"
                value={brand}
                onChange={handleBrandChange}
              />
              <h2 className="lolii">CATEGORY*</h2>
              <select
                className="inphmn"
                value={category}
                onChange={handleCategoryChange}
              >  
                <option value="TOP">TOP</option>
                <option value="BOTTOM">BOTTOM</option>
                <option value="OUTWEAR">OUTWEAR</option>
                <option value="ONE PIECE">ONE PIECE</option>
                <option value="BAG">BAG</option>
                <option value="SHOES">SHOES</option>
                <option value="ACCESSORY">ACCESSORY</option>
                <option value="JEWELRY">JEWELRY</option>
                <option value="SWIM">SWIM</option>
              </select>
                {/* {['TOP', 'BOTTOM', 'OUTWEAR', 'ONE PIECE', 'BAG', 'SHOES', 'ACCESSORY', 'JEWELRY', 'SWIM'].map((option, index) => (
                  <option key={index} value={option}>{option}</option>
                ))} */}


              <h2 className="lolii">SUB-CATEGORY*</h2>
              <select
                className="inphmn"
                value={subCategory}
                onChange={handleSubCategoryChange}
              >
                <option value="">Select Sub-Category</option>
                {renderSubCategoriesOptions()}
              </select>

              <h2 className="lolii">SIZE*</h2>
              <input
                className="inphmn"
                value={size}
                onChange={handleSizeChange}
              />

              <h2 className="lolii">COLOR</h2>
              <div className="row color_items">
                {colors.map((color, index) => (
                  <div key={index} className="col-1">
                    {/* <div
                      className={`color_listhw } ${color === matchColor? "bacgroundcolowrrwrwwr" : " "}`}
                      style={{ background: color }}
                    >
                    </div> */}
                    <Row  className={`color_listhw } `}
                      style={{ background: color, border: color === matchColor ? '2px solid black' : 'none', }}  onClick={() => handleClicked(index, color)}>
                    </Row>
                  </div>
                ))}
              </div>
               <div style={{ marginBottom: "15px" }}></div>

              <h2 className="lolii">SEASON</h2>
              <div style={{ marginBottom: "10px", display: "flex" }}>
                <div className="bo_r1">
                  <div className="te_xt">SPRING</div>
                </div>
                <div className="bo_r2">
                  <div className="te_xt">SUMMER</div>
                </div>
              </div>
              <div style={{ marginBottom: "10px", display:"flex" }}>
                <div className="bo_r1">
                  <div className="te_xt">FALL</div>
                </div>
                <div className="bo_r2">
                  <div className="te_xt">WINTER</div>
                </div>
              </div>
              <h2 className="lolii">NAME</h2>
              <input
                className="inphmn"
                placeholder="Enter 2-3 word dexcription"
                value={name}
                onChange={handleNameChange}
              />
              <h2 className="lolii">ITEM LOCATION</h2>
              <input
                className="inphmn"
                placeholder="Item Location"
                value={itemLocation}
                onChange={handleItemLocationChange}
              />
              <h2 className="lolii">SHOPPING URL</h2>
              <input
                className="inphmn"
                placeholder="Enter Shopping URL"
                value={shoppingUrl}
                onChange={handleShoppingUrlChange}
              />

              
            </div>
            <button style={{padding:"3px"}} onClick={handleupdate}>Done</button>

          </div>
        )}

        {activeTab === 2 && (
          <div className="tab_block22">
            {/* Content for Tab 2 */}
            <h2 className="lolii">PERSONAL NOTE</h2>
            <input className="inphmin" placeholder="Enter a personal note about this item" />
            <h2 className="lolii">ITEM DESCRIPTION</h2>
            <input className="inphmin" type="text" placeholder="Enter item Description" />
            <h2 className="lolii">CONDITION</h2>
            <input className="inphmn" placeholder="Item Condition" />
            <h2 className="lolii">CONDITION DETAILS</h2>
            <input className="inphmn" placeholder="Select main material" />
            <h2 className="lolii">ORIGIN</h2>
            <input className="inphmn" placeholder="Eg China,Turky,Italy" />
            <h2 className="lolii">PURCHASE DATE</h2>
            <input className="inphmn" placeholder="Purchased Date" />
          </div>
        )}
      </div>
      <Modal id="loke" centered show={show} onHide={handleClose}>
        <Modal.Body style={{ overflowY: "auto" }}>
          <div className="tipo">Coming Soon</div>
          <div className="ticki">
            <div onClick={handleClose} className="footer_flio">
              <div className="button_name_flio">GO BACK</div>
              <i style={{color:"black"}} class="bi bi-arrow-right"></i>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TabUnModal;