
import React, { useEffect, useState } from "react";
import {Calendar} from "react-calendar";
import Section from "./layouts/Section";
import "./calendar.css";
import Time from "./Time";
import Location from "./Location";
import moment from "moment";
import CalendarData from "./data/CalendarData";
import DateView from "./DateView";

import ApiCalendar from 'react-google-calendar-api';

const config = {
      "clientId": "327847313047-08qkupvnu7qlblrkvot2d9tuca42e02i.apps.googleusercontent.com",
      "apiKey": "AIzaSyCz5DGBaPoHSB0OEz9Tp71B54YiZZob_Qo",
      "scope": "https://www.googleapis.com/auth/calendar",
      "discoveryDocs": [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
      ]
}

const apiCalendar = new ApiCalendar(config)

function Calender() {

      const [currentEvents, setCurrentEvents] = useState([])
      const [value, onChange] = useState(moment().toDate());

      useEffect(() => {

            const today = moment().format('DD/MM/YYYY')
            const events = CalendarData.filter((events) => {
                  return events.date === today
            })
            setCurrentEvents(events)
      }, [])

      const showDay = (day) => {
            const today = moment(day).format('DD/MM/YYYY')
            
            const events = CalendarData.filter((events) => {
                  return events.date === today
            })

            setCurrentEvents(events)
      }

      const handleItemClick = (event, name) => {
            if (name === 'sign-in') {
              apiCalendar.handleAuthClick()
            } else if (name === 'sign-out') {
              apiCalendar.handleSignoutClick();
            }
      }

      return(
            <Section allNotification={false} searchPopup={true} title={'Calendar'}>
            <>
            <button
                  onClick={(e) => handleItemClick(e, 'sign-in')}
              >
                sign-in
              </button>
              <button
                  onClick={(e) => handleItemClick(e, 'sign-out')}
              >
                sign-out
              </button>
            <div className="container" id="calendar-section">
            <h5 className="calendar-heading5">My Schedule</h5>
              <div>
              <button type="button" className="addevent-calendar" data-toggle="modal" data-target="#modal-centered"><i class="fa fa-plus plus-icon" aria-hidden="true"></i>
              Add Event</button>
              
              {/* Begin Centered Modal */}
              <div id="modal-centered" className="modal fade">
              <div class="modal-dialog" role="document">
                        <div class="modal-content">
                              <div class="modal-header">
                                    <p className="modalevent-title">Create an Event</p>
                                    <button type="button" class="closes" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                              </div>
                              <div className="modal-body">
                                    <div className="row">
                                          <div className="col-xs-12 startdate">
                                                <label className="col-xs-4" for="starts-at">Event title</label>
                                                <input type="text" id="textinput-title" name="name" requiredminlength="4" maxlength="80" size="100" />
                                          </div>
                                    </div>
                              <div className="row">
                              <div className="col-xs-12 startdate">
                              <label for="advisors" className="col-xs-4">Choose Advisor</label>
                                <select name="Advisor" id="Advisors-title">
                                <option value="">Select</option>
                                  <option value="">Andy Jassy</option>
                                  <option value="">Stephen Squeri</option>
                                  <option value="">Doug Parker</option>
                                </select>
                              </div>
                              </div>
                              <div className="row">
                              <div className="col-xs-12 startdate">
                                    <label className="col-xs-4" for="starts-at">Starts Time</label>
                                    <input type="time" id="appts" ></input>
                              </div>
                              </div>
                              <div className="row">
                              <div className="col-xs-12 endsdate">
                                    <label className="col-xs-4" for="starts-at">Ends Time</label>
                                    <input type="time" id="appt" name="appt" min="09:00" max="18:00" required></input>
                              </div>
                              </div>
                              <div className="row">
                              <div className="col-xs-12 startdate">
                                    <label className="col-xs-4" for="starts-at">Starts date</label>
                                    <input type="date" id="fname" name="fname"></input>
                              </div>
                              </div>
                              <div className="row">
                              <div className="col-xs-12 endsdate">
                                    <label className="col-xs-4" for="ends-at">Ends date</label>
                                    <input type="date" id="fname" name="fname"></input>

                                 </div>
                                 </div>
                                 <div className="row">
                                   <div className="col-xs-12 endsdate">
                                   <form action="">
                                      <textarea id="message-area" name="message-area" rows="4" cols="50">Appointment Note </textarea>
                                      <br/>
                                    </form>

                                  </div>
                                 </div>
                              </div>
                              <div className="modal-footer">
                                  <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                  <button type="button" className="btn button-save" id="save-event">Save</button>
                              </div>
                          </div>      
                      </div>
                </div>
                {/* End Centered Modal */}
              </div>
            <p className="calender-paragraph"><Time/>
            <i class="fa fa-map-marker" aria-hidden="true"><Location/></i>
            </p>
            </div>
            <div id="calender-section">
            
            <Calendar onClickDay={showDay} value={value} onChange={onChange} formatDay= { (locale, date) => <DateView date={date} /> } />
            </div>
            {
                  currentEvents.map((data, i) => {

                        const classNameTxt = "calender-item myevent1 cal-" + (i % 5);
                        return (
                        <div key={data} className={classNameTxt}>
                              
                                    <div className="health-area-2-row1">
                                          <p className="previous-meeting2">{data.title}</p>
                                    </div>
                                    <div className="health-area-2-row2">
                                          <div className="">
                                                <img src={process.env.PUBLIC_URL + data.image} alt="img" width="40" className="avatar" />
                                                <p className="advisorname">{data.to}</p>
                                          </div>  
                                    
                                    <div className="health-area-2-row1">
                                          <p className="previous-meeting">{data.occupation}</p>
                                    </div>
                                    <div className="row health-area-2-row">
                                          <div className="col-6">
                                          <p className="previous-meeting">{data.time}</p>
                                          </div> 
                                          <div className="col-6">
                                          <p className="previous-meeting previous-meeting1">{data.date}</p>
                                    </div>
                                    </div>
                                    <div className="health-area-2-row2 area2-row-2">
                                          <p className="previous-meeting">
                                          <span className="previous-meeting1"> <i class="fa fa-map-marker" aria-hidden="true"></i></span>
                                          {data.address}
                                          </p>
                                    </div>
                              </div>
                        </div>
                        )
                  })
            }
            <div style={{height: '10px'}}></div>
            </>
            </Section>
      );

} 



export default Calender;