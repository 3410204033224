import { CHATS_ERROR, FETCH_CHATS, LOADER } from "../store/redux-constant"

const initialState = {
    chats: [],
    error: false,
	errorMessage: '',
	loader:false
}

const chatReducers = (state = initialState, action) => {
	
    switch (action.type) {
		case LOADER:
			return { ...state, loader:true }
		case FETCH_CHATS:
			return {...state, loader:false, chats:action.payload}
		case CHATS_ERROR:
			return {
				...state,
				error:true,
				loader:false,
				errorMessage:'getting errors in chats'
			}
		default:
			return state
	}
}

export default chatReducers