import React, { Component } from "react";
import { connect } from "react-redux";
import { getPaymentHistory } from '../../../actions'
import Spinner from "../spinner/Spinner";
import moment from "moment";

class RechargeList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            historyList: []
        }
    }

    componentDidMount() {
        this.props.getPaymentHistory()
    }


    static getDerivedStateFromProps(props, state) {
        if (props) {
            let history_list = { ...state.historyList }
            history_list = props.payment_history
            return { ...state, historyList: history_list };
        }
        return state;
    }

    formatButtonLabel(status) {
        // console.log(status)
        let color = ""
        let text = ""
        if (status === 0) {
            color = "orange"
            text = "InProcess"
        } else if (status === 1) {
            color = "green"
            text = "Success"
        } else if (status === 2) {
            color = "red"
            text = "Failed"
        } else {
            color = "grey"
            text = "NA"
        }
        return (<p style={{color:color}}>{text}</p>)
    }


    render() {
        return (
            <div className="">
                <div className="goal-area pd-top-36">
                    {
                        this.state.historyList.length > 0 ? this.state.historyList.map((item) => {
                            // console.log("data:", item)
                            const date = item.created_at;
                            const day = moment(date).format("DD")
                            const month = moment(date).format("MMM")
                            const time = moment(date).format("hh:mm:ss A")

                            return (
                                <React.Fragment key={item}>
                                    <div className="single-goal single-goal-one">
                                        <div className="row">
                                            <div className="col-3 pr-0">
                                                <div className="details-date">
                                                    <h6 className="pt-0">{day} </h6>
                                                    <p>{month}</p>
                                                </div>
                                            </div>
                                            <div className="col-7 px-0">
                                                <div className="details add">
                                                    <h6 className="pt-0">{time}</h6>
                                                    {this.formatButtonLabel(item.status)}
                                                </div>
                                            </div>
                                            <div className="col-2 px-0">
                                                <div className="circle-inner circle-inner-one mt-4">
                                                    <h6 className="goal-amount pt-0">${item.amount}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }) : this.state.historyList.length === 0 ? 'No Data available' : <Spinner />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    // console.log(state.payment)
    return {
        payment_history: state.payment.paymentHistory,
        loader: state.payment.loader
    }
}

export default connect(mapStateToProps, { getPaymentHistory })(RechargeList);