import React, { Component } from "react";
import ReactDOM from "react-dom";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect.js";
import { components } from "react-select";

const Option = props => {

  
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();

export default class Example extends Component {

  constructor(props) {
    super(props);
    this.state = {
      optionSelected: null,
      options: []
    };
  }

  componentDidMount() {
    const {options, defaultValue} = this.props;
    const myOptions = [];
    if(options !== undefined) {
      for(let option of options) {
        myOptions.push({
          value: option.id,
          label: option.name
        })
      }

      this.setState({
        options: myOptions,
        optionSelected: defaultValue
      })
    }
  }

  componentDidUpdate(prev) {

    const {options, defaultValue} = this.props;
    if(prev.options.length !== options.length){
      const myOptions = [];
      if(options !== undefined) {
        for(let option of options) {
          myOptions.push({
            value: option.id,
            label: option.name
          })
        }

        this.setState({
          options: myOptions,
          optionSelected: defaultValue
        })
      }
    }

    if (defaultValue && prev.defaultValue !== defaultValue) {
      this.setState({
        optionSelected: defaultValue
      })
    }

  }

  handleChange = selected => {
    this.setState({
      optionSelected: selected
    });

    this.props.selected(selected);
  };

  render() {
    return (
      <MySelect
        options={this.state.options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, animatedComponents }}
        onChange={this.handleChange}
        allowSelectAll={true}
        value={this.state.optionSelected}
        defaultValue={this.props.defaultValue}
      />
    );
  }
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Example />, rootElement);