
import React, { useState } from 'react';
import { Modal } from "bootstrap";
import DailyLiving from './cash flow/DailyLiving/DailyLiving';
import Entertainment from './cash flow/Entertainment/Entertainment';
import Transpotation from './cash flow/Transportation/Transportation';
import Irregular from './cash flow/Irregular/Irregular';
import Health from './cash flow/Health/Health';
import Income from './cash flow/Income/Income';
import OtherExpenses from './cash flow/OtherExpense/OtherExpense';
import Education from './cash flow/Education/Education';
import RiskManagemnet from './cash flow/RiskManagement/RiskManagement';
import CharityGift from './cash flow/CharityGift/CharityGift';
import OtherObligation from './cash flow/OtherObligation/OtherOblifation';
import DebtObligation from './cash flow/DebtObligation/DebtObligation';
import Utilities from './cash flow/Utili/Utilities';
import Housing from './cash flow/Housing/Housing';
import MonthlyPosition from './cash flow/MonthlyPosition/MonthlyPosition';

function CashflowModel(props) {
    const { list } = props;
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    let myData;
    try {
        myData = JSON.parse(list.report_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const [getDataOt_, setGetDataOt_] = useState("");
    const [getDataOb_, setGetDataOb_] = useState("");
    const [getDatarm_, setGetDatarm_] = useState("");
    const [getTc_, setGetTc_] = useState("");
    const [getDl_, setGetDl_] = useState("");
    const [getDo_, setGetDo_] = useState("");
    const [getEdu_, setGetEdu_] = useState("");
    const [getEt_, setGetEt_] = useState("");
    const [getHe_, setGetHe_] = useState("");
    const [getHo_, setGetHo_] = useState("");
    const [getIn_, setGetIn_] = useState("");
    const [getIr_, setGetIr_] = useState("");
    const [getTr_, setGetTr_] = useState("");
    const [getUt_, setGetUt_] = useState("");


    const getDataOt = (data) => {
        setGetDataOt_(data);
    };
    const getDataOb = (data) => {
        setGetDataOb_(data);
    };

    const getDatarm = (data) => {
        setGetDatarm_(data);
    };

    const getTc = (data) => {
        setGetTc_(data);
    };

    const getDl = (data) => {
        setGetDl_(data);
    };

    const getDo = (data) => {
        setGetDo_(data);
    };

    const getEdu = (data) => {
        setGetEdu_(data);
    };

    const getEt = (data) => {
        setGetEt_(data);
    };

    const getHe = (data) => {
        setGetHe_(data);
    };

    const getHo = (data) => {
        setGetHo_(data);
    };

    const getIn = (data) => {
        setGetIn_(data);
    };

    const getIr = (data) => {
        setGetIr_(data);
    };

    const getTr = (data) => {
        setGetTr_(data);
    };

    const getUt = (data) => {
        setGetUt_(data);
    };


    return (
        <div>
            <button onClick={openModal} type="button" className="btn btn-sm">
                <i className="bi bi-eye-fill"></i>
            </button>
            {showModal && (
                <div
                    className="modal"
                    tabIndex="-1"
                    role="dialog"
                    style={{ display: "block", background: "#111110a8" }}
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Total Expense and Income</h5>
                                <button
                                    type="button"
                                    className="close _newclose"
                                    onClick={closeModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container">
                                    <DailyLiving
                                        sendDataDl={getDl}
                                        myData={myData} />
                                </div>

									<div className="container">
										<Entertainment sendDataEt={getEt} myData={myData} />
									</div>

									<div className="container">
										<Transpotation sendDataTr={getTr} myData={myData} />
									</div>

									<div className="container">
										<Health sendDataHe={getHe} myData={myData} />
									</div>

									<div className="container">
										<Education sendDataedu={getEdu} myData={myData} />
									</div>

									<div className="container">
										<CharityGift sendDatatc={getTc} myData={myData} />
									</div>

									<div className="container">
										<Housing sendDataHo={getHo} myData={myData} />
									</div>

									<div className="container">
										<Utilities sendDataUt={getUt} myData={myData} />
									</div>

									<div className="container">
										<DebtObligation sendDataObs={getDataOb} myData={myData} />
									</div>

									<div className="container">
										<OtherObligation sendDataOb={getDo} myData={myData} />
									</div>

									<div className="container">
										<RiskManagemnet sendDatarm={getDatarm} myData={myData} />
									</div>

									<div className="container">
										<OtherExpenses sendDataOt={getDataOt} myData={myData} />
									</div>

									<div className="container">
										<Income sendDataIn={getIn} myData={myData} />
									</div>

									<div className="container">
										<Irregular sendDataRe={getIr} myData={myData} />
									</div>

                                <div className="container">
                                    <MonthlyPosition
                                        getDataOt_={getDataOt_}
                                        getDataOb_={getDataOb_}
                                        getDatarm_={getDatarm_}
                                        getTc_={getTc_}
                                        getDl_={getDl_}
                                        getDo_={getDo_}
                                        getEdu_={getEdu_}
                                        getEt_={getEt_}
                                        getHe_={getHe_}
                                        getHo_={getHo_}
                                        getIn_={getIn_}
                                        getIr_={getIr_}
                                        getTr_={getTr_}
                                        getUt_={getUt_}
                                        myData={myData}
                                    />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="commonmodalbutton"
                                    onClick={closeModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CashflowModel
