import React, { useEffect, useState } from "react";
import './reports.css'
import ReactApexChart from "react-apexcharts";
import { Container, Row, Col } from 'react-bootstrap';
function Reports(){
    // Bar Chart
   const barseries = [{
        name: 'Stock Price PreCorrection',
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 120]
      }, {
        name: 'Current Stock price',
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41,38]
      }];
    const baroptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: ['XY1', 'XY2', 'XY3', 'XY4', 'XY5', 'XY6', 'XY7', 'XY8', 'XY9', 'XY10'],
        },
        yaxis: {
          title: {
            
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$ " + val + " thousands"
            }
          }
        }
    }
}
      // Donut Chart 
      const [getDonutBar, setDonutBar] = useState({
          
        series: [44, 55, 41, 17, 15, 25, 45, 23, 35, 41],
        options: {
            labels: ['XY1',
                     'XY2',
                     'XY3',
                     'XY4',
                     'XY5',
                     'XY6',
                     'XY7',
                     'XY8',
                     'XY9',
                      'XY10'
    ],
          chart: {
            type: 'donut',
          },
          theme: {
            mode: 'light', 
            palette: 'palette1', 
            monochrome: {
                enabled: false,
                color: '#255aee',
                shadeTo: 'light',
                shadeIntensity: 0.65
            },
        },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }]
        }
    }) 
     // Basic Side Bar Chart
     const [getSideBar, setSideBar] = useState({
          
        series: [{
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['XY1', 'XY2', 'XY3', 'XY4', 'XY5', 'XY6', 'XY7',
              'XY8', 'XY9', 'XY10'
            ],
          }
        }
    })
    // Basic Side 2 Bar Chart
    const [getSideBar2, setSideBar2] = useState({
          
        series: [{
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
        options: {
          chart: {
            type: 'bar',
            height: 350
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: ['XY1', 'XY2', 'XY3', 'XY4', 'XY5', 'XY6', 'XY7',
              'XY8', 'XY9', 'XY10'
            ],
          }
        }
    })

    // Distributed Column Chart
    const [getDistributedBar, setDistributedBar] = useState({
          
        series: [{
          data: [0, 2, 4, 6, 8]
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: [
              ['Fund 10'],
              ['Fund 9'],
              ['Fund 6'],
              ['Fund 5'],
              ['Fund 7'], 
            ],
            
          }
        }
    })

    // Line Chart
    const [getLineBar, setLineBar] = useState({
          
        series: [
            {
              name: "High - 2013",
              data: [28, 29, 33, 36, 32, 32, 33]
            },
            {
              name: "Low - 2013",
              data: [12, 11, 14, 18, 17, 13, 13]
            }
          ],
          options: {
            chart: {
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth'
            },
            title: {
              text: 'Average High & Low Temperature',
              align: 'left'
            },
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
              title: {
                text: 'Month'
              }
            },
            yaxis: {
              title: {
                text: 'Temperature'
              },
              min: 5,
              max: 40
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
    })

    // Distributed 2 Column Chart
    const [getDistributedBar2, setDistributedBar2] = useState({
          
        series: [{
          data: [0, 10, 20, 30, 40, 50,25,34]
        }],
        options: {
          chart: {
            height: 350,
            type: 'bar',
          },
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          xaxis: {
            categories: [
                ['John', 'Doe'],
                ['Joe', 'Smith'],
                ['Jake', 'Williams'],
                'Amber',
                ['Peter', 'Brown'],
                ['Mary', 'Evans'],
                ['David', 'Wilson'],
                ['Lily', 'Roberts'], 
              ],
            
          }
        }
    })
return(
    <>
    <div className="row">
            <div className="col-12">
            <Container>
      <Row className="mb-3">
        <Col xs={12} md={4} className="personalgridlabel">
          <div className="headcolor">
            <p>PreCorrection vs Current Stock Price</p>
          </div>
          <ReactApexChart options={baroptions} series={barseries} type="bar" height={350} />
        </Col>
        <Col xs={12} md={4} className="personalgridlabel">
          <div className="headcolor">
            <p>Current Portfolio Composition</p>
          </div>
          <ReactApexChart options={getDonutBar.options} series={getDonutBar.series} type="donut" />
        </Col>
        <Col xs={12} md={4} className="personalgridlabel">
          <div className="headcolor">
            <p>Current Value</p>
          </div>
        </Col>
      </Row>
    </Container>
                </div>
                </div>

                <div className="row">
            <div className="col-12">
            <Container>
      <Row className="mb-3">
        <Col xs={12} md={6} className="personalgridlabel">
          <div className="headcolor">
            <p>DECLINE % YTD</p>
          </div>
          <ReactApexChart options={getSideBar.options} series={getSideBar.series} type="bar" height={350} />
        </Col>
        <Col xs={12} md={6} className="personalgridlabel">
          <div className="headcolor">
            <p>POTENTIAL GAIN</p>
          </div>
          <ReactApexChart options={getSideBar2.options} series={getSideBar2.series} type="bar" height={350} />
        </Col>
      </Row>
    </Container>
                </div>
                </div>
                <div className="row">
            <div className="col-12">
            <Container>
      <Row className="mb-3">
        <Col className="personalgridlabel" xs={12} md={6}>
          <div className="headcolor">
            <p>TOP 5 WORST SCENARIOS-LOST VALUE</p>
          </div>
          <ReactApexChart options={getDistributedBar.options} series={getDistributedBar.series} type="bar" height={350} />
        </Col>
        <Col className="personalgridlabel" xs={12} md={6}>
          <div className="headcolor">
            <p>COMMENT ABOUT LOST AND GAINS</p>
          </div>
          <p>Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa. Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione. È sopravvissuto non solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato. Fu reso popolare, negli anni ’60, con la diffusione dei fogli di caratteri trasferibili “Letraset”</p>
        </Col>
      </Row>
    </Container>
                </div>
                </div>
                <div className="row">
                <div className="col-12">
                <Container>
      <Row className="mb-3">
        <Col xs={12} md={6} className="personalgridlabel">
          <div className="headcolor">
            <p>TIMELINE RECOVER</p>
          </div>
          <ReactApexChart
            options={getLineBar.options}
            series={getLineBar.series}
            type="line"
            height={350}
          />
        </Col>
        <Col xs={12} md={6} className="personalgridlabel">
          <div className="headcolor">
            <p>TOTAL EQUITY REPLACEMENT</p>
          </div>
          <ReactApexChart
            options={getDistributedBar2.options}
            series={getDistributedBar2.series}
            type="bar"
            height={350}
          />
        </Col>
      </Row>
    </Container>
                </div>
                </div>
    </>
)
}


export default Reports;
