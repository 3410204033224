import React from 'react'
import Section from "../../layouts/Section"
import { connect } from 'react-redux'
import { getChats } from "../../../actions/chat"
import Item1 from './item1'

function ChatList(props) {

  const { chats } = props

  return (
    <>
      <Section allNotification={false} searchPopup={false} title={'Chat'} />
      <ul className="list-group mg-top-50 container">
        {
          chats.map((chat, i) =>
            <li id="chatuserlist" className='list-group-item d-flex justify-content-between align-items-center'>
              <Item1 item={chat} />
            </li>
          )
        }
      </ul>
    </>
  )
}

const mapStateToProps = (state) => ({
  chats: state.chat.chats,
})

export default connect(mapStateToProps, { getChats })(ChatList)