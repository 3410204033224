import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Header, Icon, Image, Segment } from "semantic-ui-react";
import {NavLink} from "react-router-dom";

import Section from "../../layouts/Section";
import { updatePayment } from "../../../actions"
import History from "../../../History";

// Documentation: https://react.semantic-ui.com/elements/loader/#variations-inline
 
class StripeSuccess extends Component {
  constructor(props){
    super(props)
    this.state={

    }
  }
  componentDidMount(){
    const payment_intent  = new URLSearchParams(window.location.search).get('payment_intent');
    const client_secret   = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    const redirect_status = new URLSearchParams(window.location.search).get('redirect_status');

    let param = {
      payment_intent,
      client_secret,
      redirect_status
    }
    console.log(param)
    this.props.updatePayment(param)
    setTimeout(()=>{
      History.push('/home')
    },3000)
  }
  render(){
    return (
      <Section title={'Stripe Payment Success'}>
          <div className="transaction-area pd-top-36 mg-bottom-35">
              <div className="container mt-5">
                <Image src={process.env.PUBLIC_URL + '/assets/img/right-tick.jpeg'} size='medium' centered />
                  <Segment placeholder>
                      <Header icon>
                      <Icon name='check circle outline' />
                      Your Transaction is Successfull!
                      </Header>
                      <Segment.Inline>
                      <Button primary><NavLink to={'/payment-history'}><p style={{'color':'white'}}>Go to Transaction List</p></NavLink></Button>
                      <Button secondary><NavLink to={'/home'}><p style={{'color':'white'}}>Home</p></NavLink></Button>
                      </Segment.Inline>
                  </Segment>
              </div>
          </div>
      </Section>
    );
  
  }
}

const mapStateToProps = state =>{
  return {

  }
}

export default connect(mapStateToProps,{updatePayment})(StripeSuccess);