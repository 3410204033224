import React, { useState, useEffect } from 'react';
import "./nullcontent.css";


const NullContent = (props) => {
  const { dragimgaebox,setCalanderdata } = props;
  const hasImages = dragimgaebox.length > 0;
  const [storedImages, setStoredImages] = useState([]);


  useEffect(() => {
    if (hasImages) {
      setStoredImages(prevImages => [...prevImages, dragimgaebox]);
    }
  }, [dragimgaebox, hasImages]);
 

  useEffect(() => {
    // Call setCalanderdata whenever storedImages is updated
    setCalanderdata(storedImages);
  }, [storedImages, setCalanderdata]);
console.log("yeh rahi",storedImages);
  return (
   <>
  {hasImages && (
      <div>
        <div className='row' style={{ justifyContent: "start" }}>
         
          {storedImages.map((imageSet, setIndex) => (
            <div key={setIndex} className='col-3 columnthree'>
            
              {imageSet.map((data, index) => (
                <img className='nullimageclass' key={index} src={data} alt={`item-${index}`} />
              ))}
            </div>
          ))}
        </div>
      </div>
    )
  }
  {!hasImages && (
        <div>
          <h3 className='padh3'>No Outfit Created</h3>
        </div>
      )}
    </>
    
  );
};

export default NullContent;
