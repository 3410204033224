import React, { useEffect, useState } from "react";
import CommonTable from "./CommonTable";
import { get_all_stock } from "../../../src/api/auth";
import { Spinner } from "react-bootstrap";



function StockOpportunity() {

  const [allstock, setAllstock] = useState({});
  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(true);

  const getAllstaock = async () => {
    try {
      await get_all_stock().then((respons) => {
        console.log("respons", respons)
        setAllstock(respons);
        setLoading(false);
      });
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }

  useEffect(() => {
    getAllstaock()
  }, []);


// console.log("allstock", allstock)
  

  const columns = [
    {
      field: 'id',
      headerName: 'Symbol',
      headerClassName: 'super-app-theme--header hideRightSeparator',
      width: 80,
      color: 'black'
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'super-app-theme--header hideRightSeparator',
      width: 190,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false,
    },
    {
      field: 'price',
      headerName: 'Price(Intraday)',
      headerClassName: 'super-app-theme--header_2 hideRightSeparator',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false,
    },
    {
      field: 'change',
      headerName: 'Change',
      headerClassName: 'super-app-theme--header_2 hideRightSeparator',
      type: 'number',
      width: 141,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false,
    },
    {
      field: 'percentagechange',
      headerName: '%Change',
      sortable: false,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      disableColumnMenu: true,
      disableColumnFilter: true,
      disableColumnSelector: true,
      sortable: false,
      headerClassName: 'header-color hideRightSeparator',
    },
    {
      field: 'volume',
      headerName: 'Volume',
      headerClassName: 'header-color hideRightSeparator',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false,
    },
    {
      field: 'avg_vol',
      headerName: 'Avg Vol(3 month)',
      headerClassName: 'super-app-theme--header_4 hideRightSeparator',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false,
    },
    {
      field: 'marketcap',
      headerName: 'Market Cap',
      headerClassName: 'super-app-theme--header_4 hideRightSeparator',
      width: 125,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false,
    },
    {
      field: 'ratio',
      headerName: 'PE Ratio (TTM)',
      headerClassName: 'super-app-theme--header_4 hideRightSeparator',
      width: 121,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      disableColumnFilter: false,
      disableColumnSelector: false,
    },
    {
      field: 'weekrange',
      headerName: '52 Week Range',
      headerClassName: 'super-app-theme--header_4 hideRightSeparator',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: 'true',
      disableColumnFilter: 'false',
      disableColumnSelector: 'false',
    },
  ];

  const rows = allstock && allstock[0] && allstock[0].quotes ? allstock[0].quotes : [];

  const rowsWithIds = rows.map((row, index) => {
    // Check if the required properties exist before accessing them
    const trailingPE = row.trailingPE ? row.trailingPE.fmt : 'N/A';
    const regularMarketChange = row.regularMarketChange ? row.regularMarketChange.fmt : 'N/A';
    const regularMarketChangePercent = row.regularMarketChangePercent ? row.regularMarketChangePercent.fmt : 'N/A';
    const regularMarketVolume = row.regularMarketVolume ? row.regularMarketVolume.fmt : 'N/A';
    const averageDailyVolume3Month = row.averageDailyVolume3Month ? row.averageDailyVolume3Month.fmt : 'N/A';
    const marketCap = row.marketCap ? row.marketCap.fmt : 'N/A';
    const fiftyTwoWeekRange = row.fiftyTwoWeekRange ? row.fiftyTwoWeekRange.fmt : 'N/A';

    // Create a new object with the required properties
    return {
      ...row,
      id: row.symbol,
      name: row.shortName,
      price: row.regularMarketPrice.raw,
      change: regularMarketChange,
      percentagechange: regularMarketChangePercent,
      volume: regularMarketVolume,
      avg_vol: averageDailyVolume3Month,
      marketcap: marketCap,
      ratio: trailingPE,
      weekrange: fiftyTwoWeekRange,
    };
  });
  const checkTest = () => {
    alert("test");
  }
  return (<>



{loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <CommonTable
          columns={columns}
          allstock={allstock}
          rows={rowsWithIds}
          height={650}
        />
      )}
    
    
  </>)
}

export default StockOpportunity;