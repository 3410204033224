import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

function Transpotation(props) {
    const { myData } = props;

    let parsedData;
    try {
        parsedData = JSON.parse(myData.form_data);
    } catch (error) {
        console.error("Error parsing JSON data:", error);
    }

    const allValues =
        Number(parsedData.transpotation.vehicle_insurence) +
        Number(parsedData.transpotation.bus_train_taxi) +
        Number(parsedData.transpotation.fuel);

    props.sendDataTr(allValues);


    return (
        <div className="dhhejk">
            <div className="daildy_living">TRANSPORTATION</div>
            <Form>
                <div className="living_state">
                    <Form.Label className=" lable_css ">Vehicle Insurance</Form.Label>
                    <input
                        type="text"
                        className=" input_style jkha "
                        name="vehicle_insurence"
                        value={parsedData.transpotation.vehicle_insurence}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="adksk">Fuel</Form.Label>
                    <input
                        className="input_style jadj"
                        type="text"
                        name="fuel"
                        value={parsedData.transpotation.bus_train_taxi}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css">Bus/Taxi/Train/Airfare</Form.Label>
                    <input
                        className="input_style jkha"
                        type="text"
                        name="bus_train_taxi"
                        value={parsedData.transpotation.fuel}
                    />
                </div>
                <div className="living_state">
                    <Form.Label className="lable_css_">Total Amount</Form.Label>
                    <input
                        className="input_style_ jadj jkdju"
                        type="text"
                        name="totaleHousing"
                        value={allValues}
                    />
                </div>
            </Form>
        </div>
    );
}

export default Transpotation;
