import React from 'react';
import BankImg from '../assets/bank.png';
import './bankauth.css';

function BankAuth(props) {

  const {handleClose, handleSubmit, bank  } = props;

  if(bank === null)
    return null

  return (
    <div>
      <div className='container' style={{ paddingTop: "50px" }}>
        <div className='external_bank'>
          <div className='external_title'>
            <h6 className='external_tit6'> External bank account details</h6>
          </div>

          <div className="TbwUpd NJjxre iUh30 apx8Vc ojE3Fb">
            <span className="H9lube">
              <div className="eqA2re NjwKYd Vwoesf" aria-hidden="true">
                <img className="XNo5Ab" src={BankImg} alt="" data-atf="1" data-frt="0" />
              </div>
            </span>
            <div>
              <h5 className="VuuXrf">{bank.institution.name}</h5>
              <div className="byrV5b">
                <p className='apx8Vc tjvcx GvPZzd cHaqb'> Account : <span>**** **** **** {bank.account.mask}</span></p>
              </div>
            </div>
          </div>
          <hr size="30"></hr>
          <div className='external_account'>
            <p className='external_datap'>Bank account name</p>
            <p className='external_datap' style={{ marginTop: "0px" }}>{bank.account.name}</p>
          </div>

          <div className='external_data'>
            <p className='external_datap1'>ACH routing number</p>
            <p className='external_datap1' style={{ marginTop: "0px" }}>0123456</p>
          </div>
        </div>
      </div>
      <div className='container'>
        <h6 className=''> Email for Awesome tech Ltd.</h6>
        <input type="email" name='email' placeholder='Email address' className='external_mail' />
        <p className='external_eauth'>We will send you a copy of Direct Debit Authorization below to this email.</p>
      </div>
      <div className='container pd-top-10'>
        <p className='_auth_rize'>You authorize Airwallex US, LLC ("Airwallex"), on behalf of [AIRWALLEX PLATFORM CUSTOMER], and, if applicable, [AIRWALLEX PLATFORM CUSTOMER]’s affiliated entities ("Merchant"), to debit the bank account specified above for any amount owed for charges arising from your use of Merchant’s services and/or purchase of products from Merchant, pursuant to Merchant’s terms, and, if necessary, to electronically credit your bank account to correct erroneous debits, until this authorization is revoked.
          If you use Merchant’s services or purchase additional products periodically, pursuant to Merchant’s terms, you authorize Airwallex to debit your bank account periodically. Payments that fall outside of any regular debits authorized above will only be debited after your authorization is obtained.
          You may amend or cancel this authorization at any time by providing 30 (thirty) days' notice to Airwallex prior to the scheduled debit. You may provide such notice to Airwallex by contacting <b style={{color:"blue"}}>support@airwallex.com.</b></p>
      </div>
      <div className='container pd-top-10'>
        <div className='row'>
          <div className='col-6'></div>
          <div className='col-6 d-flex' style={{gap:"10px"}}>
          <button onClick={handleClose} className="back_button">Back</button>
          <button onClick={handleSubmit} className="confirm_button">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankAuth;


