import React from "react";

function Tooltip (props) {
    
    const {title, placement, children} = props

    return(
        <div className="tooltip-container">
            <div className="tooltip" data-placement={placement} >
                {title}
            </div>
            {children}
        </div>
    )
}

export default Tooltip