import React, { useState } from 'react';
import { SITE_URL } from '../../../api/auth';

function CashPdfModal(props) {

	const [showModal, setShowModal] = useState(false);
	const { list } = props;
	const openModal = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<div>
			<button type="button" className="btn btn-sm" onClick={openModal}>
				<i className="bi bi-file-earmark-text-fill"></i>
			</button>

			{showModal && (
				<div
					className="modal"
					tabIndex="-1"
					role="dialog"
					style={{ display: "block",background:"#111110a8" }}
				>
					<div className="modal-dialog" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
						<div className="modal-content"  data-backdrop="true">
							<div className="modal-header">
								<h5 className="modal-title">PDF</h5>
								<button
									type="button"
									className="close _newclose"
									onClick={closeModal}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<iframe
									title="PDF Viewer"
									src={`${SITE_URL}/${list.pdf}`}
									width="100%"
									height="500px"
								></iframe>
							</div>
							<div className="modal-footer">
								<button
									className="commonmodalbutton"
									onClick={closeModal}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default CashPdfModal;
