import * as React from 'react';
import Table from 'react-bootstrap/Table';

import './Investment.css'
import { useEffect, useState } from 'react';


export default function DenseTable({ getSelectedstock, selectStock, inputValue, sharesPerStock,handleCloseB, handleRemoveStock}) {

  const [totalValue, setTotalValue] = useState(0);

  useEffect(() => {
    let sum = 0;
    if (Array.isArray(getSelectedstock)) {
      getSelectedstock.forEach((row) => {
        const stockPrice = row.regularMarketPrice.raw;
        const shares = sharesPerStock[row.symbol] || 0;
        sum += stockPrice * shares;
      });
    }
    const multipledata = selectStock && selectStock.regularMarketPrice && selectStock.regularMarketPrice.raw
    ? Number(selectStock.regularMarketPrice.raw) * Number(inputValue)
    : 0;
    sum += multipledata;
    setTotalValue(sum);
  }, [getSelectedstock, selectStock, inputValue, sharesPerStock]);
  
  const multipledata = Number(selectStock && selectStock.regularMarketPrice.raw )*Number(inputValue);
  
  return (
  <>
  <div className='investment'>
    <h1 className='guigig'>${totalValue.toFixed(2)}  </h1>
        <div className='investment_heading'>
            <div>
                <p className='jhuj'> 1 DAY </p>
                <span className='investment_amount'>$0</span>
            </div>
            <div className='investment_con'>
                <p className='jhuj'> 1 WEEK </p>
                <span className='investment_amount'>$0</span>
            </div>
        </div>
  </div>
  
        <Table responsive bordered striped>
        <thead className='theadct'>
          <tr>
            <th className='uguiguigui'>ASSET</th>
            <th className='uguiguigui' align="right">IRR</th>
            <th className='uguiguigui' align="right">VALUE</th>
            <th className='uguiguigui' align="right">Menu</th>
            <th className='uguiguigui' align="right">More</th>
            <th className='uguiguigui' align="right">Close</th>
          </tr>
        </thead>
        <tbody className='tbodyct'>
          {Array.isArray(getSelectedstock) && getSelectedstock.map((row, index) => {
            let totalValue = row.regularMarketPrice.raw;

            if (sharesPerStock && sharesPerStock[row.symbol]) {
              totalValue *= sharesPerStock[row.symbol]; // Multiply by the shares for this symbol
            }
            return (
              <tr key={index}>
                <td >{row.symbol}</td>
                <td align="right">{totalValue.toFixed(2)}</td>
                <td align="right">&nbsp;${totalValue.toFixed(2)}</td>
                <td align="right"><i class="bi bi-list"></i></td>
                <td align="right"><i class="bi bi-three-dots-vertical"></i></td>
                <td align="right"><i style={{cursor:"pointer"}}
                 onClick={() => handleRemoveStock(row.symbol)} class="bi bi-x"></i></td>
              </tr>
            )
          })}
          {selectStock && (
            <tr>
              <td >{selectStock && selectStock.symbol || ""}</td>
              <td align="right">{parseFloat(multipledata).toFixed(2) || ""}</td>
              <td align="right">&nbsp;${parseFloat(multipledata).toFixed(2) || ""}</td>
              <td align="right"><i class="bi bi-list"></i></td>
              <td align="right"><i class="bi bi-three-dots-vertical"></i></td>
              <td align="right"><i style={{cursor:"pointer"}} onClick={handleCloseB} class="bi bi-x"></i></td>
            </tr>
          )}
        </tbody>
      </Table>
    </> );
}