import React from 'react'
import Section from './layouts/Section';

function UploadDocuments() {

    const styles = {
        fontSize:"16px",
        fontWeight:"600",
        color:"#111110"
    }
    const btn_save = {
        background:"rgb(206, 164, 73)",
        color:"#fff",
        fontSize:"20px",
        fontWeight:"600"
    }

    return (
        <div>
            <Section allNotification={false} title={'Upload Documents'}>
                <div className="container" style={{ marginTop: "4.5rem" }}>
                    <form>
                        <div className="mb-4">
                            <label style={styles} for="formFile" className="form-label">Upload Bank Statement :-</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>
                        <div className="mb-4">
                            <label style={styles} for="formFile" className="form-label">Upload Brokerage Statement :-</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>
                        <div className="mb-4">
                            <label style={styles} for="formFile" className="form-label">Upload Investment Statement :-</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>
                        <div className="mb-4">
                            <label style={styles} for="formFile" className="form-label">Upload Tax Statement :-</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>
                        <div className="mb-4">
                            <label style={styles} for="formFile" className="form-label">Upload Will Statement :-</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>
                        <div className="mb-4">
                            <label style={styles} for="formFile" className="form-label">Upload Trust Statement :-</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>
                        <div className="mb-4">
                            <label style={styles} for="formFile" className="form-label">Upload Medical Statement :-</label>
                            <input className="form-control" type="file" id="formFile" />
                        </div>
                        <button type="submit" className="btn" style={btn_save}>Save</button>
                    </form>
                </div>
                <div style={{height:"15px"}}></div>
            </Section>
        </div>
    )
}

export default UploadDocuments;
