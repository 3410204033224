import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function AnnualIncomeDetail(props) {
	const { post } = props;

	const [income, setIncome] = useState({
		series: [
			{
				name: "Income",
				data: Object.values(post).map((item) => item.income),
			},
		],
		options: {
			chart: {
				type: "bar",
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "55%",
					endingShape: "rounded",
				},
			},
			colors: ["#8884d8", "#82ca9d"],
			markers: {
				size: 8,
			},
			legend: {
				position: "top",
			},
			xaxis: {
				categories: [
					"JAN",
					"FEB",
					"MAR",
					"APR",
					"MAY",
					"JUN",
					"JUL",
					"AUG",
					"SEP",
					"OCT",
					"NOV",
					"DEC",
				],
			},
			yaxis: {
				title: {
					data: Object.values(post).map((item) => item.income),
				},
			},
			fill: {
				opacity: 1,
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " ";
					},
				},
			},
		},
	});

	return (
		<div>
            <h5>Annual Income Details</h5>
            <hr/>
			<ReactApexChart
				options={income.options}
				series={income.series}
				type="bar"
				height={200}
			/>
		</div>
	);
}

export default AnnualIncomeDetail;
