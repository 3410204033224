import React from "react";
import { countries } from "../../data/country";

function CountrySelectBox(props) {
  return (
    <select {...props} className="form-control" data-live-search="true">
      <option value="">Select Country</option>
      {countries.map((country, i) => (
        <option key={i} value={country.code}>{country.name}</option>
      ))}
    </select>
  );
}

export default CountrySelectBox
