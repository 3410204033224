/*--------------------------LOADER---------------------------------*/
export const LOADER = 'LOADER'
export const LOADER_AUTH = 'LOADER_AUTH'
export const LOADER_PAYMENT = 'LOADER_PAYMENT'
export const LOADER_BILL = 'LOADER_BILL'
export const LOADER_PROFILE = 'LOADER_PROFILE'

/*--------------------------OTHER---------------------------------*/
export const USER_PROFILE = 'USER_PROFILE';
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const OTP = 'OTP'
export const OTP_ERROR = 'OTP_ERROR'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const PROFILE_FETCH = 'PROFILE_FETCH'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const INITIATE_PAYMENT = 'INITIATE_PAYMENT'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const FETCH_PAYMENT = 'FETCH_PAYMENT'
export const FETCH_BILLS = 'FETCH_BILLS'
export const BILLS_ERROR = 'BILLS_ERROR'
export const SAVE_BILLS = 'SAVE_BILLS'
export const UPLOAD_BILLS = 'UPLOAD_BILLS'

export const FETCH_CHATS = 'FETCH_CHATS'
export const CHATS_ERROR = 'CHATS_ERROR'
export const VENDORLIST_ERROR = 'VENDORLIST_ERROR'
export const FETCH_VENDORLIST = 'FETCH_VENDORLIST'
export const LOADER_VENDORLIST = 'LOADER_VENDORLIST'
export const LOADER_CARDDETAILOPT = 'LOADER_CARDDETAILOPT'
export const FETCH_CARDDETAILOPT = 'FETCH_CARDDETAILOPT'
export const ERROR_CARDDETAILOPT = 'ERROR_CARDDETAILOPT'

export const AVATAR_UPDATE = 'AVATAR_UPDATE'
export const AVATAR_UPDATE_ERROR = 'AVATAR_UPDATE_ERROR'

export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS'
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR'

export const GET_MEETING_LIST = 'GET_MEETING_LIST'
export const GET_MEETING_LIST_ERROR = 'GET_MEETING_LIST_ERROR'

export const PASSWORD_CHANGE = 'PASSWORD_CHANGE'
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR'

export const GET_GALLERY = 'GET_GALLERY'
export const GET_GALLERY_SUCCESS = 'GET_GALLERY_SUCCESS'
export const GET_GALLERY_ERROR = 'GET_GALLERY_ERROR'

export const TOGGLE_THEME = 'TOGGLE_THEME'